import '../scss/section.scss';

'use strict';

//console.log("section clientlib");

var _sections = document.querySelectorAll(".cmp-section__wrapper");

function setBackground(el) {	
    var _el = el;
    var _width = window.innerWidth; 
    var _desktopBackground = _el.dataset.desktopBg || '';
	var _tabletBackground = _el.dataset.tabletBg || ''; 
	var _mobileBackground = _el.dataset.mobileBg || '';

    //console.log("_el", _el);        
    //console.log("_width:", _width);  

    if (_width > 1024 && _desktopBackground != '') {
        _el.style.backgroundImage = "url(" + _desktopBackground + ")";
    }

    if (_width < 1024 && _tabletBackground != '') {
        _el.style.backgroundImage = "url(" + _tabletBackground + ")";
    }

    if (_width < 768 && _mobileBackground != '') {
        _el.style.backgroundImage = "url(" + _mobileBackground + ")";
    }
}

function resizeBackground() {
	for (var i=0; i<_sections.length; i++) {
    	setBackground(_sections[i]);
	}
}

if (_sections.length) {
	resizeBackground(); 
	window.addEventListener("resize", resizeBackground);
}