import '../scss/tire-decision-guide.scss';
import { getUserLocation, searchUserLocation } from "./location-search-tdg.js";
import { FeatureSwitches } from '../../../client-store/v1/js/profile/utils/featureFlag/feature-switches.js';
import { urlLanguageCheck } from '../../../../../js/utils/urlLanguageCheck.js';
import initParams from './params.js';
import queryString from 'query-string';
import getBestMatches from './best-matches.js';
import '../../../tire-search-results/tire-card-grid/v1/js/tire-card-grid.js';
import '../../../tire-comparison/v1/js/tire-comparison.js';

if (!window.BATO) window.BATO = {};
if (!window.BATO.tireSearchResults) window.BATO.tireSearchResults = {};
if (!window.BATO.tireComparison) window.BATO.tireComparison = {};
if (!window.BATO.storeLocator) window.BATO.storeLocator = {};
window.BATO.tireComparison.maxSelected = false;


let currentPage = 0;
let totalPages = 8;
let thePrevBtn = document.querySelector('.prevBtn');
let theNextBtn = document.querySelector('.nextBtn');
let searchMethod = "";
let skipToResultsClicked = false;
let valuesArray = [];
let selectedTopFeatures = [];
let checkedInputs = null;
let finalResults = [];
let whyNeedTiresArray = [];
let tiresTypeArray = [];
let vehicleOrSizeSelectedArray = [];
let languageInUrl = urlLanguageCheck() ? urlLanguageCheck() : '';

const url = new URL(window.location.href);
let selectedLimit = 0;
let tdg_params = [];
const stepCheckboxes = document.querySelectorAll('input[type="checkbox"]');
const emailInput = document.querySelector('#tdg-email');
const emailButton = document.querySelector('#tdg-email-button');
const statusEmpty = `<svg width="232" height="9" viewBox="0 0 232 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M14.9238 0.750732H4.61292L0.488281 9.00002H14.9238V0.750732Z" fill="#CCCCCC"/><rect x="14.9219" y="0.750732" width="203.227" height="8.24928" fill="#CCCCCC"/><path fill-rule="evenodd" clip-rule="evenodd" d="M218.148 9.00002H227.866L231.991 0.750732H218.148V9.00002Z" fill="#CCCCCC"/></svg>`;
const statusFull = `<svg width="232" height="9" viewBox="0 0 232 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M14.9238 0.750732H4.61292L0.488281 9.00002H14.9238V0.750732Z" fill="#E42300"/><rect x="14.9219" y="0.750732" width="203.227" height="8.24928" fill="#E42300"/><path fill-rule="evenodd" clip-rule="evenodd" d="M218.148 9.00002H227.866L231.991 0.750732H218.148V9.00002Z" fill="#E42300"/></svg>`;
const searchForTiresContainer = document.querySelectorAll('.search-for-tires-container');
const tireDecisionGuide = document.querySelector('.tire-decision-guide');
const tdgFormContainer = document.querySelector('.tdg-form__container');
const vehicleState = window.BATO?.Store?.getState()?.vehicles;
const vehicleTireBySize = window.BATO?.Store?.getState()?.tiresBySize;
const vehicleSelectorBtn = document.querySelector('.selected-info.editVehicleSelectorBtn');
const vehicleInfoContainer = document.getElementById("vehicle-info-container");
const tdgLinks = document.querySelectorAll('.tire-decision-guide__steps [data-page="7"] a[href~="javascript:void(0)"]');
const tireCardsContainer = document.getElementById("tdg-matches__container");
const tireCardTemplate = document.getElementById("tire-card-template");
const editVehicleButton = document.querySelector('.editVehicleSelectorBtn');
const tdgAllResultsButton = document.querySelector('.tdg-results-all');
let findTireSizeContent = "";
let learnMoreContent = "";

if (tireDecisionGuide !== null) { 
  let element = window.BATO.CONFIG.lang_iso == "es_US" ? document.querySelector('#tdg-find-tire-size-cf-es') : document.querySelector('#tdg-find-tire-size-cf-en'); 
  if (element){
    if (window.BATO.CONFIG.lang_iso == "es_US"){
      findTireSizeContent = document.querySelector('#tdg-find-tire-size-cf-es .cmp-contentfragment__elements')?.innerHTML;
      learnMoreContent = document.querySelector('#tdg-learn-more-cf-es .cmp-contentfragment__elements')?.innerHTML;

    } else {
      findTireSizeContent = document.querySelector('#tdg-find-tire-size-cf-en .cmp-contentfragment__elements')?.innerHTML;
      learnMoreContent = document.querySelector('#tdg-learn-more-cf-en .cmp-contentfragment__elements')?.innerHTML;
    }

  } 
  // else {
  //   console.log(">>>> TDG CF's DO NOT Exist");
  // }
}


// console.log(window.BATO.tireComparison, 'window.BATO.tireComparison');
// console.log('window.BATO.tireComparison.loadTireComparisonCards', window.BATO.tireComparison.loadTireComparisonCards);
// console.log('vehicleTireBySize' ,vehicleTireBySize);
// console.log('vehicleState', vehicleState);

const setDisabledAttr = () => {
  const step = sessionStorage.getItem(currentPage.toString());
  theNextBtn.setAttribute('disabled', true);
  if ((step !== null && JSON.parse(step).length !== 0)) { //currentPage === 3 || (step !== null && JSON.parse(step).length !== 0)
    theNextBtn.removeAttribute('disabled');
  }
}

const prevFunct = () => {
  if (currentPage > 0) {
    document.querySelector('[data-page="' + currentPage + '"]').classList.remove('active');
    currentPage--;
    document.querySelector('[data-page="' + currentPage + '"]').classList.add('active');
    setDisabledAttr();
  }
  makePageChanges(currentPage);
}

const nextFunct = () => {
  if (currentPage == 3){
    adobeDataLayer.push({"event":"dg.location_search", 
      "search_method":searchMethod
    
    }); 
  }

  if (currentPage < totalPages) {
    document.querySelector('[data-page="' + currentPage + '"]').classList.remove('active');
    currentPage++;
    document.querySelector('[data-page="' + currentPage + '"]').classList.add('active');
  }
  setDisabledAttr();
  makePageChanges(currentPage);
}

const makePageChanges = (page) => {
  updateStatus();
  switch (page) {
    case 0:
      console.log('PAGE 0');
      thePrevBtn.classList.add('hide');
      theNextBtn.classList.add('hide');
      document.querySelector('.getStartedBtn').classList.remove('hide');
      break;
    case 1:
      console.log('PAGE 1');
      adobeDataLayer.push({"event":"dg.step_loaded", 
        "step_name":"tire_needs",
      });  
      selectedLimit = 0;
      break;
    case 2:
      document.querySelector('.tire-decision-guide__footer').classList.remove('hide');

      // Prep and make call for Cardinal Path
      checkedInputs = document.querySelectorAll(
        '.tire-decision-guide__page[data-page="1"] .tire-decision-guide__container input[type="checkbox"]:checked'
      );
      
      whyNeedTiresArray = [];

      checkedInputs.forEach(input => {
        whyNeedTiresArray.push(input.dataset.label);
      });
      adobeDataLayer.push({"event":"dg.step_loaded", 
        "step_name":"select_vehicle",
        "decision_guide":{
          "tire_reason":whyNeedTiresArray //report selection from previous step
        }
      });

      selectedLimit = 0;
      break;
    case 3:
      console.log('PAGE 3');


      function validateZipCode(zip) {
        // Regular expression for zip code in formats: xxxxx or xxxxx-xxxx
        const zipCodePattern = /^\d{5}(-\d{4})?$/;
        return zipCodePattern.test(zip);
      }

      // Get the input elements
      const zipCodeInput = document.querySelector('.location-tdg');
      const zipCodeInputDefaultValue = zipCodeInput.value;
      let invalid = document.querySelector('.location-search-tdg .invalid');

      validateZipCode(zipCodeInputDefaultValue) ? theNextBtn.removeAttribute("disabled") : null;

      const tdgSearchButton = document.querySelector('.location-search-tdg .button');

      // Add event listener for input change
      zipCodeInput.addEventListener('input', (event) => {
          const currentValue = zipCodeInput.value;
          if (validateZipCode(currentValue)) {
            searchMethod = "default";
            if (currentValue.length === 5 || currentValue.length === 10) {
              searchUserLocation(event);
              tdgSearchButton.classList.add('location-loading');
            }
          } else {
            theNextBtn.setAttribute('disabled', true);
            if (currentValue === "") {
              document.querySelectorAll('.location-search-tdg p.error').forEach((elem) => elem.classList.remove('show-error'))
              tdgSearchButton.classList.remove('location-loading');
            } else {
              if (currentValue.length === 5 || currentValue.length === 10) {
                invalid.classList.add('show-error');
              }
            }
            
          }
      });

      // Add event listener for input focus
      zipCodeInput.addEventListener('focus', function() {
        tdgSearchButton.classList.remove('location-loading');
        if (zipCodeInput.value === zipCodeInputDefaultValue) {
          zipCodeInput.value = '';
        }
      });

      // Add event listener for input focus
      zipCodeInput.addEventListener('blur', function() {
        tdgSearchButton.classList.remove('location-loading');
        document.querySelectorAll('.location-search-tdg p.error').forEach((elem) => {
          if (elem.classList.contains('show-error')) {
            theNextBtn.setAttribute('disabled', true);
          }
        })
        if (!validateZipCode(zipCodeInput.value)) {
          theNextBtn.setAttribute('disabled', true);
        }
      });

      let tdg_params = JSON.parse(sessionStorage.getItem('tdg_params'));
      if (tdg_params) {
        let page2Data = tdg_params.find(item => item.page === "2");

        if (page2Data) {
          let values = page2Data.values;
          if (values.length == 4){
            adobeDataLayer.push({"event":"dg.step_loaded", 
              "step_name":"location",
              "vehicle": {  // if vehicle details submitted
                  "make":values[1],
                  "model":values[2],
                  "trim":values[3],
                  "year":values[0]
                }
            }); 
            vehicleOrSizeSelectedArray = {
              "make":values[1],
              "model":values[2],
              "trim":values[3],
              "year":values[0]
            }
          } else if (values.length == 3){
            adobeDataLayer.push({"event":"dg.step_loaded", 
              "step_name":"location",
              "size":{  // if size details submitted
               "width":values[0],
               "aspect_ratio":values[1],
               "diameter":values[2]
            }
            }); 
            vehicleOrSizeSelectedArray = {
              "width":values[0],
              "aspect_ratio":values[1],
              "diameter":values[2]
            }
          }
        } else {
          console.log("Page 2 not found in tdg_params");
        }
      } else {
        console.log("Page 2 tdg_params is not found in session storage");
      }

      selectedLimit = 0;  
      break;
    case 4:
      console.log('PAGE 4');
      adobeDataLayer.push({"event":"dg.step_loaded", 
        "step_name":"tire_type",
      });       
      break;
    case 5:
      console.log('PAGE 5');

      // Prep and make call for Cardinal Path
      checkedInputs = document.querySelectorAll(
        '.tire-decision-guide__page[data-page="4"] .tire-decision-guide__container input[type="radio"]:checked'
      );
      tiresTypeArray = [];

      checkedInputs.forEach(input => {
        tiresTypeArray.push(input.dataset.label);
      });
      adobeDataLayer.push({"event":"dg.step_loaded", 
        "step_name":"driving_style",
        "decision_guide":{
          "tire_type":tiresTypeArray //selected in previous step
        }
      });
      selectedLimit = 0;     
      break;
    case 6:
      console.log('PAGE 6');
      // Prep and make call for Cardinal Path
      checkedInputs = document.querySelectorAll(
        '.tire-decision-guide__page[data-page="5"] .tire-decision-guide__container input[type="radio"]:checked'
      );
      valuesArray = [];

      checkedInputs.forEach(input => {
        valuesArray.push(input.dataset.label);
      });

      adobeDataLayer.push({"event":"dg.step_loaded", 
        "step_name":"top_features",
        "decision_guide":{
          "driving_style":valuesArray //selected in previous step
        }
      });
      selectedLimit = 3;      
      break;
    case 7:
      document.querySelector('.tire-decision-guide__footer').classList.add('hide');
      console.log('PAGE 7');

      // Prep and make call for Cardinal Path
      checkedInputs = document.querySelectorAll(
        '.tire-decision-guide__page[data-page="6"] .tire-decision-guide__container input[type="checkbox"]:checked'
      );
      selectedTopFeatures = [];

      checkedInputs.forEach(input => {
        selectedTopFeatures.push(input.dataset.label);
      });

      updateVehicleInfoContainer();
      handleSearchTires();
      selectedLimit = 0;
      break;
    default:
      //code
  }
}
// console.log('searchForTiresContainer:', searchForTiresContainer);
// console.log('tireDecisionGuide:', tireDecisionGuide);
window.onbeforeunload = function() {
  // console.log("LEAVING TDG");
  searchForTiresContainer.forEach((element) => {
    element.style.display = 'unset';
  });
  // console.log("searchForTiresContainer visibility set to: block");
};

const initPage = () => {
  if (new URLSearchParams(url.search).has("1") === false) {
    document.querySelector('[data-page="0"]').classList.add('active'); //0
    sessionStorage.clear();
  }
  searchForTiresContainer.forEach((element) => {
    element.style.display = 'none';
  });
  // console.log("searchForTiresContainer visibility set to: none");

  // Add text copy to each input, used for CP
  let containers = document.querySelectorAll('.tire-decision-guide__container');

  containers.forEach(container => {
    let inputs = container.querySelectorAll('input');

    inputs.forEach(input => {
      let label = input.nextElementSibling;

      if (label && label.querySelector('.tire-decision-guide__p')) {
        let pElement = label.querySelector('.tire-decision-guide__p');
        let labelText = pElement.textContent.trim();
        input.setAttribute('data-label', labelText);
      }
    });
  });


if (tireDecisionGuide !== null) { 
  // Substitute Bridgestone for Firestone on FST site
  const currentURL = window.location.href;
  if (currentURL.includes("firestonetire")) {
      const saveShareMsg = document.querySelector('.tdg-form__subheader');
      saveShareMsg.innerHTML = saveShareMsg.innerHTML.replace('Bridgestone', 'Firestone');        
  }
}

  makePageChanges(currentPage);
}

const updateStatus = () => {
  let statusDiv = document.querySelector('.tire-decision-guide__footer--progress');
  let tempContent = "";
  for (let i = 0; i < totalPages-2; i++) {
    currentPage <= i ? tempContent +=  statusEmpty : tempContent +=  statusFull;
  }
  statusDiv.innerHTML = tempContent;
  updateSession();
}

// Click handler for entire TDG container, can't use traditional event listeners since content
// is loaded via sly include and not ready when js is loaded
if (tireDecisionGuide !== null) { 
  tireDecisionGuide.addEventListener('click', function (e) {
    const f = e.target.classList;
    if (f.contains('getStartedBtn')) {
      let buttonElement = document.querySelector('.getStartedBtn').textContent;
      adobeDataLayer.push({"event":"component_click", 
        "component_detail":{
          "type":"cta", 
              "title":buttonElement
        }
      });
      document.querySelector('.getStartedBtn').classList.add('hide');
      thePrevBtn.classList.remove('hide');
      theNextBtn.classList.remove('hide');
      nextFunct();
    } else if (f.contains('findTireSizeBtn')) {
      window.BATO.modal.activateModal(findTireSizeContent, true, null, true, 'modal');
    } else if (f.contains('learnMoreBtn')) {
      window.BATO.modal.activateModal(learnMoreContent, true, null, true, 'modal');
    } else if (f.contains('skipToResultsBtn')) {
      skipToResultsClicked = true;
      theNextBtn.removeAttribute("disabled");
      theNextBtn.click();
    } else if (f.contains('option')) {
      const changeEvent = new Event('change');
      document.dispatchEvent(changeEvent);
    } else if (f.contains('prevBtn')) {
      prevFunct();
    } else if (f.contains('nextBtn')) {
      nextFunct();
    } else if (f.contains('use-current')) {
      searchMethod = "use current location";
      getUserLocation();
    }
  });

  emailInput.addEventListener('input', (e) => {

    const regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+(\.[a-zA-Z]{2,6}){1,2}$/;

    if (e.target.value.match(regexEmail)) {
      emailButton.removeAttribute('disabled');
    } else {
      emailButton.setAttribute('disabled', true);
    }
  })
}

// FeatureSwitches
document.addEventListener("DOMContentLoaded", () => {
  const featureSwitches = new FeatureSwitches();
  featureSwitches.create('tdg-flag', true).remote(); //set to false if feature flag is required
  // console.log("'tire-decision-guide' feature flag created and remote setup initialized");
  featureSwitches.initialize();

  if (tireDecisionGuide !== null) {
    console.log("'tdg-flag' enabled");
    initPage();
  }
});

const updateVehicleInfoContainer = (disabled = true) => {

  // remove previous vehicle info content
  vehicleInfoContainer.textContent = "";

  // Create a div element to render the vehicle info
  const vehicleInfoDiv = document.createElement("div");

  // Set the content with the vehicle details
  let vehicleInfo = '';

  if (sessionStorage.getItem('2') !== null) {
    vehicleInfo = JSON.parse(sessionStorage.getItem(2)).join(" ");
  } else {
    vehicleInfo = decodeURIComponent(tdgQueryString[2].join(" "));
  }

  if (disabled === false) {
    if (vehicleSelectorBtn && sessionStorage.getItem("1") === null) {
      vehicleSelectorBtn.classList.add('disabled');
      vehicleSelectorBtn.style.pointerEvents = 'none'; // Makes it unclickable

      // Find and remove the SVG element
      const svgIcon = vehicleSelectorBtn.querySelector('svg');
      if (svgIcon) {
        svgIcon.remove();
      }
    }
  }

  vehicleInfoDiv.innerHTML = vehicleInfo;
  // Optionally, add a class for styling (e.g., 'vehicle-info')
  vehicleInfoDiv.classList.add("vehicle-info");

  // Append the vehicle info div to the container
  vehicleInfoContainer.appendChild(vehicleInfoDiv);
};

const handleSearchParams = () => {
  const tdgQueryString = queryString.parse(window.location.search);
  
  if (tdgQueryString && new URLSearchParams(url.search).has("1")) {
    // remove tdg-flag param from url
    delete tdgQueryString['tdg-flag'];
    // hide all steps and show last step
    document.querySelectorAll('[data-page]').forEach(dp => {
      dp.classList.remove('active')
    });
    Array.from(document.querySelectorAll('[data-page]')).slice(-1)[0].classList.add('active');
    document.querySelector('.tire-decision-guide__steps:has([data-page="7"]) + .tire-decision-guide__footer').classList.add('hide');
    // populate tdg_params based on query-string results
    for (const key in tdgQueryString) {
      tdg_params.push({
        page: key,
        values: Array.from(tdgQueryString[key])
      })
    }
    sessionStorage.setItem("tdg_params", JSON.stringify(tdg_params));  
  }
}

export const updateStepValue = (selectedValues) => {
  sessionStorage.setItem(currentPage.toString(), JSON.stringify(selectedValues));
}

document.addEventListener('change', (event) => {
  let selected = [];
  let input = null;
  let vehicleOrSizeArray = [];
  
  switch (event.target.type) {
      case "checkbox":
          input = document.querySelectorAll(`[data-page='${currentPage}'] input[type="checkbox"]:checked`);
          break;
      case "radio":
          input = document.querySelectorAll(`[data-page='${currentPage}'] input[type="radio"]:checked`);
          break;
      case "text":
          input = document.querySelectorAll(`[data-page='${currentPage}'] input[type="text"]`);
          break;
      default:
          input = null;

      // refactor activeTabNum - sks
      // I don't like basing this value off of a character in the id name
      const activeTabNum = document.querySelector('.cmp-tabs__tab--active').getAttribute('aria-controls').split('1').length

      // check if object has a null value
      const isEmpty = (object) => Object.values(object).some(x => x === null || x === "");

      let vehicleObject = {};
      // console.log("Initial vehicleObject:", vehicleObject);
      
      if (activeTabNum > 1) {
        vehicleObject = window.BATO.Store.getState().vehicles.vehicle;
        // console.log("Fetched vehicleObject from store:", vehicleObject);
      
        if (vehicleObject && isEmpty(vehicleObject) === false) {
          vehicleOrSizeArray = [vehicleObject.year, vehicleObject.make, vehicleObject.model, vehicleObject.trim];
          // console.log("Updated vehicleOrSizeArray with vehicle data:", vehicleOrSizeArray);
        } else {
          console.log("vehicleObject is empty or undefined.");
        }
      }
      
      let tiresBySizeObject = {};
      // console.log("Initial tiresBySizeObject:", tiresBySizeObject);
      
      if (activeTabNum === 1) {
        tiresBySizeObject = window.BATO.Store.getState().tiresBySize.tireBySize;
        // console.log("Fetched tiresBySizeObject from store:", tiresBySizeObject);
      
        if (tiresBySizeObject && tiresBySizeObject.front.tireSize !== "") {
          vehicleOrSizeArray = [tiresBySizeObject.front.cs, tiresBySizeObject.front.ar, tiresBySizeObject.front.rs];
          // console.log("Updated vehicleOrSizeArray with tire size data:", vehicleOrSizeArray);
        } else {
          console.log("tiresBySizeObject is empty or does not have a valid front tire size.");
        }
      }
      

        break;
  }

  if (input !== null) {
    selected = Array.from(input).map(x => x.value);
    updateStepValue(selected);
    setDisabledAttr();

    if (selectedLimit !== 0) {
      checkInputLimits();
    } 
  } else {
    selected = vehicleOrSizeArray.map(x => x);

    const isSearchByVehicle = selected?.length === 4;
    const isSearchByTireSize = selected?.length === 3;

    if ((isSearchByVehicle === true && selected?.length === 4) || (isSearchByTireSize === true && selected?.length === 3)) {
      if (document.querySelectorAll(`.cmp-tabs__tabpanel--active .option.active`).length < selected?.length) {
        theNextBtn.setAttribute('disabled', true);
      } else {
        theNextBtn.removeAttribute('disabled');
      }
    }
    updateStepValue(selected);
  }
});

const checkInputLimits = () => {
  const step = sessionStorage.getItem(currentPage.toString());
  const stepArray = JSON.parse(step);

  if (stepArray.length >= selectedLimit) {
    setDisabledAttr();
    stepCheckboxes.forEach(cb => {
        if (!cb.checked) {
          cb.disabled = true
        };
    })
  } else {
    stepCheckboxes.forEach(cb => {
        cb.disabled = false;
    })
  }
}

export const updateParams = (selectedValues) => {

  if (currentPage === 0 || JSON.parse(selectedValues) === null) return;

  tdg_params.push({
      page: (currentPage - 1).toString(),
      values: JSON.parse(selectedValues)
  });

  // ensure tdg_params does not have duplicate key:value pairs in sessionStorage
  tdg_params = [...new Map(tdg_params.map(v => [v.page, v])).values()];
  sessionStorage.setItem("tdg_params", JSON.stringify(tdg_params));
}


const updateSession = () => {
  let newURL = "";
  const tdgURL = window.location.href.replace('#', '');

  updateParams(sessionStorage.getItem((currentPage - 1 ).toString()))
  
  // add searchParams to URL on the last step
  if (currentPage === totalPages - 1) {
    const tdgParams = initParams(JSON.parse(sessionStorage.getItem("tdg_params")))      
    const searchParams = new URLSearchParams(tdgParams);

    // determine if URL already has params
    // check for `tdg-flag` to format newURL correctly 
    if (url.search.includes('?')) {
      newURL = `${tdgURL}&${searchParams}`;
    } else {
      newURL = `${tdgURL}?${searchParams}`;
    }
  } else {
    // remove params when using back button
    newURL = `${tdgURL}`;
  }
  // edit location.history to "route" to URL with params
  window.history.pushState({}, '', newURL); 
}


// handle email sending
const successMessage = document.getElementById('successMessage');
const emailPlaceholder = document.getElementById('emailPlaceholder');
const changeEmailButton = document.getElementById('changeEmailButton');
const tryAgainButton = document.querySelector('.no-results-reset');
const tdgSubheader = document.querySelector('.tdg-form__subheader'); // Select the subheader by class

const isMobileScreen = () => window.innerWidth <= 768; // Helper function to check screen width

const fetchSendEmail = async (email) => {
  const url = "/brgtrestwebservices/contact/lead";
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        "params": {
          "country": window.BATO.CONFIG.country,
          "lang_iso": window.BATO.CONFIG.lang_iso,
          "site": window.BATO.CONFIG.site,
          "id": "tdg-link"
        },
        "customer": {
        },
        "report": {
            "to-email": email,
            "url": window.location.href
        }
      }),
    });

    if (response.ok) {
      emailPlaceholder.textContent = email;
      successMessage.style.display = 'block';
      emailInput.style.display = 'none';
      emailButton.style.display = 'none';

      // Only hide the subheader on mobile screens
      if (isMobileScreen()) {
        tdgSubheader.style.display = 'none';
      }
      
      // console.log("Email sent successfully.");
    } else {
      throw new Error(`Response status: ${response.status}`);
    }

    const json = await response.json();
    console.log(json);
  } catch (error) {
    console.error(error.message);
  }
};


emailButton?.addEventListener('click', (e) => {
  e.preventDefault();
  adobeDataLayer.push({"event":"dg.email_results"}); 
  fetchSendEmail(emailInput.value);
});

// Event listener for the Change Email button
changeEmailButton?.addEventListener('click', () => {
  // Reset form visibility on "Change Email" button click
  successMessage.style.display = 'none';
  emailInput.style.display = 'block';
  emailButton.style.display = 'inline';

  // Show the subheader again on mobile if it was hidden
  if (isMobileScreen()) {
    tdgSubheader.style.display = 'block';
  }
});

editVehicleButton?.addEventListener('click', (e) => {
  document.querySelector('.getStartedBtn').classList.add('hide');
  thePrevBtn.classList.remove('hide');
  theNextBtn.classList.remove('hide');
  adobeDataLayer.push({ event: 'dg.edit_vehicle' });
  const inputField = document.querySelector('.location-tdg');
  inputField.value = JSON.parse(sessionStorage.getItem('3'))[0];
  let invalid = document.querySelector('.location-search-tdg .invalid');
  invalid.classList.remove('show-error');
  setCheckedRadioButtons();
  const isSearchByTireSize = sessionStorage.getItem("searchByTireSize");
  const searchByVehicleTab = document.querySelector('.cmp-tabs__tab[data-cmp-hook-tabs="tab"]:nth-child(1)');
  const searchByTireSizeTab = document.querySelector('.cmp-tabs__tab[data-cmp-hook-tabs="tab"]:nth-child(2)');
  const vehicleSelectorTabPanel1 = document.getElementById('tabs-vehicle-selector-1-tabpanel');
  const vehicleSelectorTabPanel2 = document.getElementById('tabs-vehicle-selector-2-tabpanel');

  if (isSearchByTireSize) {
    searchByTireSizeTab.classList.add('cmp-tabs__tab--active');
    searchByTireSizeTab.setAttribute('aria-selected', 'true');
    searchByVehicleTab.classList.remove('cmp-tabs__tab--active');
    searchByVehicleTab.setAttribute('aria-selected', 'false');
    vehicleSelectorTabPanel2.classList.add('cmp-tabs__tabpanel--active');
    vehicleSelectorTabPanel1.classList.remove('cmp-tabs__tabpanel--active');
  } else {
    searchByVehicleTab.classList.add('cmp-tabs__tab--active');
    searchByVehicleTab.setAttribute('aria-selected', 'true');
    searchByTireSizeTab.classList.remove('cmp-tabs__tab--active');
    searchByTireSizeTab.setAttribute('aria-selected', 'false');
    vehicleSelectorTabPanel2.classList.remove('cmp-tabs__tabpanel--active');
    vehicleSelectorTabPanel1.classList.add('cmp-tabs__tabpanel--active');

  }
});

function setCheckedRadioButtons() {
  const keyToGroupMap = {
    4: 'tire-type',
    5: 'driving-style',
    6: 'top-features',
  };

  Object.keys(keyToGroupMap).forEach((key) => {
    const groupName = keyToGroupMap[key];
    const storedValues = JSON.parse(sessionStorage.getItem(key));

    if (key === '6') {
      // Handle multiple checkboxes for top-features
      if (storedValues && Array.isArray(storedValues)) {
        storedValues.forEach((value) => {
          const checkbox = document.querySelector(
            `input[name="${groupName}"][value="${value}"]`
          );
          if (checkbox) {
            checkbox.checked = true;
          }
        });
      }
    } else {
      const storedValue = storedValues?.[0];
      if (storedValue) {
        const radioButton = document.querySelector(
          `input[name="${groupName}"][value="${storedValue}"]`
        );
        if (radioButton) {
          radioButton.checked = true;
        }
      }
    }
  });
}

tdgAllResultsButton?.addEventListener('click', (e) => {  
  
  e.preventDefault();

  // check sessionStorage for tire size data and set href to catalog/size/XX-XXRXXX
  if (JSON.parse(sessionStorage.getItem('tdg_params'))[1].values.length === 3) {
    window.location.assign(`${url.origin}/${languageInUrl === '' ? "" : languageInUrl+'/'}catalog/size/${JSON.parse(sessionStorage.getItem('tdg_params'))[1].values[0].replace(/\s+/g, '-')}-${JSON.parse(sessionStorage.getItem('tdg_params'))[1].values[1].replace(/\s+/g, '-')}R${JSON.parse(sessionStorage.getItem('tdg_params'))[1].values[2].replace(/\s+/g, '-')}`);
  } else {
    window.location.assign(`${url.origin}/${languageInUrl === '' ? "" : languageInUrl+'/'}catalog/${JSON.parse(sessionStorage.getItem('tdg_params'))[1].values[1].replace(/\s+/g, '-').toLowerCase()}/${JSON.parse(sessionStorage.getItem('tdg_params'))[1].values[2].replace(/\s+/g, '-').toLowerCase()}/${JSON.parse(sessionStorage.getItem('tdg_params'))[1].values[0].replace(/\s+/g, '-')}/${JSON.parse(sessionStorage.getItem('tdg_params'))[1].values[3].replace(/\s+/g, '-').toLowerCase()}`);
  }

  adobeDataLayer.push({"event":"component_click", 
    "component_detail":{
      "type":"cta", 
          "title":"decision guide:see all results",
    }
  });  
});

// handle search results
const handleSearchTires = async () => {
  try {
    handleSearchParams();
    const searchSession = JSON.parse(sessionStorage.getItem("tdg_params"));
    const searchStep = searchSession?.find(s => s.page === "2").values;
    const isSearchByVehicle = searchStep?.length === 4;
    const isSearchByTireSize = searchStep?.length === 3;
    let url, searchURL = "";

    if (isSearchByVehicle === true) {
      url = `/brgtrestwebservices/tire-search/tires-by-ymm-trim?site=${window.BATO.CONFIG.site}&lang_iso=${window.BATO.CONFIG.lang_iso}&ymm=${JSON.parse(sessionStorage.getItem('tdg_params'))[1].values[0]}-${JSON.parse(sessionStorage.getItem('tdg_params'))[1].values[1]}-${JSON.parse(sessionStorage.getItem('tdg_params'))[1].values[2]}&trim=${JSON.parse(sessionStorage.getItem('tdg_params'))[1].values[3]}&country=${window.BATO.CONFIG.country}&32ewe=wewerw`;
    } else if (isSearchByTireSize === true) {
      url = `/brgtrestwebservices/tire-search/tires-by-width-ratio-diameter?site=${window.BATO.CONFIG.site}&country=${window.BATO.CONFIG.country}&lang_iso=${window.BATO.CONFIG.lang_iso}&width=${encodeURIComponent(JSON.parse(sessionStorage.getItem('tdg_params'))[1].values[0])}&ratio=${encodeURIComponent(JSON.parse(sessionStorage.getItem('tdg_params'))[1].values[1])}&diameter=${encodeURIComponent(JSON.parse(sessionStorage.getItem('tdg_params'))[1].values[2])}&version=V1`;
    }

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      });
      const json = await response.json();
      console.log(json);
      searchURL = json;
    } catch (error) {
      console.error(error.message);
    }

    if (sessionStorage.getItem("tdg_params") !== null) {
      resetValues();
      finalResults = getBestMatches(searchURL);
      // console.log(finalResults, "<-----------");

      // Handle no results
      if (new URLSearchParams(url.search).has("1") === true && (!finalResults || finalResults.length === 0)) {
        // console.log("No results found");
        showNoResults();
        return;
      }

      let cpMatches = [];
      // Generate tire cards using the template, up to a maximum of 3 products
      for (let i = 0; i < finalResults.length; i++) {
        const tire = finalResults[i];
        // console.log(tire, "<----------- tire!!!!!!");
        // Extract necessary tire information from the current tire object
        const brand = tire.base.brand.toLowerCase();
        const subBrand = tire.base.subBrand.toLowerCase().replace(/\s+/g, '-').replace(/\//g, '');
        const displaySubBrand = tire.base.subBrand;
        const name = tire.base.name.toLowerCase().replace(/\s+/g, '-').replace(/\//g, '');
        const displayName = tire.base.name;
        const article = Object.keys(tire.variants)[0]; // Article ID
        const warrantyDistance = tire.variants[article]?.specs?.warranty_distance || 'N/A';
        const msrpValue = tire.variants[article]?.msrp?.value || 'N/A';
        const subHeader = tire.marketing?.reasonsToBuy?.headline || '';
        // const tireID = tire.variantArticles[0] || '';
        // console.log(tireID, "<----------- tireID!?????????????3423423423423423423423423423!!!!!");
        // const tireValue = tire;
        // console.log(tireValue, "<----------- tireValue!!!9089098098098098098098989908908908!!!");
        // Clone the template and populate it with data
        const tireCard = tireCardTemplate.content.cloneNode(true);
        const cardElement = tireCard.querySelector(".tdg-tire-card");

        if (cardElement) {
          cardElement.dataset.brand = tire.base.brand;
          cardElement.dataset.type = tire.base.category;
          cardElement.dataset.season = tire.base.season;
          cardElement.dataset.price = tire.variants[article]?.msrp?.value || 'N/A';
          cardElement.dataset.warranty = tire.variants[article]?.specs?.warranty_distance || 'N/A';
          cardElement.dataset.sizebaseurl = tire.variantSizes[0];
          cardElement.dataset.subbrand = tire.base.subBrand.replace(/\s+/g, '-').replace(/\//g, '');
          cardElement.dataset.productName = tire.base.name.replace(/\s+/g, '-').replace(/\//g, '');
          cardElement.dataset.articlenumber = Object.keys(tire.variants)[0];
          cardElement.dataset.articles = Object.keys(tire.variants)[0];
          // cardElement.dataset.tireid = tire.variantArticles[0] || '';
          cardElement.dataset.size = `${Math.round(tire.variants[article]?.specs?.width)}-${Math.round(tire.variants[article]?.specs?.ratio)}r${Math.round(tire.variants[article]?.specs?.diameter).toString()}`;
          cardElement.dataset.sizebase = `${Math.round(tire.variants[article]?.specs?.width)}-${Math.round(tire.variants[article]?.specs?.ratio)}r${Math.round(tire.variants[article]?.specs?.diameter).toString()}`;
          cardElement.dataset.msrp = tire.variants[article]?.msrp?.value || 'N/A';
          cardElement.dataset.diameter = tire.variants[article]?.specs?.diameter;

          cardElement.dataset.dryperformance = tire.performanceRatings.dryPerformance;
          cardElement.dataset.fuelefficiency = tire.performanceRatings.fuelEfficiency;
          cardElement.dataset.iceperformance = tire.performanceRatings.icePerformance;
          cardElement.dataset.offroadperformance = tire.performanceRatings.offRoadPerformance;
          cardElement.dataset.quietride = tire.performanceRatings.quietRide;
          cardElement.dataset.ridecomfort = tire.performanceRatings.rideComfort;
          cardElement.dataset.snowperformance = tire.performanceRatings.snowPerformance;
          cardElement.dataset.treadlife = tire.performanceRatings.treadLife;
          cardElement.dataset.wetperformance = tire.performanceRatings.wetPerformance;








          cardElement.dataset.marketingcategories = tire.marketing?.categories;
          cardElement.dataset.rtb1 = tire.marketing?.reasonsToBuy?.reasons ? tire.marketing?.reasonsToBuy?.reasons[0] : '';
          cardElement.dataset.rtb2 = tire.marketing?.reasonsToBuy?.reasons ? tire.marketing?.reasonsToBuy?.reasons[1] : '';
          cardElement.dataset.rtb3 = tire.marketing?.reasonsToBuy?.reasons ? tire.marketing?.reasonsToBuy?.reasons[2] : '';
          cardElement.dataset.rtb4 = tire.marketing?.reasonsToBuy?.reasons ? tire.marketing?.reasonsToBuy?.reasons[3] : '';

          cardElement.dataset.speedrating = tire.variants[article]?.specs?.speed_rating;
          cardElement.dataset.tireweight = tire.variants[article]?.specs?.net_weight_lbs;
          cardElement.dataset.ratio = tire.variants[article]?.specsMetric?.ratio;
          cardElement.dataset.treaddepth = tire.variants[article]?.specsMetric?.tread_depth_in_32_of_inch;



          cardElement.dataset.utqgrating = tire.variants[article]?.specs?.UTQG_tread_wear_rating;
          cardElement.dataset.utqgtraction = tire.variants[article]?.specs?.UTQG_traction;


          




      }
      
      // console.log(`Card dataset attributes added for tire ${i}:`, cardElement?.dataset);

        // Construct the tire size dynamically
        const sectionWidth = tire.variants[article]?.specs?.width;
        const aspectRatio = tire.variants[article]?.specs?.ratio;
        const diameter = tire.variants[article]?.specs?.diameter;

        // let productValue = tire;
        // console.log(productValue, 'product value TDG <<<<<<<-----------------')
  
        // Format the tire size to match the pattern (e.g., 275-50r22)
        const formattedSectionWidth = Math.round(sectionWidth).toString();
        const formattedAspectRatio = Math.round(aspectRatio).toString();
        const formattedDiameter = Math.round(diameter).toString();

        // Build the tire size in the correct format: 275-50r22
        const tireSize = `${formattedSectionWidth}-${formattedAspectRatio}r${formattedDiameter.toLowerCase()}`;

        // Construct the product URL dynamically
        let tireUrl = `/tire/${subBrand}-${name}/${tireSize}/`.replace(/\/\//g, '/');
        
        tireUrl = tireDecisionGuide && window.BATO.CONFIG.lang_iso === 'es_US'
          ? `/${languageInUrl}${tireUrl}` 
          : tireUrl;
        
        // console.log('Final tireUrl:', tireUrl);
        // Extract the image URL
        //const imageUrl = tire.media?.assets?.image2; // Provide a default image if none is found
        let imageUrl = "";
        
        if (brand == "bridgestone"){
          imageUrl = `https://s7d1.scene7.com/is/image/bridgestone/bridgestone-${subBrand}-${name}-60-zoom-bst-web-consumer`;
        } else {
          imageUrl = `https://s7d1.scene7.com/is/image/bridgestone/firestone-${subBrand}-${name}-60-zoom-fst-web-consumer`;
        }

        if (imageUrl == `https://s7d1.scene7.com/is/image/bridgestone/bridgestone-dueler-dueler-at-ascent-60-zoom-bst-web-consumer`){
          imageUrl = `https://s7d1.scene7.com/is/image/bridgestone/bridgestone-dueler-at-ascent-60-zoom-bst-web-consumer`;
        }
        

        // Populate tire card fields
        let mileageWarranty = formatMileage(warrantyDistance);
        if (mileageWarranty == "0K"){
          window.BATO.CONFIG.lang_iso == "es_US" ? tireCard.querySelector(".tdg-card-mileage").textContent = `Garantía` : tireCard.querySelector(".tdg-card-mileage").textContent = `Warranty`;
        } else {
          tireCard.querySelector(".warranty-distance").textContent = formatMileage(warrantyDistance);
        }
        

        // Conditionally set the card header to avoid duplicate subBrand and name
        const cardHeader = tireCard.querySelector(".tdg-card-header");
        cardHeader.textContent = displaySubBrand === displayName ? displayName : `${displaySubBrand} ${displayName}`;

        tireCard.querySelector(".msrp-value").textContent = `${msrpValue}`;
        tireCard.querySelector(".tdg-card-description").textContent = `${subHeader}`;
    
        // Set the image source dynamically
        const tireImage = tireCard.querySelector(".tire-image");
        tireImage.src = imageUrl;
        tireImage.alt = `${displayName}`;

        if (msrpValue !== 'N/A') {
          const totalForFour = msrpValue * 4;
          const formattedTotal = totalForFour.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
      
            // Check the language configuration
            if (window.BATO.CONFIG.lang_iso === 'es_US') {
                tireCard.querySelector(".total-for-four").textContent = `o ${formattedTotal} por 4 llantas`;
            } else {
                tireCard.querySelector(".total-for-four").textContent = `or ${formattedTotal} for 4 tires`;
            }
        } else {
            const totalForFourElement = tireCard.querySelector(".total-for-four");
            if (totalForFourElement) {
                totalForFourElement.textContent = ''; // Clear the content
            }
        }
        
      
    
        // Configure the compare checkbox and label with unique identifiers
        const compareCheckbox = tireCard.querySelectorAll('.cmp-tire-card__checkbox input');
        // console.log("compareCheckbox:", compareCheckbox);
        // console.log('compareCheckbox inputs tdg----->>>>>:', compareCheckbox);
        // console.log('compareCheckbox inputs tdg!!!!!!------->>>>>>:', compareCheckbox.length);

        const compareLabel = tireCard.querySelector("label.checkbox");

        // Set the `id` and `value` for the checkbox
        compareCheckbox.id = `t${article}`;
        // console.log(`compareCheckbox.id set to: ${compareCheckbox.id}`);


        compareCheckbox.value = `t${article}`;
        // console.log(`compareCheckbox.value set to: ${compareCheckbox.value}`);

        // Set the `for` attribute on the label to match the checkbox ID
        compareLabel.setAttribute("for", `t${article}`);
        // console.log(`compareLabel 'for' attribute set to: ${compareLabel.getAttribute("for")}`);
        const tsrTDG = document.querySelector('.tire-search-results');
        // console.log(tsrTDG, '-----tsrTDG--------')
        const tsrGrid = (tsrTDG != null) ? tsrTDG.querySelector(".tdg-matches__cards") : null;
        // console.log(tsrGrid, '<<<<------ tdg-matches__cards')

        const tsrHeader = (tsrTDG != null) ? tsrTDG.querySelector(".tsr-header") : null;
        // console.log('tsrHeader ------>>>>>', tsrHeader)
        const tct = document.querySelector('.tire-comparison-tray');
        // console.log(tct, 'tct here it is for tdg')
        const tctActive = 'tire-comparison-tray--active';
        // console.log(tctActive, 'tct Active for TDG')
        const tcti18n = document.querySelector('.tire-comparison-tray-translations');
        // console.log(tcti18n, 'tct tcti18n for TDG')

        // Iterate through each checkbox and configure it
        compareCheckbox.forEach((checkbox) => {
            const uniqueId = `t${article}`; // Ensure a unique ID per checkbox
            checkbox.id = uniqueId;
            // console.log(`Checkbox ID set to-------->>>>>>>>>>>>>>>>>>>>>>>: ${checkbox.id}`);

            checkbox.value = article; // Set the value to the article ID
            // console.log(`Checkbox value set to ----------------->>>>>>>>>>>>>>>>>>>>: ${checkbox.value}`);
            
            if (compareLabel) {
                compareLabel.setAttribute("for", uniqueId); // Link the label to the checkbox
                // console.log(`Compare label 'for' attribute set to: ${compareLabel.getAttribute("for")}`);
            }

            // Add event listener to each checkbox
            checkbox.addEventListener('click', function(event) {
                // console.log("Checkbox clicked:", checkbox);
            });

            // console.log("Added click listener to checkbox:", checkbox);
        });

        if(compareCheckbox) {

          const openCompareModal = (e) => {
              // console.log("Event triggered open compare modal ---->>>>>> TDG: ", e);
  
              if (e?.target) e.stopPropagation();
  
              let compareModalContent = "";
  
              let addToComparesChecked = tsrGrid.querySelectorAll('.cmp-tire-card__checkbox input:checked'); //.cmp-tire-card-search__checkbox
              // console.log("Checked inputs ------>>>>>> TDG: ", addToComparesChecked);
  
              // Handle Enabing/Disabling Compare Checkboxes only on variable change
              const setMaxSelected = (val) => {
                  // console.log("Setting maxSelected to: ", val);
  
                  let matchingCompares = tsrHeader.querySelectorAll('.cmp-tire-card__checkbox input');
                  // console.log("Matching compares: ", matchingCompares);
                  let matchingComparesChecked = tsrHeader.querySelectorAll('.cmp-tire-card__checkbox input:checked'); //.cmp-tire-card-search__checkbox
                  // console.log("Matching compares checked: ", matchingComparesChecked);
                  if(window.BATO.tireComparison.maxSelected != val) {
  
                      window.BATO.tireComparison.maxSelected = val;
  
                      if (window.BATO.tireComparison.maxSelected){
  
                        compareCheckbox.forEach(function(addToCompare) {
  
                              if(!addToCompare.checked) {
                                  addToCompare.disabled = true;
                                  tsrTDG.querySelector("label[for='" + addToCompare.id + "']").classList.add('disabled');
                              }
  
                          });
  
                          matchingCompares.forEach(function(matchingCompare) {
  
                              if(!matchingCompare.checked) {
                                  matchingCompare.disabled = true;
                                  tsrTDG.querySelector("label[for='" + matchingCompare.id + "']").classList.add('disabled');
                              }
  
                          });
  
                      } else {
  
                        compareCheckbox.forEach(function(addToCompare) {
  
                              addToCompare.disabled = false;
                              tsrTDG.querySelector("label[for='" + addToCompare.id + "']").classList.remove('disabled');
  
                          });
  
                          matchingCompares.forEach(function(matchingCompare) {
  
                              matchingCompare.disabled = false;
                              tsrTDG.querySelector("label[for='" + matchingCompare.id + "']").classList.remove('disabled');
  
                          });
  
                      }
                  }
              };
  
              let maxComparisons = 3;
              let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
              width <= 768 ? maxComparisons = 2 : '';
              // console.log("Max comparisons: ", maxComparisons);
  
              let cardsSelected = addToComparesChecked.length,
                  cardsRemaining = maxComparisons-cardsSelected,
                  tirePlural = "";
  
              cardsRemaining == 2 ? tirePlural = "s" : '';
              // console.log(`Cards selected: ${cardsSelected}, Cards remaining: ${cardsRemaining}`);
  
              cardsSelected == maxComparisons ? setMaxSelected(true) : setMaxSelected(false);
  
              let flyoutActive = tct.classList.contains(tctActive);
  
              if(addToComparesChecked.length) {
  
                  compareModalContent += `<div class="cmp-tire-comparison__flyout">`;
  
                  addToComparesChecked.forEach(function(addToCompareChecked) {
  
                      // let tireID = addToCompareChecked.value;
                      // console.log(tireID, '<<<----------- TIRE ID TDG!!!!!!')
                      let tireID = addToCompareChecked.value;
                      // console.log(tireID, "<----------- tireID!?????????????22222222222222222222!!!!!");
  
                      let tireCard = addToCompareChecked.closest(".tdg-tire-card");
                      let articleNumber = tireCard.dataset.articlenumber;
                      // console.log(articleNumber, 'article number TDG <<<<<<<-----------------')
                      let productId = tireCard.dataset.productid;;
                      // console.log(productId, 'product value TDG <<<<<<<-----------------')
  
                      // console.log(tireCard, 'tire card here for TDG <<<<<---------------')
                      let tireName = tireCard.querySelector("h3").innerText;
                      //May need to revisit code below, this class was never used in tire-card-search.html, there was a
                      //class 'cmp-tire-card__search__image' that was replaced with 'cmp-tire-card__image'
                      let tireImage = tireCard.querySelector(".cmp-tire-card__image").innerHTML;
                      // console.log("Adding tire to modal:", { tireID, tireName });
                      // console.log(tireID, "<<<<<<<<<<<<<<<_____________ Here is the tireID for TDG");
                      // console.log(tireName, "<<<<<<<<<<<<<<<_____________ Here is the tireName for TDG");
                      compareModalContent += `<div class="flyout__card">
                                                  <div class="remove-tire" data-id="t${tireID}" data-articlenumber="t${articleNumber}" data-productid="${productId}"></div>
                                                  <div class="flyout__card__image">${tireImage}</div>
                                                  <div class="flyout__card__name">${tireName}</div>
                                              </div>`;
  
                  });
  
                  for(let i = 0; i < cardsRemaining; i++){
  
                      let iAdjusted = i+2;
                      width <= 768 ? iAdjusted = i+1 : '';
  
                      if(cardsRemaining == 1) {
                          width <= 768 ? iAdjusted = 2 : iAdjusted = 3;
                      }
  
                      compareModalContent += `<div class="flyout__card">
                                                  <div class="flyout__card__image flyout__card__image__placeholder"></div>
                                                  <div class="flyout__card__name">${iAdjusted}</div>
                                              </div>`;
                  }
                  // console.log("Final compare modal content: ", compareModalContent);
  
  
                  if(cardsRemaining > 0) {
                      compareModalContent += `<p>${tcti18n.dataset.selectUpTo} ${cardsRemaining} ${tcti18n.dataset.moreTire}${tirePlural} ${tcti18n.dataset.toCompare}</p>`;
                  }
  
                  let buttonClass = "button";
  
                  if(width <= 768){
                      cardsRemaining == 1 ? buttonClass += " button--secondary disabled" : "";
                  } else {
                      cardsRemaining == 2 ? buttonClass += " button--secondary disabled" : "";
                  }
  
                  compareModalContent += `<a class="${buttonClass}">${tcti18n.dataset.compare} ${cardsSelected} ${tcti18n.dataset.selectedTires}</a>`;
  
                  compareModalContent += `</div>`;
  
                  if(flyoutActive) {
  
                      tct.innerHTML = compareModalContent;
  
                  } else {
  
                      tct.classList.add(tctActive);
                      tct.innerHTML = compareModalContent;
  
                  }
  
  
                  // Listener to Remove Cards from Comparison Flyout
                  const removeFlyoutCard = () => {
                      // console.log("Setting up flyout card removal listeners");
  
                      let removeTires = tct.querySelectorAll(".remove-tire");
  
                      if(removeTires.length) {
  
                          removeTires.forEach(function(btn) {
  
                              btn.addEventListener('click', (e) => {
                                  // console.log("Removing tire --->>>>: ", btn.dataset.id);
  
                                  let connectedLabel = tsrTDG.querySelectorAll("label[for='" + btn.dataset.id + "']"),
                                      connectedCheckbox = connectedLabel[0]?.control,
                                      matchingCards = tsrHeader.querySelectorAll('.cmp-tire-card-recommended');
                                      // console.log(matchingCards, '<<<<______ matchingCards', matchingCards)
                                      // console.log("Connected Label", connectedLabel);

                                      matchingCards.forEach((matchingCard, i) => {
                                        let matchingCardArticleNumber = matchingCard.dataset.articles;
                                    
                                        // console.log(`Matching card index: ${i}`);
                                        // console.log(`Matching card article number  ${matchingCardArticleNumber}`);
                                        // console.log(`Button dataset ID: ${btn.dataset.id}`);
                                    
                                        // Compare Article IDs
                                        if (matchingCardArticleNumber == btn.dataset.id) {
                                            // console.log(`Match found for card index ${i} with article number ${matchingCardArticleNumber}.`);
                                            
                                            let matchingCardCompare = matchingCard.querySelector('input');
                                            // console.log("Checkbox before modification:", matchingCardCompare);
                                    
                                            matchingCardCompare.checked = false;
                                            matchingCardCompare.removeAttribute('checked');
                                    
                                            // console.log("Checkbox after modification:", matchingCardCompare);
                                        }
                                    });
                                    
  
                                  connectedCheckbox.checked = false;
                                  openCompareModal();
                              });
  
                          });
  
                      }
                  }
  
                  removeFlyoutCard();
  
  
                  // Compare X Selected Tires Button
                  let loadTCButton = tct.querySelector("a.button");
  
                  if(loadTCButton) {
  
                      const loadTCOverlay = () => {
                        // console.log("Calling loadTireComparisonCards with:", addToComparesChecked);
                          window.BATO.tireComparison.loadTireComparisonCards(addToComparesChecked);
                          window.scrollTo({ top: 0, behavior: 'smooth' });
                      }
  
                      if(cardsRemaining == 2) {
  
                          loadTCButton.removeEventListener('click', loadTCOverlay)
  
                      } else {
  
                          loadTCButton.addEventListener('click', loadTCOverlay);
  
                      }
  
                  }
  
              } else {
                  let el = document.querySelector('.tire-search-results');
                  flyoutActive ? tct.classList.remove(tctActive) : '';
              }
  
              const checkMatchingCards = () => {
  
                  if (e) {
  
                      //console.log("e from checkMatchingCards",e);
  
                      let parentCard = e.target?.closest('.cmp-tire-card') || null;
  
                      if (parentCard) {
  
                          let parentArticle = parentCard.dataset.articlenumber,
                              parentStatus = e.currentTarget.checked;
  
                          let matchingCard = tsrHeader.querySelector('[data-articlenumber="t' + parentArticle + '"]');
  
                          if(matchingCard) {
  
                              setTimeout(() => {
  
                                  let matchingCardCompare = matchingCard.querySelector('input');
  
                                  matchingCardCompare.checked = parentStatus;
                                  parentStatus == true ? matchingCardCompare.setAttribute('checked','checked') : matchingCardCompare.removeAttribute('checked');
  
                              }, 250);
                          }
  
                      }
                  }
  
              }
  
              checkMatchingCards();
          }

          compareCheckbox.forEach((addToCompare) => {
            addToCompare.addEventListener('click', openCompareModal);
            // console.log("Added click listener to checkbox: ", addToCompare);
        });
  
          window.BATO.tireComparison.openCompareModal = openCompareModal;
      }



        // Wrap the card in an anchor tag for navigation
        const cardLink = document.createElement('a');
        cardLink.href = tireUrl;
        cardLink.classList.add('tire-card-link');
        cardLink.appendChild(tireCard);

        //Add click handler
        cardLink.addEventListener('click', function(event) {
          adobeDataLayer.push({"event":"product_click", 
            "product":[{
                "product_id":name + brand,
                "article_id":Object.keys(tire.variants)[0],
                "name":name,
                "product_brand":brand,
                "brand":subBrand,
              }],          
          });
        });
        
        //CP tire data
        const productDetails = {
          article_id: Object.keys(tire.variants)[0],
          product_id: name + brand,
          product_name: name,
          category: tire.base.category
        };
        cpMatches.push(productDetails);

        // Append the linked card to the results container
        tireCardsContainer.appendChild(cardLink);
      } 

      let vehicleObj;
      let sizeObj;
      let vehicleSearchType;

      const vehicleOrSizeSelectedArrayLength = Object.keys(vehicleOrSizeSelectedArray).length;

      if (vehicleOrSizeSelectedArrayLength == 4){
        vehicleObj = vehicleOrSizeSelectedArray;
        sizeObj = "none";
        vehicleSearchType = "vehicle";
      } else if (vehicleOrSizeSelectedArrayLength == 3) {
        vehicleObj = "none";
        sizeObj = vehicleOrSizeSelectedArray;
        vehicleSearchType = "size";
      }
      //Make call for CP with results
      adobeDataLayer.push({"event":"td.results",
        "number_of_results":finalResults.length,
        "search_type":"vehicle",
        "result_type":vehicleSearchType,  
        "sorting":"relevance",
        products:[
          cpMatches[0],
          cpMatches[1],
          cpMatches[2]
        ],
        ...(vehicleObj != "none" && {
          "vehicle": {  
            ...vehicleObj
          }
        }),
        ...(sizeObj != "none" && {
          "size": {  
            ...sizeObj
          }
        }),
        "decision_guide":{
          "tire_reason":whyNeedTiresArray,
          "tire_type":tiresTypeArray,
          "top_features": selectedTopFeatures,
          "driving_style":valuesArray,
          "skipped":skipToResultsClicked
        }
      });
    } else {
      return;
    }
  } catch (error) {
    console.error("An error occurred while handling search results:", error);
    // Display no results in case of error
    showNoResults();
  }
};

const resetValues = () => {
  // remove any tire data from a previous tdg search
  if (sessionStorage.getItem("1") === null) {
    document.querySelectorAll('input').forEach(input => {
      input.checked = false;
    });
    const a = document.querySelectorAll('input[type="text"]');
    a.value = "";
  }
  const b = document.getElementById("tdg-matches__container");
  b.innerHTML = "";
}

// Utility function to display the no results section
const showNoResults = () => {
  const noResultsElement = document.getElementById("noResults");
  const resultsContainer = document.getElementById("tdg-results");
  if (noResultsElement && resultsContainer) {
    noResultsElement.style.display = "flex";
    resultsContainer.style.display = "none";
  }
};


// handle searchParams from shared link
const tdgQueryString = queryString.parse(window.location.search);


if (tdgQueryString && new URLSearchParams(url.search).has("1")) {

  if (sessionStorage.getItem("1") === null) {
    tdgFormContainer.classList.add('hide');
  }

  searchUserLocation(new Event('onload'));
  

  // remove tdg-flag param from url
  delete tdgQueryString['tdg-flag'];
  // hide all steps and show last step
  document.querySelectorAll('[data-page]').forEach(dp => {
    dp.classList.remove('active')
  });
  // route to last step to view tire results from shared link
  Array.from(document.querySelectorAll('[data-page]')).slice(-1)[0].classList.add('active');
  updateVehicleInfoContainer(false);
  handleSearchTires();
}


tdgLinks?.forEach((link, i) => {
  link.addEventListener('click', (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.currentTarget.classList.contains("no-results-reset") || e.currentTarget.classList.contains("editDetailsBtn")) {
      if (new FeatureSwitches()?.isTrue('tdg-flag')) {
        window.location.replace(url.href.split('&')[0]);
      } else {
        window.location.replace(url.href.split('?')[0]);
      }
      
    } else {
      currentPage = 2;
      document.querySelector('.tire-decision-guide__footer').classList.remove('hide');
      document.querySelector('[data-page="7"]').classList.remove('active');
      document.querySelector('[data-page="2"]').classList.add('active');
      makePageChanges(currentPage);

      window.history.pushState({}, '', url.href.split('?')[0]);
    }
  })
});

// Helper function to format mileage
function formatMileage(mileage) {
  // Convert to an integer to remove any decimals
  const mileageInt = parseInt(mileage, 10);
  // Divide by 1000 and append "K" for thousands
  return `${mileageInt / 1000}K`;
}
