import elementListener from '../../../../../js/library/eventHelpers.js';

// setup window object for components to consume
if (!window.BATO) window.BATO = {};

const pageContainer = document.querySelector(".page");

const tdp = document.querySelector(".tire-detail");
const tdpData = (tdp != null) ? tdp.querySelector(".tire-detail-datawrapper") : null;
const tdpDropdown = (tdp != null) ? tdp.querySelector(".tire-size-dropdown") : null;

const mainNav = pageContainer.querySelector('.main-nav-container');

const tdpChangeVehicle = (tdp != null) ? tdp.querySelector('.tire-detail__vehicle-info a[href^="#vehicle-selector"]') : null;
const tdpChangeVehicleClasslist = (tdpChangeVehicle != null) ? tdpChangeVehicle.classList : null;

const ts = pageContainer.querySelector(".tire-specs");
const tdpDropdownTechnicalSpecs = (tdp != null) ? ts.querySelector(".tire-spec-dropdown") : null;

const tdpDropdownAnalyticsObject = {
	"event": "tdp.size_change",
	"type": "dropdown",
	"component_detail": "",
	"tirefit_results": "not sure tire fits",
	"product": [{
		"product_brand": "",
		"product_subbrand": "",
		"product_name": "",
		"product_id": "",
		"product_size": "",
		"article_id": "",
		"msrp": "",
		"warranty_distance": "",
		"warranty_unit": ""
	}]
};

let whichDropdown;

// Since the Dropdowns are Tied Together, we need to decouple them
// Since click events cannot be added to option elements, we check initial click
// event and get the target className, then only use the one that matches the initial click
export const init = () => {

	if (tdpDropdown != null) {

		tdpDropdown.addEventListener('click', (evt) => {
			whichDropdown = evt.target.className;
		});

		tdpDropdown.addEventListener('change', (evt) => {
			tdpDropdownEventHandler(evt);
		});
	}

	if (tdpDropdownTechnicalSpecs != null) {

		tdpDropdownTechnicalSpecs.addEventListener('click', (evt) => {
			whichDropdown = evt.target.className;
		});

		tdpDropdownTechnicalSpecs.addEventListener('change', (evt) => {
			tdpDropdownEventHandler(evt);
		});

	}

	// Main Nav Vehicle Picker
	if (mainNav != null) {
		mainNav.querySelectorAll('a[href^="#vehicle-selector"]').forEach(menuUtilityContainer =>
			menuUtilityContainer.addEventListener('click', (evt) => {
				whichDropdown = evt.currentTarget.className;
			})
		);
	}

	// Will It Fit Vehicle Picker
	if (tdpChangeVehicle != null) {
		tdpChangeVehicle.addEventListener('click', (evt) => {
			whichDropdown = evt.currentTarget.className;
		});
	}

}

const tdpDropdownEventHandler = (evt) => {

	let el = evt.target;

	if( whichDropdown && (
		(whichDropdown == el.className) ||
		(whichDropdown == tdpChangeVehicleClasslist) ||
		(whichDropdown.includes('icon')) )) {

		let analyticsObject = JSON.parse(JSON.stringify(tdpDropdownAnalyticsObject));

		// Will It Fit Vehicle Picker
		if (whichDropdown == tdpChangeVehicleClasslist) {

			analyticsObject.event = 'tdp.tirefit_change_vehicle';
			analyticsObject.type = 'vehicle_picker_will-it-fit';
			analyticsObject.component_detail = 'vehicle_picker';

		} else {

			analyticsObject.component_detail = whichDropdown;

		}

		// Main Nav Vehicle Picker
		if (whichDropdown.includes('icon')) {

			analyticsObject.event = 'tdp.tirefit_change_vehicle';
			analyticsObject.type = 'vehicle_picker_main-nav';
			analyticsObject.component_detail = 'vehicle_picker';

		}

		let willItFit = tdp.querySelector(".will-it-fit");
		let willItFitClasslist = willItFit.classList;

		if (willItFitClasslist.contains("will-it-fit--notfit")) {
			analyticsObject.tirefit_results = "tire does not fit";
		} else if (willItFitClasslist.contains("will-it-fit--fit")){
			analyticsObject.tirefit_results = "tire does fit";
		}

		if(tdpData) {

			analyticsObject.product[0].product_brand = tdpData.dataset.productBrand || "";
			analyticsObject.product[0].product_subbrand = tdpData.dataset.productSubbrand || "";
			analyticsObject.product[0].product_name = tdpData.dataset.productName || "";
			analyticsObject.product[0].product_id = tdpData.dataset.productId || "";
			analyticsObject.product[0].product_size = tdpData.dataset.productSize || "";
			analyticsObject.product[0].article_id = tdpData.dataset.articlenumber || "";
			analyticsObject.product[0].msrp = tdpData.dataset.msrp || "";

		}

		let selectedDataset = tdpDropdown.options[el.selectedIndex].dataset;

		if(selectedDataset) {

			analyticsObject.product[0].warranty_distance = selectedDataset.warrantydistance || "";
			analyticsObject.product[0].warranty_unit = selectedDataset.warrantyunit || "";

		}

		adobeDataLayer.push(analyticsObject);

		whichDropdown = null;

	}

}

export default {
	init
}