import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { fetchMoreReviews, fetchReviewsAndSummary, sortReviews, useReviews } from './reviews-context.js';
import { RatingsSummary } from './ratings-summary/RatingsSummary.jsx';
import { ReviewsList } from './reviews-list/ReviewsList.jsx';
import '../../scss/tire-reviews.scss';
import { useSelector } from "react-redux";

const ReviewsApplication = (props) => {
    
    const i18n = useSelector( state => state.i18n);

    const {
        type,
        productType,
        id,
        tireBrand,
        tireName,
        tireSubBrand,
        classes,
        meta: {
            site,
            country,
            lang_iso,
            groupId,
            merchId,
            apiKey,
            canReview
        },
        pageSize,
        defaultSort
    } = props;

    const [ state, dispatch ] = useReviews();

    useEffect(() => {
        if (state.reviewData.status === 'initial') {
            fetchReviewsAndSummary(dispatch, state, {
                id,
                country,
                groupId,
                lang_iso,
                pageSize,
                site,
                sortBy: defaultSort
            });
        }
    }, []);

    const handleSortReviews = (sortValue) => {
        sortReviews(dispatch, state, {
            id,
            country,
            groupId,
            lang_iso,
            pageSize,
            site,
            sortBy: sortValue
        });
    };

    const handleLoadMoreReviews = () => {
        fetchMoreReviews(dispatch, state, {
            id,
            country,
            groupId,
            lang_iso,
            nextPageKey: state.reviewData.data.nextPageKey,
            pageSize,
            site,
            sortBy: state.reviewData.params.sortBy
        })
    }

    return (
        <section className="ratings-and-reviews app-global">
            <h2 className="heading">{i18n.ratingsandreviewsheading}</h2>
            <RatingsSummary
                apiKey={apiKey}
                canReview={canReview}
                classes={classes}
                groupId={groupId}
                id={id}
                locale={lang_iso}
                merchId={merchId}
                productType={productType}
                tireBrand={tireBrand}
                tireName={tireName}
                tireSubBrand={tireSubBrand}
            />
            <ReviewsList
                handleLoadMoreReviews={handleLoadMoreReviews}
                handleSortReviews={handleSortReviews}
            />
        </section>
    );
};

const ReviewsApp = ReviewsApplication;

ReviewsApplication.defaultProps = {
    classes: '',
    meta: {
        apiKey: '',
        canReview: false,
        country: 'US',
        lang_iso: 'en_US',
        merchId: '0',
        site: 'BST'
    },
    pageSize: 6,
    tireBrand: '',
    tireName: ''
};

ReviewsApplication.propTypes = {
    classes: PropTypes.string,
    id: PropTypes.string.isRequired,
    defaultSort: PropTypes.string.isRequired,
    meta: PropTypes.shape({
        apiKey: PropTypes.string,
        canReview: PropTypes.bool,
        country: PropTypes.string,
        groupId: PropTypes.string.isRequired,
        lang_iso: PropTypes.string,
        merchId: PropTypes.string,
        site: PropTypes.string
    }),
    pageSize: PropTypes.number,
    productType: PropTypes.string.isRequired,
    tireBrand: PropTypes.string,
    tireName: PropTypes.string,
    tireSubBrand: PropTypes.string,
    type: PropTypes.string.isRequired
};

export { ReviewsApp };
