import elementListener from '../../../../../../js/library/eventHelpers.js';
import {cardHTML} from './store-card-markup.js'
//import stubData from './store-card-stub-data.js'

import {urlLanguageCheck} from '../../../../../../js/utils/urlLanguageCheck.js';

document.addEventListener("DOMContentLoaded", function() {
    
    if (!window.BATO) window.BATO = {};
    if (!window.BATO.storeLocator) window.BATO.storeLocator = {};

    window.BATO.storeLocator.basketTransfer = {
        exists: false,
        checked: false
    }

    const availabilityService = window.BATO.Services.availability;

    let cardsWrapper = document.querySelector(".cmp-store-cards"),
        translatedText = document.querySelector("[data-opensat][data-closesat]") ? document.querySelector("[data-opensat][data-closesat]").dataset : '';

    // Consolidating getState() Calls
    const vehicleData = window.BATO.Store.getState().vehicles.vehicle;

    const getFCACUrl = (fcacType) => {

        switch (fcacType) {
            case 'FCAC':
                return "firestonecompleteautocare.com";
            case 'TP':
                return "tiresplus.com";
            case 'WW':
                return "wheelworks.net";
            case 'HTP':
                return "hibdontire.com";
            default:
                return false;
        }

    }

    const setArticleNumberLength = (articleNumber) => {

        let articleNumberDiff = 6 - articleNumber.length;

        if(articleNumberDiff > 0) {
            for(let i = 0; i < articleNumberDiff; i++) {
                articleNumber = '0' + articleNumber;
            }
        }

        return articleNumber;
    }

    const getStoreCards = (noOfCards, customData) => {

        let returnData = window.BATO.Store.getState().stores;

        if (customData) {
            returnData = customData;
        }

        // If Data Is Not Available ...
        //let returnData = stubData;
        //let dataStringed = JSON.stringify(returnData);
        //let returnData = JSON.parse(dataStringed);

        // List of Stores was moved from keyname storeNames to storeNumbers, below is to support cached users who do not perform a new location search
        let storeIDs = (returnData.data.storeNumbers != null) ? returnData.data.storeNumbers : returnData.data.storeNames,
            storeCards = "",
            storeNumber,
            externalStoreId,
            storeName,
            storeDistance,
            storeDistanceUnit,
            storeAddress,
            simpleZip,
            storeHours,
            openClose,
            openCloseClass,
            ampm,
            openingTime,
            openingTimeHour,
            openingTimeHalf,
            openingTimeData,
            closingTime,
            closingTimeHour,
            closingTimeHalf,
            closingTimeFormatted,
            closingTimeData,
            storeDetails,
            urlTarget,
            basketTransferURL,
            storeHandoff,
            tceEnabled = true, // Switch to Enable TCE, for when TCE Basket Transfer is Ready
            storePhone,
            index = 1,
            awayTxt,
            d = new Date().getDay(),
            h = new Date().getHours(),
            languageInUrl = urlLanguageCheck(),
            whatsAppNumber,
            excellenceStamp;

        languageInUrl = (languageInUrl ? '/' + languageInUrl : '');

        storeCards += "<h5>" + returnData.data.quantity + " " + translatedText.nearbystores + "</h5>";
        storeCards += "<div class='cards-wrapper'>";

        // If Number of Cards less than Returned Results, Adjust noOfCards
        if (noOfCards) {
            //console.log("storeIDs.length",storeIDs.length);
            noOfCards >= storeIDs.length ? noOfCards = storeIDs.length : '';
        }

        storeIDs.forEach((storeID) => {

            if ((noOfCards && index <= noOfCards) || !noOfCards) {

                storeNumber = returnData.data.stores[storeID]['base']['storeNumber'];
                storeName = returnData.data.stores[storeID]['base']['storeName'];
                storeDistance = returnData.data.stores[storeID]['location']['distance'];

                storeDistanceUnit = translatedText.units;
                awayTxt = translatedText.away;

                if (storeDistanceUnit) {
                    storeDistanceUnit === 'Miles' ? storeDistanceUnit = 'mi' : storeDistanceUnit = 'km';
                } else {
                    storeDistanceUnit = 'mi';
                }

                const storeStateDisplay = returnData.data.stores[storeID]['location']['stateDisplay'] ? returnData.data.stores[storeID]['location']['stateDisplay'] : returnData.data.stores[storeID]['location']['state'];

                storeAddress = returnData.data.stores[storeID]['location']['address'];
                storeAddress += ", " + returnData.data.stores[storeID]['location']['city'];
                storeAddress += " " + storeStateDisplay;
                storeAddress += ", " + returnData.data.stores[storeID]['location']['zip'];

                simpleZip = returnData.data.stores[storeID]['location']['zip'].split("-");

                openingTimeData = 99;
                closingTimeData = -1;

                openClose = "";
                openCloseClass = "";
                closingTimeFormatted = "";
                storeHours = returnData.data.stores[storeID]?.hours || "";

                if (storeHours.length) {

                    closingTime = storeHours[d]?.closeTime || "";
                    openingTime = storeHours[d]?.openTime || "";

                    if (((closingTime !== "") && (openingTime !== "")) && ((closingTime !== "00:00") && (openingTime !== "00:00"))) {

                        if (closingTime.includes('h') && openingTime.includes('h')) {

                            closingTimeHour = parseInt(closingTime.substr(0, closingTime.indexOf('h')));
                            h >= closingTimeHour ? openClose = translatedText.closed : openClose = translatedText.open;
                            h >= closingTimeHour ? openCloseClass = 'closed' : openCloseClass = 'open';


                            if (openClose === translatedText.open) {

                                closingTimeFormatted = translatedText.closesat + " " + closingTime;

                            } else {

                                closingTimeFormatted = translatedText.opensat + " " + openingTime;

                            }

                            openingTimeData = parseFloat(openingTime.replace(" h ", "."));
                            closingTimeData = parseFloat(closingTime.replace(" h ", "."));

                        } else {

                            closingTimeHour = parseInt(closingTime.substr(0, closingTime.indexOf(':')));

                            h >= closingTimeHour ? openClose = translatedText.closed : openClose = translatedText.open;
                            h >= closingTimeHour ? openCloseClass = 'closed' : openCloseClass = 'open';

                            closingTimeHour >= 12 ? ampm = "PM" : ampm = "AM";

                            if (openClose === translatedText.open) {

                                closingTimeHour = ((closingTimeHour + 11) % 12 + 1);
                                closingTimeHalf = parseInt(closingTime.substr(closingTime.indexOf(':') + 1, closingTime.length));

                                closingTimeFormatted = translatedText.closesat + " ";
                                closingTimeHalf !== 0 ? closingTimeFormatted += closingTimeHour + ":" + closingTimeHalf + ampm : closingTimeFormatted += closingTimeHour + ampm;

                            } else {

                                openingTimeHour = parseInt(openingTime.substr(0, openingTime.indexOf(':')));
                                openingTimeHour >= 12 ? ampm = "PM" : ampm = "AM";
                                openingTimeHour = ((openingTimeHour + 11) % 12 + 1);

                                openingTimeHalf = parseInt(openingTime.substr(openingTime.indexOf(':') + 1, openingTime.length));

                                closingTimeFormatted = translatedText.opensat + " ";
                                openingTimeHalf !== 0 ? closingTimeFormatted += openingTimeHour + ":" + openingTimeHalf + ampm : closingTimeFormatted += openingTimeHour + ampm;

                            }

                            openingTimeData = parseFloat(openingTime.replace(":", "."));
                            closingTimeData = parseFloat(closingTime.replace(":", "."));
                        }

                    } else {
                        openingTimeData = 99;
                        closingTimeData = -1;
                    }

                }

                basketTransferURL = false;
                storeHandoff = false;
                urlTarget = '_self';

                if (getFCACUrl(returnData.data.stores[storeID]['retailer']['retailerType']) && returnData.data.stores[storeID].retailer?.dealerCustomPageEnabled === '1') {

                    storeDetails = 'https://local.' + getFCACUrl(returnData.data.stores[storeID]['retailer']['retailerType']) + '/location/' + returnData.data.stores[storeID]['base']['storeNumber'] + '/';
                    urlTarget = '_blank';

                } else if (returnData.data.stores[storeID].base?.storePageUrl && returnData.data.stores[storeID].retailer?.dealerCustomPageEnabled === '1') {

                    storeDetails = returnData.data.stores[storeID]['base']['storePageUrl'];
                    urlTarget = '_blank';

                    const urlRegex = /^((http|https):\/\/)(www.)?[A-Za-z0-9]+([\-\.]{1}[A-Za-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
                    const alphaNumericRegex = /[A-Za-z0-9]/;

                    if (!urlRegex.test(storeDetails)) {

                        // If URL does not pass regex test, first check for existence of semicolon
                        // incase http:// is malformed ... ie 'htp://', 'http:www'. etc etc ...
                        if (storeDetails.indexOf(':') > -1) {

                            let afterColon = storeDetails.slice(storeDetails.indexOf(':'));
                            let azPosition = afterColon.search(alphaNumericRegex);
                            let baseUrl = afterColon.slice(azPosition);

                            storeDetails = 'http://' + baseUrl;

                            // If no semicolon, we presume HTTP:// is missing, and append to front of string
                        } else if (storeDetails.indexOf(':') === -1) {

                            storeDetails = 'http://' + returnData.data.stores[storeID]['base']['storePageUrl'];

                        }

                    }

                } else if (returnData.data.stores[storeID].base?.storePageUrl && returnData.data.stores[storeID].retailer?.dealerCustomPageEnabled === '0') {

                    storeDetails = languageInUrl + returnData.data.stores[storeID]['base']['storePageUrl'];

                } else {

                    storeDetails = false;

                }

                //typeof returnData.data.stores[storeID]['base']['storePageUrl'] ? storeDetails = returnData.data.stores[storeID]['base']['storePageUrl'] : storeDetails = false;

                // No Purchase At Store for Canadian Sites
                //if(window.BATO.CONFIG.country != 'CA') {

                let previouslyViewedTires = window.BATO.Store.getState().flex?.previouslyViewedTires;

                if (previouslyViewedTires.length !== 0) {

                    basketTransferURL = returnData.data.stores[storeID]['retailer']['retailerType'];
                    externalStoreId = returnData.data.stores[storeID]['retailer']['externalStoreId'];

                    let articleNumber = previouslyViewedTires[0].articlenumber;
                    if (previouslyViewedTires[0].articlenumberclean) articleNumber = previouslyViewedTires[0].articlenumberclean;

                    // console.log('basketTransferURL',basketTransferURL);

                    // No FCAC or CostCo 'Purchase at Store' for Canadian Sites
                    if (window.BATO.CONFIG.country !== 'CA') {

                        if (basketTransferURL === "FCAC" ||
                            basketTransferURL === "TP" ||
                            basketTransferURL === "WW" ||
                            basketTransferURL === "HTP" ||
                            basketTransferURL === "Costco") {

                            // use store type as initial 'pre-populated' value in data-href
                            // full HREF construction below in purchaseButtonSetup()
                            storeHandoff = basketTransferURL;

                        } else {

                            storeHandoff = false;

                        }

                    }

                    // TCE Enabled for US & Canadian Sites
                    if (basketTransferURL === "TCE" && tceEnabled === true) {
                        storeHandoff = basketTransferURL;
                    }

                }

                //}

                if (typeof returnData.data.stores[storeID]['contact'] === 'object') {

                    let retailerType = returnData.data.stores[storeID]['retailer']['retailerType'];

                    if (returnData.data.stores[storeID]['contact']['trackingPhone'] && (
                        retailerType === "FCAC" ||
                        retailerType === "TP" ||
                        retailerType === "WW" ||
                        retailerType === "HTP")) {
                        returnData.data.stores[storeID]['contact']['trackingPhone'] !== "" ? storePhone = returnData.data.stores[storeID]['contact']['trackingPhone'] : storePhone = false;
                    } else {
                        returnData.data.stores[storeID]['contact']['phone'] !== "" ? storePhone = returnData.data.stores[storeID]['contact']['phone'] : storePhone = false;
                    }

                } else {
                    storePhone = false;
                }
                                
                whatsAppNumber = returnData.data.stores[storeID].base?.isWhatsAppEnabled 
                ? returnData.data.stores[storeID].contact?.whatsAppNumber 
                : false;

                excellenceStamp = returnData.data.stores[storeID].retailer?.badge 
                ? returnData.data.stores[storeID].retailer.badge 
                : false;

                if (!window.BATO.storeLocator.hasOwnProperty("cardHTML")) {
                    storeCards += cardHTML(index, storeNumber, externalStoreId, storeName, storeDistance, storeDistanceUnit, storeAddress, simpleZip[0], openClose, openCloseClass, closingTimeData, openingTimeData, closingTimeFormatted, storeDetails, urlTarget, storeHandoff, storePhone, awayTxt, whatsAppNumber, excellenceStamp);
                } else {
                    storeCards += `${cardHTML(index, storeNumber, externalStoreId, storeName, storeDistance, storeDistanceUnit, storeAddress, simpleZip[0], openClose, openCloseClass, closingTimeData, openingTimeData, closingTimeFormatted, storeDetails, urlTarget, storeHandoff, storePhone, awayTxt, whatsAppNumber, excellenceStamp)}`;
                }

                index++;
            }

        });

        storeCards += "</div>";

        return new Promise((resolve) => {
            resolve(storeCards);
        });

    }

    const bindStoreCards = () => {

        let cardsWrapper = document.querySelector(".cmp-store-cards");
        if (document.querySelector('.dynamic-top-content')) cardsWrapper = document.querySelector('.dynamic-top-content .cmp-store-cards');

        const cardsBindToMap = (e) => {

            (async () => {
                while (window.BATO.mapPins.length === 0)
                    await new Promise(resolve => setTimeout(resolve, 250));

                let pins = window.BATO.mapPins;
                let allCards = cardsWrapper.querySelectorAll('.store-card');

                allCards.forEach(function (card, index) {
                    card.addEventListener("click", window.BATO.mapInteractions.bind(this, pins[index], "card"));
                    card.addEventListener("focus", window.BATO.mapInteractions.bind(this, pins[index], "focus"));
                });

            })();

        }

        (async () => {
            while (!window.BATO.hasOwnProperty("mapPins"))
                await new Promise(resolve => setTimeout(resolve, 250));
            cardsBindToMap();
        })();

        const cardsSort = (filterBy) => {

            let storeCardsHeader = "",
                storeCards = "",
                cardsBuffer = [];

            let allCards = cardsWrapper.querySelectorAll('.store-card');

            allCards.forEach(function (card, index) {
                card.classList.remove('highlight');
                cardsBuffer.push(card);
            });

            if (filterBy) {

                cardsWrapper.classList.add('cmp-store-cards--fade-out');

                switch (filterBy) {


                    case "filterByRelevance":
                        cardsBuffer.sort(function (a, b) {
                            return a.dataset.relevance.localeCompare(b.dataset.relevance, undefined, {numeric: true});
                        });
                        break;

                    case "filterByDistance":
                        cardsBuffer.sort(function (a, b) {
                            return a.dataset.distance.localeCompare(b.dataset.distance, undefined, {numeric: true});
                        });
                        break;

                    case "filterByOpen":
                        cardsBuffer.sort(function (a, b) {
                            return a.dataset.open.localeCompare(b.dataset.open, undefined, {numeric: true});
                        });
                        break;

                    case "filterByClose":
                        cardsBuffer.sort(function (a, b) {
                            return a.dataset.close.localeCompare(b.dataset.close, undefined, {numeric: true});
                        }).reverse();
                        break;

                    case "filterByName":
                        cardsBuffer.sort(function (a, b) {
                            return a.dataset.name.localeCompare(b.dataset.name);
                        });
                        break;
                }
            }

            // Wait for the Fade Animation
            setTimeout(() => {

                let storePinNumber = 1;

                cardsBuffer.forEach(function (card, index) {

                    if (card.classList.contains('hide-filter')) {
                        card.classList.remove('hide');
                        card.classList.remove('hide-filter');
                    }

                    if (!card.classList.contains('hide')) {
                        card.querySelector('h5 span').innerHTML = storePinNumber;
                        storePinNumber++;
                    }

                    storeCards += card.outerHTML;
                });

                storePinNumber--;
                storeCardsHeader = "<h5>" + storePinNumber + " " + translatedText.nearbystores + "</h5>"

                cardsWrapper.innerHTML = storeCardsHeader + "<div class='cards-wrapper'>" + storeCards + "</div>";
                cardsBindToMap();

                window.BATO.storeLocator.basketTransfer.exists ? basketTransferFilterRender() : '';
                cardsWrapper.classList.remove('cmp-store-cards--fade-out');

                // Rerender FSD Card after Sort - Since FSD data not stored in Profile ...
                if (typeof window.BATO?.storeLocator?.handoffCheck === 'function') {
                    window.BATO.storeLocator.handoffCheck();
                }

            }, 500);

        }

        window.BATO.cardsSort = cardsSort;

    }

    // Close Purchase Online Modal
    const purchaseButtonModalClose = () => {

        let tireCardGrid = document.querySelector(".tire-card-grid");
        let tireDetailLocate = document.querySelector(".will-it-fit__notfit");
        let cardsWrapper = document.querySelector(".cmp-store-cards");
        let pageWrapper = document.querySelector(".page");

        // Fallback on where to Focus on Modal Close
        if (tireCardGrid) {
            window.BATO.modal.deactivateModal(tireCardGrid); // Tire Search Results Grid
        } else if (tireDetailLocate) {
            window.BATO.modal.deactivateModal(tireDetailLocate); // Tire Detail Locate Button
        } else if (cardsWrapper) {
            window.BATO.modal.deactivateModal(cardsWrapper); // Store Cards Wrapper (Tire Details or Dealer Search)
        } else {
            window.BATO.modal.deactivateModal(pageWrapper); // Page Wrapper as last fallback
        }
    }

    // Purchase at Store (Basket Transfer) Redirect Prompt
    const purchaseButtonModal = (name, href, number) => {

        return `<div class="redirect-modal" data-name="${name}" data-number="${number}">
                    <div class="h2">You are being redirected to ${name}</div>
                    <div>
                        <a href="${href}" target="_blank" class="button">Yes, Continue</a>
                        <button class="button button--tertiary"">Cancel</button>
                    </div>
                </div>`;
    }

    const purchaseButtonEvent = (btn) => {

        // Close Store Card Flyout Before Activating Modal
        if (document.querySelector('.dynamic-top-container')) {

            window.BATO.storeLocator.purchaseButtonModalClose();

            // This is the only method that consistantly works ...
            setTimeout(() => {
                // Pass Data Variables for Analytics
                window.BATO.modal.activateModal(window.BATO.storeLocator.purchaseButtonModal(btn.dataset.name, btn.dataset.href, btn.dataset.number), true, null, true, 'modal');
            }, 200);

        } else {
            // Pass Data Variables for Analytics
            window.BATO.modal.activateModal(window.BATO.storeLocator.purchaseButtonModal(btn.dataset.name, btn.dataset.href, btn.dataset.number), true, null, true, 'modal');

        }

    }

    const hideNonOnlineRetailers = (basketTransferInput) => {

        let cardsWrapper = document.querySelector('.cmp-store-cards');

        if (document.querySelector(".tire-detail") !== null) {
            let allCardsWrapper = document.querySelectorAll(".cmp-store-cards");
            cardsWrapper = allCardsWrapper[allCardsWrapper.length - 1];
        }

        let storeCardsVisible = cardsWrapper.querySelectorAll('.store-card:not(.hide)');

        if (storeCardsVisible && storeCardsVisible.length > 0) {

            if (basketTransferInput.checked) {

                let storeCardArray = [];

                storeCardsVisible.forEach((storeCard) => {

                    if ((storeCard.querySelector('.basket-transfer[data-href="false"]')) ||
                        (storeCard.querySelector('.basket-transfer[data-href]:not([data-href=""]') == null)) {
                        storeCard.classList.add('hide');
                        storeCard.classList.add('hide-filter');
                        storeCardArray.push(storeCard.dataset.number);
                    }

                });

                storeCardsVisible.length === 0 ? basketTransferFilter.classList.add('hide') : '';

                let mapPinsLength = window.map.entities.getLength();

                for (let x = 0; x < mapPinsLength; x++) {
                    let pin = window.map.entities.get(x);
                    if (storeCardArray.includes(pin.metadata.storeNumber)) {
                        pin.setOptions({visible: false});
                    }
                }

                storeCardsVisible = cardsWrapper.querySelectorAll('.store-card:not(.hide)');
                let storeString = 'Stores';
                storeCardsVisible.length === 1 ? storeString = 'Store' : '';
                cardsWrapper.querySelector('.basket-transfer-filter p').innerText = 'Showing ' + storeCardsVisible.length + ' Online ' + storeString;

            } else {

                let storeCardsHidden = cardsWrapper.querySelectorAll('.store-card.hide-filter');

                if (storeCardsHidden && storeCardsHidden.length > 0) {

                    let storeCardArray = [];

                    storeCardsHidden.forEach((storeCard) => {
                        storeCard.classList.remove('hide');
                        storeCard.classList.remove('hide-filter');
                        storeCardArray.push(storeCard.dataset.number);
                    });

                    let mapPinsLength = window.map.entities.getLength();

                    for (let x = 0; x < mapPinsLength; x++) {
                        let pin = window.map.entities.get(x);

                        if (storeCardArray.includes(pin.metadata.storeNumber)) {
                            pin.setOptions({visible: true});
                        }
                    }
                }

                cardsWrapper.querySelector('.basket-transfer-filter p').innerText = 'Show Buy Online Stores';
            }

        } else {

            let basketTransferFilter = cardsWrapper.querySelector(".basket-transfer-filter");
            basketTransferFilter.classList.add('hide');

        }
    }

    const basketTransferFilterShowHide = (setTrue) => {

        let cardsWrapper = document.querySelector(".cmp-store-cards");

        if (document.querySelector(".tire-detail") !== null) {
            let allCardsWrapper = document.querySelectorAll(".cmp-store-cards");
            cardsWrapper = allCardsWrapper[allCardsWrapper.length - 1];
        }

        let basketTransferFilter = cardsWrapper.querySelector(".basket-transfer-filter");

        if (basketTransferFilter) {

            let basketTransferInput = basketTransferFilter.querySelector("#basketTransferFilter");
            basketTransferInput ? basketTransferInput.checked = window.BATO.storeLocator.basketTransfer.checked : '';
            basketTransferFilter.querySelector('p').innerText = 'Show Buy Online Stores';

            const returnBasketTransfers = () => {

                let allBasketTransfers = cardsWrapper.querySelectorAll('.store-card:not(.hide) .basket-transfer[data-href]:not([data-href="false"]')

                return new Promise((resolve) => {
                    resolve(allBasketTransfers);
                });

            }

            const getAllBasketTransfers = async () => {
                const returnedBasketTransfers = await returnBasketTransfers();

                if (returnedBasketTransfers.length !== 0) {
                    basketTransferFilter.classList.remove('hide');
                } else {
                    basketTransferFilter.classList.add('hide');
                }
            }

            if(setTrue) {
                basketTransferFilter.classList.remove('hide');
            } else {
                getAllBasketTransfers();
            }
        }
    }

    const basketTransferFilterRender = () => {

        let cardsWrapper = document.querySelector(".cmp-store-cards");

        let basketTransferFilter = cardsWrapper.querySelector('.basket-transfer-filter');

        if(!basketTransferFilter) {

            if (document.querySelector(".tire-detail") !== null) {
                let allCardsWrapper = document.querySelectorAll(".cmp-store-cards");
                cardsWrapper = allCardsWrapper[allCardsWrapper.length - 1];
            }

            let basketTransferFilter = document.createElement('div');
            basketTransferFilter.classList.add('basket-transfer-filter');
            basketTransferFilter.classList.add('hide');

            let basketTransferInput = document.createElement('input');

            Object.assign(basketTransferInput, {
                type: 'checkbox',
                id: 'basketTransferFilter',
                value: 'basketTransferFilter',
                name: 'basketTransferFilter'
            });

            // This is to check if Basket Transfer Filter Clicked and then Sorting Option Clicked
            if (window.BATO.storeLocator.basketTransfer.checked === true) {
                basketTransferInput.checked = true;
                hideNonOnlineRetailers(basketTransferInput);
            }

            basketTransferInput.addEventListener('click', () => {
                window.BATO.storeLocator.basketTransfer.checked = basketTransferInput.checked;
                hideNonOnlineRetailers(basketTransferInput);
            });

            let basketTransferLabel = document.createElement('label');
            basketTransferLabel.classList.add('switch');
            basketTransferLabel.setAttribute('for', 'basketTransferFilter');

            let basketTransferSpan = document.createElement('span');
            basketTransferSpan.classList.add('slider');
            basketTransferSpan.classList.add('round');

            let basketTransferText = document.createElement('p');
            basketTransferText.innerText = 'Show Buy Online Stores';

            basketTransferLabel.append(basketTransferInput);
            basketTransferLabel.append(basketTransferSpan);
            basketTransferFilter.append(basketTransferLabel);
            basketTransferFilter.append(basketTransferText);

            let cardsWrapperList = cardsWrapper.querySelector('.cards-wrapper');
            cardsWrapper.insertBefore(basketTransferFilter, cardsWrapperList);
        }

    }

    // Event Listeners for Basket Transfer Flow
    const purchaseButtonSetup = async () => {

        let previouslyViewedTires = window.BATO.Store.getState().flex?.previouslyViewedTires;

        // Make Sure We Have a Tire in our Previously Viewed Tire Data
        if (previouslyViewedTires && previouslyViewedTires.length > 0) {

            let tireDetail = document.querySelector('.tire-detail');
            let cardsWrappers = document.querySelectorAll(".cmp-store-cards"); // Account for Multiple Store Card Areas

            // Construct Our URL based on Domain
            let currentSubdomain = window.location.hostname.split(".")[0];
            let basketTransferSubdomain = "www";

            // Match Subdomains Across Environments
            if (currentSubdomain === 'ix-dev-tng' || currentSubdomain === 'ix-dev') basketTransferSubdomain = "ix-dev";
            if (currentSubdomain === 'ix-qa-tng' || currentSubdomain === 'ix-qa') basketTransferSubdomain = "ix-qa";
            if (currentSubdomain === 'ix-r-tng' || currentSubdomain === 'ix-r') basketTransferSubdomain = "ix-r";

            if (currentSubdomain === 'beta-int' || currentSubdomain === 'cwh-int') basketTransferSubdomain = "cwh-int";
            if (currentSubdomain === 'beta-qa' || currentSubdomain === 'cwh-qa') basketTransferSubdomain = "cwh-qa";
            if (currentSubdomain === 'beta-uat' || currentSubdomain === 'cwh-uat') basketTransferSubdomain = "cwh-uat";

            // Create Array of All Article Numbers if Applicable
            let allArticles = (previouslyViewedTires[0].hasOwnProperty('articles')) ? previouslyViewedTires[0].articles.split(',') : null;

            // If Something Has Gone Really Wrong, ie 'articles' is not present ...
            if (!allArticles) {

                // If On Tire Detail Page Check for Data from Dropdoown Selection
                if (tireDetail) {

                    let tireSizeDropdown = tireDetail.querySelector(".tire-size-dropdown");
                    let selectedSize = tireSizeDropdown.options[tireSizeDropdown.selectedIndex].dataset;

                    allArticles = [selectedSize.articles];

                    // Use articlenumber (singluar) as a last resort
                } else {

                    let singleArticle = (previouslyViewedTires[0].hasOwnProperty('articlenumber')) ? previouslyViewedTires[0].articlenumber : null;
                    let singleArticleClean = (previouslyViewedTires[0].hasOwnProperty('articlenumberclean')) ? previouslyViewedTires[0].articlenumberclean : null;

                    if (singleArticleClean) {
                        allArticles = [singleArticleClean]; // In case Click from 'Recommended' Cards and Redux Is Missing 'articles' data attr
                    } else {
                        allArticles = [singleArticle];
                    }


                }

            }

            // Check Availability at FCAC Stores before Looping Through Buttons
            let storeArray = ["FCAC", "TP", "WW", "HTP"];
            let validArticles = {
                'FCAC': '',
                'TP': '',
                'WW': '',
                'HTP': ''
            };

            // Since the BSRO API has a latent delay, we run the Article ID Availability Check Here
            for (const store of storeArray) {

                let purchaseButtonCheck = document.querySelectorAll('button.basket-transfer[data-storehandoff=' + store + ']');

                if (purchaseButtonCheck.length > 0) {

                    let matchFound = false,
                        availabilityResult = "",
                        articleToUse = "",
                        storeToUse = getFCACUrl(store);

                    /*
                    if (store == 'TP') storeToUse = "tiresplus.com";
                    if (store == 'WW') storeToUse = "wheelworks.net";
                    if (store == 'HTP') storeToUse = "hibdontire.com";
                    */

                    let availabilityURL = "https://" + basketTransferSubdomain + "." + storeToUse + "/bsro/services/handoff/availability?articleId=";

                    for (const article of allArticles) {

                        //console.log(article + " / " + allArticles);

                        availabilityResult = "";

                        if (matchFound === false) {

                            availabilityResult = await (await fetch(availabilityURL + article)).json();

                            // console.log("availabilityResult",availabilityResult);

                            if (availabilityResult.data.available === "true") {
                                matchFound = true;
                                articleToUse = article;
                            }
                        }
                    }

                    matchFound !== false ? validArticles[store] = articleToUse : '';

                }

            }

            cardsWrappers.forEach(function (cardsWrapper, index) {

                let allPurchaseButtons = cardsWrapper.querySelectorAll('button.basket-transfer');

                allPurchaseButtons.forEach(function (btn, index) {

                    let basketTransferURL;

                    if (btn.dataset.storehandoff === "Costco") {

                        let costcoArticle = previouslyViewedTires[0].articlenumber;
                        previouslyViewedTires[0].articlenumberclean ? costcoArticle = previouslyViewedTires[0].articlenumberclean : '';

                        // CostCo expects Article/Part Numbers to be exactly 6 Numbers in Length
                        // If our system truncates the number by removing leading zeros (0) we have to
                        // reconstruct the number by adding the zeros back in ...

                        costcoArticle = setArticleNumberLength(costcoArticle);

                        basketTransferURL = "https://tires.costco.com/SearchResultsByItemOrPart?";
                        basketTransferURL += "partno=" + costcoArticle + "&";
                        basketTransferURL += "lang=en-us&";
                        basketTransferURL += "vendor=Bridgestone&";
                        basketTransferURL += "whs=" + btn.dataset.externalstoreid + "&";
                        basketTransferURL += "zipCode=" + btn.dataset.zip;

                        btn.dataset.href = basketTransferURL;

                        if (cardsWrapper.parentElement.className !== 'tire-detail__stores-wrapper' &&
                            window.BATO.storeLocator.basketTransfer.exists === false) {
                            window.BATO.storeLocator.basketTransfer.exists = true;
                            targetProxy.exists = true;
                        }

                    } else if (btn.dataset.storehandoff === "TCE") {
                        let storeNumber = btn.dataset.number;
                        storeNumber.length < 6 ? storeNumber = '0' + storeNumber : '';

                        let tceArticle = setArticleNumberLength(allArticles[0]);

                        availabilityService.getAvailabilityByStoreArticleRetailer(storeNumber, tceArticle, 'TCE').then(tceAvailabilityResult => {
                            if (tceAvailabilityResult.data.available === "true") {

                                basketTransferURL = "https://wl.tireconnect.ca/redirect/bato?";
                                basketTransferURL += "storeNumber=" + storeNumber + "&";
                                basketTransferURL += "zipCode=" + btn.dataset.zip + "&";

                                // If Vehicle is Known, Pass Vehicle Details
                                if (window.BATO.Store.getState().vehicles.status === "known") {
                                    basketTransferURL += "year=" + vehicleData.year + "&";
                                    basketTransferURL += "make=" + vehicleData.make + "&";
                                    basketTransferURL += "model=" + vehicleData.model + "&";
                                    basketTransferURL += "trim=" + vehicleData.trim + "&";
                                }

                                basketTransferURL += "frontArticleNumber=" + allArticles[0] + "&";
                                basketTransferURL += "frontQuantity=" + "4" + "&";
                                //basketTransferURL += "rearArticleNumber=" + allArticles[0] + "&";
                                //basketTransferURL += "rearQuantity=" + "2" + "&";
                                basketTransferURL += "acesVehicleId=" + vehicleData.acesVehicleId;
                                btn.dataset.href = basketTransferURL;

                                if (cardsWrapper.parentElement.className !== 'tire-detail__stores-wrapper' &&
                                    window.BATO.storeLocator.basketTransfer.exists === false) {
                                    window.BATO.storeLocator.basketTransfer.exists = true;
                                    targetProxy.exists = true;
                                }

                            } else {
                                btn.dataset.href = 'false';
                            }

                        });

                    } else if (btn.dataset.storehandoff === "FCAC" || btn.dataset.storehandoff === "TP" || btn.dataset.storehandoff === "WW" || btn.dataset.storehandoff === "HTP") {

                        if (validArticles[btn.dataset.storehandoff] === '') {

                            btn.dataset.href = 'false';

                        } else {

                            // Article Number Is The Most Relevant Number To Pass
                            // Tire Size and Vehicle Type are Not Pertinent, but Nice To Pass

                            // tireSize comes from Tire Detail Page, tireSizeBase comes from 'Locate Tire' CTA on Tire Search Results
                            let tireSize = previouslyViewedTires[0].size,
                                tireSizeBase = previouslyViewedTires[0].sizebaseurl,
                                formattedTireSize = "",
                                baseURL = 'firestonecompleteautocare.com';

                            // Format Tire Size as Online Retailers Want - XXX-XX-XX

                            // Tire Size from tireSize
                            if (tireSize !== undefined) formattedTireSize = tireSize.replace(/\D/g, '-');

                            // Tire Size From tireSizeBase
                            if (tireSizeBase !== undefined) formattedTireSize = tireSizeBase.replace(/[a-z]/gi, '-');

                            // Remove Errant Front Dash from 'P' Tire Sizes
                            if (formattedTireSize.charAt(0) === '-') formattedTireSize = formattedTireSize.substring(1);

                            // Match Dealer URL
                            if (btn.dataset.storehandoff === 'TP') baseURL = "tiresplus.com";
                            if (btn.dataset.storehandoff === 'WW') baseURL = "wheelworks.net";
                            if (btn.dataset.storehandoff === 'HTP') baseURL = "hibdontire.com";

                            basketTransferURL = "https://" + basketTransferSubdomain + "." + baseURL + "/bsro/services/handoff/inbound/?";
                            basketTransferURL += "url=/tires/brands/&"
                            basketTransferURL += "service=quote&";
                            basketTransferURL += "storeNumber=" + btn.dataset.number + "&";
                            basketTransferURL += "zipCode=" + btn.dataset.zip + "&";

                            // If Vehicle is Known, Pass Vehicle Details
                            if (window.BATO.Store.getState().vehicles.status === "known") {
                                basketTransferURL += "year=" + vehicleData.year + "&";
                                basketTransferURL += "make=" + vehicleData.make + "&";
                                basketTransferURL += "model=" + vehicleData.model + "&";
                                basketTransferURL += "trim=" + vehicleData.trim + "&";
                            }

                            if (formattedTireSize !== "") {
                                // If Size Is Known, Pass Size
                                basketTransferURL += "size=" + formattedTireSize + "&";
                            }

                            basketTransferURL += "frontArticleNumber=" + validArticles[btn.dataset.storehandoff] + "&";
                            basketTransferURL += "frontQuantity=4";

                            btn.dataset.href = basketTransferURL;

                            if (cardsWrapper.parentElement.className !== 'tire-detail__stores-wrapper' &&
                                window.BATO.storeLocator.basketTransfer.exists === false) {
                                window.BATO.storeLocator.basketTransfer.exists = true;
                                targetProxy.exists = true;
                            }
                        }
                    }

                    btn.addEventListener("click", (e) => {
                        purchaseButtonEvent(btn);
                    });
                });
            });

            // Close Modal, Even on 'Yes, Continue'
            let pageWrapper = document.querySelector(".page");

            (async () => {
                while (!window.BATO?.storeLocator.hasOwnProperty("purchaseButtonModalClose"))
                    await new Promise(resolve => setTimeout(resolve, 250));

                elementListener.addBubblerListener(pageWrapper, 'click', '.redirect-modal a.button', window.BATO.storeLocator.purchaseButtonModalClose);
                elementListener.addBubblerListener(pageWrapper, 'click', '.redirect-modal button.button--tertiary', window.BATO.storeLocator.purchaseButtonModalClose);
            })();
        }

    }

    if (cardsWrapper) {

        const updateStores = () => {

            //Remove previously results from the map
            window.hasOwnProperty("map") ? window.map.entities.clear() : '';
            window.BATO.hasOwnProperty("mapPins") ? delete window.BATO.mapPins : '';

            /*
                Moving Store Card Render to Async Function to handle Race Condition
                introduced when Purchase Button event attachment was happening before Store Card
                Rendering - Issue only presented on Dealer SEO Pages, but better to move all
                card rendering to promise based system
                Also affected:
                    tire-detail.js
                    store-locator-flyout.js
                    location-search.js
            */
            const asyncCardRender = async (customQty, customStoreList) => {
                const cardData = await getStoreCards(customQty, customStoreList);
                cardsWrapper.innerHTML = cardData;
                basketTransferFilterRender();
                purchaseButtonSetup();
            }

            const params = document.querySelector('.store-seo-funnel .ssf-storecards');

            if (params?.dataset?.city && params?.dataset?.state) {
                window.BATO.ProfileActions.setStoresByCityState(params.dataset.city, params.dataset.state).then(seoStores => {
                    asyncCardRender(parseInt(seoStores.storeListByCityState.data.qty), seoStores.storeListByCityState);
                });
            } else {
                asyncCardRender();
            }

            window.hasOwnProperty("map") ? window.BATO.mapSort() : '';
            bindStoreCards();

            window.BATO.storeLocator.hasOwnProperty("setRadiusValues") ? window.BATO.storeLocator.setRadiusValues() : '';
        }

        window.BATO.Store.observeStore(window.BATO.Store, function (state) {
            return state.stores;
        }, updateStores);

    }

    // Listener for 'any' Basket Transfer exists condition
    // Render the Filter when found
    // This is because of severe latency with TCE availability checks in certain locations
    let targetProxy = new Proxy(window.BATO.storeLocator.basketTransfer, {
        set: function (target, key, value) {
            target[key] = value;
            basketTransferFilterShowHide(value);
            return true;
        }
    });

    window.BATO.storeLocator.getStoreCards = getStoreCards;
    window.BATO.storeLocator.bindStoreCards = bindStoreCards;

    window.BATO.storeLocator.purchaseButtonSetup = purchaseButtonSetup;
    window.BATO.storeLocator.purchaseButtonModal = purchaseButtonModal;
    window.BATO.storeLocator.purchaseButtonModalClose = purchaseButtonModalClose;
    window.BATO.storeLocator.purchaseButtonEvent = purchaseButtonEvent;

    window.BATO.storeLocator.basketTransferFilterRender = basketTransferFilterRender;
    window.BATO.storeLocator.basketTransferFilterShowHide = basketTransferFilterShowHide;

});