import React, { Fragment, useEffect, useState } from 'react';
import { DynamicPortal } from '../../../react-shared/utils/DynamicPortal.jsx';

import { HeaderVehicleApp } from './HeaderVehicleApp.jsx';

const vehicleContainerApps = (appRoots) => {
  const portalArray = appRoots
    .map((root, i) => {
      const uniqueId = `${i}${new Date().getTime()}`;
      const portalId = root.id || 'vehicleHeaderContainer';
      const searchForTiresText = root.dataset.searchForTires || 'Search for Tires';
      const viewTiresText = root.dataset.viewTires || 'View Tires'
      const tireDecisionGuideText = root.dataset.tireDecisionGuide || 'Tire Decision Guide';
      return (
        <Fragment>
          <DynamicPortal
            id={portalId}
            className="search-for-tires-container"
            dynamicParentElement={root}
            hideParent={true}
          >
            <HeaderVehicleApp searchForTires={searchForTiresText} viewTires={viewTiresText} tireDecisionGuide={tireDecisionGuideText} />
          </DynamicPortal>
        </Fragment>
      );
    })
    .filter(Boolean);

    return portalArray;
};

const HeaderVehicleLoader = () => {
  const vehicleContainers = Array.from(
    document.querySelectorAll('.search-for-tires-container')
  );

  const [roots, setRoots] = useState(vehicleContainers);
  const [portals, setPortals] = useState(vehicleContainerApps(roots));

  useEffect(()=> {
    setPortals(vehicleContainerApps(roots));
  }, [roots]);

  return portals ? portals : null;
};

export { HeaderVehicleLoader };