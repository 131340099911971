import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import { RootReactApp } from './RootReactApp.jsx';

const reactAppLoader = async () => {
  try {

    if (!window.BATO) throw new Error ('there is no window.BATO object');
    if (!window.BATO.Store) throw new Error('there is no window.BATO.Store object');
    if (!window.BATO.CONFIG) throw new Error('there is no window.BATO.CONFIG object');

    const store = window.BATO.Store;
    const config = window.BATO.CONFIG;

    const rootDiv = document.createElement('div');

    return render(
      <Provider store={store}>
        <RootReactApp config={config} />
      </Provider>
    , rootDiv);

  } catch (err) {
    console.log("error in Consumer Tire react-loader: ", err);
    return { success: false };
  }

};

export { reactAppLoader };

reactAppLoader();