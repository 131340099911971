import React from 'react';
import RatingsLoader from './ui/RatingsLoader.jsx';
import { RatingsProvider } from './ui/ratings-context.js';

const RatingsApp = ( { config }) => {
	return (
		<RatingsProvider>
			<RatingsLoader config={config} />
		</RatingsProvider>
	);
};

export { RatingsApp };