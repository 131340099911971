import { scrollToElement } from '../../../../../../js/utils/scrollToElement.js';
import { getStoresByLatLon } from '../../../../services/v1/js/api/stores.js';

if (!window.BATO) window.BATO = {};
if (!window.BATO.map) window.BATO.map = {};
if (!window.BATO.storeLocator) window.BATO.storeLocator = {};

// TODO: These should come from context aware config
// const MAP_KEY = 'Alqx3peKgY_8B05zrSse0rDrgzAF9hoQ7hIDk1r8MVx9BO_4Pnk7n8FfGXXWWIdO';
const MAP_KEY = window.BATO.COMPONENTS.StoreLocator.v1.mapAPIKey;
const icon = "<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26 36' id='shape-mapPin-red' width='26' height='36'><path fill='#ff0017' d='M13 0C5.82 0 0 5.757 0 12.858S13 36 13 36s13-16.041 13-23.142S20.18 0 13 0z'></path></svg>";

let theMap = document.querySelector('.map__render'),
    pins = [];
let tdgStores;
let stores;

const mapInit = () => {

    theMap = document.querySelector('.map__render');

    // Destroy Any Instance of Bing Map Before Reinstating
    // We must do this to allow for Modal Flyouts with Maps
    if(window.map) {
        window.map.dispose();
        window.map = null;
        window.BATO.mapPins = null;
        delete window.BATO.mapPins;
    }

    if (document.querySelector('.tire-decision-guide') !== null && sessionStorage.getItem('tdgLocation')) {
        stores = getStoresByLatLon({lat: JSON.parse(sessionStorage.getItem('tdgLocation'))[0], lon: JSON.parse(sessionStorage.getItem('tdgLocation'))[1]});
        window.map = new Microsoft.Maps.Map(theMap, {
            credentials: MAP_KEY,
            center: new Microsoft.Maps.Location(JSON.parse(sessionStorage.getItem('tdgLocation'))[0], JSON.parse(sessionStorage.getItem('tdgLocation'))[1]),
            mapTypeId: Microsoft.Maps.MapTypeId.ROADMAP,
            showMapTypeSelector: false,
            disableScrollWheelZoom: true,
            showLocateMeButton: false,
            showTermsLink: false,
            zoom: 10,
        });
    } else {
        window.map = new Microsoft.Maps.Map(theMap, {
            credentials: MAP_KEY,
            center: new Microsoft.Maps.Location(window.BATO.map.latitude, window.BATO.map.longitude),
            mapTypeId: Microsoft.Maps.MapTypeId.ROADMAP,
            showMapTypeSelector: false,
            disableScrollWheelZoom: true,
            showLocateMeButton: false,
            showTermsLink: false,
            zoom: 10,
        });
    }

    pins = [];

    if (document.querySelector('.tire-decision-guide') !== null && sessionStorage.getItem('tdg_params') && !sessionStorage.getItem('1')) {
        stores.then((d) => {
            tdgStores = d;
            mapSort()
        })
    } else {
        window.BATO.Store.getState().stores.data ? mapSort() : '';
    }

}

window.mapInit = mapInit;

const mapLoad = (customCallback) => {

    if(!window.map || customCallback){

        window.BATO.map = {
            latitude:  41.85003,
            longitude: -87.65005
        }

        let pageWrapper = document.querySelector('.page');
        let existingBingScript = document.getElementById('bingScript');

        if ( existingBingScript ) {
            pageWrapper.removeChild(existingBingScript);
        }

        let bingScript = document.createElement('script'),
            bingURL = 'https://www.bing.com/api/maps/mapcontrol';

        customCallback ? bingURL += '?callback=' + customCallback : bingURL += '?callback=mapInit';

        bingScript.setAttribute('id','bingScript');
        bingScript.setAttribute('src', bingURL);
        bingScript.setAttribute('async', '');
        bingScript.setAttribute('defer', '');
        document.body.appendChild(bingScript);

    } else {

        mapInit();

    }

}

window.BATO.mapLoad = mapLoad;

const mapSort = (filterBy) => {

    pins = [];

    let mapObjStores;
    if (tdgStores) {
        mapObjStores = tdgStores;
    } else {
        mapObjStores = window.BATO.Store.getState().stores;
    }

    // List of Stores was moved from keyname storeNames to storeNumbers, below is to support cached users who do not perform a new location search
    let map = window.map,
        storeIDs = (mapObjStores?.data.storeNumbers != null) ? mapObjStores?.data.storeNumbers : mapObjStores?.data.storeNames,
        storeData = mapObjStores?.data.stores,
        locations = [],
        latlng = "",
        indexAdjusted = "",
        storeNumber = "",
        distance = "",
        storeHours = "",
        storeOpen = "",
        storeClose = "",
        storeName = "",
        d = new Date().getDay();

    const setupMap = () => {

        map.entities.clear();

        if (storeIDs?.length) {

            let storeDetailPage = document.querySelector('.store-details');

            if (storeDetailPage) {

                let singleStore = storeDetailPage.querySelector('.store-details-info__location');
                storeIDs = [singleStore.dataset.storeNumber];

            }

            storeIDs.forEach(function (storeID, index) {

                let locationData = storeData[storeID]['location'];

                latlng = new Microsoft.Maps.Location(parseFloat(locationData['latitude']), parseFloat(locationData['longitude']));
                storeNumber = storeData[storeID]['base']['storeNumber'];
                distance = locationData['distance'];

                storeHours = storeData[storeID]['hours'];
                storeOpen = storeHours[d]?.openTime || "";
                storeClose = storeHours[d]?.closeTime || "";

                storeName = storeData[storeID]['base']['storeName'];

                index++;
                indexAdjusted = index.toString();

                locations.push({
                    relevance: indexAdjusted,
                    latlng: latlng,
                    storeNumber: storeNumber,
                    distance: distance,
                    open: storeOpen,
                    close: storeClose,
                    storeName: storeName
                });

            });

            if (typeof filterBy !== "undefined") {

                switch (filterBy) {

                    case "filterByRelevance":
                        locations.sort(function (a, b) {
                            return a.relevance.localeCompare(b.relevance, undefined, {
                                numeric: true,
                                sensitivity: 'base'
                            });
                        });
                        break;

                    case "filterByDistance":
                        locations.sort(function (a, b) {
                            return a.distance.localeCompare(b.distance, {numeric: true});
                        });
                        break;

                    case "filterByOpen":
                        locations.sort(function (a, b) {
                            return a.open.localeCompare(b.open, {numeric: true})
                        });
                        break;

                    case "filterByClose":
                        locations.sort(function (a, b) {
                            return a.close.localeCompare(b.close, {numeric: true});
                        }).reverse();
                        break;

                    case "filterByName":
                        locations.sort(function (a, b) {
                            return a.storeName.localeCompare(b.storeName);
                        });
                        break;
                }

            }

            mapAddPins(locations, filterBy);
        }
    }

    const params = document.querySelector('.store-seo-funnel .ssf-storecards');

    if(params?.dataset?.city && params?.dataset?.state) {

        window.BATO.ProfileActions.setStoresByCityState(params.dataset.city, params.dataset.state).then(seoStores => {
            storeIDs = seoStores.storeListByCityState.data.storeNumbers;
            storeData = seoStores.storeListByCityState.data.stores;
            setupMap();
        });

    } else {

        setupMap();

    }

}

window.BATO.mapSort = mapSort;

const mapAddPins = (locations, filterBy) => {
    
    window.BATO.mapPins = "";
    window.BATO.storeLocator.bingLatLngReady = false;

    let map = window.map,
        bingLatLng = [],
        bounds = [],
        center = [],
        storePinNumber = 1,
        radiusSelected = 'flyoutMap',
        radiusSelector = document.querySelector('input[name="distance"]:checked');

    // If Sorting, bypass the Radius Selector Check
    typeof filterBy !== "undefined" ? window.BATO.storeLocator.radiusReady = true : '';

    // If no radiusSelector found, we are in a flyout w/o a Radius Selector, so jump in the loop immediately
    !radiusSelector ? window.BATO.storeLocator.radiusReady = true : '';

    // Otherwise we wait for the Radius Selector to be ready so we populate the map correctly
    (async() => {
        while(!window.BATO.storeLocator.radiusReady)
            await new Promise(resolve => setTimeout(resolve, 200));

        if (typeof filterBy !== "undefined") {
            radiusSelector = document.querySelector('input[name="distance"]:checked');
        } else {
            radiusSelector = document.querySelector('input[name="distance"]:last-of-type');
        }

        radiusSelector ? radiusSelected = parseFloat(radiusSelector.value) : '';

        locations.forEach((location, index) => {

            if((radiusSelected == 'flyoutMap') && (index > 9)) {

                return false;

            } else {

                let pinOptions = {
                    icon: icon,
                    anchor: new Microsoft.Maps.Point(13, 36)
                }

                // If Radius Selected, Place but Hide The Appropriate Pins
                if( (parseFloat(location['distance']) <= radiusSelected) || radiusSelected == 'flyoutMap') {
                    pinOptions.visible = true;
                    pinOptions.text = storePinNumber.toString();
                    storePinNumber++;
                } else {
                    pinOptions.visible = false;
                }

                let pin = new Microsoft.Maps.Pushpin(location['latlng'], pinOptions);

                pin.metadata = {
                    relevance: location['relevance'],
                    latlng: location['latlng'],
                    storeNumber: location['storeNumber'],
                    distance: location['distance'],
                    storeName: location['storeName']
                }

                Microsoft.Maps.Events.addHandler(pin, 'click', function () {
                    mapInteractions(pin);
                });

                pins.push(pin);

                if( (parseFloat(location['distance']) <= radiusSelected) || radiusSelected == 'flyoutMap') {
                    bingLatLng.push(location['latlng']);
                }
            }

        });

        window.BATO.storeLocator.radiusReady = false;

        map.entities.push(pins); // Add Pins to Map
        window.BATO.mapPins = pins;

        center = bingLatLng[0];
        window.BATO.mapCenter = center;

        bounds = Microsoft.Maps.LocationRect.fromLocations(bingLatLng); // Wants array of Microsoft.Maps.Location objects
        window.BATO.mapBounds = bounds;

        let renderMapFromCenter = false;

        locations.length == 1 ? renderMapFromCenter = true : '';

        mapBoundsReset(renderMapFromCenter);

    })();
}

const mapBoundsReset = (renderMapFromCenter) => {

    let mapOptions = {};

    if(renderMapFromCenter) {
        mapOptions = {
            center: window.BATO.mapCenter,
            zoom: 15
        }
    } else {
        mapOptions = {
            bounds: window.BATO.mapBounds
        }
    }

    map.setView(mapOptions);

    window.BATO.storeLocator.bingLatLngReady = true;

}

window.BATO.mapBoundsReset = mapBoundsReset;

const mapInteractions = (e, interactionFrom) => {

    let storeNumber = e.metadata.storeNumber,
        storeCards = document.querySelector('.cmp-store-cards'),
        storeCardsInner = storeCards.querySelector('div:last-of-type'),
        selectedCard,
        cardsInFlyout = false;

    // Are Cards In Overlay/Flyout
    if ( document.querySelector('.dynamic-top-content') ) {
        storeCards = document.querySelector('.dynamic-top-content .cmp-store-cards');
        cardsInFlyout = true;
    }

    // Check that Store Card Container Exists
    // Store Card Container is Removed on Tire Search Results when 'Purchase at Store' is Clicked
    if (storeCards) {

        let storeCardsScrolling = storeCards;

        // Clear All Highlights
        storeCards.querySelectorAll('.store-card').forEach(i => i.classList.remove('highlight'));

        // Scroll Card Into View & Highlight
        selectedCard = storeCards.querySelector('[data-number="' + storeNumber + '"]');

        // Bypass Animation if User is Tab Focusing Between Cards
        // Otherwise, Scroll Card Into View
        if(interactionFrom != 'focus') {

            let windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            let scrollFrom = 'top';

            // If Screen < Tablet and Not In Flyout
            if(windowWidth < 1024 && !cardsInFlyout) {
                scrollFrom = 'left';
                storeCardsScrolling = storeCardsInner;
            }

            // If Screen < Phablet and In Flyout
            if(windowWidth < 768 && cardsInFlyout) {
                scrollFrom = 'left';
                storeCardsScrolling = storeCardsInner;
            }

            scrollToElement(storeCardsScrolling, selectedCard , 1, scrollFrom);

        }

        selectedCard.classList.add('highlight');

    }

    // Reposition Map
    map.setView({
        center: e.metadata.latlng,
        zoom: 17
    });

}

window.BATO.mapInteractions = mapInteractions;

document.addEventListener("DOMContentLoaded", async function(event) {

    if(theMap) {

        try {

            mapLoad();

            // Make Map Responsive
            let storeLocator = document.querySelector('.store-locator'),
                storeDetails = document.querySelector('.store-details'),
                mapWrapper = document.querySelector('.map'),
                mapDivWidth = "";

            if((storeLocator || storeDetails) && mapWrapper) {

                const mapChangeWidth = () => {
                    storeLocator ? mapDivWidth = storeLocator.offsetWidth : mapDivWidth = storeDetails.offsetWidth;
                    mapWrapper.style.width = (mapDivWidth-400) + "px";
                }

                mapChangeWidth();
                window.addEventListener('resize', mapChangeWidth, true);

            }

        } catch(err) {

            console.error("there was a problem: ", err);

        }

    }

});
