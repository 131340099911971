import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { reviewsSortSwitch } from '.././../utils/reviewsSortSwitch.js';
import { useReviews } from '../reviews-context.js';
import { useSelector } from "react-redux";

const SortBy = (props) => {
    const i18n = useSelector( state => state.i18n);
    const { onChange, disabled } = props;
    const canSort = reviewsSortSwitch();

    const [ state, dispatch ] = useReviews();
    const {
        reviewData: {
            data: {
                reviews
            }
        },
        reviewSummary: {
            data: {
                summary: {
                    review_count: totalReviews
                }
            }
        }
    } = state;

    const onSort = (evt) => {
        evt.preventDefault();
        onChange(evt.target.value);
    };

    return (
        <div className="reviews-sort">
            {canSort && (
                <>
                    <div>{i18n.showing} 1-{reviews.length} {i18n.of} {totalReviews}{' '} {i18n.reviews}</div>
                    <div className="custom-select">
                        <label htmlFor="reviews-sort-by">{i18n.sortby}</label>
                        <select
                            name="reviews-sort-by"
                            id="reviews-sort-by"
                            onChange={onSort}
                            disabled={disabled}
                        >
                            <option value="latest">{i18n.mostrecent}</option>
                            <option value="highestRatings" default="default">
                                {i18n.highestratings}
                            </option>
                            <option value="oldest">{i18n.oldest}</option>
                            <option value="lowestRatings">
                                {i18n.lowestratings}
                            </option>
                        </select>
                    </div>
                </>
            )}
        </div>
    );
};

SortBy.defaultProps = {
    disabled: false
};

SortBy.propTypes = {
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool
};

export { SortBy };
