// setup window object for components to consume
if (!window.BATO) window.BATO = {};

if (!window.adobeDataLayer) window.adobeDataLayer = [];

const tabsClass = "cmp-tabs__tab"; 
const tabsAnalyticsObject = {
	"event": "component_click",
	"component_detail": {
		"type": "tab", 
		"title": "",
		"id": "",
		"click_element": {
			"title": "",
			"id": ""
		}
	}
};

let tabs;

export const init = () => {
	tabs = document.querySelectorAll(`.${tabsClass}`); 
	
	tabs.forEach(tab => {
		tab.addEventListener('click', tabEventHandler); 
	}); 
}

const tabEventHandler = (evt) => {
	let el = evt.currentTarget; 
	let analyticsObject = JSON.parse(JSON.stringify(tabsAnalyticsObject)); 
	
	analyticsObject.component_detail.title = analyticsObject.component_detail.click_element.title = el.innerText; 
	analyticsObject.component_detail.id = analyticsObject.component_detail.click_element.id = el.id;
	
	adobeDataLayer.push(analyticsObject); 
}

export default {
	init
}