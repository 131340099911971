import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Review } from '../atoms/Review.jsx';
import { SortBy } from '../atoms/SortBy.jsx';
// import { getProductReviews } from '../../../../data/services/reviews/reviews';
//import { satelliteTrack } from '../../../../data/services/analytics/satellite';
import { useReviews } from '../reviews-context.js';
import { useSelector } from "react-redux";

const ReviewsList = (props) => {
    
    const i18n = useSelector( state => state.i18n);

    const {
        handleLoadMoreReviews,
        handleSortReviews
    } = props;

    const [ state, dispatch ] = useReviews();

    const [liveData, setLiveData] = useState({
        reviewData: {
            status: state.reviewData.status,
            data: {
                nextPageKey: state.reviewData.data.nextPageKey,
                reviews: state.reviewData.data.reviews
            },
            params: {
                pageSize: state.reviewData.params.pageSize,
                sortBy: state.reviewData.params.sortBy
            }
        },
        totalReviews:  state.reviewSummary?.data?.summary?.review_count || 0,
        reviewCards: <></>
    });

    useEffect(()=> {
        setLiveData({
            reviewData: {
                status: state.reviewData.status,
                data: {
                    nextPageKey: state.reviewData.data.nextPageKey,
                    reviews: state.reviewData.data.reviews
                },
                params: {
                    pageSize: state.reviewData.params.pageSize,
                    sortBy: state.reviewData.params.sortBy
                }
            },
            totalReviews: state.reviewSummary?.data?.summary?.review_count || 0
        })
    }, [state.reviewData.data]);

    return (
        <>
            {liveData.totalReviews > 0 ? (
                <>
                    <SortBy
                        onChange={handleSortReviews}
                        disabled={false}
                    />
                    <ul className="reviews-list">
                        {liveData.reviewData.data.reviews.map((review, i) => (
                            <li key={`${review.id}_${i}`}>
                                <Review
                                    rating={review.rating}
                                    data={review}
                                />
                            </li>
                        ))}
                    </ul>
                    <div className="load-more-reviews">
                        {liveData.reviewData.data.nextPageKey &&
                            <>
                                <button
                                    className="button button--tertiary"
                                    onClick={handleLoadMoreReviews}
                                    disabled={(liveData.reviewData.status === 'loading')}
                                >
                                    {i18n.load} {liveData.reviewData.params.pageSize} {i18n.morereviews}
                                </button>
                            </>
                        }
                        {!liveData.reviewData.data.nextPageKey &&
                            <div>
                                {i18n.showingallreviews}
                            </div>
                        }
                    </div>
                </>
            ) : (
                <div className="empty-reviews-list"></div>
            )}
        </>
    );
};

ReviewsList.defaultProps = {};

ReviewsList.propTypes = {
    handleLoadMoreReviews: PropTypes.func.isRequired,
    handleSortReviews: PropTypes.func.isRequired
};

export { ReviewsList };
