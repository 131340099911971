import "../scss/store-seo-funnel.scss";

document.addEventListener("DOMContentLoaded", function () {
  const showMoreButton = document.querySelector(".show-more-button");
  const storecardContainer = document.querySelector(".ssf-storecard-wrapper");

  if (showMoreButton && storecardContainer) {

    document.querySelector('.store-locator__location-unknown').classList.add('hide');

    const maxCardsPerRow = 1;
    let visibleRows = 1;
    let cards = storecardContainer.querySelectorAll(".ssf-storecard");
    const cardHeight = 445;

    function updateMaxCardsToShow() {
      cards = storecardContainer.querySelectorAll(".ssf-storecard");

      const isMobile = window.matchMedia("(max-width: 1023px)").matches;
      const maxCardsToShow = isMobile ? 1 : 4;

      if (cards.length > maxCardsToShow) {
        showMoreButton.classList.remove("show-more-hidden");
      } else {
        showMoreButton.classList.add("show-more-hidden");
      }
    }

    updateMaxCardsToShow();

    window.addEventListener("resize", function () {
      updateMaxCardsToShow();

      // Check if the button needs to be hidden when resizing down
      if (cards.length <= 3 && cards.length > 2 && showMoreButton.remove()) {
        showMoreButton.classList.remove("show-more-hidden");
      } else if (
          cards.length <= 2 &&
          showMoreButton.classList.contains("show-more-button")
      ) {
        showMoreButton.remove();
      }
    });

    showMoreButton.addEventListener("click", function () {
      visibleRows += 1;
      const cardsToShow = visibleRows * maxCardsPerRow;
      const totalHeight = storecardContainer.scrollHeight;

      storecardContainer.dataset.visibleRows = visibleRows;

      if (cardsToShow > 0 && totalHeight > visibleRows * cardHeight) {
        storecardContainer.style.maxHeight = `calc(${cardsToShow} * ${cardHeight}px)`;
        window.scrollBy(0, cardHeight);
      } else {
        storecardContainer.classList.add("ssf-storecard-wrapper-expanded");
        showMoreButton.remove();
      }
    });


    // This is hacky and not good ...
    // Need to design a bulletproof flag/method to know when Map/Cards are Ready ...
    (async() => {
      while(!window.BATO.storeLocator.hasOwnProperty("bingLatLngReady"))
        await new Promise(resolve => setTimeout(resolve, 250));

      setTimeout(()=>{
        document.querySelector('.store-cards').classList.add('fade-in');
        document.querySelector('.map').classList.add('fade-in');
      },200)

    })();

  }

});
