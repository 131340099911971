import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const RatingSimple = (props) => {
    const {
        rating,
        reviewCount,
        showNumbers,
        classes
    } = props;
    const starSize = classes.includes('large')
        ? 'large'
        : classes.includes('small')
        ? 'small'
        : 'medium';


    return (
        <>

            {rating > 0 && showNumbers && (
                <>
                    <div className="h2 average-rating" aria-label="average star rating">{rating}</div>
                </>
            )}

            <div class="stars" data-rating={rating} arial-label="">
                <span style={{width: `${(rating * 20)}%`}}></span>
            </div>

            {rating > 0 && showNumbers && (
                <>
                    {reviewCount && (
                        <div className="review-count" aria-label="total number of reviews">
                            {url ? (
                                <a
                                    href={url}
                                    onClick={handleReviewCountClick}
                                >{`(${reviewCount})`}</a>
                            ) : productType === 'store' ? (
                                <a
                                    href={storeLink}
                                    onClick={handleReviewCountClick}
                                >{`(${reviewCount})`}</a>
                            ) : (
                                <>{`(${reviewCount})`}</>
                            )}
                        </div>
                    )}
                </>
            )}
        </>
    );
};

RatingSimple.defaultProps = {
    classes: '',
    showNumbers: false,
};

RatingSimple.propTypes = {
    rating: PropTypes.number.isRequired,
    reviewCount: PropTypes.number,
    classes: PropTypes.string,
    showNumbers: PropTypes.bool
};

export { RatingSimple };
