const apiPath = (endpoint, queryParams) => {
  let queryString;
  if (queryParams) {
    queryString = createQueryStringFromObject(queryParams);
  }
  return `/brgtrestwebservices/${endpoint}${(queryString ? `?${queryString}` : '')}`;
};

export const createQueryStringFromObject = (queryObject) => {
  if (typeof queryObject === 'object') {
    const newQs = new URLSearchParams(queryObject);
    return newQs.toString();
  } else {
    return 'createQueryStringFromObject() required a valid object to convert into a query string';
  }
}

export const postOptions = (dataPackage) => {
  //console.log("postOptions dataPackage: ", dataPackage);
  return {
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify(dataPackage),
    headers: {
      'Content-Type': 'application/json'
    }
  };
};

export default {
  apiPath,
  createQueryStringFromObject,
  postOptions
}