import apiUtils from '../util/apiUtils.js';

const getReviewsById = async ({id, country, groupId, lang_iso, nextPageKey, pageSize, site, sortBy}) => {
  try {
    let queryParams = {
      id,
      site: site || 'BST',
      country: country || 'US',
      lang_iso: lang_iso || 'en_US',
      sortBy: sortBy || 'latest',
      pageSize: pageSize || 3,
      groupId: groupId
    }

    if (nextPageKey) queryParams.nextPageKey = nextPageKey;

    const response = await fetch(apiUtils.apiPath('reviews/reviews-by-product-id', queryParams));

    if (!response.ok) {
      const errorData = await response.json();
      const err = `there's a problem with 'getReviewsById()': ${errorData}`;
      throw new Error(err);
    };

    const data = await response.json();

    if (data) return data;

  } catch (err) {
    console.log(`there's been a error in getReviews() API function: ${err}`);
    return {
      success: false
    }
  }
};
const getReviewSummariesByIds = async ({ids, country, groupId, lang_iso, site}) => {
  try {
    const dedupedIds = Array.from( new Set(ids));

    const response = await fetch(apiUtils.apiPath('reviews/review-summaries-by-product-ids', {
      id: dedupedIds.join(','),
      country: country || 'US',
      groupId: groupId || '',
      lang_iso: lang_iso || 'en_US',
      site: site || 'BST'
    }));

    if (!response.ok) {
      const errorData = await response.json();
      const err = `there's a problem with 'getReviewSummariesByIds()': ${errorData}`;
      throw new Error(err);
    };

    const data = await response.json();

    if (data) return data;

  } catch (err) {
    console.log(`there's been an error in the getReviewSummariesByIds() function: ${err}`);
    return {
      success: false
    }
  }
};

const submitReview = async (formData, pageId) => {
  try {
    console.log("RECEIVED FORMDATA: ", pageId);
    const URL = window.BATO?.CONFIG?.REVIEWS?.writeUrl || false;
    if (!URL) throw Error('reviews.js: submitReview(formData);: no write url specified');

    const apiKey = window.BATO?.CONFIG?.REVIEWS?.apiKey || false;
    if (!apiKey) throw Error('review.js: submiReview(formData);: no apiKey available');

    const merchantId = window.BATO?.CONFIG?.REVIEWS?.merchId || false;
    if (!merchantId) throw Error('review.js: submiReview(formData);: no merchantId available');

    const latamShared =  window.BATO?.CONFIG?.REVIEWS?.latamShared || false;
    const locale = window.BATO?.CONFIG?.REVIEWS?.lang_iso || false;
    const sharedLangIso = latamShared && latamShared.includes(locale) ? 'es_MX' : locale;

    if (!locale) throw Error('review.js: submiReview(formData);: no locale specified');

    if (!pageId) throw Error('review.js: submitReview(formData);: no product ID (pageId) available');

    const UrlWithParams = `${URL}?apikey=${apiKey}&merchant_id=${merchantId}&page_id=${pageId}&locale=${sharedLangIso}`;

    const response = await fetch(UrlWithParams, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    });

    if (!response.ok) {
      throw Error(await response.json());
    }

    const data = await response.json()
    console.log("parsed data: ", data);

    return data;

  } catch (err) {
    throw Error(err);
  }
};

const getTranslateReview = async ({ ugc_id }) => {
  try {
    const apiKey = window.BATO?.CONFIG?.REVIEWS?.apiKey || false;
    if (!apiKey) throw Error('review.js: getTranslateReview();: no apiKey available');

    const merchantId = window.BATO?.CONFIG?.REVIEWS?.merchId || false;
    if (!merchantId) throw Error('review.js: getTranslateReview();: no merchantId available');
    
    const locale = window.BATO?.CONFIG?.REVIEWS?.lang_iso || false;
    if (!locale) throw Error('review.js: getTranslateReview();: no locale specified');
    
    const lang = locale.slice(0, 2);
    
    const apiUrl = `https://display.powerreviews.com/m/${merchantId}/u/${ugc_id}/lang/${lang}/translate?apikey=${apiKey}`;
    
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      const errorData = await response.json();
      const err = `There's a problem with 'getTranslateReview()': ${errorData}`;
      throw new Error(err);
    }

    const data = await response.json();

    if (data) {
      const modifiedData = { ...data, success: true };
      return modifiedData;
    }

  } catch (err) {
    console.log(`There's been an error in the getTranslateReview() function: ${err}`);
    return {
      success: false
    };
  }
};

export {
  getReviewsById,
  getReviewSummariesByIds,
  submitReview,
  getTranslateReview
};