import { Feature } from './feature.js';

class FeatureSwitches {
    constructor() {
        this.switches = new Map();
    }

    /**
     * Defines a new feature switch.
     * @param {string} name The name of the feature switch
     * @param {any} defaultValue The default value of the feature switch
     * @param {object} options Options for the feature switch
     * @returns {Feature} The feature switch object
     */
    create(name, defaultValue, options = {}) {
        if (this.switches.has(name)) {
            console.error(`Replacing existing feature switch ${name}`);
        }
        const feature = new Feature(name, defaultValue, options);
        this.switches.set(name, feature);
        return feature;
    }

    /**
     * Initializes all the feature switches. This will look in query string for
     * values, and set cookies or local storage as needed. It will also apply
     * body classes as defined by feature.
     */
    initialize() {
        this.switches.forEach((feature) => {
            // Look for feature name in query string
            const urlParams = new URLSearchParams(window.location.search);
            const featureValue = urlParams.get(feature.name);
            if (featureValue) {
                feature.setCurrentValue(featureValue);
            } else {
                feature.apply();
            }
        });
    }

    /**
     * Gets the current value of a feature switch
     * @param {string} name The name of the feature switch
     * @returns The value of the feature switch
     */
    get(name) {
        const feature = this.switches.get(name);
        return feature?.getCurrentValue();
    }

    /**
     * Convenience method to compare a feature switch value.
     * `if (featureSwitches.is('bsro-ui-appt', 'v2')) { ... }`
     * @param {string} name Name of the feature switch
     * @param {any} value The value to compare against
     * @returns boolean
     */
    is(name, value) {
        return this.get(name) === value?.toString();
    }

    /**
     * Conveinence method to test if a feature switch is true.
     * @param {string} name The name of the feature switch to test
     * @returns boolean
     */
    isTrue(name) {
        return this.get(name) === 'true';
    }

    /**
     * Sets the current value of a feature switch. This will store the value in the appropriate
     * storage and apply the value to the UI.
     * @param {string} name The feature switch name.
     * @param {string} value The value to set the feature switch to.
     */
    set(name, value) {
        const feature = this.switches.get(name);
        if (!feature) {
            throw new Error(`No feature switch named ${name}`);
        }
        const changed = feature.setCurrentValue(value);
        feature.apply();
        if (changed) {
            document.dispatchEvent(new CustomEvent('featureSwitchChange', { detail: { name, value } }));
        }
        console.info(`Feature switch "${name}" set to "${value}"`);
    }

    /**
     * Resets the current value of a feature switch to its default value. This removes the
     * value from storage and applies the default value to the UI.
     * @param {string} name The name of the feature switch to reset. If not provided, ALL feature switches
     *    will be reset.
     */
    reset(name) {
        let changed = false;
        const features = name ? [this.switches.get(name)] : this.switches;
        features.forEach((feature) => {
            changed = feature.reset();
            if (changed) {
                document.dispatchEvent(new CustomEvent('featureSwitchChange', { detail: { name: feature.name, value: feature.getCurrentValue() } }));
            }
            feature.apply();
        });
    }

    /**
     * Dumps the current state of all feature switches to the console.
     */
    dump() {
        console.info('Feature Switches:');
        this.switches.forEach((feature) => {
            console.info(`- ${feature}`);
        });
    }
}

export { FeatureSwitches };