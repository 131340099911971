import { merge } from 'lodash-es';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  status: 'unknown',
  data: {
    quantity: 0,
    storeNumbers: [],
    stores: {}
  }
};

export const stores = createSlice({
  name: 'stores',
  initialState: initialState,
  reducers: {
    clearStores: (state) => {
      return initialState;
    },
    setStores: (state, action) => {
      return {
        ...state,
        status: 'known',
        data: {
            quantity: action.payload.quantity,
            storeNumbers: action.payload.storeNumbers,
            stores: action.payload.stores,
            lang_iso: action.payload.lang_iso
        }
      }
    },
  }
});

const actions = stores.actions;

export { actions };

if (!window.BATO) {
  window.BATO = {}
}

if (!window.BATO.StoreActions) {
  window.BATO.StoreActions = {}
}

window.BATO.StoreActions.stores = actions;

export default stores.reducer;