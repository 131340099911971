import '../scss/data-privacy.scss';

const optOutForm = document.querySelector('.optoutform');
const checkboxWrappers = (optOutForm != null) ? optOutForm.querySelectorAll('.opt-out-request-checkbox') : '';
const formWrappers = (optOutForm != null) ? optOutForm.querySelectorAll('.opt-out-form-information-section .opt-out-form') : '';

if(checkboxWrappers.length > 0) {

    checkboxWrappers.forEach(checkBoxWrapper => {

        let inputs = checkBoxWrapper.querySelectorAll('input[type=checkbox]');
        let labels = checkBoxWrapper.querySelectorAll('label');

        inputs.forEach((input, index) => {

            let divWrapper = document.createElement('div');

            divWrapper.setAttribute("class", "checkbox-wrapper");

            divWrapper.appendChild(input);
            divWrapper.appendChild(labels[index]);

            checkBoxWrapper.appendChild(divWrapper);
        });

    });

}