import '../scss/store-locator.scss';
import '../scss/store-locator-author.scss';

import '../../fsd-card/v1/scss/fsd-card.scss';
import '../../fsd-card/v1/scss/fsd-modal.scss';
import '../../fsd-card/v1/js/fsd-card-render.js';

import '../../store-card/v1/scss/store-card.scss';
import '../../store-card/v1/js/store-card-render.js';

import '../../map/v1/scss/map.scss';
import '../../map/v1/js/map.js';

import '../../store-filter/v1/scss/store-filter.scss';
import '../../store-filter/v1/js/store-filter.js';

import '../../location-search/v1/js/location-search.js';

import '../../store-locator-flyout/v1/js/store-locator-flyout.js';

import '../../store-seo-funnel/v1/scss/store-seo-funnel.scss';
import '../../store-seo-funnel/v1/js/store-seo-funnel.js'



document.addEventListener("DOMContentLoaded", function() {

    if (!window.BATO) window.BATO = {};

    const storeLocator = document.querySelector(".store-locator");
    const tireCardsContainer = (storeLocator != null) ? storeLocator.querySelector(".store-cards") : null;
    const map = (storeLocator != null) ? storeLocator.querySelector(".map") : null;
    const listViewButton = (storeLocator != null) ? storeLocator.querySelector(".list-view-cta") : null;
    const mapViewButton = (storeLocator != null) ? storeLocator.querySelector(".map-view-cta") : null;

    if(storeLocator) {

        listViewButton.addEventListener('click', () => {
            tireCardsContainer.classList.add("store-cards-list-view");
            listViewButton.style.display = "none";
            mapViewButton.style.display = "flex";
            map.style.display = "none";
        })

        mapViewButton.addEventListener('click', () => {
            tireCardsContainer.classList.remove("store-cards-list-view");
            mapViewButton.style.display = "none";
            listViewButton.style.display = "flex";
            map.style.display = "block";
        })

        const locationStatusOverlay = storeLocator.querySelector(".store-locator__location-unknown");

        if(locationStatusOverlay) {

            const observeStore = window.BATO?.Store?.observeStore || null;

            const hideOverlay = async () => {

                window.BATO.Store.getState().location.status == "known" ? locationStatusOverlay.classList.add("hide") : '';
                storeLocator.classList.add("fade-in");

            }

            window.BATO.Store.observeStore(window.BATO.Store, function(state) {
                return state.location.status;
            }, hideOverlay);

            // For ease, we are Cloning the Online Retailer Grid into the 'Shop Online' Overlay
            const storeLocatorShopOnline = storeLocator.querySelector(".store-locator__shop-online");

            if(storeLocatorShopOnline && locationStatusOverlay) {

                const locationStatusOverlayTitle = locationStatusOverlay.getElementsByTagName("h3")[0];
                const locationStatusOverlayTable = locationStatusOverlay.getElementsByTagName("table")[0];
                const locationStatusOverlayParagraph = locationStatusOverlay.querySelector(".store-locator__cf").getElementsByTagName("p")[0];
                
                if(locationStatusOverlayTitle) {
                    let titleClone = locationStatusOverlayTitle.cloneNode(true);
                    storeLocatorShopOnline.appendChild(titleClone);
                }

                if(locationStatusOverlayTable) {
                    let tableClone = locationStatusOverlayTable.cloneNode(true);
                    storeLocatorShopOnline.appendChild(tableClone);
                }

                 if(locationStatusOverlayParagraph){
                    let paragraphClone = locationStatusOverlayParagraph.cloneNode(true);
                    storeLocatorShopOnline.appendChild(paragraphClone);
                }
            }
        }
    }
});
