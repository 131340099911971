function setLocalStorage(key, value) {
    try {
        localStorage.setItem(key, value);
    } catch (e) {
        console.error(`Error setting localStorage ${key}`, e);
    }
}

function getLocalStorage(key) {
    try {
        return localStorage.getItem(key);
    } catch (e) {
        console.error(`Error getting localStorage ${key}`, e);
    }
}

function clearLocalStorage(key) {
    try {
        localStorage.removeItem(key);
    } catch (e) {
        console.error(`Error clearing localStorage ${key}`, e);
    }
}

/**
 * Sets a cookie.
 * @param {string} name The name of the cookie to set
 * @param {string} value Value of the cookie
 * @param {number|Date} expires The expiration date of the cookie. If a number, it is the number of days from now.
 */
function setCookie(name, value, expires = null) {
    let cookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;
    if (expires) {
        const expDate =
            expires instanceof Date
                ? expires
                : new Date(Date.now() + expires * 24 * 60 * 60 * 1000);
        cookie += `; expires=${expDate.toUTCString()}`;
    }
    try {
        document.cookie = `${cookie};path=/`;
    } catch (e) {
        console.error(`Error setting cookie ${name}`, e);
    }
}

/**
 * Gets the value of cookie. Returns null if the cookie is not found.
 * @param {string} name The cookie name to get
 */
function getCookie(name) {
    try {
        return (
            document.cookie
                .match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')
                ?.pop() || null
        );
    } catch (e) {
        // If cookies are disabled, this will throw an error
        console.error(`Error getting cookie ${name}`, e);
    }
}

function clearCookie(name) {
    try {
        document.cookie = `${encodeURIComponent(name)}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
    } catch (e) {
        console.error(`Error clearing cookie ${name}`, e);
    }
}

/**
 * Check if a string or regex is in another string.
 * @param {RegExp | string} s1 The thing to look for
 * @param {string} s2 The string to look in
 * @returns
 */
function isMatching(s1, s2) {
    if (s1 instanceof RegExp) {
        return s1.test(s2);
    }
    return String(s2).includes(s1);
}

/**
 * Check if a scope matches the current url.
 * @param {string | RegExp | Array<string | RegExp>} scope The scope to match
 * @param {string} url The url to match against
 * @returns true if the scope matches the url
 */
function matchesScope(scope, url) {
    if (!scope) {
        return true;
    }
    if (Array.isArray(scope)) {
        return scope.some((s) => isMatching(s, url));
    }
    return isMatching(scope, url);
}

class Feature {
    /**
     * Creates a new feature flag.
     * @param {string} name The name of the feature flag
     * @param {string} defaultValue The default value of the feature flag
     * @param {Object} [options={}] Options
     *    - expires The expiration time in days or a Date object. Default is session.
     *    - utils Alternative utility functions to use for storage
     */
    constructor(name, defaultValue, options = {}) {
        // Allow for dependency injection of storage functions
        this.util = Object.assign(
            {
                setLocalStorage,
                getLocalStorage,
                clearLocalStorage,
                setCookie,
                getCookie,
                clearCookie
            },
            options.utils || {}
        );
        if (typeof defaultValue === 'undefined' || defaultValue === null) {
            throw new Error('defaultValue must be provided');
        }
        this.name = name;
        this.defaultValue = defaultValue.toString();
        this.previousValue = null;
        this.storageType = 'localStorage';
        this.hasBodyClass = false;
        this.bodyClassScope = null;
        this.expires = options.expires || null;
    }

    /**
     * Fluent Interface to indicate the feature must be visible on the server side.
     * This is for feature flags needed by AEM or micoservices. The switch value will be
     * stored in a cookie.
     * @param {boolean} [state=true] True if you want remote flag. If false, the cookie
     *  will be converted to a localStorage item.
     * @returns {Feature} The current feature flag.
     */
    remote(state = true) {
        try {
            const current = this.getCurrentValue(false);
            if (current !== null) {
                // There is already a value set
                if (state && this.storageType === 'localStorage') {
                    // move local storage to cookie
                    this.util.setCookie(this.name, current, this.expires);
                    this.util.clearLocalStorage(this.name);
                }
                if (!state && this.storageType === 'cookie') {
                    // move cookie to local storage
                    this.util.clearCookie(this.name);
                    this.util.setLocalStorage(this.name, current);
                }
            }
        } catch (e) {
            console.error(`Error persisting ${this.name}`, e);
        }
        this.storageType = state ? 'cookie' : 'localStorage';
        return this;
    }

    /**
     * Fluent Interface to indicate the feature will be be set on a body class.
     * This is for feature flags needed by the UI. The body class will be set
     * to the feature name and value (e.g. bsro-ui-tdp-v2).
     * If the value is `true`, the body class will be set to the feature name.
     * If the value is `false`, the body class will be removed.
     * @param {String|RegExp|Array<String|RegExp>|boolean} [scope=null] Match the
     *   url of pages on which the body class should be applied. False to remove body class.
     * @returns {Feature} The current feature flag.
     */
    bodyClass(scope = null) {
        this.bodyClassScope = scope;
        this.hasBodyClass = true;
        return this;
    }

    /**
     * Sets the current value of the feature flag and stores it in
     * the appropriate storage.
     * @param {any} value The value of the feature flag to set.
     * @returns {boolean} true if the switch has changed.
     */
    setCurrentValue(value) {
        let changed = false;
        if (typeof value === 'undefined' || value === null) {
            throw new Error('value must be provided');
        }
        const valueString = value.toString();
        const current = this.getCurrentValue();
        if (current !== valueString) {
            this.previousValue = current;
            if (this.storageType === 'localStorage') {
                this.util.setLocalStorage(this.name, valueString);
            } else {
                this.util.setCookie(this.name, valueString, this.expires);
            }
            changed = true;
        }
        this.apply();
        return changed;
    }

    /**
     * Loads the current value of the feature flag from storage.
     * @returns {string} The current value of the feature flag.
     */
    getCurrentValue(applyDefault = true) {
        let value;
        if (this.storageType === 'localStorage') {
            value = this.util.getLocalStorage(this.name);
        } else {
            value = this.util.getCookie(this.name);
        }
        return applyDefault && value === null ? this.defaultValue : value;
    }

    /**
     * Internal method to add or remove the body class from the document.
     */
    addBodyClass() {
        const current = this.getCurrentValue();
        if (current === 'true') {
            document.body.classList.add(this.name);
        } else if (current === 'false') {
            document.body.classList.remove(this.name);
        } else {
            const previous =
                this.previousValue !== null
                    ? this.previousValue
                    : this.defaultValue;
            document.body.classList.remove(this.name + '-' + previous);
            document.body.classList.add(this.name + '-' + current);
        }
    }

    /**
     * Applies the feature flag to the UI.
     */
    apply() {
        if (
            this.hasBodyClass &&
            matchesScope(this.bodyClassScope, window.location.pathname)
        ) {
            if (document.body) {
                this.addBodyClass();
            } else {
                var observer = new MutationObserver(() => {
                    if (document.body) {
                        this.addBodyClass();
                        observer.disconnect();
                    }
                });
                observer.observe(document.documentElement, { childList: true });
            }
        }
    }

    /**
     * Resets the feature switch to the default value, removing it from storage.
     * @returns {boolean} true if the feature switch has changed.
     */
    reset() {
        const current = this.getCurrentValue();
        if (this.storageType === 'localStorage') {
            this.util.clearLocalStorage(this.name);
        } else {
            this.util.clearCookie(this.name);
        }
        this.apply();
        return current !== this.defaultValue;
    }

    toString() {
        const current = this.getCurrentValue();
        const changeIndicator = current !== this.defaultValue ? ' *' : '';
        return `${this.name}: "${current}"${changeIndicator}`;
    }
}

export { Feature };