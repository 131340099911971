import '../scss/modal.scss';
import overlay from '../../../../../js/library/overlay.js';
import focusHelpers from '../../../../../js/library/focusHelpers.js';

// setup window object for components to consume
if (!window.BATO) window.BATO = {};

const topLevelModalContainerClass = 'dynamic-top-container';

const addOverlayClickEvent = (modalType, elementToFocus) => {

    let overlayEl = document.querySelector(`.${overlay.overlayDivClass}`);

    if(overlayEl) {
        overlayEl.addEventListener('click', (e) => {
            const btn = document.querySelector('#modal-close-button');

            if (btn) btn.click();
            elementToFocus.focus();

        });
    }
}

const modalMarkup = (content, callerReturn, showCloseButton) => {
  // create wrapper
  const wrapper = document.createElement('div');
  wrapper.classList.add(topLevelModalContainerClass);
  wrapper.innerHTML = `<div class="dynamic-top-wrap" tabindex="0">
            <div class="sr-only">This is a modal window</div>
            ${showCloseButton ? `<div class="modal-close">
              <button id='modal-close-button' tabindex="0">&#10005;</button>
            </div>` : `<div class="modal-close"></div>`}
            <div class="dynamic-top-content">
            </div>
        </div>`;
  // attach close event handler if showCloseButton is true

  if (showCloseButton) {
    const closeButton = wrapper.querySelector('#modal-close-button');
    closeButton.addEventListener('click', () => deactivateModal(callerReturn))
  }

  const contentArea = wrapper.querySelector('.dynamic-top-content');
  if (typeof content === 'string') {
    contentArea.innerHTML = content;
  } else {
    contentArea.append(content);
  }

  return wrapper;
};

const activateModal = (content = null, useOverlay = true, callerReturn = null, showCloseButton = true, displayType = 'modal',  disableRedirect = false) => {
  
  const theBody = document.querySelector('body');
  if (useOverlay) {
    overlay.createAndActivateOverlay();
  }

  switch (displayType) {
    case 'flyout':
      // add flyout class to body
      theBody.classList.add('flyout');
      break;
    case 'modal':
    default:
      // add modal stuff to body
      theBody.classList.add('modal');
  }

  if (!showCloseButton) {
    theBody.classList.add('no-modal-close');
  }

  const markupToAppend = modalMarkup(content, callerReturn, showCloseButton);
  // add event focus-related event listeners to the top-level modal body

  let wrp = "";

  markupToAppend.querySelector('#modal-close-button') ? wrp = markupToAppend.querySelector('#modal-close-button') : '';

  // append the modal to the DOM;
  theBody.append(markupToAppend);

  // make sure Close button has initial focus
  wrp ? wrp.focus() : ''; //overriding focus below

  document.addEventListener('keydown', function (e) { //name so can be removed
    focusHelpers.trapEscapeKey(wrp, e);
    focusHelpers.trapTabKey(markupToAppend, e);
  });

  addOverlayClickEvent(displayType, callerReturn);

  //document.querySelector('.tire-search-flyout__tab-content').focus();

  window.BATO.vehicleSelector.disableRedirect = disableRedirect;

  return markupToAppend;

};

const deactivateModal = (returnTabFocusTo) => {
  // remove body classes relating to modal
  const theBody = document.querySelector('body');
  theBody.classList.remove('flyout', 'modal', 'no-modal-close');
  // remove modal from DOM
  const modalContainer = document.querySelector(`.${topLevelModalContainerClass}`);
  if (modalContainer) modalContainer.remove();
  // remove overlay from DOM if exists (overlay method handles that check for us)
  if(theBody.classList.contains(overlay.bodyActiveOverlayClass)) {
      overlay.removeActiveOverlay();
  }

  // return focus to initiating element (passed in from caller)
  if (returnTabFocusTo) returnTabFocusTo.focus();
}

window.BATO.modal = {
  activateModal,
  deactivateModal
};

const modalControl = () => {
  return {
    activateModal,
    deactivateModal
  }
}
export default modalControl;