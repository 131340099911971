/**
 * @return whether a thing appears to be a DOM element.
 */
export function isDomElement(thing) {
  return thing.nodeName !== undefined;
}

/**
 * Return the DOM element contained in a passed -in fnode.Return passed -in DOM
  * elements verbatim.
 *
 * @arg fnodeOrElement { Node | Fnode }
 */
export function toDomElement(fnodeOrElement) {
  return isDomElement(fnodeOrElement) ? fnodeOrElement : fnodeOrElement.element;
}

/*
 * Return the window an element is in.
 * This is to guard against iframes, connected windows, and in-memory elements (no, seriously)
 *
 * @throws {Error} There isn't a matching window.
 */
export function windowForElement(element) {
  let doc = element.ownerDocument;
  if (doc === null) {
    // The element itself was a document.
    doc = element;
  }
  const win = doc.defaultView;
  if (win === null) {
    throw new Error('The element was not in a window.');
  }
  return win;
}

/**
 * Yield an element and each of its ancestors.
 */
export function* ancestors(element) {
  yield element;
  let parent;
  while ((parent = element.parentNode) !== null && parent.nodeType === parent.ELEMENT_NODE) {
    yield parent;
    element = parent;
  }
}

/**
 * Return whether an element is practically visible, considering things like 0
 * size or opacity, ``visibility: hidden`` and ``overflow: hidden``.
 *
 * Merely being scrolled off the page in either horizontally or vertically
 * doesn't count as invisible; the result of this function is meant to be
 * independent of viewport size.
 *
 * @throws {Error} The element (or perhaps one of its ancestors) is not in a
 *     window, so we can't find the `getComputedStyle()` routine to call. That
 *     routine is the source of most of the information we use, so you should
 *     pick a different strategy for non-window contexts.
 */
export function isVisible(fnodeOrElement) {
  // This could be 5x more efficient if https://github.com/w3c/csswg-drafts/issues/4122 happens.
  const element = toDomElement(fnodeOrElement);
  const elementWindow = windowForElement(element);
  const elementRect = element.getBoundingClientRect();
  const elementStyle = elementWindow.getComputedStyle(element);
  // Alternative to reading ``display: none`` due to Bug 1381071.
  if (elementRect.width === 0 && elementRect.height === 0 && elementStyle.overflow !== 'hidden') {
    return false;
  }
  if (elementStyle.visibility === 'hidden') {
    return false;
  }
  // Check if the element is irrevocably off-screen:
  if (elementRect.x + elementRect.width < 0 ||
    elementRect.y + elementRect.height < 0
  ) {
    return false;
  }
  for (const ancestor of ancestors(element)) {
    const isElement = ancestor === element;
    const style = isElement ? elementStyle : elementWindow.getComputedStyle(ancestor);
    if (style.opacity === '0') {
      return false;
    }
    if (style.display === 'contents') {
      // ``display: contents`` elements have no box themselves, but children are
      // still rendered.
      continue;
    }
    const rect = isElement ? elementRect : ancestor.getBoundingClientRect();
    if ((rect.width === 0 || rect.height === 0) && elementStyle.overflow === 'hidden') {
      // Zero-sized ancestors don’t make descendants hidden unless the descendant
      // has ``overflow: hidden``.
      return false;
    }
  }
  return true;
}

/**
 * Removed children from parent element
 */
export function removeChildren(parent) {
	while (parent.firstChild) {
		parent.removeChild(parent.firstChild); 
	}
}

/**
 * Test the element to see if it matches the provided selector
 * If any of the variables is true, the return value will be true
 */
export function matches(elem, selector) {
    const isMsMatch = 'msMatchesSelector' in elem && elem.msMatchesSelector(selector);
    const isMatchSelector = 'matchesSelector' in elem && elem.matchesSelector(selector)
    const isMatch = 'matches' in elem && typeof elem.matches === 'function' && elem.matches(selector);

    return isMsMatch || isMatchSelector || isMatch;
}