import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { urlLanguageCheck } from '../../../../../js/utils/urlLanguageCheck.js';

const HeaderLocationApp = (props) => {
  const {
    status,
    data: {
      zip
    }
  } = useSelector(state => state.location);
  const {
    data: {
      quantity
    }
  } = useSelector(state => state.stores);

  const {
    locateMe,
    dealersNear
  } = props;

  const [dealersNearState, setDealersNearState] = useState({partA: '', partB: ''});

  useEffect(() => {
    if (dealersNear) {
      const splitText = dealersNear.split(' ');
      setDealersNearState({
        partA: splitText[0],
        partB: splitText[1]
      })
    }
  }, [dealersNear]);

  const [dealerURL, setDealerURL] = useState();

  useEffect(() => {
      
      const languageInUrl = urlLanguageCheck();
      let dealerURL = '/dealer/';

      languageInUrl ? dealerURL = '/' + languageInUrl + dealerURL : '';

      setDealerURL(dealerURL);

  }, []);


  return (
    <>
      {(status === 'unknown' || !zip) &&
        <a href={dealerURL} className="dealer-locator-status">
          <i className="icon-consumer-storelocator"></i>
          <span className="cmp-button__text">{locateMe}</span>
        </a>
      }
      {(status === 'known' && zip) &&
        <a href={dealerURL} className="dealer-locator-status">
          <i className="icon-consumer-storelocator"></i>
          <span className="cmp-button__text">
            <span className="full-text">
              {quantity &&
                <>{quantity}&nbsp;</>
              }
              {dealersNearState.partA} {dealersNearState.partB}
            </span>&nbsp;{zip.split('-')[0]}
          </span>
        </a>
      }
    </>
  );
};

export { HeaderLocationApp };