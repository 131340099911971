import React, { Fragment } from 'react';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// Radio input
const RatingInput = (props) => {
    const {
        active,
        i,
        name,
        friendlyName,
        value,
        onChange,
        onBlur,
        id,
        setFieldValue,
        onChangeHandler,
        onMouseOverHandler,
        onMouseOutHandler,
        overActive,
        register,
        setValue
    } = props;

    const registeredInput = register(name, { required: true })

    const handleOnChange = (e) => {
        registeredInput.onChange(e);
        setValue(name, e.target.value, { shouldValidate: true, shouldDirty: true });
    }


    return (
        <div>
            <input
                //name={`${name}-input`}
                id={id}
                type="radio"
                value={i}
                onChange={(e) => {
                    if (onChangeHandler) {
                        onChangeHandler(e);
                    }
                    handleOnChange(e);
                }}
                onBlur={registeredInput.onBlur}
                defaultChecked={false}
                className={classNames('sr-only')}
                ref={registeredInput.ref}
                //{...register(name)}
                //{...props}
            />
            <label
                htmlFor={id}
                className={classNames(
                    'pr-star',
                    `pr-star-${
                        (active && !overActive.isOver) ||
                        (overActive.isOver && overActive.index >= i)
                            ? '100'
                            : '0'
                    }-filled`
                )}
                onMouseOver={(e) => {
                    onMouseOverHandler(e);
                }}
                onMouseOut={(e) => {
                    onMouseOutHandler(e);
                }}
            >
                <span className="pr-accessible-text">
                    Give {friendlyName} {i} star{i > 1 && 's'}
                </span>
            </label>
        </div>
    );
};

RatingInput.propTypes = {
    active: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    friendlyName: PropTypes.string.isRequired,
    field: PropTypes.object,
    i: PropTypes.number.isRequired,
    id: PropTypes.string,
    setFieldValue: PropTypes.string,
    onChangeHandler: PropTypes.func,
    onMouseOverHandler: PropTypes.func.isRequired,
    onMouseOutHandler: PropTypes.func.isRequired,
    overActive: PropTypes.object.isRequired,
    register: PropTypes.func
};

export default RatingInput;
