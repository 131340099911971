// setup window object for components to consume
if (!window.BATO) window.BATO = {};

const storeDetailsPageAnalyticsObject = {
	"store": {
		"store_id": "",
		"name": "",
		"city": "",
		"state": "",
		"brand": ""
	}
};

export const init = () => {

	let storeDetailsInfoEl = document.querySelector(".store-details-info__location");
	let storeDetailData = (storeDetailsInfoEl != null) ? storeDetailsInfoEl.dataset : null;

	if (storeDetailData) {

		window.BATO.ANALYTICS.page.page_type = "sdp";

		storeDetailsPageAnalyticsObject.store.store_id = storeDetailData.storeNumber;
		storeDetailsPageAnalyticsObject.store.name = storeDetailData.storeName;
		storeDetailsPageAnalyticsObject.store.city = storeDetailData.storeCity;
		storeDetailsPageAnalyticsObject.store.state = storeDetailData.storeState;
		storeDetailsPageAnalyticsObject.store.brand = storeDetailData.storeBrand;

		window.BATO.ANALYTICS.store = storeDetailsPageAnalyticsObject.store;
	}
}

export default {
	init
}