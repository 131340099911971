import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';

const Star = (props) => {
    const { size, percent } = props;
    const starSize = size === 'large' ? 24 : size === 'small' ? 14 : 18;
    const path = `/etc.clientlibs/consumer/shared/common/clientlibs/clientlib-icons/resources/images/ratings/star-${percent}.svg`;

    return (
        <>
            <SVG aria-label="star" src={path} width={starSize} height={starSize} />
        </>
    );
};

Star.defaultProps = {
    size: 'medium',
    percent: '100'
};

Star.propTypes = {
    size: PropTypes.string,
    percent: PropTypes.string
};

export { Star };
