import elementListener from '../../../../../js/library/eventHelpers.js';

// setup window object for components to consume
if (!window.BATO) window.BATO = {};

const storeFilterContainer = document.querySelector(".store-filter__filters");
const radiusContainer = (storeFilterContainer != null) ? storeFilterContainer.querySelector(".store-filter__filters__distance") : null;
const sortByContainer = (storeFilterContainer != null) ? storeFilterContainer.querySelector(".store-filter__filters__sortby__display b") : null;

const storeDetailsAnalyticsObject = {
	"event": "dealer.filtering",
	"filter": "",
	"sorting": "",
	"number_of_results":""
};

export const init = () => {
	if (radiusContainer != null) {
		elementListener.addBubblerListener(radiusContainer, 'click', 'label', storeFilterEventHandler);
	}
}

const storeFilterEventHandler = (evt) => {

	if(window.BATO.storeLocator.searchFromUnknown != true && window.BATO.storeLocator.radiusSetProgrammatically != true) {

		let el = evt.target;
		let analyticsObject = JSON.parse(JSON.stringify(storeDetailsAnalyticsObject));

		let storeFilterInput = document.getElementById(el.htmlFor);
		analyticsObject.filter = "distance: within " + storeFilterInput.value + " miles";

		let sortByInput = sortByContainer.innerHTML;
		sortByInput.includes("Select") ? analyticsObject.sorting = "Default" : analyticsObject.sorting = sortByInput;

		let visibleCards = document.querySelectorAll(".store-card:not(.hide)");
		analyticsObject.number_of_results = visibleCards.length;

		adobeDataLayer.push(analyticsObject);

	}
}

export default {
	init
}