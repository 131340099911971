import apiUtils from '../util/apiUtils.js';

export const setLocationByNetwork = async () => {
  try {

    const response = await fetch(apiUtils.apiPath('stores/location-by-network/'));
    if (!response.ok) {
      const errorData = await response.json();
      const err = `there's a problem with 'setLocationByNetwork': ${errorData}`;
      throw new Error(err);
    }

    const data = await response.json();

    if (data) return data;

  } catch (err) {
    console.log(`there's been an error in the setLocationByNetwork() function: ${err}`);
    return {
      success: false,
    }
  }
};

export default {
  setLocationByNetwork
}