import apiUtils from '../util/apiUtils.js';

const {
    country,
} = window.BATO?.CONFIG || { country: 'US' };

export const getAvailabilityByStoreArticleRetailer = async (storeNumber, articleId, retailerType) => {
    try {
        if (!retailerType) throw new Error('you need to provide a retailer type');

        const params = {
            country,
            storeNumber: storeNumber,
            articleId: articleId,
            retailerType: retailerType
        }

        const response = await fetch(apiUtils.apiPath('services/availability-by-article-id', params));

        if (!response.ok) {
            const errorData = await response.json();
            const error = `the api response in getAvailabilityByStoreArticleRetailer() is not ok: ${errorData}`;
            throw new Error(error);
        }

        const data = await response.json();

        if (data) return data;

    } catch (err) {
        console.log(`there's been an error in the getAvailabilityByStoreArticleRetailer() function: ${err}`);
        return {
            success: false
        }
    }
}

export default {
    getAvailabilityByStoreArticleRetailer,
}