import elementListener from '../../../../../js/library/eventHelpers.js';

// setup window object for components to consume
if (!window.BATO) window.BATO = {};
if (!window.adobeDataLayer) window.adobeDataLayer = [];

const storeDetailsAnalyticsObject = {
	"event": "dealer.search",
	"data": {
		"use_current_location": false,
		"search_input": "",
		"number_of_results": "",
		"store": ""
	}
};

// Prevent Analytics from Firing on Our-Tires Pages on Initial Load
// This is Caused by the Presence of the Pre-Populated (with location) Location Search
// Component On These Pages ... ie https://ix-dev-tng.bridgestonetire.com/our-tires/tire-brand/dueler/
let disableOnInitialLoad = (window.location.href.indexOf("our-tires") > -1) ? true : false;

export const init = () => {
	window.BATO.Store.observeStore(window.BATO.Store, function(state) {
		return state.stores;
	}, locationSearchEventHandler);

}

const locationSearchEventHandler = () => {

	if( disableOnInitialLoad == false ) {

		let locationSearchWrappers = document.querySelectorAll(".location-search");

		let analyticsObject = JSON.parse(JSON.stringify(storeDetailsAnalyticsObject)),
			searchBox,
			locationWrapper,
			locationOverlay;

		let locationUnknownOverlay = document.querySelector('.store-locator__location-unknown');

		if(locationSearchWrappers.length > 0) {

			if(locationUnknownOverlay && !locationUnknownOverlay.classList.contains('hide')) {

				window.BATO.storeLocator.searchFromUnknown = true;

			} else if(window.BATO.storeLocator.searchFromUnknown == true) {

				window.BATO.storeLocator.searchFromUnknown = false;
				locationWrapper = locationUnknownOverlay.querySelector('.location-search--unknown');
				searchBox = locationWrapper.querySelector('input.location');

			} else {

				locationSearchWrappers.forEach(locationSearchWrapper => {

					// Use First Instance of .location-search
					locationWrapper = locationSearchWrapper;

					// Check if Store Locator Flyour Is Present
					locationOverlay = locationWrapper.closest('.store-filter__filters__location__select');

					// If Wrapped In an Overlay, Change Context of .location-search
					if (locationOverlay) {
						locationWrapper = locationSearchWrapper.parentElement;
					} else {
						locationOverlay = locationWrapper;
					}

					searchBox = locationWrapper.querySelector('input.location');

				});
			}

			//console.log("('.show-error').length",locationWrapper.querySelectorAll('.show-error').length);
			//console.log("searchBox",searchBox);

			if(locationWrapper != undefined && locationWrapper.querySelectorAll('.show-error').length == 0 && searchBox.value != "") {

				//console.log("window.BATO.Store.getState().stores4",window.BATO.Store.getState().stores);

				analyticsObject.data.search_input = searchBox.value;

				let storeQty = window.BATO.Store.getState().stores.data.quantity,
					storeIDs = window.BATO.Store.getState().stores.data.storeNumbers,
					storeData = window.BATO.Store.getState().stores.data.stores;

				analyticsObject.data.number_of_results = storeQty;

				// Wait For Results To Come In Before Setting Analytics Object

				const setStores = () => {

					let storeCardsWrapper = document.querySelector('.cmp-store-cards');
					let storeCards = (storeCardsWrapper != null) ? storeCardsWrapper.querySelectorAll(".store-card") : null;

					let storesArray = [];

					if (storeCards && storeCards.length > 0) {

						storeCards.forEach(storeCard => {

							if( !storeCard.classList.contains('hide') ) {
								storesArray.push({
									"store_id": storeCard.dataset.number,
									"store_name": storeCard.dataset.name
								});
							}

						});

					}

					analyticsObject.data.store = storesArray;

					adobeDataLayer.push(analyticsObject);

				}

				setStores();

			}
		}

		disableOnInitialLoad = false;
	}
}


export default {
	init
}