export function fsdModalHTML() {

    return `<div class="fsd-modal">
                <h1>Now, our mechanics come to you</h1>
                <div class="fsd-modal__sections">
                    <div>
                        <img src="/etc.clientlibs/consumer/shared/common/clientlibs/clientlib-icons/resources/images/icons/fsd/step-1.png" />
                        <div>
                            <b>Certified Firestone Mechanics</b>
                            <p>With Firestone Direct, you get the same level of expertise that separates Firestone Complete Auto Care from the pack.</p>
                        </div>
                    </div>
                    <div>
                        <img src="/etc.clientlibs/consumer/shared/common/clientlibs/clientlib-icons/resources/images/icons/fsd/step-2.png" />
                        <div>
                            <b>Timesaving Mobile Service</b>
                            <p>Fully-equipped mobile service vans help our mobile mechanics complete your service or repairs right outside your home or office.</p>
                        </div>
                    </div>
                    <div>
                        <img src="/etc.clientlibs/consumer/shared/common/clientlibs/clientlib-icons/resources/images/icons/fsd/step-3.png" />
                        <div>
                            <b>Service and Pricing You Can Trust</b>
                            <p>Because our service is backed by Firestone you can trust our word when it comes to quality of service and transparent pricing.</p>
                        </div>
                    </div>
                </div>
            </div>`;

}
