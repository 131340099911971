import elementListener from '../../../../../js/library/eventHelpers.js';

// setup window object for components to consume
if (!window.BATO) window.BATO = {};

const pageContainer = document.querySelector(".page");

const storeDetailsAnalyticsObject = {
	"event": "store.telephone_click",
	"store": {
		"store_id": "",
		"store_name": "",
		"store_telephone": "",
		"list_name": "",
	}
};

export const init = () => {
	// Store Cards (Anwyhere On Site)
	if (pageContainer != null) {
		elementListener.addBubblerListener(pageContainer, 'click', '.store-card a.button--tertiary', storeDetailsEventHandler);
	}
}

const storeDetailsEventHandler = (evt) => {
	let el = evt.target;
	let analyticsObject = JSON.parse(JSON.stringify(storeDetailsAnalyticsObject));

	let parentDiv = el.closest('div.store-card');

	analyticsObject.store.store_id = parentDiv.dataset.number;
	analyticsObject.store.store_name = parentDiv.dataset.name;
	analyticsObject.store.store_telephone = el.innerHTML;

	let flyoutCheck = pageContainer.querySelector('.tire-search-flyout__tab-content');

	flyoutCheck ? analyticsObject.store.list_name = 'dealer fly-out' : analyticsObject.store.list_name = 'dealer search';

	adobeDataLayer.push(analyticsObject);
}

export default {
	init
}