import '../scss/vehicle-selector-fitment.scss';
import { removeChildren } from '../../../../../js/library/utilsForFrontend.js';
import { urlLanguageCheck } from '../../../../../js/utils/urlLanguageCheck.js';

// setup window object for components to consume
if (!window.BATO) window.BATO = {};

const vehicleSelectorFitmentClass = 'vehicle-selector-fitment';
const vehicleService = window.BATO.Services.vehicles;
const vehicleFitment = { year: '', make: '', model: '', trim: '', tpms: '' };

let languageInUrl = urlLanguageCheck();
const vehicleSelectorFitmentResultsPath = (languageInUrl) ? '/' + languageInUrl + '/catalog' : '/catalog'; // TODO: this should be read from button clicked to show modal

let vehicleSelectorFitment, selectYear, selectMake, selectModel, selectTrim, selectedOptions, selectLists, vehicleStatus, vehicleProfile, mobileLayout;

const init = () => {

	vehicleSelectorFitment = document.querySelector(`.${vehicleSelectorFitmentClass}`);
	vehicleProfile = window.BATO.Store.getState().vehicles; //is car 'known' and if so ymmt
	vehicleStatus = vehicleProfile.status; //get status setting

	reportWindowSize();

	if (vehicleSelectorFitment) {
		selectYear = vehicleSelectorFitment.querySelector('li.year');
		selectMake = vehicleSelectorFitment.querySelector('li.make');
		selectModel = vehicleSelectorFitment.querySelector('li.model');
		selectTrim = vehicleSelectorFitment.querySelector('li.trim');
		selectedOptions = vehicleSelectorFitment.querySelector('.vehicle-selector-fitment__display');
		selectLists = [selectYear,selectMake,selectModel,selectTrim];

		window.BATO.vehicleSelectorFitment = vehicleSelectorFitment;
		window.BATO.vehicleSelectorFitmentResultsPath = vehicleSelectorFitmentResultsPath;

		getYears();
		selectYear.classList.add('active'); //make active by default for mobile

		if (vehicleStatus == "known") {
			let tmpFitment = vehicleProfile.vehicle;
			vehicleFitment.year = tmpFitment.year;
			vehicleFitment.make = tmpFitment.make;
			vehicleFitment.model = tmpFitment.model;

			getMakes(vehicleFitment.year);
		}

		window.BATO.vehicleSelectorFitment.init = init;
	}
}

const getYears = () => {
	const getYears = vehicleService.getYears();

	getYears
		.then(response => {
			setYears(response.data);
		})
		.catch(error => {
			const err = `there's a problem with vehicle-selector-fitment getYears': ${error}`;
		    throw new Error(err);
		})
}

const getMakes = (year) => {
	const getMakes = vehicleService.getMakes(year);

	getMakes
		.then(response => {
			setMakes(response.data);
		})
		.catch(error => {
			const err = `there's a problem with vehicle-selector-fitment getMakes': ${error}`;
		    throw new Error(err);
		})
}

const getModels = (year, make) => {
	const getModels = vehicleService.getModels(year, make);

	getModels
		.then(response => {
			setModels(response.data);
		})
		.catch(error => {
			const err = `there's a problem with vehicle-selector-fitment getModels': ${error}`;
		    throw new Error(err);
		})
}

const getTrims = (year, make, model) => {
	const getTrims = vehicleService.getTrims(year, make, model);

	getTrims
		.then(response => {
			setTrims(response.data);
		})
		.catch(error => {
			const err = `there's a problem with vehicle-selector-fitment getTrims': ${error}`;
		    throw new Error(err);
		})
}


const setYears = (data) => {
	const years = data && data.years;

	if (years.length) {

		const selectOptions = vehicleSelectorFitment.querySelector('li.year').lastElementChild;
		removeChildren(selectOptions);
		let yearOptionsList = selectYear.querySelector('.select__options');

		const keypressHandler = (evt) => {
			if(evt.keyCode==13){
				yearSelected(evt);
			};
		}

		const clickHandler = (evt) => {
				yearSelected(evt);
		}

		years.forEach(y => {
			const span = document.createElement('span');

			span.classList.add('option');
			span.setAttribute("tabindex", "1");
			span.dataset.value = span.innerText = y;

			span.addEventListener("click", clickHandler);
			span.addEventListener("keypress", keypressHandler);

			selectYear.lastElementChild.appendChild(span);
		});



		const yearSelected = (evt) => {
			removeChildren(selectMake.lastElementChild);
			removeChildren(selectModel.lastElementChild);
			removeChildren(selectTrim.lastElementChild);
			vehicleFitment.year = evt.target.dataset.value;
			vehicleFitment.make = "";
			vehicleFitment.model = "";
			vehicleFitment.trim = "";
			vehicleStatus = "unknown";

			setDisplay();

			if ((yearOptionsList.classList.contains("minimize-options")) && (mobileLayout)) { //is list collapsed(mobile only)?
				configureCollapsedList(selectYear,yearOptions,yearOptionsList);
			} else { //list NOT collapsed (mobile, desktop)
				if (!mobileLayout){configureCollapsedList(selectYear,yearOptions,yearOptionsList)};
				hideSpanItems(yearOptions);  // add hide class to all span items
				evt.currentTarget.classList.add('active'); //add active to span item
				evt.currentTarget.classList.remove('hide'); //remove hide to span item
				selectMake.classList.add('active'); //add active to NEXT <li>
				yearOptionsList.classList.toggle("minimize-options");
				getMakes(vehicleFitment.year);
			}


		}

		//setup year for initial visit
		resetActiveLists(selectYear); //remove active from all parent <li>'s except item
		minimizeAllLists();
		yearOptionsList.classList.remove("minimize-options");
		let yearOptions = selectYear.querySelectorAll('.option');
		showSpanItems(yearOptions); //make all spans visible


		if (vehicleStatus == "known") {
			setDisplay();
			yearOptionsList.classList.add("minimize-options");
			let yearEl = selectYear.querySelector('span[data-value="' + vehicleFitment.year + '"]');
			hideSpanItems(yearOptions);
			yearEl.classList.remove('hide');
			yearEl.classList.add('active');

			scrollToItem(yearEl, yearOptionsList);
		}
	}

};


const setMakes = (data) => {
	const makes = data && data.makes;

	if (makes.length) {
		const selectOptions = vehicleSelectorFitment.querySelector('li.make').lastElementChild;
		removeChildren(selectOptions);
		let makeOptionsList = selectMake.querySelector('.select__options');

		const keypressHandler = (evt) => {
			if(evt.keyCode==13){
				makeSelected(evt);
			};
		}

		const clickHandler = (evt) => {
				makeSelected(evt);
		}

		makes.forEach(m => {
			const span = document.createElement('span');

			span.classList.add('option');
			span.setAttribute("tabindex", "1");
			span.dataset.value = span.innerText = m;

			span.addEventListener("click", clickHandler);
			span.addEventListener("keypress", keypressHandler);

			selectOptions.appendChild(span);
		});

		const makeSelected = (evt) => {
			removeChildren(selectModel.lastElementChild);
			removeChildren(selectTrim.lastElementChild);

			vehicleFitment.make = evt.target.dataset.value;
			vehicleFitment.model = "";
			vehicleFitment.trim = "";
			vehicleStatus = "unknown";

			setDisplay();

			if ((makeOptionsList.classList.contains("minimize-options")) && (mobileLayout)) {
				configureCollapsedList(selectMake,makeOptions,makeOptionsList);
			} else {
				if (!mobileLayout) {
					configureCollapsedList(selectMake,makeOptions,makeOptionsList)
				};

				hideSpanItems(makeOptions);
				evt.currentTarget.classList.add('active');
				evt.currentTarget.classList.remove('hide');
				selectModel.classList.add('active');
				makeOptionsList.classList.toggle("minimize-options");

				getModels(vehicleFitment.year, vehicleFitment.make);
			}
		}

		resetActiveLists(selectMake);
		minimizeAllLists();
		makeOptionsList.classList.remove("minimize-options");
		let makeOptions = selectMake.querySelectorAll('.option');
		showSpanItems(makeOptions);
		selectMake.setAttribute("tabindex","1")
		selectMake.focus();

		if (vehicleStatus == "known") {
			setDisplay();
			makeOptionsList.classList.add("minimize-options");
			let makeEl = selectMake.querySelector('span[data-value="' + vehicleFitment.make + '"]');
			hideSpanItems(makeOptions);
			makeEl.classList.remove('hide');
			makeEl.classList.add('active');

			scrollToItem(makeEl, makeOptionsList);

			getModels(vehicleFitment.year, vehicleFitment.make);
		}
	}
};



const setModels = (data) => {
	const models = data && data.models;

	if (models.length) {
		const selectOptions = vehicleSelectorFitment.querySelector('li.model').lastElementChild;
		removeChildren(selectOptions);
		let modelsOptionsList = selectModel.querySelector('.select__options');

		const keypressHandler = (evt) => {
			if(evt.keyCode==13){
				modelSelected(evt);
			};
		}

		const clickHandler = (evt) => {
				modelSelected(evt);
		}

		models.forEach(m => {
			const span = document.createElement('span');

			span.classList.add('option');
			span.setAttribute("tabindex", "1");
			span.dataset.value = span.innerText = m;

			span.addEventListener("click", clickHandler);
			span.addEventListener("keypress", keypressHandler);

			selectOptions.appendChild(span);
		});

		const modelSelected = (evt) => {
			removeChildren(selectTrim.lastElementChild);

			vehicleFitment.model = evt.target.dataset.value;
			vehicleFitment.trim = "";
			vehicleFitment.tpms = "";
			vehicleStatus = "unknown";

			setDisplay();

			if (modelsOptionsList.classList.contains("minimize-options") && mobileLayout) {
				configureCollapsedList(selectModel,modelOptions,modelsOptionsList);
			} else {
				if (!mobileLayout) {
					configureCollapsedList(selectModel,modelOptions,modelsOptionsList)
				};

				hideSpanItems(modelOptions);
				evt.currentTarget.classList.add('active');
				evt.currentTarget.classList.remove('hide');
				selectTrim.classList.add('active');
				modelsOptionsList.classList.toggle("minimize-options");

				getTrims(vehicleFitment.year, vehicleFitment.make, vehicleFitment.model);
			}
		}

		resetActiveLists(selectModel);
		minimizeAllLists();
		modelsOptionsList.classList.remove("minimize-options");
		let modelOptions = selectModel.querySelectorAll('.option');
		showSpanItems(modelOptions);
		selectModel.setAttribute("tabindex","1")
		selectModel.focus();

		if (vehicleStatus == "known") {
			setDisplay();
			modelsOptionsList.classList.add("minimize-options");
			let modelEl = selectModel.querySelector('span[data-value="' + vehicleFitment.model + '"]');
			hideSpanItems(modelOptions);
			modelEl.classList.remove('hide');
			modelEl.classList.add('active');

			scrollToItem(modelEl, modelsOptionsList);

			getTrims(vehicleFitment.year, vehicleFitment.make, vehicleFitment.model);
		}
	}
};

const setTrims = (data) => {
	const trims = data && data.trims;

	if (trims.length) {
		const selectOptions = vehicleSelectorFitment.querySelector('li.trim').lastElementChild;
		removeChildren(selectOptions);
		let trimOptionsList = selectTrim.querySelector('.select__options');

		const keypressHandler = (evt) => {
			if(evt.keyCode==13){
				trimSelected(evt);
			};
		}

		const clickHandler = (evt) => {
			trimSelected(evt);
		}

		trims.forEach(t => {
			const span = document.createElement('span');

			span.classList.add('option');
			span.setAttribute("tabindex", "1");
			span.dataset.value = span.innerText = t.trim;
			span.dataset.tpms = t.tpms;
			span.dataset.acesVehicleId = t.acesVehicleId;
			span.dataset.urlFriendly = t.trimUrlFriendly;

			span.addEventListener("click", clickHandler);
			span.addEventListener("keypress", keypressHandler);

			selectOptions.appendChild(span);
		});

		const trimSelected = (evt) => {
			vehicleFitment.trim = evt.target.dataset.value;
			vehicleFitment.tpms = evt.target.dataset.tpms;
			vehicleFitment.acesVehicleId = evt.target.dataset.acesVehicleId;

			let trimUrlFriendly = evt.target.dataset.urlFriendly;

			setDisplay();

			if (trimOptionsList.classList.contains("minimize-options") & mobileLayout) {
				configureCollapsedList(selectTrim,trimOptions,trimOptionsList);
			} else {
				if (!mobileLayout) {
					configureCollapsedList(selectTrim,trimOptions,trimOptionsList)
				};

				hideSpanItems(trimOptions);
				evt.currentTarget.classList.add('active');
				evt.currentTarget.classList.remove('hide');
				trimOptionsList.classList.toggle("minimize-options");

				window.BATO.ProfileActions.setVehicle(vehicleFitment).then(response => {
					if (!window.BATO.vehicleSelector.redirect) {
						if (window.BATO.vehicleSelector.callback) {
							//TODO: trigger callback if one is set

							//window.BATO.vehicleSelector.callback.vehicleSet();
						}
						window.BATO.modal.deactivateModal(vehicleSelectorFitment);
						return;
					}

					let redirectPath = window.BATO.vehicleSelectorFitmentResultsPath + '/' + data.makeUrlFriendly + '/' + data.modelUrlFriendly + '/' + vehicleFitment.year + '/' + trimUrlFriendly + '/' + window.BATO.vehicleSelector.hrefSuffix;

					window.location.href = redirectPath;
				});
			}

		}

		resetActiveLists(selectTrim);
		minimizeAllLists();
		trimOptionsList.classList.remove("minimize-options");
		let trimOptions = selectTrim.querySelectorAll('.option');
		showSpanItems(trimOptions);
		selectTrim.setAttribute("tabindex","1")
		selectTrim.focus();
		if(sessionStorage.getItem("2")) {
			let trimEl = selectTrim.querySelector('span[data-value="' + JSON.parse(sessionStorage.getItem("2"))[3] + '"]');
			trimEl.classList.remove('hide');
			trimEl.classList.add('active');
		}
	}
};

const configureCollapsedList = (select,options,itemList) => {
	resetActiveLists(select);
	removeActive(options);
	showSpanItems(options);
	minimizeAllLists();
	itemList.classList.toggle("minimize-options");
}

//remove active to all parent <li>'s, except object
const resetActiveLists = (item) => {
	selectLists.forEach(y => {
		y.classList.remove('active');
	});
	item.classList.add('active');
}

//add minimization to all ymmt lists
const minimizeAllLists = () => {
	selectLists.forEach(y => {
		let selectList = y.querySelector('.select__options');
		selectList.classList.add("minimize-options");
	});
}

//make all spans visible for object
const showSpanItems = (what) => {
	what.forEach(y => {
		y.classList.remove('hide');
	});
}

//hide each span item
const hideSpanItems = (what) => {
	what.forEach(y => {
		y.classList.add('hide');
	});
}

//reset all spans for object so none is active
const removeActive = (what) => {
	what.forEach(y => {
		y.classList.remove('active');
	});
}

//scroll to selected list item when modal is opened
const scrollToItem = (theItem, theOptionsList) => {
	let rect = theItem.getBoundingClientRect().top-350;
	theOptionsList.scrollTo({top: rect, behavior: 'smooth'});
}


const setDisplay = () => {
	selectedOptions.innerHTML = "";
	for (const prop in vehicleFitment) {
		var _v = vehicleFitment[prop];

		if (_v && prop != 'tpms') {
			_v += " ";
			selectedOptions.innerHTML += _v;
		}
	}
}

export default {
	selectYear: function(year) { // Called from vehicle-picker-widget
		if (year !== undefined){ // Year was selected and 'Next Vehicle Make' was clicked
			removeChildren(selectMake.lastElementChild);
			removeChildren(selectModel.lastElementChild);
			removeChildren(selectTrim.lastElementChild);

			let yearOptionsList = selectYear.querySelector('.select__options');
			let yearOptions = yearOptionsList.querySelectorAll('.option');

			configureCollapsedList(selectYear,yearOptions,yearOptionsList);

			vehicleFitment.year = year;
			vehicleFitment.make = "";
			vehicleFitment.model = "";
			vehicleFitment.trim = "";

			hideSpanItems(yearOptions); //NEW, addresses bug, see ticket BATO2020-3078

			let yearElWidget = selectYear.querySelector('span[data-value="' + year + '"]');
			yearElWidget.classList.add('active');
			yearElWidget.classList.remove('hide');

			setDisplay();
			getMakes(year);
		}
	}
}



document.addEventListener("DOMContentLoaded", () => {
	init();
})


const reportWindowSize = () => {
	mobileLayout = (document.body.clientWidth < 1024) ? (1) : (0); //client width value will need to be changed if we change the mobile breakpoint
}

window.onresize = reportWindowSize;
