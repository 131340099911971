export function fsdHTML(name, urlPfx, fsdParameterMap) {

    let dataHref = urlPfx + '&';

    for (const [key, value] of Object.entries(fsdParameterMap)) {
        dataHref += `${key}=${value}&`;
    }

    dataHref = dataHref.slice(0, -1);

    return `<div class="fsd-card__banner">Now available in your area!</div>
            <div class="fsd-card__logo"></div>          
            <hr />
            <p>Mobile tire installation that comes to you.</p>
            <button class="button basket-transfer" data-name="${name}" data-zip="${fsdParameterMap.zipCode}" data-href="${dataHref}">Schedule Now</button>
            <a class="button button--tertiary" href="#">Learn More</a>
            
            <a href="https://www.firestonedirect.com/" class="fsd-card__link" target="_blank">on Firestonedirect.com</a>`;
}