import '../scss/accordion.scss';

const initFaqPageSeoSchema = () => {
    if (document.querySelectorAll("body.faq-page").length) {

        const faqs = Array.from(document.querySelectorAll(".cmp-accordion__item"));
        let mainEntity = [];

        if (faqs.length) {

            faqs.forEach((value) => {
                let question = value.querySelector(".cmp-accordion__item-label");
                let answer = value.querySelector(".cmp-accordion__item-text");
                if (question !== null && answer !== null) {
                    mainEntity.push({
                        "@type": "Question",
                        "name": `${question.textContent}`,
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": `${answer.textContent}`
                        }
                    });
                }
            });

            const structuredData = {
                "@context": "https://schema.org",
                "@type": "FAQPage",
                mainEntity
            };

            const script = document.createElement('script');
            script.setAttribute('type', 'application/ld+json');
            script.textContent = JSON.stringify(structuredData);
            document.head.appendChild(script);

        }
    }
    
    const accordionItemClicked = (item) => {
        item.checked = true;
    }

    const theAccordions = document.querySelectorAll('.accordion');
    //console.log(">>>> theAccordions, " , theAccordions)
    theAccordions.forEach(accordion => {
        let theAccordionItems = document.querySelectorAll('.cmp-accordion__item .cmp-accordion__item-label');

        theAccordionItems.forEach(accordionItem => {
            //console.log(">>>>accordionItem, ",accordionItem);
            //console.log("accordionItem.previousElementSibling , ", accordionItem.previousElementSibling)
            accordionItem.what = accordionItem;
            accordionItem.addEventListener('focus', accordionItemClicked.bind(null, accordionItem.previousElementSibling));
        });
        
    });

};

document.addEventListener("DOMContentLoaded", function() {
    initFaqPageSeoSchema();
});