import elementListener from '../../../../../js/library/eventHelpers.js';

// setup window object for components to consume
if (!window.BATO) window.BATO = {};

//const storeCardsContainer = document.querySelector(".cmp-store-cards");
const pageContainer = document.querySelector(".page");

const storeDetailsAnalyticsObject = {
	"event": "store_click",
	"store": {
		"store_id": "",
		"store_name": "",
		"list_name": "search results",
	}
};

export const init = () => {
	if (pageContainer != null) {
		elementListener.addBubblerListener(pageContainer, 'click', 'a.store-card_details', storeDetailsEventHandler);
	}
}

const storeDetailsEventHandler = (evt) => {
	let el = evt.target;
	let analyticsObject = JSON.parse(JSON.stringify(storeDetailsAnalyticsObject));

	let parentDiv = el.closest('div.store-card');

	analyticsObject.store.store_id = parentDiv.dataset.number;
	analyticsObject.store.store_name = parentDiv.dataset.name;

	adobeDataLayer.push(analyticsObject);
}

export default {
	init
}