import profileApi from './profile/profile.js';

profileApi.setupProfile();

// BATO2020-620 - Clear Profile Work
// Can Reactivate this when ready to test properly
/*
profileApi.checkProfile().then(profileReturn => {
    // profileApi.clearProfile();
    console.log('profileReturn', profileReturn);
});
*/

let flexStorageJSON = JSON.parse(window.localStorage.getItem('BST-NA_PROFILE_FLEX::flex'));

if(flexStorageJSON) {

    if(!flexStorageJSON.hasOwnProperty('previouslyMatchedSizes')) {
        //console.log('no previouslyMatchedSizes');

        flexStorageJSON.previouslyMatchedSizes = [];
        let flexStorageString = JSON.stringify(flexStorageJSON);
        window.localStorage.setItem('BST-NA_PROFILE_FLEX::flex', flexStorageString);

        //console.log(flexStorageJSON);
        //console.log(flexStorageString);
    }

}