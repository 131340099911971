import { debounce } from './debounce.js';


/**
 * Universal Scrolling Function
 *
 * @param {string} container  - parent element the scrolling takes place in
 * @param {string} elm        - the element to scroll into view
 * @param {integer} duration  - how long to complete scroll in seconds
 * @param {string} scrollFrom - the direction to scroll - starting position: 'top', 'left'
 * @param {string} easingType - ease animations, add types as needed
                              - easeInOutQuad : default
                              - easeOutSine
                              - easeOutQuint
**/

const scrollToElement = (container, elm, duration, scrollFrom, easingType) => {

    let to = 0;
    let pos = getRelativePos(elm);

    if(scrollFrom == 'left') {

        pos.left < 0 ? pos.left = Math.abs(pos.left) : ''; // If Value is Negative, Switch it to Positive
        to = pos.left;

    } else {

        to = pos.top;

    }

    scrollTo(container, Math.abs(to), duration, false, scrollFrom, easingType);
}

const getRelativePos = (elm) => {

    let pPos = elm.parentNode.getBoundingClientRect(), // parent pos
        cPos = elm.getBoundingClientRect(), // target pos
        pos = {};

    pos.top    = cPos.top    - pPos.top + elm.parentNode.scrollTop,
    pos.right  = cPos.right  - pPos.right,
    pos.bottom = cPos.bottom - pPos.bottom,
    pos.left   = cPos.left   - pPos.left + elm.parentNode.scrollLeft;

    return pos;

}

const scrollTo = (element, to, duration, onDone, scrollFrom, easingType) => {

    let start;
    scrollFrom == 'top' ? start = element.scrollTop : start = element.scrollLeft;

    let change = to - start,
        startTime = performance.now(),
        val, now, elapsed, t;

    const animateScroll = () => {

        now = performance.now();
        elapsed = (now - startTime)/1000;
        t = (elapsed/duration);

        let moveEasing;

        switch (easingType) {
            case "easeOutSine":
                moveEasing = change * easeOutSine(t);
            break;
            case "easeOutQuint":
                moveEasing = change * easeOutQuint(t);
            break;
            default:
                moveEasing = change * easeInOutQuad(t);
            break;
        }

        let move = start + moveEasing;

        scrollFrom == 'top' ? element.scrollTop = move : element.scrollLeft = move;

        t < 1 ? window.requestAnimationFrame(animateScroll) : onDone && onDone();
    };

    animateScroll();

}

const easeInOutQuad = (t) => { return t<.5 ? 2*t*t : -1+(4-2*t)*t };
const easeOutSine = (t) => { return Math.sin((t * 3) / 2) };
const easeOutQuint = (t) => { return 1 - Math.pow(1 - t, 5) };

export { scrollToElement };
