import '../scss/tire-comparison.scss';
import '../../tire-card-comparison/v1/js/tire-card-comparison-render.js';
import elementListener from '../../../../../js/library/eventHelpers.js';

document.addEventListener("DOMContentLoaded", function() {

    const tcr = document.querySelector('.tire-search-results');

    const findDifferences = (evt) => {

        let allCards = tcr.querySelectorAll('.cmp-tire-comparison__col'),
            cardComparisonArray = [];

        if(allCards.length > 0) {

            allCards.forEach((card, i) => {

                let details = card.querySelectorAll('.details-section > div');

                cardComparisonArray.push(details);

            });

            let checkbox = evt.target.parentElement.querySelector('input');

            if(!checkbox.checked) {

                cardComparisonArray[0].forEach((detail, i) => {

                    if( !detail.isEqualNode(cardComparisonArray[1][i]) ) {

                        detail.classList.add('highlight');
                        cardComparisonArray[1][i].classList.add('highlight');

                    }

                });

                // If 3 Cards, Compare 1 and 2 Against 3, This Needs to be Discussed
                if(cardComparisonArray.length == 3) {

                    for (var x = 0; x < 2; x++) {

                        cardComparisonArray[x].forEach((detail, i) => {

                            if( !detail.isEqualNode(cardComparisonArray[2][i]) ) {

                                cardComparisonArray[2][i].classList.add('highlight');

                            }

                        });

                    }

                }

            } else {

                cardComparisonArray.forEach((card, i) => {

                    card.forEach((detail, i) => {
                        detail.classList.remove('highlight');
                    });

                });


            }

        }

        //console.log("cardComparisonArray",cardComparisonArray);

    }

    if(tcr != null){
        elementListener.addBubblerListener(tcr, 'click', '.slider', findDifferences);
    }

});