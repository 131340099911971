import '../scss/store-details.scss';

document.addEventListener("DOMContentLoaded", async function(event) {

    if (!window.BATO) window.BATO = {};
    if (!window.BATO.storeLocator) window.BATO.storeLocator = {};

    const icon = "<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26 36' id='shape-mapPin-red' width='26' height='36'><path fill='#ff0017' d='M13 0C5.82 0 0 5.757 0 12.858S13 36 13 36s13-16.041 13-23.142S20.18 0 13 0z'></path></svg>";
    const storeDetails = document.querySelector('.store-details');

    if(storeDetails) {

        let locationDiv = storeDetails.querySelector('.store-details-info__location');
        let state = document.querySelector('.store-details-info__location__state');

        if(locationDiv) {

            const dropPin = () => {

                let map = window.map,
                    latlng = new Microsoft.Maps.Location( parseFloat(locationDiv.dataset.lat), parseFloat(locationDiv.dataset.lng) );

                let pin = new Microsoft.Maps.Pushpin(latlng, {
                              icon: icon,
                              anchor: new Microsoft.Maps.Point(13, 36)
                          });


                map.entities.push(pin);

                map.setView({
                    center: latlng,
                    zoom: 15
                });
            }

            (async() => {
                while(!window.hasOwnProperty("map"))
                    await new Promise(resolve => setTimeout(resolve, 250));
                dropPin();
            })();

            if(window.BATO.CONFIG.displayFullStateName) {
                window.BATO.CONFIG.displayFullStateName === 'true' 
                    ? state.textContent = locationDiv.dataset.storeStateDisplay
                    : state.textContent = locationDiv.dataset.storeState
            } else {
                state.textContent = locationDiv.dataset.storeState;
            }
            
        }

        let hoursWrapper = storeDetails.querySelector('.store-details-info__hours');
        let dateUL = (hoursWrapper !== null) ? hoursWrapper.querySelectorAll('li') : null;
        let translatedText = (hoursWrapper !== null) ? hoursWrapper.dataset : null;

        if(dateUL) {

            let d = new Date().getDay(),
                h = new Date().getHours(),
                closingTime,
                closingTimeHour;

            // TODO: OPEN/CLOSE need i18n Treatment

            let openClosed = translatedText.open,
                openClosedSpan = document.createElement('span');

            closingTime = dateUL[d].dataset.close;

            // Since We Updated Java Model to Bring Back Formatted Date, We Need To
            // Convert the Time to Perform Open/Close Check against Current Time
            if(parseInt(closingTime.substr(0, closingTime.indexOf(':')))) {
                closingTimeHour = parseInt(closingTime.substr(0, closingTime.indexOf(':')));
            } else {
                closingTimeHour = parseInt(closingTime.substr(0, closingTime.indexOf(' ')));
            }

            // Crude Conversion to 24 Hour Time
            if(closingTime.substr(0, closingTime.indexOf('PM'))){
                closingTimeHour = closingTimeHour+12;
            }

            if (h >= closingTimeHour) {
                openClosedSpan.classList.add("closed");
                openClosed = translatedText.closed;
            } else {
                openClosedSpan.classList.add("open");
            }

            openClosedSpan.innerHTML = openClosed;

            dateUL[d].children[1].appendChild(openClosedSpan);

        }
        
        let whatsappEL = document.querySelector('.store-details-info__phone-whatsapp');
        const whatsAppNumber = whatsappEL?.dataset.storeContactWhatsapp;

        if(whatsAppNumber) {
            let whatsappNumberFormated = whatsAppNumber.replace(/[^\d]/g, '');
            whatsappNumberFormated = whatsappNumberFormated.replace(/^0+/, '');
            //whatsappEL.href = `https://wa.me/${whatsappNumberFormated}`;
            whatsappEL.href = `https://api.whatsapp.com/send?phone=${whatsappNumberFormated}&text=Ol%C3%A1%2C%20gostaria%20de%20falar%20com%20o%20atendimento%20Bridgestone%20%28dealerlocator%29`;
        }
        
    }
});