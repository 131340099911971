export function headerHTML(cardTotal,i18n) {
    var theHeaderContent = `
        <div class="tire-comparison__header">
            <div>
                <a href="#">${i18n.comparisonHeaderBackToResults}</a>
                <h2>${i18n.comparisonHeaderTitle}</h2>
            </div>
            <div>${i18n.comparisonHeaderComparing} ${cardTotal} ${i18n.comparisonHeaderTires}</div>
            <div class="compare-switch">
                <label class="switch">
                    <input type="checkbox">
                    <span class="slider round"></span>
                </label>
                ${i18n.comparisonHeaderHighlightDifferences}
            </div>
        </div>
    `;

    return theHeaderContent;

}
