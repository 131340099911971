import elementListener from '../../../../../../js/library/eventHelpers.js';
import {urlLanguageCheck} from '../../../../../../js/utils/urlLanguageCheck.js';
import { getStoresByLatLon } from '../../../../services/v1/js/api/stores.js';

document.addEventListener("DOMContentLoaded", function() {

    let pageWrapper = document.querySelector('.page');
    let tdgStores;

    if (!window.BATO) window.BATO = {};
    if (!window.BATO.storeLocator) window.BATO.storeLocator = {};


    const getTranslatedText = () => {

        let tireFilter = document.querySelector('.tire-filter'),
            tireDetail = document.querySelector('.tire-detail-datawrapper');

        if(tireFilter) {
            return tireFilter.dataset;
        } else if (tireDetail) {
            return tireDetail.dataset;
        }

    }

    const urlRedirectConstruct = () => {

        let languageInUrl = urlLanguageCheck(),
        urlRedirect = '/dealer/';

        languageInUrl ? urlRedirect = '/' + languageInUrl + urlRedirect : '';

        return urlRedirect;
    }


    // Locate Tire Right Hand Flyout
    // TODO: i18n Variables Needed
    const storeLocatorModalContent = (locationKnown, storeCards) => {

        let zip = '',
            totalStores = '',
            translatedText = getTranslatedText(),
            overlayClasses = (locationKnown) ? '' : ' fade-in location-search--overlay location-search--unknown';

        if (document.querySelector('.tire-decision-guide') !== null && sessionStorage.getItem('tdg_params') && JSON.parse(sessionStorage.getItem('tdg_params'))[2] !== null && !sessionStorage.getItem('1')) {
            zip = JSON.parse(sessionStorage.getItem('tdg_params'))[2].values.join("");
            totalStores = tdgStores?.data.qty;
            overlayClasses = '';
        } else if (locationKnown) {
            zip = window.BATO.Store.getState().location.data.zip;
            totalStores = window.BATO.Store.getState().stores.data.quantity;
            overlayClasses = '';
        }

        const returnData = `<div class='tire-search-flyout__tab-content' tabindex="0">
                                <input type="radio" id="tab-local" name="tab-content" tabindex="0" checked /><label for="tab-local" >` + translatedText.localdealers + `</label>
                                <input type="radio" id="tab-national" name="tab-content" tabindex="0" /><label for="tab-national" >` + translatedText.onlineretailers + `</label>
                            </div>
                            <div class='tire-search-flyout__filters'>
                                <div class="tire-search-flyout__filters__location">
                                    ` + totalStores + ` ` + translatedText.dealersnear + ` <span>` + zip.split('-')[0] + `</span>
                                    <div class="location-search` + overlayClasses + `">
                                        <div class="close-overlay"></div>
                                        <p>` + translatedText.locationSearch + `</p>
                                        <p class="error invalid">` + translatedText.locationErrorInvalid + `</p>
                                        <p class="error no-results">` + translatedText.locationErrorNoresults + `</p>
                                        <input type="text" class="location" name="location" autocomplete="off" /><button class="button">` + translatedText.locationSubmit + `</button>
                                        <p class="use-current">` + translatedText.locationUsecurrent + `</p>
                                    </div>
                                </div>
                                <a href="` + urlRedirectConstruct() + `">` + translatedText.seealldealers + `</a>
                            </div>
                            <div class="map__render"></div>
                            <div class="cmp-store-cards">` + storeCards + `</div>`;


        return new Promise((resolve) => {
            resolve(returnData);
        });

    }

    const storeLocatorModalEvents = () => {

        window.BATO.mapLoad();
        window.BATO.storeLocator.bindStoreCards();

        window.BATO.storeLocator.basketTransferFilterRender();
        window.BATO.storeLocator.purchaseButtonSetup();

        // Location/Zip
        let locationFilterWrapper = document.querySelector('.tire-search-flyout__filters');
        let locationFilter = locationFilterWrapper.querySelector('.tire-search-flyout__filters__location span');
        let locationOverlay = locationFilterWrapper.querySelector('.location-search');
        let locationOverlayClose = locationFilterWrapper.querySelector('.location-search .close-overlay');
        let tdgExists = document.querySelector('.tire-decision-guide') !== null || sessionStorage.getItem('tdgLocation');

        if (!tdgExists) { 
            locationFilter.addEventListener('click', () => {
                if (locationOverlay) {
                    locationOverlay.classList.add('location-search--overlay');
                    locationOverlay.classList.add('fade-in'); 
                }
            });
        } else {
            locationFilter.style.borderBottom = 'none';
            locationFilter.style.cursor = 'default';
        }
        
        locationOverlayClose.addEventListener('click', () => {
            locationOverlay.classList.remove('location-search--overlay');
            locationOverlay.classList.remove('fade-in'); 
        });
        

        (async () => {
            while(!window?.map?._mapHasLoaded)
                await new Promise(resolve => setTimeout(resolve, 250));
            window.BATO.loadAutoSuggest(true, true);
            window.BATO.overlayMap = true;
        })();

        // Local/National Dealer Tab Setup
        let onlineRetailerCF = document.querySelector('.store-locator__cf');

        if (onlineRetailerCF) {

            let onlineRetailerWrapper = document.createElement("div");
            onlineRetailerWrapper = document.querySelector('.store-locator__cf').cloneNode(true);
            onlineRetailerWrapper.classList.add('tire-search-flyout__retailers');

            let flyout = document.querySelector('.dynamic-top-content');
            flyout.appendChild(onlineRetailerWrapper);

            flyout.querySelector('.tire-search-flyout__retailers').style.display = "none";

            // Local vs Online

            flyout.querySelectorAll('input[name="tab-content"]').forEach((elem) => {
                elem.addEventListener("change", function(event) {
                    var item = event.target.id;
                    if(item == "tab-national") {

                        onlineRetailerWrapper.style.display = "flex";

                        setTimeout(function(){
                            onlineRetailerWrapper.classList.add("fade-in");
                        },10);

                    } else {

                        onlineRetailerWrapper.classList.remove("fade-in");

                        setTimeout(function(){
                            onlineRetailerWrapper.style.display = "none";
                        },300);

                    }
                });
            });
        }

        if (typeof window.BATO?.storeLocator?.handoffCheck === 'function') {
            window.BATO.storeLocator.handoffCheck();
        }
    }

    const storeLocatorModal = (e) => {

        e.preventDefault();
        e.stopPropagation();

        // If On Tire-Detail Page, Look for Tire-Detail-Datawrapper as our Data Source
        if (pageWrapper.querySelector('.tire-detail')) {
            let tireDetailDataset = pageWrapper.querySelector('.tire-detail-datawrapper').dataset;
            window.BATO.ProfileActions.setViewedTires({ ...tireDetailDataset });
        } else {
            if(e.target.parentElement.parentElement.classList.contains("cmp-tire-card")) {
                window.BATO.ProfileActions.setViewedTires({ ...e.target.parentElement.parentElement.dataset });
            }
        }

        let locationStatus;

        if (document.querySelector('.tire-decision-guide') !== null && sessionStorage.getItem('tdg_params') && !sessionStorage.getItem('1')) {
            const stores = getStoresByLatLon({lat: JSON.parse(sessionStorage.getItem('tdgLocation'))[0], lon: JSON.parse(sessionStorage.getItem('tdgLocation'))[1]});
            stores.then((d) => {
                tdgStores = d;
                asyncModalContent(false);
            })
        } else if (window.BATO?.Store) {
            locationStatus = window.BATO.Store.getState().location?.status === 'known' ? true : false;
        }

        // dynamic-top-container
        // Reset Window Variables for Basket Transfer Filter
        window.BATO.storeLocator.basketTransfer = {
            exists: false,
            checked: false
        }

        const asyncModalContent = async (locationStatus) => {

            let storeCards = '';
            if(locationStatus) {
                storeCards = await window.BATO.storeLocator.getStoreCards(10);
            } else {
                storeCards = await window.BATO.storeLocator.getStoreCards(10, tdgStores);
            }
            
            const modalContent = await storeLocatorModalContent(locationStatus, storeCards);

            window.BATO.modal.activateModal(
                modalContent,
                true,
                e.target,
                true,
                'flyout'
            );

            storeLocatorModalEvents();

        }

        // if (!sessionStorage.getItem('tdgLocation')) {
            asyncModalContent(locationStatus);
        // }

    }

    elementListener.addBubblerListener(pageWrapper, 'click', 'a[href^="#locate-tire"]', storeLocatorModal);

    // Close All On Outside Click
    document.addEventListener('click', (event) => {
        if ( event.target.closest(".tsr-profile__location") ||
             event.target.closest(".tire-detail__stores") ||
             event.target.closest(".cmp-store-cards") ||
             event.target.closest(".dynamic-top-content") ||
             event.target.closest(".location")
            ) { return; }
        if(document.querySelector('.location-search')) {
            document.querySelector('.location-search').classList.remove('location-search--overlay');
        }
    });

    window.BATO.storeLocator.storeLocatorModal = storeLocatorModal;

});