const isType = (obj) => {
	if (obj == null) {
		return obj + ""; 
	}
	
	var _classType = {
		"[object Boolean]": "boolean",
		"[object Number]": "number",
		"[object String]": "string",
		"[object Function]": "function",
		"[object Array]": "array",
		"[object Date]": "date",
		"[object RegExp]": "regexp",
		"[object Object]": "object",
		"[object Symbol]": "symbol",
		"[object Null]": "null",
		"[object Undefined]": "undefined",
		"[object Error]": "error"
	}
	
	var toString = Object.prototype.toString; 
	
	return _classType[toString.call(obj)];
}

const isObject = (obj) => {
	return (isType(obj) === "object") ? true : false; 
}

const isArray = (obj) => {
	return (isType(obj) === "array") ? true : false; 
}

const isEmpty = (obj) => { 
	if (isObject(obj)) {
		if (Object.keys(obj).length == 0) {
			return true; 
		} 

		for (var o in obj) {
			var _o = obj[o]; 
			
			if (isObject(_o)) {
				isEmpty(_o);
			}
			
			if (isEmpty(_o)) {
				return true; 
				break; 
			}
		}
		
		return false;
	}
	
	return (obj == '' || obj == undefined || obj == null) ? true : false; 
}

const cleanObject = (data) =>  {
	var _data = data;
	
	for(var o in _data) {
		var _o = _data[o]; 
		
		if (isObject(_o)) {
			cleanObject(_o);
		}
		
		if (isArray(_o)) {
			var _arr = _o; 
			var _i = 0;
			var _len = _arr.length; 
			for (; _i < _len; _i++) {
				cleanObject(_arr[_i]); 
			}
		}

		if (isEmpty(_o)) {
			delete _data[o];
		}
	}

	return _data;
}

export default {
	cleanObject
}