import elementListener from '../../../../../js/library/eventHelpers.js';

// setup window object for components to consume
if (!window.BATO) window.BATO = {};

const pageContainer = document.querySelector(".page");

const storeDetailsAnalyticsObject = {
	"event": ""
};

export const init = () => {
	if (pageContainer != null) {
		elementListener.addBubblerListener(pageContainer, 'click', '.redirect-modal a', storeDetailsEventHandler);
	}
}

const storeDetailsEventHandler = (evt) => {

	let el = evt.target;
	let analyticsObject = JSON.parse(JSON.stringify(storeDetailsAnalyticsObject));

	let parentDiv = el.closest('div.redirect-modal');

	if(parentDiv.dataset.number !== "undefined") {

		analyticsObject.event = 'basket_transfer.proceed';

		analyticsObject.store = {
			"store_id": "",
			"store_name": ""
		};

		analyticsObject.product = {
			"product_id": "",
			"article_id": ""
		};

		let previouslyViewedTires = window.BATO.Store.getState().flex?.previouslyViewedTires;

		if (previouslyViewedTires.length) {
			analyticsObject.product.product_id = previouslyViewedTires[0].productId;
			analyticsObject.product.article_id = previouslyViewedTires[0].articlenumber;
		}

		analyticsObject.store.store_id = parentDiv.dataset.number;
		analyticsObject.store.store_name = parentDiv.dataset.name;

	} else {

		analyticsObject.event = 'dealer_transfer.proceed';

		analyticsObject.component_detail = {
			"type": "anchor",
			"title": "Yes, Continue",
			"id": "yescontinue"
		};

		analyticsObject.dealer = {
			"dealer_id": "firestonedirect",
			"dealer_name": "Firestonedirect"
		};
	}

	adobeDataLayer.push(analyticsObject);

}

export default {
	init
}