import location from './api/location.js';
import * as reviews from './api/reviews.js';
import tires from './api/tires.js';
import tireSizes from './api/tire-sizes.js';
import vehicles from './api/vehicles.js';
import catalog from './api/catalog.js';
import handoff from './api/handoff.js';
import availability from './api/availability.js';


const api = {
  location,
  reviews,
  tires,
  tireSizes,
  vehicles,
  catalog,
  handoff,
  availability
};
export default api;

if (window.BATO) {
  window.BATO.Services = api;
};