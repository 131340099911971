import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    previouslyViewedTires: [],
    previouslyMatchedSizes: []
};

export const flex = createSlice({
    name: 'flex',
    initialState: initialState,
    reducers: {
        clearFlex: (state) => {
          return initialState
        },
        setViewedTires: (state, action) => {
            return {
                ...state,
                previouslyViewedTires: action.payload
            }
        },
        setMatchedSizes: (state, action) => {
            return {
                ...state,
                previouslyMatchedSizes: action.payload
            }
        }
    }
});

const actions = flex.actions;

export { actions };

if (!window.BATO) {
  window.BATO = {}
}

if (!window.BATO.StoreActions) {
  window.BATO.StoreActions = {}
}

window.BATO.StoreActions.flex = actions;

export default flex.reducer;