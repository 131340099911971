import React, { useState, useEffect, Fragment } from 'react';
import { DynamicPortal } from '../../../../react-shared/utils/DynamicPortal.jsx';
// import { ReviewsStarsSpinner } from '../../../../react-shared/ui/spinners-loaders/ReviewsStarsSpinner.jsx';
import { stringSanitizer } from '../../../../../../js/utils/stringSanitizer.js';
import { ReviewsApp } from './ReviewsApp.jsx';

const ReviewsLoader = ({ config }) => {
    if (!config) return null;

    // LATAM AR, CO, CR, CL share the same MX db
    const latamShared =  window.BATO?.CONFIG?.REVIEWS?.latamShared;
    const sharedLangIso = latamShared && latamShared.includes(config.lang_iso) ? 'es_MX' : config.lang_iso;

    const mainConfig = {
        apiKey: config.REVIEWS?.apiKey || '',
        canReview: config.REACT?.ratingsAndReviews?.writeReviews.find((lang) => lang === config.lang_iso) ? true : false,
        country: config.country || 'US',
        groupId: config.REVIEWS?.groupId || '',
        lang_iso: sharedLangIso || 'en_US',
        merchId: config.REVIEWS?.merchId || '1053509158',
        site: config.site || 'bst',
    }

    const initialReviews = Array.from(
        document.querySelectorAll('.reviews-app')
    );

    const reviews = (appRoots) => {
        // determine what reviews app it is and apply the matching components.

        const portalArray = appRoots
            .map((root, i) => {
                // get review type
                const type = root.dataset.type || 'reviews';
                const productType = root.dataset.productType || 'tire';
                const id = stringSanitizer(root.dataset.id, 'upper') || '';
                const tireBrand = root.dataset.tireBrand || '';
                const tireSubBrand = root.dataset.tireSubbrand || '';
                const tireName = root.dataset.tireName || '';
                const defaultSort = root.dataset.defaultSort || 'latest';
                const pageSize = parseInt(root.dataset.pageSize) || 6;
                const classes = root.dataset.class || '';

                if (type === 'inline-rating' || type === 'reviews') {
                    const uniqueId = `${i}${new Date().getTime()}`;

                    return (
                        <Fragment key={i}>
                            <DynamicPortal
                                id={`reviews-portal-${uniqueId}`}
                                className="reviews"
                                dynamicParentElement={root}
                                hideParent={true}
                            >
                                <ReviewsApp
                                    type={type}
                                    productType={productType}
                                    id={id}
                                    tireBrand={tireBrand}
                                    tireName={tireName}
                                    tireSubBrand={tireSubBrand}
                                    classes={classes}
                                    defaultSort={defaultSort}
                                    pageSize={pageSize}
                                    meta={mainConfig}
                                />
                            </DynamicPortal>
                        </Fragment>
                    );
                } else {
                    return null;
                }
            })
            .filter(Boolean);

        return portalArray;
    };

    const [roots, setRoots] = useState(initialReviews);
    const [portals, setPortals] = useState();

    useEffect(() => {
        setPortals(reviews(roots));
    }, [roots]);

    return portals ?
        <>{portals}</>
        : null;
};

export default ReviewsLoader;

if (!window.BATO) {
  window.BATO = {}
}

if (!window.BATO.COMPONENTS) {
  window.BATO.COMPONENTS = {}
}

window.BATO.COMPONENTS.ReviewsLoader = { v1: ReviewsLoader };