/**
 * removes all special characters and spaces
 * @param {string} str - string to modify
 * @param {string} strCase - case desired in output
 */

const stringSanitizer = (str, strCase) => {
  if (!str) return str;

  let sanitizedStr = str.replace(/[\W_]+/g, ''); //str.replace(/ +/g, '');

  if (strCase === 'upper') {
    sanitizedStr = sanitizedStr.toUpperCase();
  } else if (strCase === 'lower') {
    sanitizedStr = sanitizedStr.toLowerCase();
  }

  return sanitizedStr;
};

export { stringSanitizer };
