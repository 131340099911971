import '../scss/chat-widget.scss';

document.addEventListener("DOMContentLoaded", function() {

    const chatWidget = document.querySelector(".chat-widget");

    if(chatWidget){

        const chatWidgetCTA = chatWidget.querySelector(".chat-widget__cta");
        const chatWidgetButtons = chatWidget.querySelector(".chat-widget__buttons");
        const chatWidgetClose = chatWidgetButtons.querySelector(".close-overlay");
        const chatPhoneNumBtn =  chatWidgetButtons.lastElementChild;
        const chatWidgetUrl = document.getElementById("chat-widget__url")?.getAttribute("href");

        // Set the mobile chat widget HREF equal to the chat widgets url href value.
        if (chatWidgetUrl !== 'undefined') {
            document.getElementById("mobile-nav-chat-url")?.setAttribute("href", chatWidgetUrl);
        }

        const toggleChat = () => {
            chatWidgetCTA.classList.toggle("open"); 
            chatWidgetButtons.classList.toggle("open");

        }

        chatWidgetCTA.addEventListener("click", toggleChat);
        chatWidgetClose.addEventListener("click", toggleChat);

        chatWidgetCTA.addEventListener('keypress',(e) => {
            if(e.keyCode==13){ //open chat if 'enter' pressed on icon
                toggleChat();
            };
        });

        chatWidgetClose.addEventListener('keypress',(e) => {
            if(e.keyCode==13){ //close chat if 'enter' pressed on 'x'
                toggleChat();
            };
        });


        chatWidgetClose.addEventListener('keydown', function(e) { //for close (1st element)
            let isTabPressed = e.key === 'Tab' || e.keyCode === 9;
            if (!isTabPressed) { //exit listener if tab is not pressed
              return;
            }
            if (e.shiftKey) { // if shift key pressed for shift + tab combination
                toggleChat();
            }
        });

        chatPhoneNumBtn.addEventListener('keydown', function(e) { //for phone button (last element)
            let isTabPressed = e.key === 'Tab' || e.keyCode === 9;
            if (!isTabPressed) {
              return;
            }
            if (!e.shiftKey) { // if shift key NOT pressed
                toggleChat();
            }
        });


        const chatWidgetButtonChat = chatWidgetButtons.querySelector(".button");

        if(chatWidgetButtonChat){

            chatWidgetButtonChat.addEventListener('click',(e) => { //'Chat Right Now' button
                e.preventDefault();

                let url = e.target.href,
                    windowWidth = (window.screen.width / 2)- 300,
                    windowHeight = (window.screen.height / 2) - 250;

                window.open(url, 'window', "scrollbars=yes,width=600,height=500,left=" + windowWidth + ",top=" + windowHeight + ",screenX=" + windowWidth + ",screenY=" + windowHeight + "");
            });

        }

    }

});
