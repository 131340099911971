import elementListener from '../../../../../js/library/eventHelpers.js';

// setup window object for components to consume
if (!window.BATO) window.BATO = {};

const pageWrapper = document.querySelector(".page");

const locateTireAnalyticsObject = {
	"event": "locate_tire_click",
	"product":[],
};

const storeDetailsAnalyticsObject = {
	"event": "dealer.list",
	"data": {
		"store": []
	}
};

export const init = () => {

	// Store Details Page
	if(pageWrapper != null ) {

		elementListener.addBubblerListener(pageWrapper, 'click', 'a[href^="#locate-tire"]', locateTireEventHandler);

	}

}

const locateTireEventHandler = (evt) => {

	let el = evt.target;

	//
	//
	// Handle Product Detail Analytics
	let tireAnalyticsObject = JSON.parse(JSON.stringify(locateTireAnalyticsObject));

	let cardWrapper = el.parentElement.parentElement;
	let cardWrapperH3 = cardWrapper.querySelector("h3");
	let productObj;

	if(cardWrapperH3) {

		let articleNumber = cardWrapper.dataset.articlenumber;
		if (cardWrapper.dataset.articlenumberclean) articleNumber = cardWrapper.dataset.articlenumberclean

		productObj = {
			"product_id": cardWrapper.dataset.productid,
			"article_id": articleNumber,
			"name": cardWrapperH3.innerText,
			"product_brand": cardWrapper.dataset.brand,
			"brand": cardWrapper.dataset.subbrand,
			"list_name": "explore by vehicle type"
		}

	} else {

		let cardWrapper = document.querySelector('.tire-detail-datawrapper');

		productObj = {
			"product_id": cardWrapper.dataset.productId,
			"article_id": cardWrapper.dataset.articlenumber,
			"name": cardWrapper.dataset.productSubbrandName,
			"product_brand": cardWrapper.dataset.productBrand,
			"brand": cardWrapper.dataset.productSubbrand,
			"list_name": "explore by vehicle type"
		}

	}

	tireAnalyticsObject.product.push(productObj);
	adobeDataLayer.push(tireAnalyticsObject);

	//
	//
	// Handle Dealer List Analytics
	let dynamicStoreCardsContainer = document.querySelector(".dynamic-top-content .cmp-store-cards");
	let storeCards = (dynamicStoreCardsContainer != null) ? dynamicStoreCardsContainer.querySelectorAll(".store-card") : null;

	if(storeCards != null && storeCards?.length > 0) {

		let storesAnalyticsObject = JSON.parse(JSON.stringify(storeDetailsAnalyticsObject));

		let storesArray = [];

		storeCards.forEach(storeCard => {
			storesArray.push({
				"store_id": storeCard.dataset.number,
				"store_name": storeCard.dataset.name
			});
		});

		storesAnalyticsObject.data.store = storesArray;
		adobeDataLayer.push(storesAnalyticsObject);
	}

}

export default {
	init
}