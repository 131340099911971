import apiUtils from "../util/apiUtils.js";

export const getCheckProfile = async () => {

    const metaStorageJSON = JSON.parse(window.localStorage.getItem('BST-NA_PROFILE_META::profileMeta'));

    const profileParams = {
        site: window.BATO?.CONFIG?.site || 'bst',
        country: window.BATO?.CONFIG?.country || 'US',
        lang_iso: window.BATO?.CONFIG?.lang_iso || 'en_US',
        id: metaStorageJSON?.profileId || 'c9d9110b-db4c-4f2f-9b33-33bc72d6a7a2',
        uiVersion: metaStorageJSON?.uiVersion || window.BATO?.CONFIG?.uiVersion
    }

    try {

        const profileCheck = await fetch(apiUtils.apiPath('session/profile-by-id', profileParams ));

        if (!profileCheck.ok) {
            const errorData = await profileCheck.json();
            const error = `there's an error in getCheckProfile(): ${errorData}`;
            throw new Error(error);
        }
        
        const profileResponse = await profileCheck.json();

        if (profileResponse) return profileResponse;

    } catch (err) {
        console.log(`there's been an error in the getCheckProfile() function: ${err}`);
        return {
            success: false,
        }
    }
};

export default {
    getCheckProfile
};