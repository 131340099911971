import elementListener from '../../../../../js/library/eventHelpers.js';

// setup window object for components to consume
if (!window.BATO) window.BATO = {};
if (!window.adobeDataLayer) window.adobeDataLayer = [];

const storeCardsContainer = document.querySelector(".cmp-store-cards");
const tireCards = document.querySelectorAll(".cmp-tire-card-search");

const storeDetailsAnalyticsObject = {
	"event": "dealer.list",
	"data": {
		"store": []
	}
};

export const init = () => {
	// For Page Loads
	if (storeCardsContainer != null) {
		dealerListEventHandler();
	}

	// For Click Events from Tire Search Cards
	if (tireCards.length > 0) {
		tireCards.forEach(tireCard => {
			elementListener.addBubblerListener(tireCard, 'click', '.basket-transfer', dealerListEventHandler);
		});
	}
}

const dealerListEventHandler = (evt) => {
	let storeCards;

	// If Store Cards from Click Event, Rescan DOM for Store Cards Container
	if(evt) {
		let dynamicStoreCardsContainer = document.querySelector(".cmp-store-cards");
		storeCards = (dynamicStoreCardsContainer != null) ? dynamicStoreCardsContainer.querySelectorAll(".store-card") : null;
	} else {
		storeCards = (storeCardsContainer != null) ? storeCardsContainer.querySelectorAll(".store-card") : null;
	}

	if(storeCards.length > 0 && storeCards != null) {
		let analyticsObject = JSON.parse(JSON.stringify(storeDetailsAnalyticsObject));

		let storesArray = [];

		storeCards.forEach(storeCard => {
			storesArray.push({
				"store_id": storeCard.dataset.number,
				"store_name": storeCard.dataset.name
			});
		});

		analyticsObject.data.store = storesArray;

		adobeDataLayer.push(analyticsObject);
	}
}

export default {
	init
}