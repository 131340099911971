import '../../scss/overlay/_overlay.scss';
export const overlayDivClass = 'page-overlay';
export const bodyActiveOverlayClass = 'overlay-active';

export const createAndActivateOverlay = (containingEl = 'body') => {

  const overlayDiv = document.createElement('div');
  overlayDiv.className = overlayDivClass;
  const activeOverlay = document.querySelector(`.${overlayDivClass}`);
  const theEl = document.querySelector(containingEl);

  if (!activeOverlay) {
    theEl.append(overlayDiv);
  } else {
    activeOverlay.remove();
    theEl.append(overlayDiv);
  }
  const theBody = document.querySelector('body');
  theBody.classList.add(bodyActiveOverlayClass);
  //console.log("&&&& OVERLAY MADE")
};

export const removeActiveOverlay = () => {
  const activeOverlay = document.querySelector(`.${overlayDivClass}`);
  const theBody = document.querySelector('body');
  if (activeOverlay) {
    activeOverlay.remove();
    theBody.classList.remove(bodyActiveOverlayClass);
  }
  //console.log("&&&& OVERLAY REMOVED")
};

export default {
  bodyActiveOverlayClass,
  createAndActivateOverlay,
  overlayDivClass,
  removeActiveOverlay
};