document.addEventListener("DOMContentLoaded", function() {

    if (!window.BATO) window.BATO = {};
    if (!window.BATO.storeLocator) window.BATO.storeLocator = {};

    const storeFilter = document.querySelector('.store-filter');

    if(storeFilter){

        const storeFilterFilters = storeFilter.querySelector('.store-filter__filters');

        // Local vs Online
        const storeLocator = document.querySelector(".store-locator");
        const storeLocatorShopOnline = storeLocator.querySelector(".store-locator__shop-online");

        storeFilter.querySelectorAll('input[name="tab-content"]').forEach((elem) => {
            elem.addEventListener("change", function(event) {
                var item = event.target.id;
                if(item == "tab-online") {

                    storeFilterFilters.style.display = "none";
                    storeLocatorShopOnline.style.display = "flex";

                    setTimeout(function(){
                        storeLocatorShopOnline.classList.add("fade-in");
                    },10);

                } else {

                    storeLocatorShopOnline.classList.remove("fade-in");
                    storeFilterFilters.style.display = "flex";

                    setTimeout(function(){
                        storeLocatorShopOnline.style.display = "none";
                    },300);

                }
            });
        });

        // Location/Zip
        const locationFilter = storeFilter.querySelector('.store-filter__filters__location span');
        const locationOverlay = storeFilter.querySelector('.store-filter__filters__location__select');
        const locationOverlayClose = storeFilter.querySelector('.store-filter__filters__location__select .close-overlay');

        locationFilter.addEventListener('focusin', () => { //focusin triggers when the element is either clicked on or keyboard selected
            locationOverlay.classList.add('store-filter__filters__location__select--active');
            distanceOverlay.classList.remove('store-filter__filters__distance__select--active');
            sortByFilterOverlay.classList.remove('store-filter__filters__sortby__select--active');
        });

        locationOverlayClose.addEventListener('click', () => {
            locationOverlay.classList.remove('store-filter__filters__location__select--active');
        });

        locationOverlayClose.addEventListener('keypress', (e) => {
            if(e.keyCode==13){
				locationOverlay.classList.remove('store-filter__filters__location__select--active');
			};
        });










        // Within Radius
        const distanceFilter = storeFilter.querySelector('.store-filter__filters__distance__display');
        const distanceOverlay = storeFilter.querySelector('.store-filter__filters__distance__select');
        const distanceOverlayClose = storeFilter.querySelector('.store-filter__filters__distance__select .close-overlay');

        distanceFilter.addEventListener('focusin', () => { //focusin triggers when the element is either clicked on or keyboard selected
            distanceOverlay.classList.add('store-filter__filters__distance__select--active');
            locationOverlay.classList.remove('store-filter__filters__location__select--active');
            sortByFilterOverlay.classList.remove('store-filter__filters__sortby__select--active');
        });

        distanceOverlayClose.addEventListener('click', () => {
            distanceOverlay.classList.remove('store-filter__filters__distance__select--active');
        });

        distanceOverlayClose.addEventListener('keypress', (e) => {
            if(e.keyCode==13){
				distanceOverlay.classList.remove('store-filter__filters__distance__select--active');
			};
        });

        const radiusFilters = storeFilter.querySelectorAll('.store-filter__filters__distance__select label');

        let storeCardsWrapper = document.querySelector('.cmp-store-cards');

        const filterCardsByRadius = (radiusFilter) => {

            let storeCards = storeCardsWrapper.querySelectorAll('.store-card');

            // Reset All Cards to Visible
            for (const [i, storeCard] of storeCards.entries()) {
                let storeCardNumber = storeCard.querySelector('h5 span');
                storeCardNumber.textContent = i;
                storeCard.classList.remove('hide');
                storeCard.classList.remove('highlight');
            }

            // Reset Map Pins Visibility and Number
            for (const [i, pin] of window.BATO.mapPins.entries()) {
                pin.setOptions({
                    visible: true,
                    text: i.toString()
                });
            }

            let storePinNumber = 1,
                map = window.map,
                bingLatLng = [];

            // Iterate Over and Hide Relevant Pins and Cards
            for (const [i, storeCard] of storeCards.entries()) {

                let storeDistance = parseFloat(storeCard.dataset.distance);

                if(storeDistance <= radiusFilter) {

                    // Reassign Card Number
                    let storeCardNumber = storeCard.querySelector('h5 span');
                    storeCardNumber.textContent = storePinNumber;

                    bingLatLng.push(window.BATO.mapPins[i].metadata.latlng);

                    // Reassign Pin Number
                    window.BATO.mapPins[i].setOptions({ text: storePinNumber.toString() });
                    storePinNumber++;


                } else {

                    // Hide The Card
                    storeCard.classList.add('hide');

                    let storeNumber = storeCard.dataset.number;

                    // Find Object in Pin Array with Matching Store Number
                    let mapPinObject = window.BATO.mapPins.filter(obj => {
                        return obj.metadata.storeNumber === storeNumber;
                    });

                    // Hide That Pin!
                    mapPinObject[0].setOptions({ visible: false });

                }
            }

            let bounds = Microsoft.Maps.LocationRect.fromLocations(bingLatLng);

            window.BATO.mapBounds = bounds;

            map.setView({
                bounds: bounds
            }); // Reset Map View

            // Update Total Nearby Stores at Top of Store Card List
            let nearbyStores = " " + storeCardsWrapper.dataset.nearbystores;
            storeCardsWrapper.querySelector("h5").innerHTML = storePinNumber-1 + nearbyStores;

            window.BATO.storeLocator.basketTransfer.checked = false;
            window.BATO.storeLocator.basketTransferFilterShowHide();
        }

        const setRadiusValues = () => {
            //When location is changed the storesData variable is updated
            let storesData = window.BATO.Store.getState().stores.data;

            // Iterate over each store and determine distance vs # of results to set dynamic radius.
            // Loop looks at # of results that fall below 5 miles, 25 and 50 miles as benchmarks
            if(storesData.storeNumbers?.length) {
                
                let filterCount = 0,
                    whereToBuyFilter = "",
                    showFilter = true,
                    filterArray = [];

                storesData.storeNumbers.forEach((storeNumber, idx) => {
                    
                    location.id = idx + 1;

                    if(showFilter && (filterCount < 3)){
                        
                        let dealerDistance = Math.ceil(storesData.stores[storeNumber].location.distance);
                        let pushDealer = false;

                        if( (idx == 4) ){
                            if(dealerDistance >= 51) {
                                filterArray.push([dealerDistance, idx]);
                            } else {
                                dealerDistance <= 5 ? filterArray.push([5, idx]) : filterArray.push([dealerDistance, idx]);
                                filterCount++;
                            }
                        }

                        if ( (idx == 24) && dealerDistance >= 24) {
                            pushDealer = true;
                        } else if ( idx > 23 && dealerDistance >= 24 && filterCount == 1) {
                            pushDealer = true;
                        }

                        if ( (idx == 49) && dealerDistance >= 49) {
                            pushDealer = true;
                        } else if (idx > 48 && dealerDistance >= 49 && filterCount == 2) {
                            pushDealer = true;
                        }

                        if ( (idx == storesData.storeNumbers.length-1) && (filterCount < 3) ) {
                            pushDealer = true;
                        }

                        if (pushDealer) {
                            filterArray.push([dealerDistance, idx]);
                            filterCount++;
                        }

                        //console.log("idx: " + idx + " / " + filterCount + ": " + dealerDistance);
                    }

                });

                //console.log("filterArray",filterArray)

                if(showFilter) {

                    // For Analytics - Global Flag To Not Fire Analytics On This Initial
                    // Programmatic Click of Radius Filter
                    window.BATO.storeLocator.radiusSetProgrammatically = true;

                    // Loop over # of Filters Defined by Previous Loop
                    filterArray.forEach((filter, filterX) => {

                        // Rounds our miles to nearest '5'
                        let roundedMiles = Math.ceil(filter[0]/5)*5,
                            expandMiles = false;

                        filter[0] = roundedMiles;

                        if ( (filterX > 0) && (filter[0] == filterArray[filterX-1][0]) ) {
                            if (filter[0] == filterArray[filterX-1][0]) {
                                expandMiles = true;
                            }
                        }

                        // If Filter is Above 30 round to '5's
                        if(filter[0] > 30 ) {
                            roundedMiles = Math.ceil(filter[0]/5)*5;
                            filter[0] = roundedMiles;
                            expandMiles ? filter[0] = filter[0] + 5 : null;
                        } else {
                            expandMiles ? filter[0] = filter[0] + 5 : null;
                        }


                    });

                    // Setup HTML and Event Listeners for Radio Buttons
                    let distanceOverlayUL = distanceOverlay.querySelector("ul");
                    distanceOverlayUL.innerHTML = "";

                    let radiusVal,
                        initialRadiusVal,
                        distanceUnit = distanceOverlay.dataset.units,
                        distanceUnitAbbreviated = 'mi';

                    // Account for different spellings of word Kilometers
                    distanceUnit != 'Miles' ? distanceUnitAbbreviated = 'km' : '';
                    
                    filterArray.forEach((filter, filterX) => {

                        let li = document.createElement("li"),
                            radio = document.createElement("input"),
                            label = document.createElement("label");

                        Object.assign(radio, {
                            type: 'radio',
                            id: 'd'+filter[0],
                            value: filter[0],
                            name: 'distance'
                        });

                        label.setAttribute("for", 'd'+filter[0]);
                        label.setAttribute("tabindex", '0');
                        label.innerText = filter[0] + " " + distanceUnit;

                        radiusVal = parseFloat(filter[0]);

                        const showdealersHandler = (e) => {
                            radiusVal = parseFloat(filter[0]);

                            distanceFilter.getElementsByTagName('b')[0].textContent = radiusVal + distanceUnitAbbreviated;
                                
                            filterCardsByRadius(radiusVal);

                            window.BATO.mapBoundsReset();

                            distanceOverlay.classList.remove('store-filter__filters__distance__select--active');

                        }

                        label.addEventListener('click', (e) => {
                            showdealersHandler();
                        });

                        label.addEventListener('keypress', (e) => {
                            if(e.keyCode==13){
                                showdealersHandler();
                                distanceOverlayUL.querySelector('[for="d' + parseFloat(filter[0]) + '"]').click();
                            };


                        });


                        li.appendChild(radio);
                        li.appendChild(label);

                        distanceOverlayUL.appendChild(li);

                        if(filterX == 0){
                            initialRadiusVal = filter[0];
                        }

                    });

                    window.BATO.storeLocator.radiusReady = true;

                    const setRadius = () => {

                        //console.log("filterArray1",filterArray);
                        //console.log("initialRadiusVal",initialRadiusVal);

                        // Set Initial Text and Value in Dropdown
                        distanceFilter.getElementsByTagName('b')[0].textContent = initialRadiusVal + "mi";
                        distanceOverlayUL.querySelector("#d"+initialRadiusVal).checked = true;
                        distanceOverlayUL.querySelector('[for="d' + initialRadiusVal + '"]').click();

                        window.BATO.storeLocator.radiusSetProgrammatically = false; // For Analytics
                        window.BATO.storeLocator.bingLatLngReady = false; // Reset Bing Ready Flag
                    }

                    // Wait for Bing Map To Be Fully Loaded Before Setting Radius Value
                    (async() => {
                        while(window.BATO.storeLocator.bingLatLngReady == false)
                            await new Promise(resolve => setTimeout(resolve, 200));
                        setRadius();
                    })();

                }
            }
            
        }

        (async() => {
            while(!window.BATO.hasOwnProperty("mapPins"))
                await new Promise(resolve => setTimeout(resolve, 250));
            setRadiusValues();
        })();

        window.BATO.storeLocator.setRadiusValues = setRadiusValues;









        // Sort By
        const sortByFilter = storeFilter.querySelector('.store-filter__filters__sortby__display');
        const sortByFilterB = storeFilter.querySelector('.store-filter__filters__sortby__display b');
        const sortByFilterOverlay = storeFilter.querySelector('.store-filter__filters__sortby__select');
        const sortByFilterOverlayLi = storeFilter.querySelector('.store-filter__filters__sortby__select li');

        sortByFilter.addEventListener('focusin', () => { //focusin triggers when the element is either clicked on or keyboard selected
            sortByFilterOverlay.classList.add('store-filter__filters__sortby__select--active');
            locationOverlay.classList.remove('store-filter__filters__location__select--active');
            distanceOverlay.classList.remove('store-filter__filters__distance__select--active');
        });

        const storeFilters = storeFilter.querySelectorAll('.store-filter__filters__sortby__select li');

        for (const filter of storeFilters) {

            const runFilter = (what) => {

                window.BATO.mapSort(what.className); // Map Function
                window.BATO.cardsSort(what.className); // Cards Function

                let whatString = what.innerHTML;
                let length = 20;
                let trimmedString = whatString.length > length ?
                                    whatString.substring(0, length) + "..." :
                                    whatString;

                sortByFilterB.innerHTML = trimmedString;

                sortByFilterOverlay.classList.remove('store-filter__filters__sortby__select--active');

            }

            filter.addEventListener('click', (e) => {
                runFilter(e.target);
            });

            filter.addEventListener('keypress', (e) => {
                if(e.keyCode==13){
                    runFilter(e.target);
                };

            });

        }

        const updateZip = () => {

            let zip = window.BATO.Store.getState().location.data.zip;
            locationFilter.textContent = zip.split('-')[0];
            sortByFilterB.innerHTML = sortByFilterOverlay.dataset.relevance;

        }

        window.BATO.Store.observeStore(window.BATO.Store, function(state) {
            return state.location.data.zip;
        }, updateZip);






        // Close All On Outside Click

        document.addEventListener('click', () => {

            if ( event.target.closest(".store-filter__filters__sortby") || event.target.closest(".store-filter__filters__location") || event.target.closest(".store-filter__filters__distance")) {

                return;

            }

            sortByFilterOverlay.classList.remove('store-filter__filters__sortby__select--active');
            locationOverlay.classList.remove('store-filter__filters__location__select--active');
            distanceOverlay.classList.remove('store-filter__filters__distance__select--active');

        });
    }
});