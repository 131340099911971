import React, { createContext, Fragment, useEffect, useReducer } from 'react';
import { debounce } from './debounce.js';

const MetaInfoContext = createContext();

function useMetaInfo() {
	const context = React.useContext(MetaInfoContext);
	if (context === undefined) {
		throw new Error('useMetaInfo() must be used within the MetaInfoProvider');
	}
	return context;
}

const MetaWindowSizeUpdater = ({ children }) => {

	const [state, dispatch] = useMetaInfo();

	useEffect(() => {
		function handleResize() {

			const newWindowSize = {
				height: window.innerHeight,
				width: window.innerWidth
			};

			dispatch({ type: 'setWindowSize', state: newWindowSize });

		};

		window.addEventListener('resize', handleResize, 100);
		return () => window.removeEventListener('resize', debounce(handleResize, 100));
	}, []);

	return <>{children}</>
}

const initialState = {
	windowSize: {
		height: window.innerHeight,
		width: window.innerWidth
	}
};

const metaInfoReducer = (state, action) => {
	switch(action.type) {
		case 'setWindowSize': {
			return {
				...state,
				windowSize: {
					height: action.state?.height,
					width: action.state?.width
				}
			}
		}
	}
};

function MetaInfoProvider({children}) {
	const [state, dispatch] = useReducer(metaInfoReducer, initialState);

	const value = [state, dispatch];

	return (
		<MetaInfoContext.Provider value={value}>
			<MetaWindowSizeUpdater>
				{children}
			</MetaWindowSizeUpdater>
		</MetaInfoContext.Provider>
	)
}

export {
	MetaInfoProvider,
	useMetaInfo
}