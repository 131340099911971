import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRatings } from './ratings-context';
import { InlineRating } from '../../../../react-shared/elements/inline-rating/InlineRating.jsx';
import { WriteAReviewButton } from './../../../../tire-reviews/v1/js/ui/atoms/WriteAReviewButton.jsx';
import { useSelector } from "react-redux";

const RatingsApp = (props) => {
	const {
		classes,
		id,
		meta,
		productType,
		reviewLink,
		showRatingDetails,
		showWriteButton,
		tireBrand,
		tireName,
		tireSubBrand
	} = props;

	const [state, dispatch] = useRatings();
	const [ratingInfo, setRatingInfo] = useState({
		rating: 0,
		count: 0
	});

	useEffect(() => {
		const ratings = state.ratings?.data?.summaries || [];
		const thisRating = ratings.find((summary) => { return summary.product_id === id; });
		if (thisRating) {
			setRatingInfo({
				rating: Number(thisRating.average_rating),
				count: parseInt(thisRating.review_count)
			});
		}
	}, [state]);

	const i18n = useSelector( state => state.i18n);

	return (
		<>
			{ratingInfo !== null ?
				(<InlineRating
					averageRating={ratingInfo.rating}
					classes={classes}
					descriptor={productType === 'tire' ? i18n.tire : i18n.store}
					id={id}
					meta={meta}
					productType={productType}
					reviewCount={ratingInfo.count}
					showRatingDetails={showRatingDetails}
					showWriteButton={showWriteButton}
					tireBrand={tireBrand}
					tireName={tireName}
					tireSubBrand={tireSubBrand}
					url={reviewLink}
				/>)
			: <>
				{canReview && (
					<WriteAReviewButton
						id={id}
						inline={true}
						tireBrand={tireBrand}
						tireName={tireName}
						tireSubBrand={tireSubBrand}
						heading={i18n.submitatirereview}
						label={i18n.bethefirsttowriteareview}
					/>
					)}
				</>}
		</>
	);
};

RatingsApp.defaultProps = {
	id: null,
	showWriteButton: false
};

RatingsApp.propTypes = {
	classes: PropTypes.string,
	id: PropTypes.string.isRequired,
	meta: PropTypes.object,
	reviewLink: PropTypes.string,
	showWriteButton: PropTypes.bool,
	tireBrand: PropTypes.string,
	tireName: PropTypes.string,
	tireSubBrand: PropTypes.string
};

export { RatingsApp };