import '../scss/footer.scss';

// setup window object for components to consume
if (!window.BATO) window.BATO = {};

const footerLinksClass = ".footer__links"; 
const footerLinksSimpleListClass = ".simple-list"; 
const footerLinksSimpleListClassModifierClass = "simple-list--selected"; 
const footerLinksHeadingClass = ".list-heading"; 

let footerLinks, footerListHeadings; 

const init = () => {
	footerLinks = document.querySelector(footerLinksClass); 
	footerListHeadings = footerLinks.querySelectorAll(footerLinksHeadingClass); 
	
	if (footerListHeadings) {
		footerListHeadings.forEach(listHeading => {
			listHeading.addEventListener('click', toggleListHeading); 
		}); 
	}
}

const toggleListHeading = (evt) => {
	let parEl = evt.target.closest(footerLinksSimpleListClass);  
	
	if (parEl.classList.contains(footerLinksSimpleListClassModifierClass)) {
		parEl.classList.remove(footerLinksSimpleListClassModifierClass); 
	} else {
		parEl.classList.add(footerLinksSimpleListClassModifierClass); 
	}
}

document.addEventListener("DOMContentLoaded", () => {
	init(); 
})