import React, { createContext, useContext, useReducer } from 'react';
import { getReviewSummariesByIds } from "../../../../services/v1/js/api/reviews.js";
import { isEqual } from 'lodash-es';

const RatingsContext = createContext();

const initialState = {
	ratings: {
		status: 'initial',
		data: {
			summaries: []
		},
		params: {
			id: '',
			groupId: '',
			site: '',
			country: '',
			lang_iso: '',
		}
	}
};

const ratingsReducer = (state, action) => {
	switch(action.type) {
		case 'loadRatingsStart': {
			return {
				...state,
				ratings: {
					status: 'loading'
				}
			}
		}
		case 'loadRatingsSuccess': {
			return {
				...state,
				ratings: {
					status: 'loaded',
					data: action.state.data,
					params: action.state.params
				}
			}
		}
		case 'loadRatingsError': {
			return {
				...state
			}
		 }
		default: {
			throw new Error(`Unhandled action type: ${action.type}`);
		}
	};
};

function RatingsProvider({children}) {
	const [state, dispatch] = useReducer(ratingsReducer, initialState);

	const value = [state, dispatch];

	return <RatingsContext.Provider value={value}>{children}</RatingsContext.Provider>;
};

function useRatings() {
	const context = useContext(RatingsContext);
	if (context === undefined) {
		throw new Error('useRatings() must be used with the RatingsProvider');
	};

	return context;
};

const fetchRatings = async (dispatch, state, { ids = [], country, groupId, lang_iso, site }) => {
	try {
		const stateIds = state.ratings?.params.id?.split(',');

		if (isEqual(stateIds, ids)) return;
		dispatch({ type: 'loadRatingsStart', state });

		if (!ids) {
			throw Error('no IDs provided for ratings call');
		}
		const result = await getReviewSummariesByIds({
			ids,
			country,
			groupId,
			lang_iso,
			site
		});

		if (result.success === "true") {

			const passedState = {
				data: result.data,
				params: result.params
			};
			//console.log("ratings passedState: ", passedState);
			dispatch({ type: 'loadRatingsSuccess', state: passedState });

			return true;

		} else {
			throw Error('there`s been an error in the API call for ratings summaries');
		}
	} catch (err) {
		dispatch({ type: 'loadRatingsError', error: err });
	}
};

export {
	fetchRatings,
	RatingsProvider,
	useRatings
};