import elementListener from '../../../../../js/library/eventHelpers.js';

// setup window object for components to consume
if (!window.BATO) window.BATO = {};

const tireSearchResults = document.querySelector(".tire-search-results");
const sortByContainer = (tireSearchResults != null) ? tireSearchResults.querySelector(".tire-filter-top") : null;
const filterContainer = (tireSearchResults != null) ? tireSearchResults.querySelector(".tire-filter") : null;
const tireCardGrid = (tireSearchResults != null) ? tireSearchResults.querySelector(".tire-card-grid") : null;

const tireDetailsAnalyticsObject = {
	"event": "tire.results.filter",
	"data": {
		"number_of_results": "",
		"search_type": "vehicle",
		"result_type": "vehicle",
		"applied_filter": {},
		"all_filter_criteria": [{
			"tire_size": "",
			"price_min": "",
			"price_max": "",
			"average_rating": "",
			"mileage_warranty": "",
			"brands": "",
			"type": "",
			"best_for": ""
		}],
		"sorting": "",
		"vehicle": [{
			"make": "",
			"model": "",
			"trim": "",
			"year": ""
		}],
		"size": [{
			"width": "",
			"aspect_ratio": "",
			"diameter": ""
		}],
		"products": []
	}
};


export const init = () => {
	if (sortByContainer != null && filterContainer != null) {

		// Unclear why this instance of addBubblerListener is not working, but it's not
		// elementListener.addBubblerListener(filterContainer, 'click', 'label', tireFilterEventHandler);

		setTimeout(function(){
			let labels = filterContainer.querySelectorAll('label');

			labels.forEach(function(label) {
				label.addEventListener('click', tireFilterEventHandler);
			});
		},1000);
	}
}

const tireFilterEventHandler = (evt) => {

	// Check that click is user initiated vs Programmatically On Load
	// isTrusted = Manual Click
	if(evt.isTrusted) {

		const populatedCards = () => {

			let el = evt.target;
			let analyticsObject = JSON.parse(JSON.stringify(tireDetailsAnalyticsObject));

			let tireCards = tireSearchResults.querySelectorAll(".cmp-tire-card-search[data-hide='false']");
			analyticsObject.data.number_of_results = tireCards.length;

			let headingDiv = evt.target.closest('div.tire-filter__box');
			let filterUsedKey = headingDiv.querySelector('.tire-filter__box__by').innerText;
			let filterUsedKeyUS = filterUsedKey.split(' ').join('_');
			let filterUsedVal = el.htmlFor;

			Object.assign(analyticsObject.data.applied_filter, {[filterUsedKeyUS]: filterUsedVal});

			// Tire Size
			let tireSizeWrapper = filterContainer.querySelector(".filter-tire-size ul");
			let allTireSizes = tireSizeWrapper.querySelectorAll("input");

			allTireSizes.forEach(function(size) {

				if(size.checked){
					analyticsObject.data.all_filter_criteria[0].tire_size = size.value;
				}

			});

			// Price Range
			let tirePriceWrapper = filterContainer.querySelector(".filter-price");
			let priceRanges = tirePriceWrapper.querySelectorAll("input");

			analyticsObject.data.all_filter_criteria[0].price_min = priceRanges[0].value;
			analyticsObject.data.all_filter_criteria[0].price_max = priceRanges[1].value;

			// Star Rating
			let starRatingWrapper = filterContainer.querySelector(".filter-rating ul");
			let allStarRatings = starRatingWrapper.querySelectorAll("input");

			allStarRatings.forEach(function(rating) {

				if(rating.checked){
					analyticsObject.data.all_filter_criteria[0].average_rating = rating.value;
				}

			});

			// Mileage Warranty
			let warrantyWrapper = filterContainer.querySelector(".filter-warranty ul");
			let allWarranties = warrantyWrapper.querySelectorAll("input");

			allWarranties.forEach(function(warranty) {

				if(warranty.checked){
					analyticsObject.data.all_filter_criteria[0].mileage_warranty = warranty.value;
				}

			});

			// Tire Brand
			let tireBrandWrapper = filterContainer.querySelector(".filter-brand ul");
			let allBrands = tireBrandWrapper.querySelectorAll("input");
			let brandArray = [];

			allBrands.forEach(function(brand) {

				if(brand.checked){
					brandArray.push(brand.value)
				}

			});

			analyticsObject.data.all_filter_criteria[0].brands = brandArray;

			// Tire Type
			let tireTypeWrappers = filterContainer.querySelectorAll(".filter-type ul");
			let typeArray = [];

			tireTypeWrappers.forEach(function(tireTypeWrapper) {

				let allTypes = tireTypeWrapper.querySelectorAll("input");

				allTypes.forEach(function(type) {

					if(type.checked){
						typeArray.push(type.value)
					}

				});

			});

			analyticsObject.data.all_filter_criteria[0].type = typeArray;

			// Best For
			let bestForWrapper = filterContainer.querySelector(".filter-best-for ul");
			let bestFors = bestForWrapper.querySelectorAll("input");
			let bestForArray = [];

			bestFors.forEach(function(bestFor) {

				if(bestFor.checked){
					bestForArray.push(bestFor.value)
				}

			});

			analyticsObject.data.all_filter_criteria[0].best_for = bestForArray;

			// Sort Order
			let sortOrder = sortByContainer.querySelector('.tire-filter-sort b');
			analyticsObject.data.sorting = sortOrder.innerText;

			// Size
			let sizeObject = window.BATO.Store.getState().tiresBySize;

			if (sizeObject.status == "known") {

				analyticsObject.data.size[0].width = sizeObject.tireBySize.front.cs;
				analyticsObject.data.size[0].aspect_ratio = sizeObject.tireBySize.front.ar;
				analyticsObject.data.size[0].diameter = sizeObject.tireBySize.front.rs;

			}


			// YMMT
			let vehicleObject = window.BATO.Store.getState().vehicles;

			if(vehicleObject.status == "known") {

				analyticsObject.data.vehicle[0].year = vehicleObject.vehicle.year;
				analyticsObject.data.vehicle[0].make = vehicleObject.vehicle.make;
				analyticsObject.data.vehicle[0].model = vehicleObject.vehicle.model;
				analyticsObject.data.vehicle[0].trim = vehicleObject.vehicle.trim;

			}

			let tireCardArray = [],
				productName = "";

			tireCards.forEach((tireCard, index) => {

				productName = tireCard.querySelector("h3");

				tireCardArray.push({

					"product_id": tireCard.dataset.productid,
					"product_name": productName.innerHTML,
					"article_id": tireCard.dataset.articlenumber,
					"product_brand": "bridgestone",
					"list_name": "",
					"results_index": index+1

				});
			});

			analyticsObject.data.products = tireCardArray;

			adobeDataLayer.push(analyticsObject);

		};


		// Wait for Tire Card Grid Fadeout ...
		// Previous method of waiting for className to change on tire-card-grid element was
		// not working consistantly ... animation time for tire-card-grid is 500ms
		setTimeout(()=>{
			populatedCards();
		},1000);

	}

}

export default {
	init
}