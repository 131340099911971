import { getStoresByLatLon } from "../../../services/v1/js/api/stores.js";
import { updateStepValue } from "./tire-decision-guide.js";

// const MAP_KEY = 'Alqx3peKgY_8B05zrSse0rDrgzAF9hoQ7hIDk1r8MVx9BO_4Pnk7n8FfGXXWWIdO';
const MAP_KEY = window.BATO.COMPONENTS.StoreLocator.v1.mapAPIKey;
const mapWrapper = document.querySelector('.map');
const tdgSearchButton = document.querySelector('.location-search-tdg .button');
const locationError = document.querySelector(".use-current--error");

export function getUserLocation() {  
    let lat,
        lon,
        errorText;

    try {
        tdgSearchButton.classList.add('location-loading');
        document.querySelectorAll('.location-search-tdg p.error').forEach((elem) => elem.classList.remove('show-error'))
        navigator.geolocation.getCurrentPosition(async function (position) {
            lat = position.coords.latitude;
            lon = position.coords.longitude;

            let userLocation = [lat, lon];
            
            // If 'Use Current Location'
            if(userLocation) {
                
                let geoCodeRequest = window.location.protocol + '//dev.virtualearth.net/REST/v1/Locations/' + userLocation[0] + ',' + userLocation[1] + '?inclnb=1&key=' + MAP_KEY;

                async function lookUpUserLocation() {
                    let geoCodeResult = await (await fetch(geoCodeRequest)).json();
                    locationError.style.display = "none";
                    if(geoCodeResult.statusCode == 200) {
                        let result = {
                            "address": {
                                "adminDistrict": geoCodeResult.resourceSets[0].resources[0].address.adminDistrict,
                                "locality": geoCodeResult.resourceSets[0].resources[0].address.locality,
                                "countryRegionISO2": geoCodeResult.resourceSets[0].resources[0].address.countryRegion,
                                "postalCode": geoCodeResult.resourceSets[0].resources[0].address.postalCode,
                            },
                            "location": {
                                "latitude": geoCodeResult.resourceSets[0].resources[0].point.coordinates[0],
                                "longitude": geoCodeResult.resourceSets[0].resources[0].point.coordinates[1]
                            }
                        }

                        const inputField = document.querySelector('.location-tdg');
                        inputField.value = geoCodeResult.resourceSets[0].resources[0].address.postalCode;

                        constructLocation(result);

                    } else {
                        errorText = geoCodeResult.statusDescription;
                        throw new Error (errorText);
                    }
                }
                lookUpUserLocation();
            }
        }, function(err) {
            tdgSearchButton.classList.remove('location-loading');
            console.log("Location err; ",err);
            locationError.style.display = "flex";
            return {message: err, success: false};
        });

    } catch (err) {
        tdgSearchButton.classList.remove('location-loading');
        console.log("Location err; ",err);
        locationError.style.display = "flex";
        return {message: err, success: false};
    }
}

// If User Clicks 'Next' on set zip code step of TDG
export const searchUserLocation = (event) => {
    
    let searchBox = document.querySelector('.location-tdg');

    const regex = new RegExp('^[a-zA-Z0-9\s., ]+$');
    const regexNum = new RegExp('^[0-9]+$');
    const postalRegex = new RegExp(window.BATO.COMPONENTS.StoreLocator.v1.postalRegex);

    let locationWrapper = document.querySelector('.location-search-tdg');
    let noResults = locationWrapper.querySelector('.no-results');
    let invalid = locationWrapper.querySelector('.invalid');

    noResults.classList.remove('show-error');
    invalid.classList.remove('show-error');

    let query = searchBox.value;
    let zipCheck = false;
    let queryInt = parseInt(query);

    // Check If Zip Code
    if( postalRegex.test(query) ) {
        zipCheck = true;
    }

    // Test that Search is Alphanumeric, Comma or Period AND Does Not Contain Only Numbers
    // OR
    // Test that Query passes Zip Check
    if( (regex.test(query) && !regexNum.test(queryInt)) || zipCheck) {

        if(!mapWrapper) {

            // Use Bings REST API if no map is present, this data is returned differently
            // than search with a map present, so we must construct the data object to match
            const bingRESTData = (r) => {

                if (r && r.resourceSets[0].resources && r.resourceSets[0].resources.length > 0) {

                    let addressInfo = r.resourceSets[0].resources[0].address;

                    addressInfo.postalCode = "";
                    addressInfo.countryRegionISO2 = addressInfo.countryRegion;

                    let bestMatch = {
                        address: addressInfo,
                        location: {
                            latitude: r.resourceSets[0].resources[0].point.coordinates[0],
                            longitude: r.resourceSets[0].resources[0].point.coordinates[1],
                        }

                    }

                    noResults.classList.remove('show-error');
                    invalid.classList.remove('show-error');
                    constructLocation(bestMatch);

                } else {
                    tdgSearchButton.classList.remove('location-loading');
                    noResults.classList.add('show-error');

                }

            }

            window.bingRESTData = bingRESTData;

            const callBingRestAPI = (request) => {

                let bingScript = document.createElement("script");
                bingScript.setAttribute("type", "text/javascript");
                bingScript.setAttribute("src", request);
                bingScript.setAttribute('async', true);
                bingScript.setAttribute('defer', true);
                document.body.appendChild(bingScript);

            }

            let geoCodeRequest = window.location.protocol + '//dev.virtualearth.net/REST/v1/Locations?query=' + encodeURIComponent(query) + '&includeEntityTypes=PopulatedPlace,Address,Postcode1&jsonp=bingRESTData&key=' + MAP_KEY;

            callBingRestAPI(geoCodeRequest);

        }

    } else {
        invalid.classList.add('show-error');
    }

}

const constructLocation = async (result) => {   

    const truncateDecimals = (number, digits) => {
        let multiplier = Math.pow(10, digits),
            adjustedNum = number * multiplier,
            truncatedNum = Math[adjustedNum < 0 ? 'ceil' : 'floor'](adjustedNum);
        return truncatedNum / multiplier;
    };

    let locationData = {
        status: 'known',
        data: {
            accuracy: 'latlon',
            city: result.address?.locality,
            country: result.address?.countryRegionISO2,
            ip: '',
            lat: truncateDecimals(result.location.latitude, 4),
            lon: truncateDecimals(result.location.longitude, 4),
            region: result.address?.adminDistrict,
            zip: result.address?.postalCode
        }
    }

    let zipCodeRequest = window.location.protocol + '//dev.virtualearth.net/REST/v1/Locations/' + result.location.latitude + ',' + result.location.longitude + '?includeEntityTypes=PopulatedPlace,Address,Postcode1&key=' + MAP_KEY;

    // Reverse Look Up for Zip Code Because Bing is the Worst
    async function reverseLookUp() {
        let zipCodeResult = await (await fetch(zipCodeRequest)).json();

        if(!result.address?.postalCode) {
            locationData.data.zip = zipCodeResult.resourceSets[0].resources[0]?.address?.postalCode;
        }
        
        const inputField = document.querySelector('.location-tdg');
        updateStepValue([inputField.value]);
        setLocation(locationData); //// Set Data in Redux Profile Manager
    }

    if (sessionStorage.getItem('tdg_params') && !sessionStorage.getItem('1')) {

        const tdgStoreLocationByLatLon = async () => {
            let geoCodeRequest = window.location.protocol + '//dev.virtualearth.net/REST/v1/Locations/?countryRegion='+window.BATO.CONFIG.country+'&postalCode='+JSON.parse(sessionStorage.getItem('tdg_params'))[2].values.join("")+'&key=' + MAP_KEY;

            let geoCodeResult = await (await fetch(geoCodeRequest)).json();
            
            if(geoCodeResult.statusCode == 200) {
                sessionStorage.setItem('tdgLocation', JSON.stringify(geoCodeResult.resourceSets[0].resources[0].point.coordinates));

                getStoresByLatLon({
                    lat: geoCodeResult.resourceSets[0].resources[0].point.coordinates[0],
                    lon: geoCodeResult.resourceSets[0].resources[0].point.coordinates[1]
                });
                
            } else {
                console.log(`geoCodeRequest FAIL`)
            }
        }

        tdgStoreLocationByLatLon();

    } else {
        reverseLookUp();
    }
}

// Set Data in Redux Profile Manager
const setLocation = (locationData) => {

    try {
        const {accuracy, city, country, ip, lat, lon, region, zip} = locationData.data;

        updateStores = window.BATO.ProfileActions.setLocationAndStore({
            accuracy,
            city,
            country,
            ip,
            lat,
            lon,
            region,
            zip
        });

    } catch (err) {

        return {message: err, success: false};

    }
}