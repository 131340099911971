import '../scss/language-picker.scss';

const SPACEBAR_KEY_CODE = [0, 32];
const ENTER_KEY_CODE = 13;
const DOWN_ARROW_KEY_CODE = 40;
const UP_ARROW_KEY_CODE = 38;
const ESCAPE_KEY_CODE = 27;

const languagePickers = document.querySelectorAll(".language-picker .language-picker");

languagePickers.forEach(languagePicker => {

	const iconsOnly = languagePicker.dataset.iconsOnly;

	const list = languagePicker.querySelector(".custom-dropdown__list");
	const listContainer = languagePicker.querySelector(
		".custom-dropdown__list-container"
	);
	const dropdownSelectedNode = languagePicker.querySelector(
		".custom-dropdown__selected"
	);

	const dropdownArrow = languagePicker.querySelector(
		".custom-dropdown__arrow"
	);
	const listItems = languagePicker.querySelectorAll(".custom-dropdown__list-item");

	const listItemIds = Array.from(listItems).map(listItem => listItem.dataset.option);

	const activeLanguage = languagePicker.querySelector("[data-active]");

	if (!activeLanguage) {

		const cloned = listItems[0].querySelector('div').cloneNode(true);
		//cloned.role = "button";
		//cloned.tabIndex = 0;
		//cloned.ariaLabel = " ";
		dropdownSelectedNode.innerHTML = '';
		dropdownSelectedNode.appendChild(cloned);

	} else {

		let clonedActiveDiv = listContainer.querySelector(`[data-active]`).querySelector('div');
		let clonedActiveImage = listContainer.querySelector(`[data-active]`).querySelector('div img').cloneNode(true);

		let divTag = document.createElement('div');

		divTag.ariaLabel = clonedActiveDiv.getAttribute('aria-label');

		if(iconsOnly != 'true') {
			divTag.innerText = clonedActiveImage.getAttribute('alt');
		}

		divTag.prepend(clonedActiveImage);

		dropdownSelectedNode.innerHTML = '';
		dropdownSelectedNode.appendChild(divTag);

	}

	dropdownSelectedNode.addEventListener("click", e =>
		toggleListVisibility(e)
	);
	dropdownSelectedNode.addEventListener("keydown", e =>
		toggleListVisibility(e)
	);

	dropdownArrow.addEventListener("click", e =>
		toggleListVisibility(e)
	);
	dropdownArrow.addEventListener("keydown", e =>
		toggleListVisibility(e)
	);

	listItems.forEach(item => {
		item.addEventListener("click", e => {
			const url = e.target.querySelector("a").href;
			window.location.href = url;
		});

		item.addEventListener("keydown", e => {
			switch (e.keyCode) {
				case ENTER_KEY_CODE:
					setSelectedListItem(e);
					closeList();
					return;

				case DOWN_ARROW_KEY_CODE:
					focusNextListItem(DOWN_ARROW_KEY_CODE);
					return;

				case UP_ARROW_KEY_CODE:
					focusNextListItem(UP_ARROW_KEY_CODE);
					return;

				case ESCAPE_KEY_CODE:
					closeList();
					return;

				default:
					return;
			}
		});
	});

	function setSelectedListItem(e) {
		let selectedTextToAppend = document.createTextNode(e.target.innerText);
		dropdownSelectedNode.innerHTML = null;
		dropdownSelectedNode.appendChild(selectedTextToAppend);
	}

	function closeList() {
		list.classList.remove("open");
		dropdownArrow.classList.remove("expanded");
		listContainer.setAttribute("aria-expanded", false);
	}

	function toggleListVisibility(e) {
		let openDropDown =
			SPACEBAR_KEY_CODE.includes(e.keyCode) || e.keyCode === ENTER_KEY_CODE;

		if (e.keyCode === ESCAPE_KEY_CODE) {
			closeList();
		}

		if (e.type === "click" || openDropDown) {
			list.classList.toggle("open");
			dropdownArrow.classList.toggle("expanded");
			listContainer.setAttribute(
				"aria-expanded",
				list.classList.contains("open")
			);
		}

		if (e.keyCode === DOWN_ARROW_KEY_CODE) {
			focusNextListItem(DOWN_ARROW_KEY_CODE);
		}

		if (e.keyCode === UP_ARROW_KEY_CODE) {
			focusNextListItem(UP_ARROW_KEY_CODE);
		}
	}

	function focusNextListItem(direction) {
		const activeElementId = document.activeElement.dataset?.option;
		if (activeElementId === "dropdown__selected") {
			languagePicker.querySelector(`[data-option="${listItemIds[0]}"]`).focus();
		} else {
			const currentActiveElementIndex = listItemIds.indexOf(
				activeElementId
			);
			if (direction === DOWN_ARROW_KEY_CODE) {
				const currentActiveElementIsNotLastItem =
					currentActiveElementIndex < listItemIds.length - 1;
				if (currentActiveElementIsNotLastItem) {
					const nextListItemId = listItemIds[currentActiveElementIndex + 1];
					languagePicker.querySelector(`[data-option="${nextListItemId}"]`).focus();
				}
			} else if (direction === UP_ARROW_KEY_CODE) {
				const currentActiveElementIsNotFirstItem =
					currentActiveElementIndex > 0;
				if (currentActiveElementIsNotFirstItem) {
					const nextListItemId = listItemIds[currentActiveElementIndex - 1];
					document.querySelector(`[data-option=${nextListItemId}]`).focus();
				}
			}
		}
	}
});



