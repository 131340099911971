import elementListener from '../../../../../js/library/eventHelpers.js';

// setup window object for components to consume
if (!window.BATO) window.BATO = {};

const pageContainer = document.querySelector(".page");

const storeDetailsAnalyticsObject = {
	"event": "basket_transfer.prompt",
	"store": {
		"store_id": "",
		"store_name": "",
	},
	"product": {
		"product_id": "",
		"article_id": "",
	}
};

export const init = () => {
	if (pageContainer != null) {
		elementListener.addBubblerListener(pageContainer, 'click', '.store-card .basket-transfer', storeDetailsEventHandler);
	}
}

const storeDetailsEventHandler = (evt) => {

	let el = evt.target;
	let analyticsObject = JSON.parse(JSON.stringify(storeDetailsAnalyticsObject));

	let previouslyViewedTires = window.BATO.Store.getState().flex?.previouslyViewedTires;

	if(previouslyViewedTires.length) {
		analyticsObject.product.product_id = previouslyViewedTires[0].productId;
		analyticsObject.product.article_id = previouslyViewedTires[0].articlenumber;
	}

	let parentDiv = el.closest('div.store-card');

	analyticsObject.store.store_id = parentDiv.dataset.number;
	analyticsObject.store.store_name = parentDiv.dataset.name;

	adobeDataLayer.push(analyticsObject);

}

export default {
	init
}