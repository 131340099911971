import elementListener from '../../../../../js/library/eventHelpers.js';

// setup window object for components to consume
if (!window.BATO) window.BATO = {};

const storeDetailsPage = document.querySelector(".store-details");
const storeDetailsPagePhone = (storeDetailsPage != null) ? storeDetailsPage.querySelector(".store-details-info__phone a") : null;

const storeDetailsAnalyticsObject = {
	"event": "store.telephone_click",
	"store": {
		"store_id": "",
		"store_name": "",
		"store_telephone": "",
		"list_name": "dealer detail",
	}
};

export const init = () => {

	// Store Details Page
	if(storeDetailsPagePhone != null ) {
		storeDetailsPagePhone.addEventListener('click', storeDetailsEventHandler);
	}

}

const storeDetailsEventHandler = (evt) => {

	let el = evt.target;
	let analyticsObject = JSON.parse(JSON.stringify(storeDetailsAnalyticsObject));

	let infoDiv = storeDetailsPage.querySelector('.store-details-info__location');

	analyticsObject.store.store_id = infoDiv.dataset.storeNumber;
	analyticsObject.store.store_name = infoDiv.dataset.storeName;
	analyticsObject.store.store_telephone = el.innerHTML;
	analyticsObject.store.list_name = 'dealer detail';

	adobeDataLayer.push(analyticsObject);

}

export default {
	init
}