import apiUtils from '../util/apiUtils.js';

const getParams = {
	site: window.BATO?.CONFIG?.site || 'bst',
	country: window.BATO?.CONFIG?.country || 'US',
	lang_iso: window.BATO?.CONFIG?.lang_iso || 'en_us'
}

/*
	vehicles/years?site=bst&country=US&lang_iso=en_US
*/
export const getYears = async () => {
	try {
		const response = await fetch(apiUtils.apiPath('vehicles/years', getParams));

	    if (!response.ok) {
	      const errorData = await response.json();
	      const err = `there's a problem with getYears': ${errorData}`;
	      throw new Error(err);
	    }

	    const data = await response.json();

	    if (data) return data;

	} catch (err) {
	    console.log(`there's been an error in the getYears() function: ${err}`);
	    return {
	      success: false,
		}
	}
};

/*
	vehicles/makes-by-year?year={{year}}&site={{site}}&country={{country}}&lang_iso={{lang_iso}}
*/
export const getMakes = async (year) => {
	try {
	    if (!year) {
	      throw new Error('you need to provide a year');
	    };

		const newParams = {
			...getParams,
			...{ year }
		}
	    const response = await fetch(apiUtils.apiPath('vehicles/makes-by-year', newParams));

	    if (!response.ok) {
	      const errorData = await response.json();
	      const error = `there's an error in getMakes(): ${errorData}`;
	      throw new Error(error);
	    }

	    const data = await response.json();

	    if (data) return data;

	} catch (err) {
	    console.log(`there's been an error in the getMakes() function: ${err}`);
	    return {
	      success: false,
	    }
	}
};

/*
	vehicles/models-by-year-make?year={{year}}&make={{make}}&site={{site}}&country={{country}}&lang_iso={{lang_iso}}
*/
export const getModels = async (year, make) => {
	try {
	    if (!year) {
	      throw new Error('you need to provide a year');
	    };

	    if (!make) {
	      throw new Error('you need to provide a make');
	    };

		const newParams = {
			...getParams,
			...{ make, year }
		}

	    const response = await fetch(apiUtils.apiPath('vehicles/models-by-year-make', newParams));

	    if (!response.ok) {
	      const errorData = await response.json();
	      const error = `there's an error in getModels(): ${errorData}`;
	      throw new Error(error);
	    }

	    const data = await response.json();

	    if (data) return data;

	} catch (err) {
	    console.log(`there's been an error in the getModels() function: ${err}`);
	    return {
	      success: false,
	    }
	}
};

/*
	vehicles/trims-by-year-make-model?year={{year}}&make={{make}}&model={{model}}&site={{site}}&country={{country}}&lang_iso={{lang_iso}}
*/

export const getTrims = async (year, make, model) => {
	try {
	    if (!year) {
	      throw new Error('you need to provide a year');
	    };

	    if (!make) {
	      throw new Error('you need to provide a make');
	    };

	    if (!model) {
	      throw new Error('you need to provide a model');
	    };

		const newParams = {
			...getParams,
			...{ year, make, model }
		}

	    const response = await fetch(apiUtils.apiPath('vehicles/trims-by-year-make-model', newParams));

	    if (!response.ok) {
	      const errorData = await response.json();
	      const error = `there's an error in getTrims(): ${errorData}`;
	      throw new Error(error);
	    }

	    const data = await response.json();

	    if (data) return data;

	} catch (err) {
	    console.log(`there's been an error in the getTrims() function: ${err}`);
	    return {
	      success: false,
	    }
	}
};

export default {
	getYears,
	getMakes,
	getModels,
	getTrims
};