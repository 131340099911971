import { merge }  from 'lodash-es';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  status: 'unknown',
  vehicle: {
    year: null,
    make: null,
    model: null,
    trim: null,
    tpms: null,
    splitFitment: false,
    tires: {
      front: {
        cs: "",
        ar: "",
        rs: "",
        tireSize: "",
        brand: "",
        subBrand: "",
        product: "",
        article: "",
        qty: "",
      },
      rear: {
        cs: "",
        ar: "",
        rs: "",
        tireSize: "",
        brand: "",
        subBrand: "",
        product: "",
        article: "",
        qty: "",
      }
    }
  },
  previousVehicles: []
};

export const vehicles = createSlice({
  name: 'vehicles',
  initialState: initialState,
  reducers: {
    clearVehicles: (state) => {
      return initialState;
    },
    setVehicle: (state, action) => {
      let previousVehicles = state.previousVehicles;
      let newPreviousVehicles = [];
      if (state.status === 'known') {
        if (previousVehicles.length > 0) {
          newPreviousVehicles = previousVehicles.filter((itm, idx) => idx < 1);
        }
        newPreviousVehicles.unshift(state.vehicle);
      }
      const { year, make, model, trim, tpms, acesVehicleId } = action.payload;

      return {
        ...state,
        status: 'known',
        vehicle: {
          year,
          make,
          model,
          trim,
          tpms,
          acesVehicleId,
          splitFitment: false,
          tires: {
            front: {
              cs: "",
              ar: "",
              rs: "",
              tireSize: "",
              brand: "",
              subBrand: "",
              product: "",
              article: "",
              qty: "",
            },
            rear: {
              cs: "",
              ar: "",
              rs: "",
              tireSize: "",
              brand: "",
              subBrand: "",
              product: "",
              article: "",
              qty: "",
            }
          }
        },
        previousVehicles: newPreviousVehicles
      }
    },
    setPreviousVehicles: (state, action) => {

    },
    setTiresOnVehicle: (state, action) => {
      // console.log("setTiresOnVehicle() action.payload: ", action.payload);
      const rear = action.payload.rear;

      return merge(
        state,
        {
          vehicle: {
            ...(rear && { splitFitment: true }),
            tires: {
              front: action.payload.front,
              ...(rear ? { rear: rear } : {
                rear: {
                  cs: "",
                  ar: "",
                  rs: "",
                  tireSize: "",
                  brand: "",
                  subBrand: "",
                  product: "",
                  article: "",
                  qty: "",
                }})
            }
          }
        }
      )
    }
  }
});

const actions = vehicles.actions;

export { actions };

if (!window.BATO) {
  window.BATO = {}
}

if (!window.BATO.StoreActions) {
  window.BATO.StoreActions = {}
}

window.BATO.StoreActions.vehicles = actions;

export default vehicles.reducer;