import apiUtils from '../util/apiUtils.js';

const getParams = {
	site: window.BATO?.CONFIG?.site || 'bst',
	country: window.BATO?.CONFIG?.country || 'US',
	lang_iso: window.BATO?.CONFIG?.lang_iso || 'en_US'
}

/*
	/brgtrestwebservices/tire-search/tires-by-ymm-trim?site={{site}}&country={{country}}&lang_iso={{lang_iso}}&ymm={{ymm}}&trim={{trim}}
*/
export const getTiresByYMMT = async (fitment) => {
	let vehicleFitment = fitment || window.BATO.Store.getState().vehicles.vehicle;

	try {
	    if (!vehicleFitment.year) {
	      throw new Error('you need to provide a year');
	    };

	    if (!vehicleFitment.make) {
	      throw new Error('you need to provide a make');
	    };

	    if (!vehicleFitment.model) {
	      throw new Error('you need to provide a model');
	    };

	    if (!vehicleFitment.trim) {
	      throw new Error('you need to provide a trim');
	    };

	    const response = await fetch(apiUtils.apiPath('tire-search/tires-by-ymm-trim', { ...getParams, ...{ymm:`${vehicleFitment.year}-${vehicleFitment.make}-${vehicleFitment.model}`}, ...{trim:vehicleFitment.trim }}));

	    if (!response.ok) {
	      const errorData = await response.json();
	      const error = `there's an error in getTiresByYMMT(): ${errorData}`;
	      throw new Error(error);
	    }

	    const data = await response.json();

	    if (data) return data;

	} catch (err) {
	    console.log(`there's been an error in the getTiresByYMMT() function: ${err}`);
	    return {
	      success: false,
	    }
	}
};


/*
	/brgtrestwebservices/tire-search/tires-by-width-ratio-diameter?site={{site}}&country={{country}}&lang_iso={{lang_iso}}&width={{width}}&ratio={{ratio}}&diameter={{diameter}}
*/
export const getTiresByTireSize = async (sizing) => {
	let size = sizing || window.BATO.Store.getState().tiresBySize.tireBySize.front;

	let tireSize = {
		width: size.cs,
		ratio: size.ar,
		diameter: size.rs
	}

	try {
	    if (!tireSize.width) {
	      throw new Error('you need to provide a width');
	    };

	    if (!tireSize.ratio) {
	      throw new Error('you need to provide a ratio');
	    };

	    if (!tireSize.diameter) {
	      throw new Error('you need to provide a diameter');
	    };

	    const response = await fetch(apiUtils.apiPath('tire-search/tires-by-width-ratio-diameter', { ...getParams, ...tireSize }));

	    if (!response.ok) {
	      const errorData = await response.json();
	      const error = `there's an error in getTiresByTireSize(): ${errorData}`;
	      throw new Error(error);
	    }

	    const data = await response.json();

	    if (data) return data;

	} catch (err) {
	    console.log(`there's been an error in the getTiresByTireSize() function: ${err}`);
	    return {
	      success: false,
	    }
	}
};

export default {
  getTiresByYMMT,
  getTiresByTireSize,
}