import { createSlice } from '@reduxjs/toolkit';

export const i18n = createSlice({
    name: 'i18n',
    initialState: false,
    reducers: {
        seti18n: (state=false,action) => {
          return action.payload
        }
    }
});

const actions = i18n.actions;

export { actions };

if (!window.BATO) {
  window.BATO = {}
}

if (!window.BATO.StoreActions) {
  window.BATO.StoreActions = {}
}

window.BATO.StoreActions.i18n = actions;

export default i18n.reducer;