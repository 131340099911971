import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from "react-redux";

const ReviewSubmitted = (props) => {
	const {
		closeModal
	} = props;

	const i18n = useSelector( state => state.i18n);

	return (
		<div className="review-confirmation">
			<h2>{i18n.yourreviewhasbeensubmittedforapproval}</h2>
			<p>{i18n.pleasenotethatyourreviewmaytakeupto72hourstoappear}</p>
			<button className="button" onClick={closeModal}>{i18n.continuebrowsing}</button>
		</div>
	);
};

ReviewSubmitted.propTypes = {
	closeModal: PropTypes.func.isRequired
};

export { ReviewSubmitted };