import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

const DynamicPortal = ({
    id,
    children,
    className,
    dynamicParentElement,
    hideParent
}) => {
    const el = useRef(
        document.getElementById(id) || document.createElement('div')
    );
    const [dynamic] = useState(!el.current.parentElement);

    useEffect(() => {
        if (dynamic) {
            el.current.id = id;
            dynamicParentElement &&
                dynamicParentElement.insertAdjacentElement(
                    'afterend',
                    el.current
                );

            if (hideParent) {
                dynamicParentElement.style.display = 'none';
            }

            if (className) {
                el.current.classList.add(className);
            }
        }
        return () => {
            if (dynamic && el.current.parentElement) {
                el.current.parentElement.removeChild(el.current);
            }
        };
    }, [id]);

    return createPortal(children, el.current);
};

export { DynamicPortal };