/**
 *
 * UNCOMMENT DURING DEV
 *
 *
 */
// Must be the first import
import "preact/debug";

// Or if you just want the devtools bridge (~240B) without other
// debug code (useful for production sites)
import "preact/devtools";
import React, { Fragment, useState, useEffect } from 'react';
import { Modal } from '../../../react-shared/elements/modal/v1/Modal.jsx';
import { MetaInfoProvider } from "../../../react-shared/utils/meta-info-context.js";
import { setModalHandler } from '../../../react-shared/elements/modal/v1/setModalHandler.js';
import { ModalContext } from '../../../react-shared/elements/modal/v1/ModalContext.js';
import { HeaderLocation } from './../../../header-location-app/v1/js/header-location-app.js';
import { HeaderVehicle } from './../../../header-vehicle-app/v1/js/header-vehicle-app.js';
import { FullReviewApp } from './../../../tire-reviews/v1/js/tire-reviews.js';
import { RatingsApp } from './../../../tire-ratings/v1/js/tire-ratings.js';

const RootReactApp = (props) => {
  const [modal, setModal] = useState({
    isOpen: false,
    content: null,
    closeModalFunction: null
  });

  const { config } = props;

  const [ appConfig, setAppConfig ] = useState(null);
  const [ HeaderLocationComponent, setHeaderLocationComponent ] = useState(null);
  const [ HeaderVehicleComponent, setHeaderVehicleComponent ] = useState(null);
  const [ ReviewsComponent, setReviewsComponent ] = useState(null);
  const [ RatingsComponent, setRatingsComponent ] = useState(null);

  useEffect(() => {
    if (config) {
      setAppConfig(config);
    };
  } , []);

  useEffect(() => {
    if (appConfig) {
      const langIso = appConfig.lang_iso;
  
      window.BATO.ProfileActions.setI18n(langIso);

      if (appConfig.REACT?.headerLocation?.find((lang) => lang === langIso)) {
        setHeaderLocationComponent(<HeaderLocation />);
      };
      if (appConfig.REACT?.headerVehicle?.find((lang) => lang === langIso)) {
        setHeaderVehicleComponent(<HeaderVehicle />);
      };
      if (appConfig.REACT?.ratingsAndReviews?.readReviews?.find((lang) => lang === langIso)) {
        setReviewsComponent(<FullReviewApp config={config} />);
      }
      if (appConfig.REACT?.ratingsAndReviews?.showRatings.find((lang) => lang === langIso)) {
        setRatingsComponent(<RatingsApp config={config} />);
      }
    }
  }, [appConfig]);

  const modalAppElement = document.getElementsByClassName('root')[0];

  useEffect(() => {
        const existingModalDiv = document.getElementsByClassName('rm-modal');
        if (!existingModalDiv) {
            const modalDiv = document.createElement('div');
            modalDiv.classList.add('rm-modal');
            modalAppElement.parentElement.appendChild(modalDiv);
        }
    }, []);

  const handleSetModal = setModalHandler(setModal);

  return (
    <MetaInfoProvider>
      <ModalContext.Provider value={{ setGlobalModal: handleSetModal }}>
        {HeaderLocationComponent && <>{HeaderLocationComponent}</>}
        {HeaderVehicleComponent && <>{HeaderVehicleComponent}</>}
        {RatingsComponent && <>{RatingsComponent}</>}
        {ReviewsComponent && <>{ReviewsComponent}</>}
      </ModalContext.Provider>
      <Modal
        appElement={modalAppElement}
        closeModal={modal.closeModalFunction}
        isModalOpen={modal.isOpen}
        isRoute={false}
        modalContent={modal.content}
      />
    </MetaInfoProvider>
  );
};

export { RootReactApp };