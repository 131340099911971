import {headerHTML} from './tire-card-comparison-header-markup.js'
import {cardHTML} from './tire-card-comparison-markup.js'
import stubData from './tire-card-stub-data.js'
import elementListener from '../../../../../../js/library/eventHelpers.js';

document.addEventListener("DOMContentLoaded", function() {
    let tdgExists = document.querySelector('.tire-decision-guide') !== null;

    if (!window.BATO) window.BATO = {};
    if (!window.BATO.tireComparison) window.BATO.tireComparison = {};

    const theBody = document.querySelector('body');
    const tcWrapper = document.querySelector(".tire-comparison");
    const tc = document.querySelector(".cmp-tire-comparison");

    const tct = document.querySelector('.tire-comparison-tray');
    const tctActive = 'tire-comparison-tray--active';
    const tireFilter = document.querySelector('.tire-filter');

    const i18nComparisonHeader = {
        comparisonHeaderTitle: tc.dataset.comparisonHeaderTitle,
        comparisonHeaderBackToResults: tc.dataset.comparisonHeaderBackToResults,
        comparisonHeaderHighlightDifferences: tc.dataset.comparisonHeaderHighlightDifferences,
        comparisonHeaderComparing: tc.dataset.comparisonHeaderComparing,
        comparisonHeaderTires: tc.dataset.comparisonHeaderTires
    }

    const i18nTireCard = {
        tireCardOverview: tc.dataset.tireCardOverview,
        tireCardMsrpPrice: tc.dataset.tireCardMsrpPrice,
        tireCardLimitedMileageWarranty: tc.dataset.tireCardLimitedMileageWarranty,
        tireCardTireType: tc.dataset.tireCardTireType,
        tireCardPerformanceRatings: tc.dataset.tireCardPerformanceRatings,
        tireCardSpecifications: tc.dataset.tireCardSpecifications, 
        tireCardSeeDetails: tc.dataset.tireCardSeeDetails,
        tireCardLocateTire: tc.dataset.tireCardLocateTire,
        tirePerformanceRatingDryPerformance: tc.dataset.tirePerformanceRatingDryPerformance,
        tirePerformanceRatingFuelEfficiency: tc.dataset.tirePerformanceRatingFuelEfficiency,
        tirePerformanceRatingIcePerformance: tc.dataset.tirePerformanceRatingIcePerformance,
        tirePerformanceRatingIceSnowWinterAverage: tc.dataset.tirePerformanceRatingIceSnowWinterAverage,
        tirePerformanceRatingOffRoadPerformance: tc.dataset.tirePerformanceRatingOffRoadPerformance,
        tirePerformanceRatingQuietRide: tc.dataset.tirePerformanceRatingQuietRide,
        tirePerformanceRatingRideComfort: tc.dataset.tirePerformanceRatingRideComfort,
        tirePerformanceRatingSnowPerformance: tc.dataset.tirePerformanceRatingSnowPerformance,
        tirePerformanceRatingTreadLife: tc.dataset.tirePerformanceRatingTreadLife,
        tirePerformanceRatingWetPerformance: tc.dataset.tirePerformanceRatingWetPerformance,
        tirePerformanceRatingWinterPerformance: tc.dataset.tirePerformanceRatingWinterPerformance,
        tirePerformanceRatingSnowIcePerformance: tc.dataset.tirePerformanceRatingSnowIcePerformance,
        tireDetailSpeedRating: tc.dataset.tireDetailSpeedRating,
        tireDetailTreadDepth: tc.dataset.tireDetailTreadDepth,
        tireDetailUtqgRating: tc.dataset.tireDetailUtqgRating,
        tireDetailTireWeight: tc.dataset.tireDetailTireWeight
    }

    const loadTireComparisonCards = (addToComparesChecked, reRender) => {
        /*
        let returnData = stubData();

        window.BATO.tireComparison = returnData;

        let dataStringed = JSON.stringify(returnData);
        let dataParsed = JSON.parse(dataStringed);
        */


        if(!reRender) {
            tcWrapper.classList.add('tire-comparison--active');
            theBody.classList.add('no-scroll');
            tct.classList.remove(tctActive);
        }

        let dataSet,
            parentNode,
            dataID,
            name,
            image,
            detailsLink,
            utqg = "",
            comparisonCards = "";

        comparisonCards += headerHTML(addToComparesChecked.length,i18nComparisonHeader);

        addToComparesChecked.forEach(function(addToCompare) {
            dataSet = addToCompare.parentNode.parentNode.dataset;
            parentNode = addToCompare.parentNode.parentNode;

            dataID = parentNode.querySelector("input").id;
            name = parentNode.querySelector("h3").innerText;
            image = parentNode.querySelector("img").src;
            // detailsLink = parentNode.querySelector("a").href;

                    // Default to the regular details link
            let detailsLink = parentNode.querySelector("a").href;

            // If tdgExists, construct the tireUrl
            if (tdgExists) {
                // const subBrand = dataSet.subbrand?.toLowerCase().replace(/\s+/g, '-').replace(/\//g, '');
                const tireSize = dataSet.size?.toLowerCase().replace(/\s+/g, '-');
                detailsLink = `/tire/${name.toLowerCase().replace(/\s+/g, '-')}/${tireSize}/`.replace(/\/\//g, '/');


                if(window.BATO.CONFIG.lang_iso === 'es_US') {
                    detailsLink = `/es${detailsLink}`;
                }
            }


            dataSet.utqgrating && dataSet.utqgtraction ? utqg = dataSet.utqgrating + dataSet.utqgtraction : '';

            let tireType = tireFilter?.dataset?.useMarketingCategories == 'true' ? dataSet['productMarketingCategory-1'] : dataSet.type;

            if (utqg.includes('NOT REQUIRED BY FEDERAL LAW')) {
                utqg = "N/A";
            }

            comparisonCards += cardHTML(
                dataID,
                image,
                name,
                dataSet.price,
                dataSet.warranty,
                tireType, //dataSet.type
                dataSet.rtb1,
                dataSet.rtb2,
                dataSet.rtb3,
                dataSet.rtb4,
                dataSet.dryperformance,
                dataSet.fuelefficiency,
                dataSet.iceperformance,
                dataSet.icesnowwinteraverage,
                dataSet.offroadperformance,
                dataSet.quietride,
                dataSet.ridecomfort,
                dataSet.snowperformance,
                dataSet.wetperformance,
                dataSet.winterperformance,
                dataSet.speedrating,
                dataSet.treaddepth,
                utqg,
                dataSet.tireweight,
                detailsLink,
                dataSet.articlenumber,
                dataSet.sizebaseurl,
                dataSet.size,
                dataSet.width,
                dataSet.ratio,
                dataSet.diameter,
                dataSet.brand,
                dataSet.subbrand,
                dataSet.productid,
                dataSet.rating,
                dataSet.reviewCount,
                i18nTireCard

            );

        });

        if(addToComparesChecked.length == 2) {
            comparisonCards += `<div class="cmp-tire-comparison__add"><a href="#">${tc.dataset.tireCardAddAnotherTireToCompare}</a></div>`;
        }

        if (tc){

            tc.innerHTML = comparisonCards;

            // Timeout Only For Animation Purposes
            setTimeout(()=>{
                window.BATO.starRender('.tire-comparison');
            },500);

        }

        //
        // Add EventListeners for Comparison Cards Overlay //
        //


        // Close Overlay from "Back to Tire Result"
        let backToTireResultsButton,
            addAnotherTireButton;

        tc ? backToTireResultsButton = tc.querySelector(".tire-comparison__header a") : '';

        const closeComparisonOverlay = () => {
            tcWrapper.classList.remove('tire-comparison--active');
            theBody.classList.remove('no-scroll');
            window.BATO.tireComparison.openCompareModal(tc);
        }

        backToTireResultsButton.addEventListener('click', closeComparisonOverlay);
        elementListener.addBubblerListener(tc, 'click', '.cmp-tire-comparison__add', closeComparisonOverlay);



        // X-out Comparison Card
        let closeButtons;

        tc ? closeButtons = tc.querySelectorAll(".remove-comparison-card") : '';

        if(closeButtons){

            closeButtons.forEach(function(btn) {

                btn.addEventListener('click', (e) => {

                    e.stopPropagation();

                    let parentEl = e.target.parentNode.parentNode,
                        connectedLabel = document.querySelectorAll("label[for='" + parentEl.dataset.id + "']"),
                        connectedCheckbox = connectedLabel[0].control;

                    connectedCheckbox.checked = false;

                    let connectedRecommended = document.querySelector(".cmp-tire-card-recommended[data-articlenumber='" + parentEl.dataset.articlenumber + "']");

                    if(connectedRecommended) {

                        let recommendedCheckbox = connectedRecommended.querySelector("input[type='checkbox']");
                        recommendedCheckbox.checked = false;

                    }


                    // Ignore Matched Recommended Cards for Our Count
                    let addToComparesChecked = document.querySelectorAll('.cmp-tire-card__checkbox input:checked:not([id^="match"])'); //.cmp-tire-card-search__checkbox

                    if(addToComparesChecked.length == 2) {

                        loadTireComparisonCards(addToComparesChecked, true);

                    } else {

                        tcWrapper.classList.remove('tire-comparison--active');
                        theBody.classList.remove('no-scroll');
                        window.BATO.tireComparison.openCompareModal(tc);

                    }

                });

            });

        }

        // Toggle Expand Buttons -> (^) for Comparison Cards
        let toggleTableBtns;

        tc ? toggleTableBtns = tc.querySelectorAll(".expand") : '';

        const toggleTable = (e) => {
            let thisTable = e.target.nextElementSibling;
            thisTable.classList.toggle('active');
            thisTable.classList.contains('active') ? e.target.classList.add('expanded') : e.target.classList.remove('expanded');
        }

        if(toggleTableBtns){
            toggleTableBtns.forEach(function(btn) {
                btn.addEventListener('click', toggleTable);
            });
        }
    }

    window.BATO.tireComparison.loadTireComparisonCards = loadTireComparisonCards;

});