/**

    A Mutation Observer that Waits for a specified element to appear

    Implementation ->
    waitForElement('.selector').then((elm) => {
        stuff that happens after element appears
    });

    @param {HTMLElement} selector - element to observe for change
    @param {HTMLElement} elm - the new element if you need to target it

**/

const waitForElement = (selector) => {
    return new Promise(resolve => {
        if (document.querySelector(selector)) {
            return resolve(document.querySelector(selector));
        }

        const observer = new MutationObserver(mutations => {
            if (document.querySelector(selector)) {
                resolve(document.querySelector(selector));
                observer.disconnect();
            }
        });

        observer.observe(document.body, {
            childList: true,
            subtree: true
        });
    });
};

export { waitForElement };
