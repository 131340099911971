import React, { Fragment, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { RatingInputGroup } from '../../../../../react-shared/elements/rating-input/v1/RatingInputGroup.jsx';
import {submitReview} from '../../../../../services/v1/js/api/reviews';
import { submitReviewAnalytics } from '../../analytics.js';
import prDataBuilder from './utils/bato-review-write-schema';
import { useSelector } from "react-redux";

const handleSubmitReview = async (formData) => {
  try {
    let pros = [];
    /* Object.keys(formData).map(key => {
      if (key.startsWith('pa_')) {
        if (formData[key] !== false) {
          pros.push({
            // the display text, not a key
            value: formData[key]
          })
        }
      }
    }); */
    if (formData.pa?.length) {
      formData.pa.forEach((s) => {
        pros.push({ value: s });
      })
    }
    let formPackage = formData;
    formPackage.pros = pros;

    let builtPackage = prDataBuilder(formPackage);

    const submittedReturn = await submitReview(builtPackage, formData.pageId);

    return submittedReturn;

  } catch (err) {
    throw Error(err);
  }
}

const InputErrorMessage = ({ message = '' }) => {
  return <p className='input-error error'>{message}&nbsp;</p>
}

const WriteAReview = (props) => {

  const i18n = useSelector( state => state.i18n);

  const preferredAttributesArray = [
    { valueKey: 'pa_drivingComfort', displayText: i18n.drivingcomfort },
    { valueKey: 'pa_fuel', displayText: i18n.savingmoneyonfuel },
    { valueKey: 'pa_tireLife', displayText: i18n.longertirelife },
    { valueKey: 'pa_quietride', displayText: i18n.aquietride },
    { valueKey: 'pa_offroad', displayText: i18n.offroadcapabilities },
    { valueKey: 'pa_ice', displayText: i18n.icedrivingcapabilities },
    { valueKey: 'pa_dry', displayText: i18n.performanceindryconditions },
    { valueKey: 'pa_winter', displayText: i18n.winterdrivingcapabilities },
    { valueKey: 'pa_snow', displayText: i18n.snowdrivingcapabilities },
  ];

  const {
    pageId,
    tireBrand,
    tireName,
    tireSubBrand,
    heading,
    showSuccessConfirmation
  } = props;

  const {
    register,
    handleSubmit,
    formState: {
      errors,
      isValid
    },
    setValue,
    watch
  } = useForm({
    mode: 'all'
  });

  const [checkedCount, setCheckedCount] = useState(0);

  let paWatch = watch('pa', {
    pa_drivingComfort: false,
    pa_fuel: false,
    pa_tireLife: false,
    pa_quietride: false,
    pa_offroad: false,
    pa_ice: false,
    pa_dry: false,
    pa_winter: false,
    pa_snow: false,
  });

  useEffect(() => {
    const numCheckedCount = [];
    const paKeys = Object.keys(paWatch);
    paKeys.forEach((key, i) => {
      if (!!paWatch[key]) {
        numCheckedCount.push(key);
      }
    });

    setCheckedCount(numCheckedCount.length);
  }, [paWatch]);

  const formatName = (fullName) => {
    // This function takes a full name as input, splits it into parts, extracts the first name and the initial
    // of the second name (if it exists), and returns the formatted name as "FirstName L".

    const nameParts = fullName.trim().split(' ');
    const firstName = nameParts[0];
    const initial = nameParts[1] ? nameParts[1][0] : '';
    return `${firstName} ${initial}`;
}

  const onSubmit = async (data) => {
    try {
      if(window.BATO?.CONFIG?.REVIEWS?.enablePIIFilter == 'true') {
        data.name = formatName(data.name);
      }
      let formData = {
        ...data,
        ...{ pageId: pageId }
      };
      

      const result = await handleSubmitReview(formData)

      if (result) {
        submitReviewAnalytics(formData.rating);
        showSuccessConfirmation();
      }

    } catch (err) {
      console.warn("handleSubmitReview has been rejected: ", err)
    }
  };

  const handleYearKeyPress = (e) => {
    if (e.target.value.length > 3) {
      e.preventDefault();
      return false;
    }
  }

  const handleMilesKeyPress = (e) => {
    if (e.target.value.length > 5) {
      e.preventDefault();
      return false;
    }
  }

  let tireDisplayName = tireSubBrand + ' ' + tireName;
  tireSubBrand.toUpperCase() == tireName.toUpperCase() ? tireDisplayName = tireSubBrand : '';

  return (
    <>
      <div class="rm-content__heading">
        <h2 class="h1">
            {i18n.writeareview}
          <div class="h2">{i18n.modelUppper} {tireDisplayName}</div>
        </h2>
      </div>

      <div className="rm-content__guidelines">
        <h3>{i18n.reviewguidelines}</h3>
        <p>{i18n.wewantyourvoicetobeheard}</p>
        <ul>
            <li>{i18n.focusonspecificattributes}</li>
            <li>{i18n.refrainfromobjectionable}</li>
            <li>{i18n.donotincludeandidentifying}</li>
        </ul>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>

        <div className="rm-content__vehicle">

          <h3>{i18n.vehicleinformation} </h3>

          <div className="rm-content__vehicle-year">
              <input type="number" placeholder={i18n.year} min="1983" max="2024" onKeyPress={handleYearKeyPress} {...register("caryear", { max: 2024, min: 1983 })} />
              {!errors.caryear && <InputErrorMessage />}
              {errors.caryear?.type === 'min' && <InputErrorMessage message={i18n.pleaseenterayear1983orlater} />}
              {errors.caryear?.type === 'max' && <InputErrorMessage message={i18n.pleaseenteravalidmodelyear} />}
          </div>

          <div className="rm-content__vehicle-make">
              <input type="text" maxLength="40" placeholder={i18n.make} {...register("carmake")} />
          </div>

          <div className="rm-content__vehicle-model">
              <input type="text" maxLength="50" placeholder={i18n.modelLower} {...register("carmodel")} />
          </div>

          <div className="rm-content__vehicle-mileage">
              <input type="number" max="150000" min="1" onKeyPress={handleMilesKeyPress} placeholder={i18n.mileagedrivenontires} {...register("milesdriveontires", { max: 150000, min: 1 })} />
              {!errors.milesdriveontires && <InputErrorMessage />}
              {errors.milesdriveontires?.type === 'min' && <InputErrorMessage message={i18n.pleaseentermiles} />}
              {errors.milesdriveontires?.type === 'max' && <InputErrorMessage message={i18n.seemstobealotofmiles} />}
          </div>

        </div>

        <div className="rm-content__attributes">
          <h3>{i18n.preferredattributes}</h3>
          <ul>
            {preferredAttributesArray.map((pa, i) => (
              <li key={pa.valueKey+i}>
                  <input
                    type="checkbox"
                    id={pa.valueKey}
                    defaultValue={false}
                    value={pa.displayText}
                    disabled={(!document.querySelector(`#${pa.valueKey}`)?.checked) && checkedCount >=3}
                    {...register(`pa`)}
                  />
                  <label className="checkbox" htmlFor={pa.valueKey}>
                    {pa.displayText}
                  </label>
              </li>
            ))}
          </ul>
        </div>

        <div className="rm-content__rating">
          <h3>{i18n.ratingsclick}</h3>
          <p>{i18n.required}</p>
          <RatingInputGroup
            className="write-a-review-input-container"
            ratingName="rating"
            ratingFriendlyName={i18n.overallrating}
            isRequired={true}
            register={register}
            setValue={setValue}
            showLegend={false}
            showRatingTitle={true}
          />
          <RatingInputGroup
            className="write-a-review-input-container"
            ratingName="traction"
            ratingFriendlyName={i18n.traction}
            isRequired={true}
            register={register}
            setValue={setValue}
            showLegend={false}
            showRatingTitle={true}
          />
          <RatingInputGroup
            className="write-a-review-input-container"
            ratingName="durability"
            ratingFriendlyName={i18n.durability}
            isRequired={true}
            register={register}
            setValue={setValue}
            showLegend={false}
            showRatingTitle={true}
          />
          <RatingInputGroup
            className="write-a-review-input-container"
            ratingName="comfort"
            ratingFriendlyName={i18n.comfort}
            isRequired={true}
            register={register}
            setValue={setValue}
            showLegend={false}
            showRatingTitle={true}
          />
          <RatingInputGroup
            className="write-a-review-input-container"
            ratingName="value"
            ratingFriendlyName={i18n.value}
            isRequired={true}
            register={register}
            setValue={setValue}
            showLegend={false}
            showRatingTitle={true}
          />
        </div>

        <div className="rm-content__review">
          <h3>{i18n.review} </h3>

          <div className="rm-content__review-title">
            <input type="text" maxLength="61" placeholder={i18n.reviewtitle} {...register("headline", { required: true, maxLength: 60})} />
            {!errors.headline && <InputErrorMessage />}
            {errors.headline?.type === 'required' && <InputErrorMessage message={i18n.reviewtitleisrequired} />}
            {errors.headline?.type === 'maxLength' && <InputErrorMessage message={i18n.reviewtitleneedstobe60charactersorless} />}
          </div>

          <div className="rm-content__review-content">
            <textarea placeholder={i18n.enteryourreview} maxLength="5000" {...register("comments", { required: true, })}></textarea>
            {!errors.comments && <InputErrorMessage />}
            {errors.comments?.type === 'required' && <InputErrorMessage message={i18n.youmustenterareview} />}
          </div>
        </div>

        <div className="rm-content__info">
          <h3>{i18n.yourinformation}</h3>

          <div className="rm-content__info-firstname">
            <input type="text" maxLength="50" placeholder={i18n.firstname} {...register("name", { required: true, })} />
            {!errors.name && <InputErrorMessage />}
            {errors.name?.type === 'required' && <InputErrorMessage message={i18n.enteryourfirstname} />}
          </div>

          <div className="rm-content__info-city">
            <input type="text" maxLength="255" placeholder={i18n.city} {...register("location", { required: true, })} />
            {!errors.location && <InputErrorMessage />}
            {errors.location?.type === 'required' && <InputErrorMessage message={i18n.enteryourcityortownname} />}
          </div>
          
          {
            window.BATO?.CONFIG?.lang_iso === 'en_US' ? (
              <div className="rm-content__info-state">
              <select {...register("state", { required: true })}>
                  <option value="" selected="selected" disabled>{i18n.state}</option>
                  <option value="AL">AL</option>
                  <option value="AK">AK</option>
                  <option value="AR">AR</option>
                  <option value="AZ">AZ</option>
                  <option value="CA">CA</option>
                  <option value="CO">CO</option>
                  <option value="CT">CT</option>
                  <option value="DC">DC</option>
                  <option value="DE">DE</option>
                  <option value="FL">FL</option>
                  <option value="GA">GA</option>
                  <option value="HI">HI</option>
                  <option value="IA">IA</option>
                  <option value="ID">ID</option>
                  <option value="IL">IL</option>
                  <option value="IN">IN</option>
                  <option value="KS">KS</option>
                  <option value="KY">KY </option>
                  <option value="LA">LA</option>
                  <option value="MA">MA</option>
                  <option value="MD">MD</option>
                  <option value="ME">ME</option>
                  <option value="MI">MI</option>
                  <option value="MN">MN</option>
                  <option value="MO">MO</option>
                  <option value="MS">MS</option>
                  <option value="MT">MT</option>
                  <option value="NC">NC</option>
                  <option value="ND">ND</option>
                  <option value="NE">NE</option>
                  <option value="NH">NH</option>
                  <option value="NJ">NJ</option>
                  <option value="NM">NM</option>
                  <option value="NV">NV</option>
                  <option value="NY">NY</option>
                  <option value="OH">OH</option>
                  <option value="OK">OK</option>
                  <option value="OR">OR</option>
                  <option value="PA">PA</option>
                  <option value="PR">PR</option>
                  <option value="RI">RI</option>
                  <option value="SC">SC</option>
                  <option value="SD">SD</option>
                  <option value="TN">TN</option>
                  <option value="TX">TX</option>
                  <option value="UT">UT</option>
                  <option value="VA">VA</option>
                  <option value="VT">VT</option>
                  <option value="WA">WA</option>
                  <option value="WI">WI</option>
                  <option value="WV">WV</option>
                  <option value="WY">WY</option>
              </select>
              {!errors.state && <InputErrorMessage />}
              {errors.state?.type === 'required' && <InputErrorMessage message={i18n.pleaseselectastate} />}
            </div>
            ) : (
              <div className="rm-content__info-state">
                <input type="text" maxLength="255" placeholder={i18n.state} {...register("state", { required: true, })} />
                {!errors.state && <InputErrorMessage />}
                {errors.state?.type === 'required' && <InputErrorMessage message={i18n.pleaseselectastate} />}
              </div>
            )
          }

          <div className="rm-content__info-email">
            <input type="email" maxLength="255" placeholder={i18n.email} {...register("useremail", { required: true, pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })}/>
            {!errors.useremail && <InputErrorMessage />}
            {(errors.useremail?.type === 'required') && <InputErrorMessage message={i18n.youmustprovideanemailaddress} />}
            {(errors.useremail?.type === 'pattern') && <InputErrorMessage message={i18n.youmustprovideavalidemailaddress} />}
          </div>

          <div className="rm-content__info-toc">
            <input type="checkbox" id="agreeTerms" {...register("agreeTerms", { required: true, })}/>
            <label htmlFor="agreeTerms">
              {i18n.iagreetotheterms}
              {!errors.agreeTerms && <InputErrorMessage />}
              {errors.agreeTerms?.type === 'required' && <InputErrorMessage message={i18n.pleaseaccepttheterms} />}
            </label>
          </div>
        </div>

        <input type="submit" value={i18n.submitreview} class="button" disabled={!isValid} />
      </form>
    </>
  )
}

export { WriteAReview }