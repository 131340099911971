import '../scss/tire-rating.scss';

document.addEventListener("DOMContentLoaded", function() {

    if (!window.BATO) window.BATO = {};

    const starRender = (targetElement) => {

        let target = document.querySelector(targetElement);
        let allStarRatings = target.querySelectorAll(".stars");

        allStarRatings.forEach(function(starRating,index){

            let starsFill = starRating.querySelector("span"),
                avgRating = starRating.dataset.rating;

                let ratingPercent = avgRating * 20;

                starRating.setAttribute("aria-label", avgRating+"/5 Star Rating");
                starsFill.style.width = ratingPercent + "%";

        });

    }

    window.BATO.starRender = starRender;

});