import apiUtils from '../util/apiUtils.js';

const {
  site,
  country,
  lang_iso
} = window.BATO?.CONFIG || { site: 'bst', country: 'US', lang_iso: 'en_US' };
// console.log("Configuration in Stores.js:", { site, country, lang_iso });

export const getStoresByZip = async (zip) => {
  try {

    if (!zip) throw new Error('you need to provide a zip');

    const params = {
      site,
      country,
      lang_iso,
      zipCode: zip
    }

    const response = await fetch(apiUtils.apiPath('stores/stores-by-zip', params));

    if (!response.ok) {
      const errorData = await response.json();
      const error = `the api response in getStoresByZip() is not ok: ${errorData}`;
      throw new Error(error);
    }

    const data = await response.json();

    if (data) return data;

  } catch (err) {
    return {
      success: false
    }
  }
}

export const getStoresByLatLon = async ({lat, lon, radius, pageSize, sortBy}) => {
  try {
    if (!lat) throw new Error('you need to provide a latitude named "lat"');
    if (!lon) throw new Error('you need to provide a longitude named "lon"');

    let params = {
      site,
      country,
      lang_iso,
      lat,
      lon,
      sortBy: (sortBy) ? sortBy : 'distance'
    }

    // Set Default Radius & Page Size unless Overwritten from Function Call
    radius ? params.radius = radius : params.radius = 50;
    pageSize ? params.pageSize = pageSize : params.pageSize = 500;

    const response = await fetch(apiUtils.apiPath('stores/stores-by-lat-lon', params));

    if (!response.ok) {
      const errorData = await response.json();
      const error = `the api response in getStoresByLatLon() is not ok: ${errorData}`;
      throw new Error(error);
    }

    const data = await response.json();

    if (data) return data;

  } catch (err) {
    console.log(`there's been an error in the getStoresByLatLon() function: ${err}`);
    return {
      success: false
    }
  }
}

export const getStoresByExclusivity = async ({ product }) => {
  try {
    const params = { 
      site,
      country,
      lang_iso,
      product
    };

    const response = await fetch(apiUtils.apiPath('stores/stores-by-exclusivity', params));
    if (!response.ok) {
      const errorData = await response.json();
      const error = `The API response in getStoresByExclusivity() is not ok: ${errorData}`;
      throw new Error(error);
    }

    const data = await response.json();
    if (data && data.data && data.data.storeNumbers) {
      localStorage.setItem('storeNumbers', JSON.stringify(data.data.storeNumbers));
    }
    
    if(data) return data;
  } catch (err) {
    console.log(`There's been an error in the getStoresByExclusivity() function: ${err}`);
    return {
      success: false
    };
  }
};

export const getStoresByCityState = async ({city, state, radius, pageSize, sortBy}) => {
  try {
    if (!city) throw new Error('you need to provide a valid city named "city"');
    if (!state) throw new Error('you need to provide a state named "state"');

    let params = {
      site,
      country,
      lang_iso,
      city,
      state,
      sortBy: (sortBy) ? sortBy : 'distance'
    }

    // console.log('Parameters for API call in getStoresByCityState in Stores.js:', params);

    // Set Default Radius & Page Size unless Overwritten from Function Call
    radius ? params.radius = radius : params.radius = '';
    pageSize ? params.pageSize = pageSize : params.pageSize = '';

    const response = await fetch(apiUtils.apiPath('stores/stores-by-city-state', params));
    // console.log('Response from API in getStoresByCityState in Stores.js:', response);

    if (!response.ok) {
      const errorData = await response.json();
      const error = `the api response in getStoresByCityState() is not ok: ${errorData}`;
      // console.log('Error data in Stores.js:', errorData);
      throw new Error(error);
    }

    const data = await response.json();
    // console.log('Fetched data in getStoresByCityState Stores.js:', data);


    if (data) return data;

  } catch (err) {
    // console.log(`there's been an error in the getStoresByCityState() function: ${err}`);
    return {
      success: false
    }
  }
}

export default {
  getStoresByZip,
  getStoresByLatLon,
  getStoresByCityState,
  getStoresByExclusivity
}