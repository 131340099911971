// setup window object for components to consume
if (!window.BATO) window.BATO = {};

const ctaSelector = "a, button";
const ctasAnalyticsObject = {
	"event": "component_click",
	"component_detail": {
		"type": "cta",
		"title": "",
		"id": "",
		"click_element": {
			"title": "",
			"id": ""
		}
	}
};

let ctas;

export const init = () => {
	ctas = document.querySelectorAll(ctaSelector);

	ctas.forEach(cta => {

		// Check for Telephone Click
		if((!cta.classList.contains('button--tertiary') && !cta.parentNode.classList.contains('store-card')) &&
			!cta.parentNode.classList.contains('store-details-info__phone')) {

			cta.addEventListener('click', ctaEventHandler);
		}

	});
}

const ctaEventHandler = (evt) => {
	let el = evt.currentTarget;
	let elDetailsDataSet = el.dataset?.detail; 
	let analyticsObject = JSON.parse(JSON.stringify(ctasAnalyticsObject));

	analyticsObject.component_detail.title = analyticsObject.component_detail.click_element.title = el.getAttribute("title") || el.innerText;
	analyticsObject.component_detail.id = analyticsObject.component_detail.click_element.id = el.id;
	
	//console.log("elDetailsDataSet", JSON.stringify(elDetailsDataSet)); 
	
	if (elDetailsDataSet) {
		let details = JSON.parse(elDetailsDataSet); 
		analyticsObject.component_detail = {...analyticsObject.component_detail, ...details}; 
	}
	
	//console.log("\n\n CTA analyticsObject", JSON.stringify(analyticsObject));
	
	adobeDataLayer.push(analyticsObject);
}

export default {
	init
}