export const reviewsSortSwitch = () => {
  //////////////////
  // REVIEWS SORT ON
  //////////////////
  if (localStorage.getItem('reviewsSortActive') === 'false') {
    return false;
  }

  return true;

  ///////////////
  // REVIEWS SORT OFF
  ///////////////
  // if (localStorage.getItem('reviewsSortActive') === 'true') {
  //     return true;
  // }

  // return false;
};