import '../scss/content-card.scss';
import '../scss/content-card-features.scss';

document.addEventListener('DOMContentLoaded', function() {

    let videoElements = document.querySelectorAll('.background-video');

    videoElements.forEach(function(videoElement) {

        let videoSource = (videoElement != null) ? videoElement.getElementsByTagName('source')[0] : null;

        if(videoElement && videoSource) {

            let req = new XMLHttpRequest();
            req.open('GET', videoSource.src, true);
            req.responseType = 'blob';

            req.onload = function() {

                // Onload will trigger on 404, Check status
                if (this.status === 200) {
                    videoElement.classList.add('loaded');
                    videoElement.setAttribute('autoplay', '');
                    videoElement.autoplay = true;
                    videoElement.play();
               }
            }

            req.onerror = function() {
                console.log('Error Loading Video');
            }

            req.send();

        }

    });
});