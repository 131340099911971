import { configureStore } from '@reduxjs/toolkit';
import { load, save, combineLoads } from 'redux-localstorage-simple';
import flexReducer from './profile/profileSlices/flex.js';
import locationReducer from './profile/profileSlices/location.js';
import tiresBySizeReducer from './profile/profileSlices/tiresBySize.js'
import vehiclesReducer from './profile/profileSlices/vehicles.js';
import storeReducer from './profile/profileSlices/store.js';
import storesReducer from './stores/stores.js';
import userReducer from './profile/profileSlices/user.js';
import metaReducer from './profile/profileSlices/meta.js';
import i18nReducer from './profile/profileSlices/i18n.js';

const siteKey = window?.BATO?.siteName || 'BST-NA';

const reduxStore = configureStore({
  reducer: {
    flex: flexReducer,
    location: locationReducer,
    profileMeta: metaReducer,
    profileStore: storeReducer,
    stores: storesReducer,
    tiresBySize: tiresBySizeReducer,
    user: userReducer,
    vehicles: vehiclesReducer,
    i18n: i18nReducer,
  },
  middleware: [
    save({
      disableWarnings: true,
      states: ['flex'],
      namespace: `${siteKey}_PROFILE_FLEX`,
      namespaceSeparator: '::'
    }),
    save({
      disableWarnings: true,
      states: ['location'],
      namespace: `${siteKey}_PROFILE_LOCATION`,
      namespaceSeparator: '::'
    }),
    save({
      disableWarnings: true,
      states: ['profileMeta'],
      namespace: `${siteKey}_PROFILE_META`,
      namespaceSeparator: '::'
    }),
    save({
      disableWarnings: true,
      states: ['profileStore'],
      namespace: `${siteKey}_PROFILE_STORE`,
      namespaceSeparator: '::'
    }),
    save({
      disableWarnings: true,
      states: ['stores'],
      namespace: `${siteKey}_STORES`,
      namespaceSeparator: '::'
    }),
    save({
      disableWarnings: true,
      states: ['tiresBySize'],
      namespace: `${siteKey}_PROFILE_TIRES_BY_SIZE`,
      namespaceSeparator: '::'
    }),
    save({
      disableWarnings: true,
      states: ['user'],
      namespace: `${siteKey}_PROFILE_USER`,
      namespaceSeparator: '::'
    }),
    save({
      disableWarnings: true,
      states: ['vehicles'],
      namespace: `${siteKey}_PROFILE_PRODUCTS`,
      namespaceSeparator: '::'
    }),
    save({
      disableWarnings: true,
      states: ['i18n'],
      namespace: `${siteKey}_PROFILE_I18N`,
      namespaceSeparator: '::'
    }),
  ],
  preloadedState: combineLoads(
    load({
      disableWarnings: true,
      states: ['flex'],
      namespace: `${siteKey}_PROFILE_FLEX`,
      namespaceSeparator: '::'
    }),
    load({
      disableWarnings: true,
      states: ['location'],
      namespace: `${siteKey}_PROFILE_LOCATION`,
      namespaceSeparator: '::'
    }),
    load({
      disableWarnings: true,
      states: ['profileStore'],
      namespace: `${siteKey}_PROFILE_STORE`,
      namespaceSeparator: '::'
    }),
    load({
      disableWarnings: true,
      states: ['profileMeta'],
      namespace: `${siteKey}_PROFILE_META`,
      namespaceSeparator: '::'
    }),
    load({
      disableWarnings: true,
      states: ['stores'],
      namespace: `${siteKey}_STORES`,
      namespaceSeparator: '::'
    }),
    load({
      disableWarnings: true,
      states: ['tiresBySize'],
      namespace: `${siteKey}_PROFILE_TIRES_BY_SIZE`,
      namespaceSeparator: '::'
    }),
    load({
      disableWarnings: true,
      states: ['user'],
      namespace: `${siteKey}_PROFILE_USER`,
      namespaceSeparator: '::'
    }),
    load({
      disableWarnings: true,
      states: ['vehicles'],
      namespace: `${siteKey}_PROFILE_PRODUCTS`,
      namespaceSeparator: '::'
    }),
    load({
      disableWarnings: true,
      states: ['i18n'],
      namespace: `${siteKey}_I18N`,
      namespaceSeparator: '::'
    }),
  )
});

export default reduxStore;

if (!window.BATO) {
  window.BATO = {};
};

window.BATO.Store = reduxStore;

// store: the Redux store - can be passed in from or window.BATO.Store
// select: implement a private function for your component select(state) { return state.some.deep.key }
// onChange: a function that will re-render your UI bits that rely on the global Profile
window.BATO.Store.observeStore = (store, select, onChange) => {
    let currentState;

    function handleChange() {
      let nextState = select(store.getState());
      if (nextState !== currentState) {
        currentState = nextState;
        onChange(currentState);
      }
    }

    let unsubscribe = store.subscribe(handleChange);
    handleChange();
    return unsubscribe;
  }