import elementListener from '../../../../../js/library/eventHelpers.js';

// setup window object for components to consume
if (!window.BATO) window.BATO = {};

/*
	product details object
*/
const productDetailsDataObject = {
	"event":"product_click",
	"product":[],
  	"numberOfReviews":"0",
  	"scoreNumber":"0",
  	"search_index_number":"0"
}

const productObject = {
	"product_id":"",
	"article_id":"", // only available in tire details page, catalog
	"name":"",
	"product_brand":"",
	"brand":"",
	"list_name":"pdp list"
}

export const init = () => {
	let pageWrapper = document.querySelector('.page');
	elementListener.addBubblerListener(pageWrapper, 'click', '.cmp-tire-card a:not([href^="#locate-tire"], .cmp-tire-card-details a:not([href^="#locate-tire"], .last-tire-viewed a.button, .known-vehicle a.button', tireListEventHandler);
}

const tireListEventHandler = (evt) => {

	//evt.preventDefault(); //Enable for Testing

	let el = evt.target;

	let analyticsObject = JSON.parse(JSON.stringify(productDetailsDataObject)); // {...productDetailsDataObject};
	let prodObject = {...productObject};
	let comparisonCard = false;

	let tireCard = el.closest('.cmp-tire-card');

	analyticsObject["search_index_number"] = "";

	// Comparison Card
	if (!tireCard) {

		comparisonCard = true;
		tireCard = el.closest('.cmp-tire-comparison__col'); // Comparison Card Has Different Structure
		prodObject.list_name = "tire comparison";

	// Recommended Card
	} else if (tireCard.classList.contains('cmp-tire-card-recommended')){

		prodObject.list_name = "tire recommended";

		// If On Tire Detail Page
		if(document.querySelector('.tire-detail')) {

			let gridContainer = tireCard.closest('.grid');
			let tireCardWrapper = tireCard.closest('.experiencefragment');

			analyticsObject["search_index_number"] = [...gridContainer.children].indexOf(tireCardWrapper) + 1;

		// If In Search Results
		} else {

			analyticsObject["search_index_number"] = [...tireCard.parentElement.children].indexOf(tireCard) + 1;

		}

	// Tire Card in Grid
	} else {

		let visibleCards = document.querySelectorAll(".cmp-tire-card[data-hide='false']");
		let index = [].indexOf.call(visibleCards, tireCard);

		analyticsObject["search_index_number"] = index + 1;
		prodObject.list_name = "tire list";

	}

	let elData = tireCard.dataset;

	// Fallback if Tire Card does not have a Rating Data Attribute
	if(!tireCard.dataset.rating) {
		analyticsObject.scoreNumber = tireCard.querySelector('.js-stars').dataset?.rating;
	} else {
		analyticsObject.scoreNumber = elData.rating;
	}

	// Fallback if Tire Card does not have a Review Count Attribute
	if(!tireCard.dataset.reviewCount) {
		analyticsObject.numberOfReviews = tireCard.querySelector('.js-stars').dataset?.reviewCount;
	} else {
		analyticsObject.numberOfReviews = elData.reviewCount;
	}

	// Comparison Cards use 't+articleNumber' to avoid errors, articlenumberclean is w/o the leading 't'
	if(comparisonCard) {
		prodObject.article_id = elData.articlenumberclean;
	} else {
		prodObject.article_id = elData.articleNumber || elData.articlenumber;
	}

	prodObject.product_id = elData.productId || elData.productid;
	prodObject.name = elData.name || elData.productName;
	prodObject.product_brand = elData.brand;
	prodObject.brand = elData.subBrand || elData.subbrand;

	analyticsObject.product.push(prodObject);

	adobeDataLayer.push(analyticsObject);

	//console.log("\n\n product-details", JSON.stringify(analyticsObject));
}

export default {
	init
}