import store from '../profileSlices/store.js';

const setTireByVehiclePackage = (tbv) => {
  const {
    tires: {
      front: ftire,
      rear: rtire
    }
  } = tbv;
  return {
    vehicle: {
      year: tbv.year || '',
      make: tbv.make || '',
      model: tbv.model || '',
      trim: tbv.trim || '',
      tpms: tbv.tpms || '',
    },
    ftire: {
      cs: ftire.cs || '',
      ar: ftire.ar || '',
      rs: ftire.rs || '',
      tireSize: ftire.tireSize || '',
      brand: ftire.brand || '',
      subBrand: ftire.subBrand || '',
      product: ftire.product || '',
      article: ftire.article || '',
      qty: ftire.qty || '',
    },
    rtire: {
      cs: rtire.cs || '',
      ar: rtire.ar || '',
      rs: rtire.rs || '',
      tireSize: rtire.tireSize || '',
      brand: rtire.brand || '',
      subBrand: rtire.subBrand || '',
      product: rtire.product || '',
      article: rtire.article || '',
      qty: rtire.qty || '',
    }
  }
}

const setTireBySizePackage = (tbs) => {
  const {
    front: ftire,
    rear: rtire
  } = tbs;

  return {
    ftire,
    rtire
  }
}

const buildUserSlice = (user) => {
  if (user.status === 'unknown') return null;
  return {
    language: store.data.vid || '',
    site: store.data.site || '',
    userId: store.data.userId || '', // email, not guid id
    vid: store.data.vid || ''
  }
};

const buildProductsSlice = (vehicles, tiresBySize) => {
  if (vehicles.status === 'unknown' && tiresBySize.status === 'unknown') return null;
  const products = {};
  if (vehicles.status === 'known') {
    products.tireByVehicle0 = setTireByVehiclePackage(vehicles.vehicle);
    if (vehicles.previousVehicles.length) {
      vehicles.previousVehicles.forEach((veh, idx) => {
        if (idx === 0) {
          products.tireByVehicle1 = setTireByVehiclePackage(veh);
        }
        if (idx === 1) {
          products.tireByVehicle2 = setTireByVehiclePackage(veh);
        }
      });
    }
  }
  if (tiresBySize.status === 'known') {
    products.tireBySize0 = setTireBySizePackage(tiresBySize.tireBySize);
    if (tiresBySize.previousTiresbySize.length) {
      tiresBySize.previousTiresbySize.forEach((tbs, idx) => {
        if (idx === 0) {
          products.tireBySize1 = setTireBySizePackage(tbs);
        }
        if (idx === 1) {
          products.tireBySize2 = setTireBySizePackage(tbs);
        }
      });
    }
  }

  return products;
};

const buildLocationSlice = (location) => {
  if (location.status === 'unknown') return null;
  return location.data;
};

const buildStoreSlice = (store) => {
  if (store.status === 'unknown') return null;
  return {
    accuracy: store.data.accuracy || '',
    storeNumber: store.data.storeNumber || ''
  }
};
// const buildFlexSlice = (flex) => {};

const createServerSchemaFromState = (state) => {
  const user = buildUserSlice(state.user);
  const products = buildProductsSlice(state.vehicles, state.tiresBySize);
  const location = buildLocationSlice(state.location);
  const store = buildStoreSlice(state.profileStore);
  // const flex = buildFlexSlice(state.stores);
  return {
    profile: {
      ...user,
      ...products,
      ...location,
      ...store,
      // ...flex
    }
  }
};

export default createServerSchemaFromState;