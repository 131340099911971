import elementListener from '../../../../../../js/library/eventHelpers.js';
import { urlLanguageCheck } from '../../../../../../js/utils/urlLanguageCheck.js';

import '../scss/location-search.scss';
const dealerLocationError = document.querySelector('.store-locator__cc .dealer-use-current--error');
const dealerSearchButton = document.querySelector('.location-search .button');
const storeLocationError = document.querySelector('.store-filter__filters__location__select .store-use-current--error');
const storeLocatorButton = document.querySelector('.store-filter__filters__location__select .location-search .button');

document.addEventListener("DOMContentLoaded", function() {

    if (!window.BATO) window.BATO = {};
    if (!window.Microsoft) window.Microsoft = {}; // Prevents Console Error for Missing Microsoft Window Object
    if (!window.Microsoft.Maps) window.Microsoft.Maps = {}; // While Waiting for Microsoft Window Object

    // const MAP_KEY = 'Alqx3peKgY_8B05zrSse0rDrgzAF9hoQ7hIDk1r8MVx9BO_4Pnk7n8FfGXXWWIdO';
    const MAP_KEY = window.BATO.COMPONENTS.StoreLocator.v1.mapAPIKey;
    const mapWrapper = document.querySelector('.map');

    const locationSearchWrapper = document.querySelectorAll('.location-search');
    let pageWrapper = document.querySelector('.page');
    let dealerURL = "";

    const getTranslatedText = () => {

        if(document.querySelector('.tire-search-flyout__filters__location .location-search .button')?.dataset?.i18n) {
            return document.querySelector('.tire-search-flyout__filters__location .location-search .button').dataset.i18n;
        } else if( document.querySelector('.tire-filter')?.dataset?.locationSubmit ) {
            return document.querySelector('.tire-filter').dataset.locationSubmit;
        } else if( document.querySelector('.tire-detail-datawrapper')?.dataset?.locationSubmit ) {
            return document.querySelector('.tire-detail-datawrapper').dataset.locationSubmit;
        } else {
            return 'Submit';
        }

    }

    if(locationSearchWrapper.length > 0){

        locationSearchWrapper.forEach(locationSearchBox => {

            if(locationSearchBox.closest('.store-locator__location-unknown')) {
                locationSearchBox.classList.add('location-search--unknown');
            }

            if(locationSearchBox.querySelector('.full-width')) {
                locationSearchBox.dataset.fullWidth = 'true';
            }

        });

        let map,
            searchManager;

        // Use Context Config Value to Limit Address Suggestions to Country
        let managerObj = {
            addressSuggestions: false,
            countryCode: window.BATO.CONFIG.country,
            maxResults: 3
        };

        // When User Clicks 'Use Current Location'
        const getUserLocation = () => {
            // Fire UI Animations
            suggestionLoader();

            let lat,
                lon,
                errorText;

            try {

                navigator.geolocation.getCurrentPosition(async function (position) {

                    lat = position.coords.latitude;
                    lon = position.coords.longitude;

                    let userLocation = [lat, lon];

                    // If 'Use Current Location'
                    if(userLocation) {

                        let geoCodeRequest = window.location.protocol + '//dev.virtualearth.net/REST/v1/Locations/' + userLocation[0] + ',' + userLocation[1] + '?inclnb=1&key=' + MAP_KEY;
                        if (dealerLocationError) dealerLocationError.style.display = 'none';
                        if (storeLocationError) storeLocationError.style.display = 'none';
                        async function lookUpUserLocation() {
                            let geoCodeResult = await (await fetch(geoCodeRequest)).json();

                            if(geoCodeResult.statusCode == 200) {

                                let result = {
                                    "address": {
                                        "adminDistrict": geoCodeResult.resourceSets[0].resources[0].address.adminDistrict,
                                        "locality": geoCodeResult.resourceSets[0].resources[0].address.locality,
                                        "countryRegionISO2": geoCodeResult.resourceSets[0].resources[0].address.countryRegion,
                                        "postalCode": geoCodeResult.resourceSets[0].resources[0].address.postalCode,
                                    },
                                    "location": {
                                        "latitude": geoCodeResult.resourceSets[0].resources[0].point.coordinates[0],
                                        "longitude": geoCodeResult.resourceSets[0].resources[0].point.coordinates[1]
                                    }
                                }

                                //console.log("-----------> geoCodeResult",geoCodeResult);
                                //console.log("-----------> result",result);

                                constructLocation(result);

                            } else {
                                errorText = geoCodeResult.statusDescription;
                                throw new Error (errorText);
                            }

                        }

                        lookUpUserLocation();

                    }

                }, function(err) {

                    if (dealerSearchButton) {
                        dealerSearchButton.classList.remove('location-loading');
                        dealerSearchButton.innerHTML = getTranslatedText();
                        dealerSearchButton.disabled = false;
                    }
                    if (storeLocatorButton) {
                        storeLocatorButton.classList.remove('location-loading');
                        storeLocatorButton.innerHTML = getTranslatedText();
                        storeLocatorButton.disabled = false;
                    }
                    if (dealerLocationError) dealerLocationError.style.display = 'flex';
                    if (storeLocationError) storeLocationError.style.display = 'flex';
                    return {message: err, success: false};
                }
            );

            } catch (err) {                
                return {message: err, success: false};
            }

        }

        window.BATO.storeLocator.getUserLocation = getUserLocation;

        // Load AutoSuggest Module w/o Map Present
        const loadAutoSuggest = () => {

            // Keeping this at 1 for uniformity in formatting since this appears 'above' the search bar
            managerObj.maxResults = 1;

            Microsoft.Maps.loadModule('Microsoft.Maps.AutoSuggest', {

                credentials: MAP_KEY,
                callback: function () {

                    var manager = new Microsoft.Maps.AutosuggestManager(managerObj);

                    // Account for Multiple Dropdowns
                    locationSearchWrapper.forEach(locationSearchBox => {
                        let locationSearch = locationSearchBox.querySelector('.location');
                        manager.attachAutosuggest(locationSearch, locationSearchBox, suggestionLoader);
                        locationSearchBox.classList.add('fade-in');
                    });

                    searchManager = new Microsoft.Maps.Search.SearchManager();

                },
                errorCallback: function(msg){ console.log(msg); }

            });

        }

        // Load AutoSuggest Module w/ Map Present & Tie To Map
        const checkMap = (mapPresent, overlay) => {

            if(mapPresent){

                // We Have to Setup a Seperate Function if Map is in Overlay because Bing is the Worst
                if(overlay == true) {

                    let map,
                        searchManager;

                    // Use Context Config Value to Limit Address Suggestions to Country
                    let managerObj = {
                        addressSuggestions: false,
                        countryCode: window.BATO.CONFIG.country,
                        maxResults: 3
                    };

                    let locationSearchWrapper = document.querySelector('.tire-search-flyout__filters__location .location-search');

                    Microsoft.Maps.loadModule(['Microsoft.Maps.AutoSuggest', 'Microsoft.Maps.Search'], {

                        callback: function () {

                            var manager = new Microsoft.Maps.AutosuggestManager(managerObj);

                            let locationSearch = locationSearchWrapper.querySelector('.location');
                            manager.attachAutosuggest(locationSearch, locationSearchWrapper, suggestionLoader);
                            locationSearchWrapper.classList.add('fade-in');

                            searchManager = new Microsoft.Maps.Search.SearchManager(map);

                        },

                        errorCallback: function(msg){
                            console.log(msg);
                        }

                    });

                } else {

                    map = window.map;
                    managerObj.map = map;
                    managerObj.maxResults = 3;

                    // Account for Multiple Dropdowns
                    locationSearchWrapper.forEach(locationSearchBox => {

                        let locationSearch = locationSearchBox.querySelector('.location');

                        Microsoft.Maps.loadModule(['Microsoft.Maps.AutoSuggest', 'Microsoft.Maps.Search'], {

                            callback: function () {

                                var manager = new Microsoft.Maps.AutosuggestManager(managerObj);
                                manager.attachAutosuggest(locationSearch, locationSearchBox, suggestionLoader);
                                locationSearchBox.classList.add('fade-in');

                                searchManager = new Microsoft.Maps.Search.SearchManager(map);

                            },

                            errorCallback: function(msg){
                                console.log(msg);
                            }

                        });

                    });
                }

            } else {

                window.BATO.mapLoad('loadAutoSuggest');

            }
        }

        window.BATO.loadAutoSuggest = checkMap;

        const regex = new RegExp('^[a-zA-Z0-9\s., ]+$');
        const regexNum = new RegExp('^[0-9]+$');
        const postalRegex = new RegExp(window.BATO.COMPONENTS.StoreLocator.v1.postalRegex);

        // If User Clicks 'Submit Button'
        const searchUserLocation = (event) => {
            // console.log('search user location triggered');
            let searchButton = event.target;
            let searchBox = searchButton.closest('.location-search').querySelector('.location');

            // console.log('searchBox:', searchBox);
            // console.log('searchBox.value:', searchBox.value);

            let locationWrapper = searchButton.closest('.location-search');
            let noResults = locationWrapper.querySelector('.no-results');
            let invalid = locationWrapper.querySelector('.invalid');
            if (dealerLocationError) dealerLocationError.style.display = 'none';
            if (storeLocationError) storeLocationError.style.display = 'none';
            noResults.classList.remove('show-error');
            invalid.classList.remove('show-error');

            let query = searchBox.value;
            // console.log('user search query', query);
            let zipCheck = false;
            let queryInt = parseInt(query);

            // Check If Zip Code
            if( postalRegex.test(query) ) {
                zipCheck = true;
            }

            // Test that Search is Alphanumeric, Comma or Period AND Does Not Contain Only Numbers
            // OR
            // Test that Query passes Zip Check
            if( (regex.test(query) && !regexNum.test(queryInt)) || zipCheck) {

                if(!mapWrapper) {

                    // Use Bings REST API if no map is present, this data is returned differently
                    // than search with a map present, so we must construct the data object to match
                    const bingRESTData = (r) => {

                        //console.log(r);

                        if (r && r.resourceSets[0].resources && r.resourceSets[0].resources.length > 0) {

                            let addressInfo = r.resourceSets[0].resources[0].address;

                            addressInfo.postalCode = "";
                            addressInfo.countryRegionISO2 = addressInfo.countryRegion;

                            let bestMatch = {
                                address: addressInfo,
                                location: {
                                    latitude: r.resourceSets[0].resources[0].point.coordinates[0],
                                    longitude: r.resourceSets[0].resources[0].point.coordinates[1],
                                }

                            }

                            noResults.classList.remove('show-error');
                            invalid.classList.remove('show-error');
                            suggestionLoader(bestMatch);

                        } else {

                            noResults.classList.add('show-error');

                        }

                    }

                    window.bingRESTData = bingRESTData;

                    const callBingRestAPI = (request) => {

                        let bingScript = document.createElement("script");
                        bingScript.setAttribute("type", "text/javascript");
                        bingScript.setAttribute("src", request);
                        bingScript.setAttribute('async', true);
                        bingScript.setAttribute('defer', true);
                        document.body.appendChild(bingScript);

                    }

                    //After several tests it was found that parameter c (stands for locale) is the one that filters by country.
                    //The parameter c must be with hyphen.
                    let bingCountryFilter = window.BATO.CONFIG.lang_iso.replace('_', '-');

                    let geoCodeRequest = window.location.protocol + '//dev.virtualearth.net/REST/v1/Locations?query=' + encodeURIComponent(query) +'&c=' + bingCountryFilter + '&includeEntityTypes=PopulatedPlace,Address,Postcode1&jsonp=bingRESTData&key=' + MAP_KEY;
                    
                    callBingRestAPI(geoCodeRequest);

                } else {

                    var searchRequest = {
                        where: query,
                        count: 3,
                        includeCountryIso2: true,
                        callback: function (r) {

                            if (r && r.results && r.results.length > 0) {

                                noResults.classList.remove('show-error');
                                invalid.classList.remove('show-error');
                                suggestionLoader(r.results[0]);

                            } else {

                                noResults.classList.add('show-error');

                            }
                        },
                        errorCallback: function (e) {

                            noResults.classList.add('show-error');

                        }
                    }

                    searchManager.geocode(searchRequest);

                }

            } else {
                invalid.classList.add('show-error');
            }

        }

        window.BATO.storeLocator.searchUserLocation = searchUserLocation;


        // Moves Map When User Selects Option from Bing AutoSuggest Dropdown
        const suggestionLoader = (result) => {
            // console.log('Suggested Location Data:', result);

            if(window.BATO?.overlayMap) {

                let locationSearchWrapper = document.querySelector('.tire-search-flyout__filters__location .location-search');
                let locationSearchButton = locationSearchWrapper.querySelector('.button');
                let locationSearchInput = locationSearchWrapper.querySelector('.location');
                // console.log('location search input',locationSearchInput)
                dealerURL = locationSearchInput.dataset.url;
                locationSearchButton.classList.add('location-loading');
                locationSearchButton.innerHTML = '';


            } else {

                locationSearchWrapper.forEach(locationSearchBox => {
                    let locationSearchButton = locationSearchBox.querySelector('.button');
                    let locationSearchInput = locationSearchBox.querySelector('.location');
                // console.log('location search input else',locationSearchInput)
                    dealerURL = locationSearchInput.dataset.url;
                    locationSearchButton.classList.add('location-loading');
                    locationSearchButton.innerHTML = '';
                    //locationSearchButton.disabled = true;
                });

            }

            if(result){
                if (dealerLocationError) dealerLocationError.style.display = 'none';
                if (storeLocationError) storeLocationError.style.display = 'none';
                constructLocation(result);
            }

        }



        const constructLocation = (result) => {

            //console.log('result',result);

            const truncateDecimals = (number, digits) => {
                let multiplier = Math.pow(10, digits),
                    adjustedNum = number * multiplier,
                    truncatedNum = Math[adjustedNum < 0 ? 'ceil' : 'floor'](adjustedNum);
                return truncatedNum / multiplier;
            };

            let locationData = {
                status: 'known',
                data: {
                    accuracy: 'latlon',
                    city: result.address.locality,
                    country: result.address.countryRegionISO2,
                    ip: '',
                    lat: truncateDecimals(result.location.latitude, 4),
                    lon: truncateDecimals(result.location.longitude, 4),
                    region: result.address.adminDistrict,
                    zip: result.address.postalCode
                }
            }

            //console.log('locationData',locationData);

            let zipCodeRequest = window.location.protocol + '//dev.virtualearth.net/REST/v1/Locations/' + result.location.latitude + ',' + result.location.longitude + '?includeEntityTypes=PopulatedPlace,Address,Postcode1&key=' + MAP_KEY;

            //console.log('locationData',locationData);

            // Reverse Look Up for Zip Code Because Bing is the Worst
            async function reverseLookUp() {
                let zipCodeResult = await (await fetch(zipCodeRequest)).json();

                if(!result.address?.postalCode) {
                    locationData.data.zip = zipCodeResult.resourceSets[0].resources[0]?.address?.postalCode;
                }

                //console.log("locationData2",locationData);

                setLocation(locationData);
            }

            reverseLookUp();

        }

        const linkToDealerPage = async (noResultReturned) => {

            let noReleventResults = document.querySelectorAll('.no-results');

            noReleventResults.forEach(noReleventResult => {
                noReleventResult.classList.remove('show-error');
            });

            if (noResultReturned === true) {

                locationSearchWrapper.forEach(locationSearchBox => {
                    let locationSearchButton = locationSearchBox.querySelector('.button');

                    noReleventResults.forEach(noReleventResult => {
                        noReleventResult.classList.add('show-error');
                    });

                    locationSearchButton.classList.remove('location-loading');
                    locationSearchButton.innerHTML = getTranslatedText();
                    locationSearchButton.disabled = false;
                });

            } else {

                if(!mapWrapper && !window.BATO.overlayMap) {

                    // Take User to Store Locator Page
                    if(dealerURL) {
                        window.location = dealerURL;
                    } else {
                        let languageInUrl = urlLanguageCheck(),
                            urlRedirect = '/dealer/';

                        languageInUrl ? urlRedirect = '/' + languageInUrl + urlRedirect : '';

                        window.location = urlRedirect;
                    }

                } else {

                    if(window.BATO.overlayMap) {

                        let locationSearchOverlay = document.querySelector('.dynamic-top-content .tire-search-flyout__filters__location')

                        let locationSearchZip = locationSearchOverlay.querySelector('span');
                        let locationSearchWrapper = locationSearchOverlay.querySelector('.location-search');

                        let locationSearchButton = locationSearchWrapper.querySelector('.button');
                        locationSearchButton.classList.remove('location-loading');
                        locationSearchButton.innerHTML = getTranslatedText();
                        locationSearchButton.disabled = false;

                        window.BATO.mapLoad();

                        let locationFLyout = document.querySelector('.dynamic-top-content');
                        let storeCardsWrapper = locationFLyout.querySelector('.cmp-store-cards');

                        window.BATO.storeLocator.getStoreCards(10).then((storeCards)=>{

                            storeCardsWrapper.innerHTML = storeCards;

                            window.BATO.storeLocator.bindStoreCards();

                            let zip = window.BATO.Store.getState().location.data.zip;
                            locationSearchZip.innerHTML = zip.split('-')[0];

                            locationSearchWrapper.classList.remove('location-search--overlay');

                            window.BATO.storeLocator.purchaseButtonSetup();

                        });


                    } else {

                        // Reset the Submit Button(s)
                        locationSearchWrapper.forEach(locationSearchBox => {

                            let locationSearchButton = locationSearchBox.querySelector('.button');
                            locationSearchButton.classList.remove('location-loading');
                            locationSearchButton.innerHTML = getTranslatedText();
                            locationSearchButton.disabled = false;

                            let locationOverlayWrapper = locationSearchBox.closest('.store-filter__filters__location__select');
                            if (locationOverlayWrapper) {

                                locationOverlayWrapper.classList.add('store-filter__filters__location__select--fade-out');

                                setTimeout(()=>{
                                    locationOverlayWrapper.classList.remove('store-filter__filters__location__select--active');
                                    locationOverlayWrapper.classList.remove('store-filter__filters__location__select--fade-out');
                                },300);

                            }

                        });

                    }

                    let locationUnknown = document.querySelector('.store-locator__location-unknown');

                    // Wait for Fade Animation To Set First Radius Distance On Map
                    if(locationUnknown) {
                        setTimeout(()=>{
                            let initialRadiusInput = document.querySelector('input[name="distance"]'),
                                initialRadiusLabel = document.querySelector('[for="' + initialRadiusInput.id + '"]').click();
                        },200);
                    }
                }

                if (!window.BATO.storeLocator) window.BATO.storeLocator = {};
                window.BATO.storeLocator.basketTransfer = {
                    exists: false,
                    checked: false
                }

                //let basketTransferFilter = document.querySelector('.basket-transfer-filter');
                //basketTransferFilter ? basketTransferFilter.remove() : '';

                window?.BATO?.storeLocator?.purchaseButtonSetup();

            }
        }

        // Set Data in Redux Profile Manager
        const setLocation = (locationData) => {

            let updateStores = "";

            try {

                const {accuracy, city, country, ip, lat, lon, region, zip} = locationData.data;

                updateStores = window.BATO.ProfileActions.setLocationAndStore({
                    accuracy,
                    city,
                    country,
                    ip,
                    lat,
                    lon,
                    region,
                    zip
                });

            } catch (err) {

                return {message: err, success: false};

            }

            // Wait for Promise from setLocationAndStore then Redirect User to Dealer Page
            (async () => {
                try {
                    let returnFromStore = await updateStores;

                    let noResultReturned = false;
                    if (returnFromStore.data?.messages?.error === "No Stores Found") {
                        noResultReturned = true;
                    }

                    linkToDealerPage(noResultReturned);
        
                } catch (err) {
                    console.error("Error in async function:", err);
                }
            })();
        }        
        


        // Wait for Map
        if(mapWrapper) {
            (async() => {
                while(!window.hasOwnProperty("map"))
                    await new Promise(resolve => setTimeout(resolve, 250));
                checkMap(true);
            })();
        } else {
            checkMap(false);
            (async() => {
                while(!window.Microsoft.Maps.hasOwnProperty("loadModule"))
                    await new Promise(resolve => setTimeout(resolve, 250));
                loadAutoSuggest();
            })();
        }

        // Make Interactions Global and able to be tied to Dynamically Created Location Search Boxes
        elementListener.addBubblerListener(pageWrapper, 'click', '.location-search .use-current', getUserLocation);
        elementListener.addBubblerListener(pageWrapper, 'click', '.location-search .button', searchUserLocation);

        const searchButtonClick = (e) => {
            if (e.keyCode === 13) {
                e.target.closest('.location-search').querySelector('.button').click();
            }
        };

        elementListener.addBubblerListener(pageWrapper, 'keyup', '.location-search .location', searchButtonClick);
        elementListener.addBubblerListener(pageWrapper, 'click', '.location-search .button', searchUserLocation);

        const useLocationButtonClick = () => {
            if (e.keyCode === 13) {
                e.target.parentElement.querySelector('.use-current').click();
            }
        }

        elementListener.addBubblerListener(pageWrapper, 'keyup', '.location-search .use-current', useLocationButtonClick);

        if (window.BATO?.Store) {

            const injectZip = () => {
                const locationStatus = window.BATO.Store.getState().location?.status === 'known' ? true : false;
                const currentZip = window.BATO.Store.getState().location?.data?.zip;

                if (locationStatus) {
                    const contentCardLocationSearchWrapper = document.querySelectorAll('.content-card .location-search');
                    if (contentCardLocationSearchWrapper.length > 0) {
                        contentCardLocationSearchWrapper.forEach((wrapper)=> {
                            const searchInput = wrapper.querySelector('input.location');
                            searchInput.value = currentZip;
                        })
                    }
                }
            };

            window.BATO.Store.observeStore(window.BATO.Store, function(state) {
                return state.location;
            }, injectZip);
        }

    }

});