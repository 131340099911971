import createServerSchemaFromState from './utils/createServerSchemaFromState.js';
import reportUIVersion from './utils/uiVersion.js';

import {actions as flexActions} from './profileSlices/flex.js';
import {actions as locationActions} from './profileSlices/location.js';
import {actions as metaActions} from './profileSlices/meta.js';
import {actions as storeActions} from './profileSlices/store.js';
import {actions as storesActions} from './../stores/stores.js';
import {actions as vehicleActions} from './profileSlices/vehicles.js';
import {actions as tireBySizeActions} from './profileSlices/tiresBySize.js';
import {user} from './profileSlices/user.js';
import {actions as i18n} from './profileSlices/i18n.js';

import locationApi from '../../../../services/v1/js/api/location.js';
import storesApi from '../../../../services/v1/js/api/stores.js';
import profileCheckApi from '../../../../services/v1/js/api/profileCheck.js';
import api from '../api/index.js';
import store from '../redux-state.js';

//translations
import es_MX from '../i18n/es-mx.json';
import es_AR from '../i18n/es-ar.json';
import en_US from '../i18n/en-us.json';
import es_CL from '../i18n/es-cl.json';
import pt_BR from '../i18n/pt-br.json';

const dateInPast = (isThisDate, beforeThisDate = new Date(), byThisManyDays = 0) => {
  try {
    if (!isThisDate instanceof Date || !beforeThisDate instanceof Date) {
      throw new Error('both parameters must be dates');
    }
    if (!byThisManyDays) {
      if (isThisDate.setHours(0, 0, 0, 0) <= beforeThisDate.setHours(0, 0, 0, 0)) {
        return true;
      }
      return false;
    } else {
      // for dev purposes to force a refresh
      if (byThisManyDays = -1) {
        return true;
      }
      const diffInTime = beforeThisDate.getTime() - isThisDate.getTime();
      const diffInDays = diffInTime / (1000 * 3600 * 24);
      if (diffInDays <= byThisManyDays) {
        return false;
      }
      return true;
    }

  } catch (err) {
    console.log(`Error in dateInPast() function: ${err}`);
    return false;
  }
}

if (!window.BATO) window.BATO = {};
if (!window.BATO.CONFIG) window.BATO.CONFIG = {};

window.BATO.CONFIG.uiVersion = reportUIVersion();

const d = store.dispatch;

const createProfileStoreFromServerSchema = async (serverProfile) => {
  // TODO: complete once the /sync endpoint is fully ready to go.
  // set current vehicle and previous vehicles
  // vehicleActions.setVehicle();
  // vehicleActions.setTiresOnVehicle();
  // vehicleActions.setPreviousVehicles();
  // set tireBySize and previousTiresBySize
  // tireBySizeActions.setTiresBySize();
  // tireBySizeActions.setPreviousTiresBySize();
  // set location
  // locationActions.setLocation();
  // set store number/accuracy
  // storeActions.setStore();
  // set user details
  // userActions.setUser();
  // set nearest store details (count, array of them, etc) from localStorage (not profile)
  // set user details

  return true;
}

const setLocationAndStoreFromProfile = async (location, postalCode) => {

    let postalRegex = new RegExp(window.BATO.COMPONENTS.StoreLocator.v1.postalRegex);

    if (postalRegex.test(postalCode)) {
      const newProfile = await profileApi.setLocationAndStore(
        {
          ...location.data,
          ...{
            zip: postalCode
          }
        }
      );

      d(metaActions.setProfileMeta(
        {
          profileId: newProfile.data.params.id,
          nextUpdate: newProfile.data.data.profile.nextUpdate,
          lastUpdate: newProfile.data.data.profile.lastUpdate,
          uiVersion: window.BATO.CONFIG.uiVersion
        }
      ));
    }

}

const profileApi =  {
    // complete for 481
    clearProfile: async () => {
        d(locationActions.clearLocation());
        d(storeActions.clearStore());
        d(storesActions.clearStores());
        d(vehicleActions.clearVehicles());

        d(tireBySizeActions.clearTires());
        localStorage.removeItem('tireLastViewed');

        d(flexActions.clearFlex());
        d(metaActions.clearProfileMeta());
    },
    checkProfile: async () => {
        try {
            const currentProfile = await profileCheckApi.getCheckProfile();
            let profileStatus;

            if(currentProfile) {

                if(currentProfile?.status == '409.605.0605') {
                    profileStatus = 'Profile Out Of Sync';
                } else {
                    profileStatus = 'Profile Is OK';
                }

                return { success: true, data: {'status': currentProfile.status, 'profileStatus': profileStatus} }

            } else {
                return { success: true, data: false }
            }

        } catch (err) {

            console.log(`error in getCheckProfile(): ${err}`);
            return {message: err, success: false}
        }

    },
    setupProfile: async () => {
      try {
        // get profileId from state
        const {
          profileId,
          nextUpdate
        } = store.getState().profileMeta;

        // Check for Current lang_iso against Store Data lang_iso
        // This to handle triggering a new Store/Location call if user switches languages
        // But their location stays the same - i.e. .ca to .ca/fr
        let languageCurrent = window.BATO.CONFIG.lang_iso;
        let languageSaved = (window.BATO.Store.getState().stores.data.lang_iso != undefined) ? window.BATO.Store.getState().stores.data.lang_iso : languageCurrent;
        let languageMatch = (languageCurrent == languageSaved);

        let location,
            postalCodeTemp,
            postalCode;

        if (profileId && languageMatch) {

          const updateDate = new Date(nextUpdate);
          const today = new Date();

          // check to see if the nextUpdate Date has passed or is within 5 days
          // NOTE: pass in -1 for the 3rd param to force true during dev
          if (dateInPast(updateDate, today, 5)) {
            // localStorage there but profileId is soon to expire
            // - gather profile store, convert to server schema
            const serverSchema = createServerSchemaFromState(store.getState());
            // - then generate new profile on server from the server with passing no profileId to /sync endpoint
            const serverProfile = await api.updateProfile(serverSchema);
            // -- set updated profile to Redux Store with createProfileStoreFromServerSchema()
            const newlySetState = createProfileStoreFromServerSchema(serverProfile);

            if (newlySetState) {
              const updatedState = store.getState();
              return updatedState;
            }
          }
        }

        else {

          if(languageMatch == true) {
              // bootstrap a fresh profile
              // start with getting the location
              location = await locationApi.setLocationByNetwork();

              if (!location.success === 'true') {
                const errorData = location.messages.error;
                const err = `there's a problem with location bootstrap: ${errorData}`;
                throw new Error(err);
              };

              // check for valid zip and region in location.data
              postalCodeTemp = location.data?.zip || '';
              postalCode = (postalCodeTemp != '') ? postalCodeTemp.split('-')[0] : '';

          } else {

              (async () => {
                  while(window.BATO.Store.getState().location.data.lat == '')
                    await new Promise(resolve => setTimeout(resolve, 250));

                location = window.BATO.Store.getState().location;
                postalCode = window.BATO.Store.getState().location.data.zip;

              })();

          }

          setLocationAndStoreFromProfile(location, postalCode);

        }
      }
      catch (err) {
        console.log(`Profile Bootstrap Error in setupProfile(): ${err}`);
        return {
          success: false
        }
      }
    },
    // no use case for this yet
    updateProfile: async (profileSlice) => {
      try {
        // call POST on profile API endpoint
      } catch (err) {
        console.log(`there was an error in updateProfile(): ${err}`)
        return { success: false };
      }
    },
    // complete for 481
    setVehicle: async ({year, make, model, trim, tpms, acesVehicleId}) => {
      try {
        const {
          profileId,
        } = store.getState().profileMeta;
        // - set vehicle on state for UI to update
        d(vehicleActions.setVehicle({year: year, make: make, model: model, trim: trim, tpms: tpms, acesVehicleId: acesVehicleId}));

        const updatePackage = await api.updateVehicle({
          year,
          make,
          model,
          trim,
          tpms,
          acesVehicleId
        }, profileId);
        return { success: true, data: updatePackage }
      } catch (err) {
        console.log(`error in setVehicle(): ${err}`);
        return {
          success: false
        }
      }
    },
    /**
     * Tire Objects should have the following properties
     * {
     *   cs = "", // tire width
     *   ar = "", // tire ratio
     *   rs = "", // tire diameter
     *   tireSize = "", // cs-ar-rs
     *   brand = "",
     *   subBrand = "",
     *   product = "", // tire name usually in the format of BRIDGESTONEBLIZZAKLM32
     *   article = "",
     *   qty = "", // 4 if no split fitment, 2 if split fitment
     * }
     * */
    // complete for 481
    setTiresOnVehicle: async ({ front, rear = null }) => {
      try {
        const {
          profileId,
        } = store.getState().profileMeta;

        d(vehicleActions.setTiresOnVehicle({front, rear}));

        let tirePackage = { ftire: front };
        if (rear) tirePackage.rtire = rear;

        const updatedProfile = await api.updateTiresOnVehicle(tirePackage, profileId);

        return { success: true, data: updatedProfile };
      } catch (err) {
        console.log(`there's been an error in setTiresOnVehicle(): ${err}`);
        return { success: false };
      }
    },
    // see above tire object for example of front and rear objects
    // complete for 481
    setTiresBySize: async ({ front, rear = null }) => {
      try {
        const {
          profileId,
        } = store.getState().profileMeta;

        d(tireBySizeActions.setTiresBySize({ front, rear }));

        let tirePackage = { ftire: front };
        if (rear) tirePackage.rtire = rear;

        const updatedProfile = await api.updateTiresBySize(tirePackage, profileId)

        return { success: true, data: updatedProfile };

      } catch (err) {
        console.log(`there's been an error in setTiresBySize(): ${err}`);
        return { success: false };
      }
    },
    // complete for 481
    setLocation: async ({
      accuracy = "", // 'latlon' or 'user' or 'network'
      city = "",
      country = "",
      ip = "",
      lat = "",
      lon = "",
      region= "",
      zip = "",
    }) => {
      try {
          const {
            profileId,
          } = store.getState().profileMeta;

          d(locationActions.setLocation(
            {
              accuracy,
              city,
              country,
              ip,
              lat,
              lon,
              region,
              zip,
            }));
          const updatedProfile = await api.updateLocation({
            accuracy,
            city,
            country,
            ip,
            lat,
            lon,
            region,
            zip,
          }, profileId);

          return { success: true, data: updatedProfile }
      } catch (err) {
          console.log(`error in setLocation(): ${err}`);
          return {success: false}
      }
    },
    // complete for 481
    setStore: async ({
      accuracy = 'network',
      storeNumber = ''
    }) => {
      try {
        const {
          profileId,
        } = store.getState().profileMeta;
    
        // Dispatch the action to update the store
        d(storeActions.setStore({ accuracy, storeNumber }));
        
        // Update the profile on the server
        const updatedProfile = await api.updateStore({}, profileId);
        
        return { success: true, data: updatedProfile };
      } catch (err) {
        console.error('Error in setStore function:', err);
        return { success: false, error: err.message };
      }
    },
    setLocationAndStore: async ({
      accuracy = "", // 'latlon' or 'user' or 'network'
      city = "",
      country = "",
      ip = "",
      lat = "",
      lon = "",
      region= "",
      zip = "",
    }) => {
      try {
  
          const {
            profileId,
          } = store.getState().profileMeta;


        let location = {
          accuracy,
          city,
          country,
          ip,
          lat,
          lon,
          region,
          zip
        }
        
        if (document.querySelector('.tire-decision-guide') === null && !sessionStorage.getItem('tdg_params')) {
          d(locationActions.setLocation(location));
        }
        
        const storeList = await storesApi.getStoresByLatLon({
            lat, lon
        });
        if (storeList.data?.stores) {

          let storesArray = Object.keys(storeList.data.stores).map(i => storeList.data.stores[i]);

         // Check if productID is valid before filtering stores
          const productID = window.BATO?.ANALYTICS?.products ? window.BATO.ANALYTICS.products[0]?.product_id : null;

          if (productID) {
            const cachedStoreNumbers = JSON.parse(localStorage.getItem(`storeNumbers_${productID}`));
            if (cachedStoreNumbers) {
              storesArray = storesArray.filter(store => cachedStoreNumbers.includes(store.base.storeNumber));
              console.log("Filtered stores by exclusivity:", storesArray.map(store => store.base.storeNumber));
            }
          }
          console.log("SORTING");

          if (document.querySelector('.tire-decision-guide') !== null && sessionStorage.getItem('tdg_params')) {
            let theNextBtn = document.querySelector('.nextBtn');
            const tdgSearchButton = document.querySelector('.location-search-tdg .button');
            tdgSearchButton.classList.remove('location-loading');
            theNextBtn.removeAttribute("disabled");
          }

          storesArray.sort((a, b) => parseFloat(a.location.distance) - parseFloat(b.location.distance));
    
          if (storesArray.length > 0) {
            const myStore = {
              accuracy: 'user',
              storeNumber: storesArray[0].base.storeNumber
            };
  
            store.dispatch(storeActions.setStore(myStore));

            store.dispatch(storesActions.setStores({
                quantity: storesArray.length,
                storeNumbers: storesArray.map(store => store.base.storeNumber),
                stores: storesArray.reduce((acc, store) => {
                  acc[store.base.storeNumber] = store;
                  return acc;
                }, {}),
                lang_iso: storeList.params.lang_iso
            }));

            if(!zip) {

                let tempZip = storesArray[0].location.zip;

                if(window.BATO.CONFIG.country == 'US') {
                    tempZip = tempZip.split('-')[0];
                }

                location['zip'] = tempZip;

            }

            store.dispatch(locationActions.setLocation(location));
    
            const updatedProfile = await api.updateLocationAndStore(location, myStore, profileId);
  
            if (productID) {
                window.dispatchEvent(new CustomEvent('updateStoresForProduct', { detail: { productID } }));
            }
    
            return { success: true, data: updatedProfile }

          } else {
            return { success: true, data: storeList };
          }
        } else {

          if (document.querySelector('.tire-decision-guide') !== null && sessionStorage.getItem('tdg_params')) {
            let locationWrapper = document.querySelector('.location-search-tdg');
            let noResults = locationWrapper.querySelector('.no-results');
            let invalid = locationWrapper.querySelector('.invalid');
            if (storeList.messages?.error !== "" || noResults.classList.contains("show-error") || invalid.classList.contains("show-error")) {
              document.querySelector('.nextBtn').setAttribute("disabled", true);
              const tdgSearchButton = document.querySelector('.location-search-tdg .button');
              tdgSearchButton.classList.remove('location-loading');
              invalid.classList.add('show-error');
            } else {
              document.querySelector('.nextBtn').removeAttribute("disabled");
              invalid.classList.remove('show-error');
            }
          }

            return { success: true, data: storeList }

        }

      } catch (err) {

          console.log(`error in setLocationAndStore(): ${err}`);
          return {message: err, success: false}

      }
    },
    setStoresByCityState: async(city, state) => {
        try {

            const storeListByCityState = await storesApi.getStoresByCityState({
                city, state
            });

            if (storeListByCityState.data?.stores) {

                let storeListByCityStateArray = Object.keys(storeListByCityState.data.stores).map(i => storeListByCityState.data.stores[i]);

                storeListByCityStateArray.sort(function (a, b) {
                    return a.location.distance.localeCompare(b.location.distance);
                });

                return {success: true, storeListByCityState};

            } else {

                return {success: true, data: false};

            }

        } catch (err) {}

    },
    setViewedTires: async (newTire) => {
        try {
            let recentlyViewedTires = store.getState().flex.previouslyViewedTires;

            recentlyViewedTires = [newTire, ...recentlyViewedTires];

            recentlyViewedTires.length > 5 ? recentlyViewedTires.pop() : '';

            d(flexActions.setViewedTires(recentlyViewedTires));

            return { success: true, data: recentlyViewedTires };

        } catch (err) {}
    },
    setMatchedSizes: async (matchedSizes) => {
        try {
            let recentlyMatchedSizes = store.getState().flex.previouslyMatchedSizes;

            recentlyMatchedSizes = [matchedSizes, ...recentlyMatchedSizes];

            recentlyMatchedSizes.length > 5 ? recentlyMatchedSizes.pop() : '';

            d(flexActions.setMatchedSizes(recentlyMatchedSizes));

            return { success: true, data: recentlyMatchedSizes };

        } catch (err) {}
    },
    setI18n: (locale) => {
      
      if(locale === 'es_MX' || locale === 'es_CR' || locale === 'es_CO') {
        d(i18n.seti18n(es_MX));
      } else if(locale === 'es_AR') {
        d(i18n.seti18n(es_AR));
      } else if(locale === 'es_CL'){
        d(i18n.seti18n(es_CL));
      } else if(locale === 'pt_BR'){
        d(i18n.seti18n(pt_BR)) 
      } else {
        // English default
        d(i18n.seti18n(en_US));
      }

    }
  };

export default profileApi;

window.BATO.ProfileActions = profileApi;