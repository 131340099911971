import "../scss/simple-list.scss";

document.addEventListener("DOMContentLoaded", function () {

    const toggleContent = (e) => {
        const thisContent = e.target.closest(".list-heading");
        const toggleBtn = e.target.tagName === "path" ? e.target.parentElement : e.target;

        toggleBtn.classList.toggle("open");
        thisContent.nextElementSibling.classList.toggle("active");
    }

    const simpleLists = document.querySelectorAll(".simple-list");

    if(simpleLists.length > 0) {

        simpleLists.forEach(simpleList => {

            if(simpleList.querySelector('.toggle-content')) {

                let toggleContentBtn = simpleList.querySelector(".expand");
                toggleContentBtn.addEventListener("click", toggleContent);

            }

        });
    }
});
  