if (!window.BATO) window.BATO = {};
if (!window.BATO.ANALYTICS) window.BATO.ANALYTICS = {};

const tireDetailDataObject = {
	"article_id":"",
	"product_id":"",
	"product_brand":"", 
	"product_subbrand":"", 
	"product_name":"",
	"product_category":"",
	"product_season":"",
	"product_price":"", 
	"product_size":""
}

export const init = () => {

	if(document.querySelector('.tire-detail')) {

		window.BATO.ANALYTICS.page.page_type = "pdp";

		let tireDetailEl = document.querySelector("[data-product-id]");
		let tireData = (tireDetailEl != null) ? tireDetailEl.dataset : null;

		const setTireDetailData = () => {

			tireDetailDataObject.product_id = tireData.productId;
			tireDetailDataObject.product_brand = tireData.productBrand;
			tireDetailDataObject.product_subbrand = tireData.productSubbrand;
			tireDetailDataObject.product_name = tireData.productName;
			tireDetailDataObject.product_category = tireData.productCategory;
			tireDetailDataObject.product_season = tireData.productSeason;

			let currentURL = window.location.href,
		        urlSplit = currentURL.split("/");

		    // Make Sure We Are On the Tire Detail Page
		    if ( urlSplit[3] == 'tire' ) {

				let tireSizeDropdown = document.querySelector('.tire-size-dropdown'),
					tireSelectedIndex = "",
					tireSize = urlSplit[5];

				tireSize ? tireSelectedIndex = tireSizeDropdown.querySelector('option[value="' + tireSize.replace('-','/') + '"]') :
						   tireSelectedIndex = tireSizeDropdown.selectedIndex;

				tireDetailDataObject.product_size = tireSizeDropdown.options[tireSelectedIndex.index].dataset.size;
				tireDetailDataObject.product_price = tireSizeDropdown.options[tireSelectedIndex.index].dataset.msrp;
				tireDetailDataObject.article_id = tireSizeDropdown.options[tireSelectedIndex.index].dataset.articlenumber;

			}

			if(!window.BATO.ANALYTICS.hasOwnProperty('products')) {
				window.BATO.ANALYTICS.products = []
			}

			window.BATO.ANALYTICS.products.push(tireDetailDataObject);

		}


		setTireDetailData();


	}
}

export default {
	init
}