
if (!window.BATO) window.BATO = {};
if (!window.BATO.ANALYTICS) window.BATO.ANALYTICS = {};

/*
	- inject search details from page.
	- clean up data
		- no null values
		- no empty values
*/

const productDataObject = {
	"article_id": "",
	"product_id": "",
	"product_name": "",
	"brand": "",
	"subBrand": "",
	"category": "",
	"season": "",
	"size": ""
}

const productDataArray = []

export const init = () => {

	let tireSearchEl = document.querySelector(".tire-search-results");

	if (tireSearchEl) {

		window.BATO.ANALYTICS.page.page_type = "catalog";

		let tireFilter = (tireSearchEl != null) ? tireSearchEl.querySelector(".tire-filter") : null;
		let tireCards = (tireSearchEl != null) ? tireSearchEl.querySelectorAll(".cmp-tire-card") : null;
		let sortBy = (tireSearchEl != null) ? tireSearchEl.querySelector(".tire-filter-sort b").innerText.toLocaleLowerCase() : "";

		if(tireFilter.dataset.success == 'true') {

			if(tireCards.length > 0) {

				tireCards.forEach(tireCard => {

					let tireCardData = tireCard.dataset;
					let tireCardObject = {...productDataObject};
					let tireCardHeading = tireCard.querySelector("h3");

					tireCardObject.article_id = tireCardData.articlenumber;
					tireCardObject.product_id = tireCardData.productid;
					tireCardObject.product_name = tireCardHeading.innerText;
					tireCardObject.brand = tireCardData.productBrand;
					tireCardObject.subBrand = tireCardData.productSubbrandName;
					tireCardObject.category = tireCardData.productCategory;
					tireCardObject.season = tireCardData.productSeason;
					tireCardObject.size = tireCardData.size;

					productDataArray.push(tireCardObject);
				});

				window.BATO.ANALYTICS.products = productDataArray;
				window.BATO.ANALYTICS.number_of_results = productDataArray.length;
				window.BATO.ANALYTICS.sorting = sortBy;

			} else {

				window.BATO.ANALYTICS.number_of_results = "0";

			}

			if(tireFilter.dataset?.width) {

				window.BATO.ANALYTICS.search_type = "size";
				window.BATO.ANALYTICS.size = {};
				window.BATO.ANALYTICS.size.width = tireFilter.dataset.width;
				window.BATO.ANALYTICS.size.diameter = tireFilter.dataset.diameter;
				window.BATO.ANALYTICS.size.ratio = tireFilter.dataset.ratio;

				window.BATO.ANALYTICS.result_type = "Size: Success";

			} else {

				window.BATO.ANALYTICS.search_type = "vehicle";

				window.BATO.ANALYTICS.vehicle = {};

				let ymmString = tireFilter.dataset.ymm;
				let ymmt = ymmString.split('-');

				window.BATO.ANALYTICS.vehicle.year = ymmt[0];
				window.BATO.ANALYTICS.vehicle.make = ymmt[1];
				window.BATO.ANALYTICS.vehicle.model = ymmt[2];
				window.BATO.ANALYTICS.vehicle.trim = tireFilter.dataset.trim;

				window.BATO.ANALYTICS.result_type = "Vehicle: Success";

			}

		} else {

			if(tireFilter.dataset?.width) {
				window.BATO.ANALYTICS.result_type = "Size: Invalid";
			} else {
				window.BATO.ANALYTICS.result_type = "Vehicle: Invalid";
			}

		}
	}
}

export default {
	init
}