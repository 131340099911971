const userDataObject = {
	"store": {
		"default": "",
		"preferred": "",
		"store_id": "", 
		"name": "",
		"city": "",
		"state": "", 
		"brand": "" 
	}, 
	"vehicle": {
		"year":"",
		"make":"",
		"model":"",
		"trim":""
	},
	"zip":""
}

export const init = () => {
	let storeState = window.BATO?.Store?.getState(); 
	let vehicleState = storeState.vehicles; 
	let locationState = storeState.location; 
	let storesState = storeState.stores;
	
	let vehicle = vehicleState.vehicle; 

	userDataObject.vehicle.year = vehicle.year; 
	userDataObject.vehicle.make = vehicle.make; 
	userDataObject.vehicle.model = vehicle.model;
	userDataObject.vehicle.trim = vehicle.trim;
	userDataObject.zip = locationState.data.zip;

	window.BATO.ANALYTICS.user = userDataObject; 	
}

export default {
	init
}