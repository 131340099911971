import '../scss/tire-list.scss';

// setup window object for components to consume
if (!window.BATO) window.BATO = {};

const tireListClass = 'tire-list';
const tireSlidesClass = 'cmp-carousel__item';
const tireSlidesWrapperClass = 'swiper-wrapper'; 
let vehicleSelector, tireListCards;  

const init = () => {	
	tireListCards = document.querySelectorAll(`.${tireListClass} .${tireSlidesClass}`);  

	if (tireListCards) {
		window.BATO.tireList = {}; 
		window.BATO.tireList.sort = sort;		
	} 
	
}

/*
	sort cards from fit to not fit. 
*/
const sort = () => {
	tireListCards.forEach(card => {
		let fit = card.querySelector(".will-it-fit--fit") ? true : false; 
		let wrapper = card.closest(`.${tireSlidesWrapperClass}`); 
		
		if (!fit) {
			wrapper.appendChild(card); 
		}
	}); 
}

document.addEventListener("DOMContentLoaded", () => {
	init(); 
})