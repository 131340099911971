import { merge } from 'lodash-es';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  status: 'unknown',
  data: {
    vid: null,
    site: null,
    userId: null,
    language: null
  }
};

export const user = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    clearUser: (state) => {
      return initialState;
    },
    setUser: (state, action) => {
      return merge(
        state,
        {
          status: 'known',
          data: action.payload
        }
      )
    },
  }
});

const actions = user.actions;

export { actions };

if (!window.BATO) {
  window.BATO = {}
}

if (!window.BATO.StoreActions) {
  window.BATO.StoreActions = {}
}

window.BATO.StoreActions.user = actions;

export default user.reducer;