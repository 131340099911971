import '../scss/vehicle-selector-size.scss';
import { removeChildren } from '../../../../../js/library/utilsForFrontend.js';
import { urlLanguageCheck } from '../../../../../js/utils/urlLanguageCheck.js';

// setup window object for components to consume
if (!window.BATO) window.BATO = {};

const tireSelectorSizeClass = 'vehicle-selector-size';
const tireService = window.BATO.Services.tireSizes;
const tireSize = { width: '', ratio: '', diameter: '' };
let tdgExists = document.querySelector('.tire-decision-guide') !== null;

let languageInUrl = urlLanguageCheck();
const vehicleSelectorSizeResultsPath = (languageInUrl) ? '/' + languageInUrl + '/catalog/size' : '/catalog/size'; // TODO: this should be read from button clicked to show modal

let vehicleSelectorSize, selectWidth, selectRatio, selectDiameter, selectedOptions, selectLists, mobileLayout;

const init = () => {
	vehicleSelectorSize = document.querySelector(`.${tireSelectorSizeClass}`);
	let tiresBySize = window.BATO.Store.getState().tiresBySize.tireBySize.front;

	reportWindowSize();

	if (vehicleSelectorSize) {
		selectWidth = vehicleSelectorSize.querySelector('li.width');
		selectRatio = vehicleSelectorSize.querySelector('li.ratio');
		selectDiameter = vehicleSelectorSize.querySelector('li.diameter');
		selectedOptions = vehicleSelectorSize.querySelector('.vehicle-selector-size__display');
		selectLists = [selectWidth,selectRatio,selectDiameter];

		window.BATO.vehicleSelectorSize = vehicleSelectorSize;
		window.BATO.vehicleSelectorSizeResultsPath = vehicleSelectorSizeResultsPath;

		getWidths()
			.then(data => setWidths(data))
			.catch(err => console.error("Error:", err));
		selectWidth.classList.add('active'); //make active by default for mobile
		window.BATO.vehicleSelectorSize.init = init;
		if (tdgExists) {
			tireSize.width = tiresBySize.cs;
			tireSize.ratio = tiresBySize.ar;
			tireSize.diameter = tiresBySize.rs;
    }

	}
}

const getWidths = async () => {
    try {
        const response = await tireService.getWidths();
        return response.data;
    } catch (error) {
        console.error("there's a problem getting widths:", error);
    }
};

const getRatios = (width) => {
	const getRatios = tireService.getRatios(width);

	getRatios
		.then(response => {
			setRatios(response.data);
		})
		.catch(error => {
			const err = `there's a problem with vehicle-selector-size getRatios': ${error}`;
		    throw new Error(err);
		})
}

const getDiameters = (width, ratio) => {
	const getDiameters = tireService.getDiameters(width, ratio);

	getDiameters
		.then(response => {
			setDiameters(response.data);
		})
		.catch(error => {
			const err = `there's a problem with vehicle-selector-size getDiameters': ${error}`;
		    throw new Error(err);
		})
}



const setWidths = (data) => {
	if (data && data.widths && data.widths.length > 0) {
		const selectOptions = vehicleSelectorSize.querySelector('li.width').lastElementChild;
		removeChildren(selectOptions);
		let widthOptionsList = selectWidth.querySelector('.select__options');

		const keypressHandler = (evt) => {
			if(evt.keyCode==13){
				widthSelected(evt);
			};
		}

		const clickHandler = (evt) => {
				widthSelected(evt);
		}

		data.widths.forEach(w => {
			const span = document.createElement('span');

			span.classList.add('option');
			span.setAttribute("tabindex", "1");
			span.dataset.value = span.innerText = w;

			span.addEventListener("click", clickHandler);
			span.addEventListener("keypress", keypressHandler);

			selectWidth.lastElementChild.appendChild(span);
		});

		const widthSelected = (evt) => {
			removeChildren(selectRatio.lastElementChild);
			removeChildren(selectDiameter.lastElementChild);

			tireSize.width = evt.target.dataset.value;

			setDisplay();

			if((widthOptionsList.classList.contains("minimize-options")) && (mobileLayout)){ //is list collapsed?
				configureCollapsedList(selectWidth,widthOptions,widthOptionsList);
			} else { //list NOT collapsed
				if (!mobileLayout){configureCollapsedList(selectWidth,widthOptions,widthOptionsList)};
				hideSpanItems(widthOptions);  // add hide class to all span items
				evt.currentTarget.classList.add('active'); //add active to span item
				evt.currentTarget.classList.remove('hide'); //remove hide to span item
				selectRatio.classList.add('active'); //add active to NEXT <li>
				widthOptionsList.classList.toggle("minimize-options");

				getRatios(tireSize.width);
			}
		}

		//setup widths for initial visit
		resetActiveLists(selectWidth); //remove active from all parent <li>'s except item
		minimizeAllLists();
		widthOptionsList.classList.remove("minimize-options");
		let widthOptions = selectWidth.querySelectorAll('.option');
		showSpanItems(widthOptions); //make all spans visible
		if(sessionStorage.getItem("2")){
			let widthEl = selectWidth.querySelector('span[data-value="' + JSON.parse(sessionStorage.getItem("2"))[0] + '"]');
			hideSpanItems(widthOptions);
			widthEl.classList.remove('hide');
			widthEl.classList.add('active');
			getRatios(JSON.parse(sessionStorage.getItem(2))[0]);
			setDisplay();
		}
	}
};

const setRatios = (data) => {
	const ratios = data && data.aspectRatios;
	const selectOptions = vehicleSelectorSize.querySelector('li.ratio').lastElementChild;
	removeChildren(selectOptions);
	let ratioOptionsList = selectRatio.querySelector('.select__options');

	if (ratios.length) {
		const keypressHandler = (evt) => {
			if(evt.keyCode==13){
				ratioSelected(evt);
			};
		}

		const clickHandler = (evt) => {
				ratioSelected(evt);
		}

		ratios.forEach(r => {
			const span = document.createElement('span');

			span.classList.add('option');
			span.setAttribute("tabindex", "1");
			span.dataset.value = span.innerText = r;

			span.addEventListener("click", clickHandler);
			span.addEventListener("keypress", keypressHandler);

			selectRatio.lastElementChild.appendChild(span);
		});

		const ratioSelected = (evt) => {
			removeChildren(selectDiameter.lastElementChild);
			tireSize.ratio = evt.target.dataset.value;

			setDisplay();

			if((ratioOptionsList.classList.contains("minimize-options")) && (mobileLayout)){
				configureCollapsedList(selectRatio,ratioOptions,ratioOptionsList);
			} else {
				if (!mobileLayout){configureCollapsedList(selectRatio,ratioOptions,ratioOptionsList)}; //NEW
				hideSpanItems(ratioOptions);
				evt.currentTarget.classList.add('active');
				evt.currentTarget.classList.remove('hide');
				selectRatio.classList.add('active');
				ratioOptionsList.classList.toggle("minimize-options");

				getDiameters(tireSize.width, tireSize.ratio);
			}
		}

		resetActiveLists(selectRatio);
		minimizeAllLists();
		ratioOptionsList.classList.remove("minimize-options");
		let ratioOptions = selectRatio.querySelectorAll('.option');
		showSpanItems(ratioOptions);
		selectRatio.setAttribute("tabindex","1");
		selectRatio.focus();
		let ratioEl = selectRatio.querySelector('span[data-value="' + JSON.parse(sessionStorage.getItem("2"))[1] + '"]');
		ratioEl.classList.remove('hide');
		ratioEl.classList.add('active');
		getDiameters(JSON.parse(sessionStorage.getItem(2))[0], JSON.parse(sessionStorage.getItem(2))[1]);

	}
};

const setDiameters = (data) => {
	const diameters = data && data.rimSizes;
	const selectOptions = vehicleSelectorSize.querySelector('li.diameter').lastElementChild;
	removeChildren(selectOptions);
	let diameterOptionsList = selectDiameter.querySelector('.select__options');
	if (diameters.length) {

		const keypressHandler = (evt) => {
			if(evt.keyCode==13){
				diameterSelected(evt);
			};
		}

		const clickHandler = (evt) => {
				diameterSelected(evt);
		}

		diameters.forEach(d => {
			const span = document.createElement('span');

			span.classList.add('option');
			span.setAttribute("tabindex", "1");
			span.dataset.value = span.innerText = d;

			span.addEventListener("click", clickHandler);
			span.addEventListener("keypress", keypressHandler);

			selectOptions.appendChild(span);
		});

		const diameterSelected = (evt) => {
			tireSize.diameter = evt.target.dataset.value;

			setDisplay();

			if((diameterOptionsList.classList.contains("minimize-options")) && (mobileLayout)){
				configureCollapsedList(selectDiameter,diameterOptions,diameterOptionsList);
			} else {
				if (!mobileLayout){configureCollapsedList(selectDiameter,diameterOptions,diameterOptionsList)};
				hideSpanItems(diameterOptions);
				evt.currentTarget.classList.add('active');
				evt.currentTarget.classList.remove('hide');
				selectDiameter.classList.add('active');
				diameterOptionsList.classList.toggle("minimize-options");
				let _tsize = {
					front: {
						cs:tireSize.width,
						ar:tireSize.ratio,
						rs:tireSize.diameter,
						tireSize: `${tireSize.width}/${tireSize.ratio}/${tireSize.diameter}`
					},
					rear: null
				}
				window.BATO.ProfileActions.setTiresBySize(_tsize).then(response => {
					if (!window.BATO.vehicleSelector.redirect) {
						if (window.BATO.vehicleSelector.callback) {
							//TODO: trigger callback if one is set

							//window.BATO.vehicleSelector.callback.sizeSet();
						}
						window.BATO.modal.deactivateModal(vehicleSelectorSize);
					}

					let redirectPath = window.BATO.vehicleSelectorSizeResultsPath + '/' + tireSize.width + '-' + tireSize.ratio + 'R' + tireSize.diameter + '/';
					
					if(!window.BATO.vehicleSelector.disableRedirect && !tdgExists) {
						window.location.href = redirectPath;
					}
					
				});
			};
		}

		resetActiveLists(selectDiameter);
		minimizeAllLists();
		diameterOptionsList.classList.remove("minimize-options");
		let diameterOptions = selectDiameter.querySelectorAll('.option');
		showSpanItems(diameterOptions);
		selectDiameter.setAttribute("tabindex","1")
		selectDiameter.focus();
		let diameterEl = selectDiameter.querySelector('span[data-value="' + JSON.parse(sessionStorage.getItem("2"))[2] + '"]');
		diameterEl.classList.remove('hide');
		diameterEl.classList.add('active');
		sessionStorage.setItem("searchByTireSize", "true");
	}
};

const configureCollapsedList = (select,options,itemList) => {
	resetActiveLists(select);
	removeActive(options);
	showSpanItems(options);
	minimizeAllLists();
	itemList.classList.toggle("minimize-options");
}

//remove active to all parent <li>'s, except object
const resetActiveLists = (item) => {
	selectLists.forEach(y => {
		y.classList.remove('active');
	});
	item.classList.add('active');
}

const minimizeAllLists = () => {
	selectLists.forEach(y => {
		let selectList = y.querySelector('.select__options');
		selectList.classList.add("minimize-options");
	});
}

//make all spans visible for object
const showSpanItems = (what) => {
	what.forEach(y => {
		y.classList.remove('hide');
	});
}

//hide each span item
const hideSpanItems = (what) => {
	what.forEach(y => {
		y.classList.add('hide');
	});
}



//reset all spans for object so none is active
const removeActive = (what) => {
	what.forEach(y => {
		y.classList.remove('active');
	});
}

const setDisplay = () => {
	selectedOptions.innerHTML = "";
	for (const prop in tireSize) {
		let _s = tireSize[prop];
		if (_s) {
			_s += " ";
			selectedOptions.innerHTML += _s;
		}
	}
}

document.addEventListener("DOMContentLoaded", () => {
	init();
})

const reportWindowSize = () => {
	mobileLayout = (document.body.clientWidth < 1024) ? (1) : (0);
}

window.onresize = reportWindowSize;