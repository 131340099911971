import '../scss/social-share.scss';

let shareFacebook = document.querySelector('.share-facebook'),
    shareTwitter = document.querySelector('.share-twitter'),
    sharePrint = document.querySelector('.share-print'),
    shareEmail = document.querySelector('.share-email'),
    shareSMS = document.querySelector('.share-sms'),
    thisURL = window.location.href;

if(shareFacebook) {
    shareFacebook.addEventListener('click', function(){

        let facebookURL = 'http://www.facebook.com/sharer/sharer.php?u=' + thisURL;
        openWindow(facebookURL);

    });
}

if(shareTwitter) {
    shareTwitter.addEventListener('click', function(){

        let tweet = "",
            twitterDescrip = document.querySelector('meta[name="twitter:description"]'),
            twitterTitle = document.querySelector('meta[name="twitter:title"]');

        twitterDescrip ? tweet = twitterDescrip.getAttribute('content')     // Use Twitter Description First
             : twitterTitle ? tweet = twitterTitle.getAttribute('content')  // Use Twitter Title Second
             : tweet = document.title + " " + thisURL;                      // Fallback for no Twitter Meta Tags

        let twitterURL = 'https://twitter.com/intent/tweet?text=' + encodeURIComponent(tweet);
        openWindow(twitterURL);

    });
}

if(sharePrint) {
    sharePrint.addEventListener('click', function(){

        window.print();

    });
}

if(shareSMS) {
    shareSMS.addEventListener('click', function(){

        document.querySelector('.cmp-share-modal.sms').classList.add('active');

        let shareSMSClose = document.querySelector('.modal-close.sms');

        shareSMSClose.addEventListener('click', function(){
            document.querySelector('.cmp-share-modal.sms').classList.remove('active');
        });

    });
}

if(shareEmail) {
    shareEmail.addEventListener('click', function(){

        document.querySelector('.cmp-share-modal.email').classList.add('active');

        let shareEmailClose = document.querySelector('.modal-close.email');

        shareEmailClose.addEventListener('click', function(){
            document.querySelector('.cmp-share-modal.email').classList.remove('active');
        });

    });
}

function openWindow(url){

    let windowWidth = (window.innerWidth / 2) - 275,
        windowHeight = (window.innerHeight / 2) - 225,
        windowFeatures = "width=550,height=450,left=" + windowWidth + ",top=" + windowHeight;

    window.open(url, 'window', windowFeatures);

}
