import elementListener from '../../../../../js/library/eventHelpers.js';

// setup window object for components to consume
if (!window.BATO) window.BATO = {};
if (!window.BATO.tireDetails) window.BATO.tireDetails = {};

const vehicleAnalyticsObject = {
	"event":"tdp.interaction",
	"interaction_category": "tirefit",
	"interaction_value":"select vehicle"
};

const init = () => {

	let changeVehicle = document.querySelector('.tire-detail__vehicle-info a[href^="#vehicle-selector"]');

	window.BATO.Store.observeStore(window.BATO.Store, function(state) {
		return state.flex.previouslyViewedTires[0];
	}, willItFitChange);

	if(changeVehicle) changeVehicle.addEventListener("click", vehicleTireChangeEventHandler);

}

const vehicleTireChangeEventHandler = (evt) => {
	let analyticsObject = JSON.parse(JSON.stringify(vehicleAnalyticsObject));
	let vehicleProfile = window.BATO?.Store?.getState()?.vehicles;

	if (vehicleProfile && vehicleProfile.status == "known") {
		analyticsObject.interaction_value = "change vehicle";
	}

	adobeDataLayer.push(analyticsObject);
}

const willItFitChange = () => {
	//console.log('window.BATO.Store.getState().flex.previouslyViewedTires',window.BATO.Store.getState().flex.previouslyViewedTires);
	//console.log("willItFitChange: " + window.BATO.Store.getState().flex.previouslyViewedTires[0]?.articlenumber + " / " + window.BATO.Store.getState().flex.previouslyViewedTires[1]?.articlenumber);

	// willItFitProgrammatically is to ignore first fire of Will-It-Fit on Page Load
	// Analytics only wants to register Will-It-Fit from BOSS interaction
	if ((window.BATO.Store.getState().flex.previouslyViewedTires[0]?.articlenumber !==
		 window.BATO.Store.getState().flex.previouslyViewedTires[1]?.articlenumber) &&
		 window.BATO.Store.getState().flex.previouslyViewedTires[0]?.articlenumber !== undefined &&
	 	 window.BATO.tireDetails.willItFitProgrammatically != true) {

		let willItFit = document.querySelector(".tire-detail .will-it-fit, .tire-last-viewed .will-it-fit");

		(async () => {
			while(!willItFitEvent)
				await new Promise(resolve => setTimeout(resolve, 250));

			//console.log('willItFitChange');

			let willItFitClasslist;

			(async () => {

				willItFitClasslist = willItFit.classList;

				willItFit.addEventListener("will-it-fit", e => willItFitClasslist = e.target.classList);

				//console.log('willItFitClasslist 2',willItFitClasslist);

				while(typeof willItFitClasslist === 'undefined' && !willItFitClasslist)
					await new Promise(resolve => setTimeout(resolve, 250));

				//console.log('willItFitClasslist 3',willItFitClasslist);

				let analyticsObject = JSON.parse(JSON.stringify(vehicleAnalyticsObject));

				analyticsObject.interaction_value = "tirefit results";
				analyticsObject.tirefit_results = "not sure tire fits";

				if (willItFitClasslist.contains("will-it-fit--notfit")) {
					analyticsObject.tirefit_results = "tire does not fit";
				} else if (willItFitClasslist.contains("will-it-fit--fit")){
					analyticsObject.tirefit_results = "tire does fit";
				}

				analyticsObject.event = "will-it-fit-response";

				adobeDataLayer.push(analyticsObject);

			})();

		});
	}
};

export default {
	init
}