import { merge } from 'lodash-es';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  profileId: null,
  site: null,
  lastUpdate: null,
  nextUpdate: null,
  uiVersion: null,
  lang_iso: null,
  country: null,
  region: null
};

export const meta = createSlice({
  name: 'profileMeta',
  initialState: initialState,
  reducers: {
    clearProfileMeta: (state) => {
      return initialState;
    },
    setProfileMeta: (state, action) => {
      return merge(
        state,
        action.payload
      )
    },
  }
});

const actions = meta.actions;

export { actions };

if (!window.BATO) {
  window.BATO = {}
}

if (!window.BATO.StoreActions) {
  window.BATO.StoreActions = {}
}

window.BATO.StoreActions.meta = actions;

export default meta.reducer;