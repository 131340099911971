import '../scss/tire-card.scss';

import { cardClickHandler } from '../../../../../js/utils/cardClickHandler.js';

document.addEventListener("DOMContentLoaded", () => {

    // For dealing with Recommended Tire Cards referencing Tires that have been removed
    // If productID is missing (ie the tire has been discontinued from production and removed from our data)
    // we write a DIV element to page with class 'cmp-tire-card-removed'
    // this script then removes that named element
    let allRemovedTiredCards = document.querySelectorAll('.cmp-tire-card-removed');

    if(allRemovedTiredCards.length) {

        allRemovedTiredCards.forEach(function(allRemovedTiredCard) {

            let xF = allRemovedTiredCard.closest('.experiencefragment');
            xF.remove();

        });

    }

    cardClickHandler('.cmp-tire-card');

});