import elementListener from '../../../../../js/library/eventHelpers.js';

// setup window object for components to consume
if (!window.BATO) window.BATO = {};

const tireSearchResults = document.querySelector(".tire-search-results");
const sortByContainer = (tireSearchResults != null) ? tireSearchResults.querySelector(".tire-filter-top") : null;
const filterContainer = (tireSearchResults != null) ? tireSearchResults.querySelector(".tire-filter") : null;

const tireDetailsAnalyticsObject = {
	"event": "tire.results.sorting",
	"data": {
		"filter_criteria": [{
			"tire_size": "",
			"price_min": "",
			"price_max": "",
			"average_rating": "",
			"mileage_warranty": "",
			"brands": "",
			"type": "",
			"marketing_categories": "",
			"best_for": ""
		}],
		"sorting": ""
	}
};

export const init = () => {
	if (sortByContainer != null && filterContainer != null) {
		elementListener.addBubblerListener(sortByContainer, 'click', 'li', tireSortEventHandler);
	}
}

const tireSortEventHandler = (evt) => {
	let el = evt.target;
	let analyticsObject = JSON.parse(JSON.stringify(tireDetailsAnalyticsObject));

	let sortByInput = el.innerHTML;
	analyticsObject.data.sorting = sortByInput;

	// Tire Size
	let tireSizeWrapper = filterContainer.querySelector(".filter-tire-size ul");
	let allTireSizes = tireSizeWrapper.querySelectorAll("input");

	allTireSizes.forEach(function(size) {

		if(size.checked){
			analyticsObject.data.filter_criteria[0].tire_size = size.value;
		}

	});

	// Price Range
	let tirePriceWrapper = filterContainer.querySelector(".filter-price");
	let priceRanges = tirePriceWrapper.querySelectorAll("input");

	analyticsObject.data.filter_criteria[0].price_min = priceRanges[0].value;
	analyticsObject.data.filter_criteria[0].price_max = priceRanges[1].value;

	// Star Rating
	let starRatingWrapper = filterContainer.querySelector(".filter-rating ul");
	let allStarRatings = starRatingWrapper.querySelectorAll("input");

	allStarRatings.forEach(function(rating) {

		if(rating.checked){
			analyticsObject.data.filter_criteria[0].average_rating = rating.value;
		}

	});

	// Mileage Warranty
	let warrantyWrapper = filterContainer.querySelector(".filter-warranty ul");
	let allWarranties = warrantyWrapper.querySelectorAll("input");

	allWarranties.forEach(function(warranty) {

		if(warranty.checked){
			analyticsObject.data.filter_criteria[0].mileage_warranty = warranty.value;
		}

	});

	// Tire Brand
	let tireBrandWrapper = filterContainer.querySelector(".filter-brand ul");
	let allBrands = tireBrandWrapper.querySelectorAll("input");
	let brandArray = [];

	allBrands.forEach(function(brand) {

		if(brand.checked){
			brandArray.push(brand.value)
		}

	});

	analyticsObject.data.filter_criteria[0].brands = brandArray;

	// Tire Type
	let tireTypeWrapper = filterContainer.querySelector(".filter-type ul");

	if (tireTypeWrapper) {
		let allTypes = tireTypeWrapper.querySelectorAll("input");
		let typeArray = [];

		allTypes.forEach(function(type) {

			if(type.checked){
				typeArray.push(type.value)
			}

		});

		analyticsObject.data.filter_criteria[0].type = typeArray;

	}

	// Marketing Categories
	let categoryWrapper = filterContainer.querySelector(".filter-category ul");

	if (categoryWrapper) {
		let allCategories = categoryWrapper.querySelectorAll("input");
		let categoryArray = [];

		allCategories.forEach(function(category) {

			if(category.checked){
				categoryArray.push(category.value)
			}

		});

		analyticsObject.data.filter_criteria[0].marketing_categories = categoryArray;

	}

	// Best For
	let bestForWrapper = filterContainer.querySelector(".filter-best-for ul");

	if (bestForWrapper) {
		let bestFors = bestForWrapper.querySelectorAll("input");
		let bestForArray = [];

		bestFors.forEach(function(bestFor) {

			if(bestFor.checked){
				bestForArray.push(bestFor.value)
			}

		});

		analyticsObject.data.filter_criteria[0].best_for = bestForArray;
	}

	adobeDataLayer.push(analyticsObject);
}

export default {
	init
}