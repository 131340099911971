/**
 * Set Our Custom Error Messages for Form Elements
 * @param  {HTMLElement}   el    The Form Element, Any valid Form Element (input, select, etc)
 * @returns {HTMLElement}  el	 Returns updated HTML Element
*/

const validateFields = (el) => {

	const regexPostal = new RegExp(window.BATO.COMPONENTS.StoreLocator.v1.postalRegex);
	const regexEmail = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})+$/;
	const regexPhone = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/; // Redundant As We Validate with Inline Formatting
	const regexName = /^[a-zA-Z-\s]+$/;
	const regexAddress = /^([A-Za-z0-9-_\.#\s]+)?$/;
	const regexNumber = /^\d+$/;
    const regexTireId = /^[a-zA-Z0-9]*$/;
	const regexTireQTY = /^[1-9]\d{0,1}$/;

	const elName = el.name.toLowerCase();
	const elValue = el.value;
	const elType = el.type;
	const validationState = el.validity;

    // Remove All WhiteSpace And Check That There is Still Some Value
	// checkValidity() native function does not account for this state
	const elValueTemp = (elValue == '') ? 'blank' : el.value.replaceAll(/\s/g, '');

    if (validationState.valueMissing || elValueTemp == '') {
		el.setCustomValidity(el.dataset.validateRequiredMessage);
	} else {
		el.setCustomValidity('');
	}

    if (elValue != '') {

        if ( (elName == 'zipcode' && !regexPostal.test(elValue)) ||
             (elName.includes('name') && !regexName.test(elValue)) ) {
            el.setCustomValidity(el.dataset.validateRequiredMessage);
    	}

        if (elName == 'emailid' && !regexEmail.test(elValue)) {
            el.setCustomValidity(el.dataset.validateTypeMessage);
    	}

        if (elName == 'confirmemailid'){
    		let equalTo = document.getElementById(el.dataset.validateEqualto);

    		if (!regexEmail.test(elValue)) {
    			el.setCustomValidity(el.dataset.validateTypeMessage);
    		}

    		if (elValue != equalTo.value) {
    			el.setCustomValidity(el.dataset.validateEqualtoMessage);
    		}
    	}

		if (elName.includes('phone')) {
		}

        if (elName.includes('phone') && !regexPhone.test(elValue)) {
            el.setCustomValidity(el.dataset.validateFormatMessage);
        }

        if (elName.includes('address') && !regexAddress.test(elValue)) {
            el.setCustomValidity(el.dataset.validateInvalidMessage);
        }


        if (elName.includes('dealerid')) {
			if ( !(regexNumber.test(elValue) && elValue.length == 3 ) &&
				 !(regexNumber.test(elValue) && elValue.length == 6 ) ) {
    			el.setCustomValidity(el.dataset.validateMinlengthMessage);
			}
    	}

    	if (elName.includes('tireid')) {
			if(!regexTireId.test(elValue)) {
				el.setCustomValidity(el.dataset.validateRequiredMessage);
			}

			if( elValue.length < 7 ) {
				el.setCustomValidity(el.dataset.validateMinlengthMessage);
			}

			if( elValue.length > 13 ) {
				el.setCustomValidity(el.dataset.validateMaxlengthMessage);
			}
		}

    	if (elName.includes('tireqty') && ( elValue < 1 || !regexTireQTY.test(elValue) )) {
 			el.setCustomValidity(el.dataset.validateMinMessage);
    	}

    }

    return el;

};

export { validateFields };
