import React, { Fragment, useEffect, useState } from 'react';
import { DynamicPortal } from '../../../react-shared/utils/DynamicPortal.jsx';

import { HeaderLocationApp } from './HeaderLocationApp.jsx';

const locationContainerApps = (appRoots) => {
    const portalArray = appRoots
      .map((root, i) => {
        const uniqueId = `${i}${new Date().getTime()}`;
        const portalId = root.id || 'dealerLocatorHeaderContainer';
        const locateMeText = root.dataset.locateMe;
        const dealersNearText = root.dataset.dealersNear;
        return (
          <Fragment key={uniqueId}>
            <DynamicPortal
              id={portalId}
              className="dealer-locator-container button button--secondary"
              dynamicParentElement={root}
              hideParent={true}
            >
              <HeaderLocationApp locateMe={locateMeText} dealersNear={dealersNearText} />
            </DynamicPortal>
          </Fragment>
        )
      })
      .filter(Boolean);

    return portalArray;
  };

const HeaderLocationLoader = () => {

  const locationContainers = Array.from(
    document.querySelectorAll('.dealer-locator-container')
  );

  const [roots, setRoots] = useState(locationContainers);
  const [portals, setPortals] = useState(locationContainerApps(roots));

  useEffect(() => {
    setPortals(locationContainerApps(roots));
  }, [roots]);

  return portals ? portals : null;

};

export { HeaderLocationLoader };