import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { DynamicPortal } from '../../../../react-shared/utils/DynamicPortal.jsx';
import { stringSanitizer } from '../../../../../../js/utils/stringSanitizer.js';
import { fetchRatings, useRatings } from './ratings-context.js';
import { RatingsApp } from './RatingsApp.jsx';
import { useEventListener } from '../../../../react-shared/utils/hooks/useEventListenerHook';

const ratings = (appRoots, mainConfig) => {

	const portalArray = appRoots.map((root, i) => {

			const type = root.dataset.type || 'ratings'; // rating vs review
			const productType = root.dataset.productType || 'tire'; // store vs tire
			const id = stringSanitizer(root.dataset.id, 'upper') || '';
			const tireBrand = root.dataset.tireBrand || '';
			const tireSubBrand = root.dataset.tireSubbrand || '';
			const tireName = root.dataset.tireName || '';
			const classes = root.dataset.class || '';
			const showRatingDetails = (root.dataset.showRatingDetails === "true") || false;
			const showWriteButton = (root.dataset.showWriteButton === "true") || false;
			const reviewLink = root.dataset.reviewLink ? `${root.dataset.reviewLink}#section-reviews` : '#section-reviews';

			const uniqueId = `${i}${new Date().getTime()}`;

			return (
				<Fragment key={`ratings-${uniqueId}`}>
					{createPortal(<RatingsApp
							classes={classes}
							id={id}
							meta={mainConfig}
							productType={productType}
							reviewLink={reviewLink}
							showRatingDetails={showRatingDetails}
							showWriteButton={showWriteButton}
							tireBrand={tireBrand}
							tireName={tireName}
							tireSubBrand={tireSubBrand}
						/>, root)}
					{/* leaving in to allow switching to a dynamic parent portal for BSRO when it comes time to implement
					 <DynamicPortal
						id={root.id}
						className="ratings"
						dynamicParentElement={root}
						hideParent={true}
					>
						<RatingsApp
							classes={classes}
							id={id}
							meta={mainConfig}
							productType={productType}
							reviewLink={reviewLink}
							showRatingDetails={showRatingDetails}
							showWriteButton={showWriteButton}
							tireBrand={tireBrand}
							tireName={tireName}
							tireSubBrand={tireSubBrand}
						/>
					</DynamicPortal> */}
				</Fragment>
			)
	})
	.filter(Boolean);

	return portalArray;
};

const RatingsLoader = ({ config }) => {

	if (!config) return null;

	const initialRatings = Array.from(
		document.querySelectorAll('.ratings-app')
	);

	// LATAM AR, CO, CR, CL share the same MX db
    const latamShared =  window.BATO?.CONFIG?.REVIEWS?.latamShared;
    const sharedLangIso = latamShared && latamShared.includes(config.lang_iso) ? 'es_MX' : config.lang_iso;

	const mainConfig = {
        apiKey: config.REVIEWS?.apiKey || '',
        canReview: config.REACT?.ratingsAndReviews?.writeReviews.find((lang) => lang === config.lang_iso) ? true : false,
        country: config.country || 'US',
        groupId: config.REVIEWS?.groupId || '',
        lang_iso: sharedLangIso || 'en_US',
        merchId: config.REVIEWS?.merchId || '1053509158',
        site: config.site || 'bst',
    }

	const [state, dispatch] = useRatings();
	const [roots, setRoots] = useState(initialRatings);
	const [portals, setPortals] = useState();

	useEffect(() => {
		let fetchIds = [];
		roots.forEach(root => fetchIds.push(root.dataset.id));
		let uniqueFetchIds = [...new Set(fetchIds)];
		if (uniqueFetchIds.length) {
			fetchRatings(dispatch, state, {
				ids: uniqueFetchIds,
				country: mainConfig.country,
				groupId: mainConfig.groupId,
				lang_iso: mainConfig.lang_iso,
				site: mainConfig.site
			});
		}
		setPortals(ratings(roots, mainConfig));
	},[roots]);

	useEffect(() => {
		if(state.ratings?.status == 'loaded') {
			window.dispatchEvent( new CustomEvent( 'collect-ratings-complete', { detail: { state: state } } ));
		}
	},[state]);

	const listenForNewRatings = useCallback(() => {
		let updatedRatings = Array.from(document.querySelectorAll('.ratings-app'));
		if(updatedRatings.length != roots.length) {
			setRoots(updatedRatings);
		}
	}, []);

	useEventListener('collect-ratings', listenForNewRatings)

	return portals ?
		<>{portals}</>
		: null;
};

export default RatingsLoader;

if (!window.BATO) {
  window.BATO = {}
}

if (!window.BATO.COMPONENTS) {
  window.BATO.COMPONENTS = {}
}

window.BATO.COMPONENTS.RatingsLoader = { v1: RatingsLoader };