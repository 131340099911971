import overlay from '../../../../../js/library/overlay.js';
import '../scss/header.scss';

const addOverlayClickEvent = (overlayClass, checkbox) => {
  const overlayEl = document.querySelector(`.${overlayClass}`);
  overlayEl.addEventListener('click', (e) => {
    checkbox.checked = false;
    const evt = new Event('change');
    checkbox.dispatchEvent(evt);
  });
};

const setMenuTabIndices = () => {
  const menuItems = document.querySelectorAll('.main-nav-list > li');
  menuItems.forEach((item, idx) => {
    item.tabIndex = 0; //idx + 2;
  });
};

const mobileMenuActivation = () => {
  const menuCheckbox = document.querySelector('#menuCheckbox');
  const menuActivateTrigger = document.querySelector('#menuOpenTrigger');
  const menuCloseTrigger = document.querySelector('#menuCloseTrigger')
  if (menuCheckbox) {
    menuCheckbox.addEventListener("change", (e) => {
      e.preventDefault();
      if (e.target.checked) {
        overlay.createAndActivateOverlay('.main-nav-container');
        menuActivateTrigger.tabIndex = 0;
        menuCloseTrigger.tabIndex = 1;
        menuCloseTrigger.focus();
        addOverlayClickEvent(overlay.overlayDivClass, menuCheckbox);
      } else {
        //removeOverlayClickEvent(overlay.overlayDivClass);
        overlay.removeActiveOverlay();
        menuCloseTrigger.tabIndex = 0;
        menuActivateTrigger.tabIndex = 1
        menuActivateTrigger.focus();
      }
    });
  }
  const menuTriggerLabel = document.getElementById('menuOpenTrigger');
  menuTriggerLabel.addEventListener('keydown', (ev) => {
    switch (ev.code) {
      case "Space":
      case "Enter":
        ev.preventDefault();
        menuCheckbox.checked = true;
        const evt = new Event('change');
        menuCheckbox.dispatchEvent(evt);
        break;
      default:
        return;
    }
  });
  menuCloseTrigger.addEventListener('keydown', (ev) => {
    switch (ev.code) {
      case "Space":
      case "Enter":
        ev.preventDefault();
        menuCheckbox.checked = false;
        const evt = new Event('change');
        menuCheckbox.dispatchEvent(evt);
        break;
      default:
        return;
    }
  });
}

const resetOtherTriggers = (elements, sourceTrigger) => {
  /* const clippedEls = elements.filter(el => el.htmlFor != sourceTrigger);
  clippedEls.forEach((el) => {
    el.classList.replace('icon-consumer-Arrowup', 'icon-consumer-Arrowdown');
  }); */
  elements.forEach((el) => {
    el.classList.replace('icon-consumer-Arrowup', 'icon-consumer-Arrowdown');
  });
}

const handleSubNavLiTriggerEvent = (e, triggerArray) => {

  let theInput, theLabel;
  if (e.target.nodeName === "LI") {
    theLabel = e.target.querySelector('label.mobile-subnav-trigger');
    theInput = e.target.querySelector('input.subnav-toggle');
  } else if (e.target.nodeName === "LABEL") {
    e.preventDefault();
    theLabel = e.target;
    const inptId = e.target.htmlFor;
    theInput = document.querySelector(`#${inptId}`);
  } else {
    return;
  }
  resetOtherTriggers(triggerArray, theLabel);
  const classes = theLabel.classList;

  if (!theInput.checked) {
    theInput.checked = true;
    classes.add('icon-consumer-Arrowup')
  } else {
    theInput.checked = false;
  }
}

const activateSubNavTriggers = () => {
  const subNavTriggers = document.querySelectorAll('.mobile-subnav-trigger');
  const triggerArray = Array.from(subNavTriggers);

  const subNavLiTriggers = document.querySelectorAll('.main-nav-list > li');

  subNavLiTriggers.forEach((li) => {
    li.addEventListener('click', (e) => {
      e.stopPropagation();
      handleSubNavLiTriggerEvent(e, triggerArray);
    });
    li.addEventListener('keydown', (evt) => {
      switch (evt.code) {
        case 'Enter':
        case 'Space':
          evt.preventDefault();
          const ev = new Event('click');
          li.dispatchEvent(ev);
          break;
        default:
          return;
      }
    });
  });
}

const header = () => {
  activateSubNavTriggers();
  setMenuTabIndices();
  mobileMenuActivation();
}

export default header;

const menu = document.querySelector('.main-nav');
if (menu) {
  header();
};