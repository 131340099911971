import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { RatingSimple } from '../atoms/RatingSimple.jsx';
import { WriteAReviewButton } from '../atoms/WriteAReviewButton.jsx';
import { RatingHistogram } from '../atoms/RatingHistogram.jsx';
// import { RecommendPercent } from '../atoms/RecommendPercent';
import { useReviews } from '../reviews-context.js';
import { useSelector } from "react-redux";

const RatingsSummary = (props) => {   
    const i18n = useSelector( state => state.i18n);

    const {
        apiKey,
        canReview,
        groupId,
        id,
        locale,
        merchId,
        productType,
        tireBrand,
        tireName,
        tireSubBrand,
        url
    } = props;

    const [summary, setSummary] = useState({
        averageRating: 0,
        descriptor: '',
        reviewCount: 0,
        ratingHistogram: [],
        recommendScore: 0,
        status: '',
        title: '',
        url: url
    });

    const [state] = useReviews();

    useEffect(() => {
        let newSummary = state.reviewSummary?.data?.summary;
        if (newSummary) {
            let {
                average_rating = 0,
                rating_histogram = [],
                recommended_score = 0,
                review_count = 0,
            } = newSummary;

            let titlePlural = review_count === 1 ? '' : 's';

            setSummary({
                averageRating: Number(average_rating),
                descriptor: productType === 'tire' ? i18n.tire : i18n.store,
                reviewCount: review_count,
                ratingHistogram: rating_histogram,
                recommendScore: +recommended_score,
                status: state.reviewSummary.status,
                title: `${review_count} ${i18n.review}${titlePlural}`,
                url: url
            });
        };

    }, [state]);

    return (
        <div className="ratings-summary">
            <div className="rating-col">
                {(summary.reviewCount > 0) ? (
                    <>
                        <div className="reviews-inline-rating wrap tire-rating-medium">
                            <RatingSimple
                                rating={summary.averageRating}
                                showNumbers={true}
                            />
                        </div>
                        <div className="title">{summary.title}</div>
                    </>
                ) : <>
                        {canReview && (
                            <WriteAReviewButton
                                apiKey={apiKey}
                                groupId={groupId}
                                heading={i18n.submitatirereview}
                                id={id}
                                inline={true}
                                label={i18n.bethefirsttowriteareview}
                                locale={locale}
                                merchId={merchId}
                                tireBrand={tireBrand}
                                tireName={tireName}
                                tireSubBrand={tireSubBrand}
                            />
                        )}
                    </>
                }

            </div>
            <div className="histogram-col">
                <RatingHistogram
                    histogram={summary.ratingHistogram}
                    count={summary.reviewCount}
                />
                {/* <RecommendPercent percent={recommendScore} /> */}
            </div>
            {canReview && (
                <WriteAReviewButton
                    apiKey={apiKey}
                    groupId={groupId}
                    heading={i18n.submitatirereview}
                    id={id}
                    inline={false}
                    label={i18n.writeareview}
                    locale={locale}
                    merchId={merchId}
                    tireBrand={tireBrand}
                    tireName={tireName}
                    tireSubBrand={tireSubBrand}
                />
            )}

        </div>
    );
};

RatingsSummary.defaultProps = {
    canReview: true,
    classes: '',
    productType: 'tire',
    url: '#'
};

RatingsSummary.propTypes = {
    apiKey: PropTypes.string.isRequired,
    canReview: PropTypes.bool,
    classes: PropTypes.string,
    groupId: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired,
    merchId: PropTypes.string.isRequired,
    productType: PropTypes.string,
    tireBrand: PropTypes.string.isRequired,
    tireName: PropTypes.string.isRequired,
    tireSubBrand: PropTypes.string.isRequired,
    url: PropTypes.string
};

export { RatingsSummary };
