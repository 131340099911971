const getUIVersion = (uiPackage) => {

    switch(uiPackage) {
        case 'shared':
            return '2.0.75.0';
        case 'bst-na':
            return "2.0.45.0"; //48
        case 'fst-na':
            return "2.0.26.0"; //29
        case 'bst-la':
            return "2.0.30.0";
        case 'fst-la':
            return "2.0.20.0";
        default:
            return false;
    }
};

const reportUIVersion = () => {

    let uiVersionObject = {
        'shared': getUIVersion('shared'),
        'bst': {
            'na': getUIVersion('bst-na'),
            'la': getUIVersion('bst-la')
        },
        'fst': {
            'na': getUIVersion('fst-na'),
            'la': getUIVersion('fst-la')
        }
    };

    return uiVersionObject;
}

export default reportUIVersion;
