import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  status: 'unknown',
  data: {
    accuracy: null,
    storeNumber: null
  }
};

export const profileStore = createSlice({
  name: 'profileStore',
  initialState: initialState,
  reducers: {
    clearStore: (state) => {
      return initialState
    },
    setStore: (state, action) => {
      return {
        ...state,
        status: 'known',
        data: action.payload
      }
    }
  }
});

const actions = profileStore.actions;

export { actions };

if (!window.BATO) {
  window.BATO = {}
}

if (!window.BATO.StoreActions) {
  window.BATO.StoreActions = {}
}

window.BATO.StoreActions.profileStore = actions;

export default profileStore.reducer;