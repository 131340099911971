import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Star } from './Star.jsx';

const RatingBar = (props) => {
    const { stars, count, total } = props;
    const percent = total === 0 ? 0 : Math.round((count / total) * 100);

    return (
        <>
            <div className="number-of-stars">{stars}</div>{' '}
            <div className="star">
                <Star />
            </div>{' '}
            <div className="rating-bar">
                <div
                    className="rating-bar-inner"
                    style={{ width: `${percent}%` }}
                ></div>
            </div>{' '}
            <div className="count">({count})</div>
        </>
    );
};

RatingBar.defaultProps = {
    stars: 0,
    count: 0,
    total: 0
};

RatingBar.propTypes = {
    stars: PropTypes.number.isRequired,
    count: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired
};

export { RatingBar };
