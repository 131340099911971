import React, { useContext } from 'react';
import { ModalContext } from '../../../../../react-shared/elements/modal/v1/ModalContext.js';
import { WriteAReview } from '../write-a-review/WriteAReview.jsx';
import { ReviewSubmitted } from '../write-a-review/ReviewSubmitted.jsx';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { writeAReviewStartAnalytics } from '../../analytics.js';

const WriteAReviewButton = (props) => {
    const {
        id,
        tireBrand,
        tireName,
        tireSubBrand,
        heading,
        inline,
        label
    } = props;

    const { setGlobalModal } = useContext(ModalContext);

    const handleOnClick = (e) => {
        e.preventDefault();
        openModal();
    }

    const openModal = () => {

        writeAReviewStartAnalytics();

        setGlobalModal(
            <WriteAReview
                pageId={id}
                tireBrand={tireBrand}
                tireName={tireName}
                tireSubBrand={tireSubBrand}
                heading={heading}
                showSuccessConfirmation={() => setGlobalModal(<ReviewSubmitted closeModal={() => setGlobalModal()} />)}
            />
        );
    };

    return (
        <div className="write-a-review-button">
            {inline ?
                <a href="#section-reviews" className={classNames({ 'write-a-review-inline': inline })} onClick={handleOnClick}>
                    {label}
                </a>
            :
            <button
                className={classNames({ 'button': !inline }, { 'write-a-review-inline': inline } )}
                onClick={openModal}
            >
                {label}
            </button>}
        </div>
    );
};

WriteAReviewButton.defaultProps = {
    inline: false,
    label: 'Write a Review',
    tireBrand: '',
    tireName: ''
};

WriteAReviewButton.propTypes = {
    id: PropTypes.string.isRequired,
    tireBrand: PropTypes.string,
    tireName: PropTypes.string,
    tireSubBrand: PropTypes.string,
    heading: PropTypes.string.isRequired,
    inline: PropTypes.bool,
    label: PropTypes.string
};

export { WriteAReviewButton };
