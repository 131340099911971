import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import RatingInput from './RatingInput';

const RatingInputGroup = (props) => {
    const {
        maxRating,
        className,
        ratingName,
        ratingFriendlyName,
        register,
        setValue,
        // setInputValue,
        showLegend,
        showRatingTitle,
        error,
    } = props;

    const [active, setActive] = useState(0);
    const [overActive, setOverActive] = useState({ isOver: false, index: 0 });
    const [stars, setStars] = useState([]);

    useEffect(() => {
        let starCount = [];
        Array(5).fill().map((item, i) => { starCount.push(i) });
        setStars(starCount);
    }, [maxRating]);

    useEffect(() => {
        if (active > 0) {
            // setInputValue(active);
        }
    }, [active])

    return (
        <div
            className={classNames('form-group pr-rating-form-group', className)}
        >
            {/* {showLegend ?
                <legend className="pr-control-label">
                    <span>
                        {ratingFriendlyName}
                        <abbr className="pr-required-indicator" title="required">
                            *
                        </abbr>
                    </span>
                </legend>*/}
            {/* : null} */}

            {(showRatingTitle ?
                <div className="pr-rating__title">
                    <p>{ratingFriendlyName}</p>
                </div>
            : null)}

            <div id="pr-rating">
                <div
                    aria-hidden="false"
                    className="pr-rating-stars"
                    role="radiogroup"
                >
                    {stars.map((star, i) => (
                        <RatingInput
                            name={ratingName}
                            friendlyName={ratingFriendlyName}
                            id={`tire-review-stars-${ratingName}-${i + 1}`}
                            i={i + 1}
                            key={i}
                            active={active > i}
                            overActive={overActive}
                            onChangeHandler={(e) => {
                                setActive(i + 1);
                            }}
                            onMouseOverHandler={(e) => {
                                setOverActive({ isOver: true, index: i + 1 });
                            }}
                            onMouseOutHandler={(e) => {
                                setOverActive({ isOver: false, index: active });
                            }}
                            register={register}
                            setValue={setValue}
                        />
                    ))}
                </div>
            </div>
            <div className="error pr-error fl-error-msg fl-error-show">
                {error}
            </div>
        </div>
    );
};

RatingInputGroup.defaultProps = {
    maxRating: 5,
    showLegend: false,
    showRatingTitle: true
};

RatingInputGroup.propTypes = {
    maxRating: PropTypes.number,
    className: PropTypes.string,
    ratingName: PropTypes.string.isRequired,
    ratingFriendlyName: PropTypes.string.isRequired,
    register: PropTypes.func,
    setInputValue: PropTypes.func,
    showLegend: PropTypes.bool,
    showRatingTitle: PropTypes.bool
};

export { RatingInputGroup };
