import elementListener from '../../../../../js/library/eventHelpers.js';

// setup window object for components to consume
if (!window.BATO) window.BATO = {};

//const storeCardsContainer = document.querySelector(".cmp-store-cards");
const pageContainer = document.querySelector(".page");

const storeDealerTypeAnalyticsObject = {
	"event": "component_click",
	"component_detail": {
		"type":"tab",
		"title": "dealer menu",
		"click_elem": {
			"title":"",
		}
	}
};

export const init = () => {
	if (pageContainer != null) {
		elementListener.addBubblerListener(pageContainer, 'click', '.store-filter__tab-content label', storeDealerType);
		elementListener.addBubblerListener(pageContainer, 'click', '.tire-search-flyout__tab-content label', storeDealerType);
	}
}

const storeDealerType = (evt) => {

	let analyticsObject = JSON.parse(JSON.stringify(storeDealerTypeAnalyticsObject));
	analyticsObject.component_detail.click_elem.title = evt.target.innerText;
	adobeDataLayer.push(analyticsObject);
}

export default {
	init
}