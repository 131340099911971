// setup window object for components to consume
if (!window.BATO) window.BATO = {};

const accordionAnalyticsObject = {
	"event": "component_click",
	"component_detail": {
		"type": "content_expand",
		"click_elem": {
			"title": "",
			"id": ""
		}
	}
}

// Track which accordion items we've fired analytics for
const trackedIds = new Set();

const init = () => {
	// Clear tracking on init
	trackedIds.clear();

	let accordionItems = document.querySelectorAll(".cmp-accordion__item .cmp-accordion__item-label"); 
		
	if (accordionItems?.length) {
		accordionItems.forEach(item => {
			item.addEventListener("click", accordionItemEventHandler); 
			item.addEventListener("focus", accordionItemEventHandler); 
		});
	}
}

const accordionItemEventHandler = (evt) => {
	let el = evt.target;
	let elPar = el.parentElement;   
	let label = elPar.querySelector(".cmp-accordion__item-label");
	let input = el.previousElementSibling;

	// Generate a unique ID for this accordion
	const accordionId = input?.id || `accordion-${label.textContent.trim()}`;

	// Only fire analytics if we haven't already tracked this accordion
	if (!trackedIds.has(accordionId)) {
		// Add to tracked set
		trackedIds.add(accordionId);

		let analyticsObject = JSON.parse(JSON.stringify(accordionAnalyticsObject));

		const clickElem = {
			"title": label.textContent.trim() || "",
			"id": input?.id || ""
		}

		analyticsObject.component_detail.click_elem = clickElem;
		window.adobeDataLayer = window.adobeDataLayer || [];
		window.adobeDataLayer.push(analyticsObject);
	}
}

export default {
	init
}
