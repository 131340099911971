import '../scss/vehicle-selector.scss';
import vehicleFitment from '../../../vehicle-selector-fitment/v1/js/vehicle-selector-fitment.js';
import '../../../vehicle-selector-size/v1/js/vehicle-selector-size.js';

import { urlLanguageCheck } from '../../../../../js/utils/urlLanguageCheck.js';
import { waitForElement } from '../../../../../js/utils/waitForElement.js';

// setup window object for components to consume
if (!window.BATO) window.BATO = {};

const catalogService = window.BATO.Services.catalog;
//const hrefContext = '/catalog';
let vehicleSelector, btns;

const init = () => {

	btns = document.querySelectorAll("a[href*='/catalog'], a[href^='#vehicle-selector']");
	let tsr = document.querySelector('.tire-search-results'),
		tdp = document.querySelector('.tire-detail-datawrapper');

	btns.forEach(btn => {
		
		let _href = btn.getAttribute('href');
		let _isVehicleSelector = (_href == '#vehicle-selector') ? true : false;

		let _redirectFromPage;

		if(_isVehicleSelector && tdp) {
			_redirectFromPage = false;						// Keep BOSS on TDP if Vehicle Changed, Update TDP Info
		} else {
			_redirectFromPage = (tsr || tdp) ? true : false; // Refresh Catalog Page on Vehicle Change, Redirect to Catalog Page on 'View Tires' Click
		}

		let _hasTireSize = (_href.search(/(\d{2,3}-\d{1,2}(\.[0-9]{1,2})?r\d{2})+/gi) != -1) ? true : false; // Regex Check for Tire Pattern

		let _hrefArr = _href.split('/').filter(el => el != "");

		/*
			url patterns:
				#vehicle-selector
				/catalog
				/catalog/{prefilters}
				/catalog/{M/M/Y/T}/* -- should be ignored
				/catalog/size/* -- should be ignored
		*/

		let languageInUrl = urlLanguageCheck(),
			hrefLengthCheck = 3;

		languageInUrl ? hrefLengthCheck = 4 : '';

		let redirectPath = '';

		if ( _hrefArr.length <= hrefLengthCheck && !_hasTireSize ) {

			btn.addEventListener('click', evt => {

				evt.preventDefault();
				evt.stopPropagation(); // stop this from bubbling to the "clickable card" event handlers

				let vehicleProfile = window.BATO.Store.getState().vehicles;

				// if the button links to vehicle selector just pop modal
				if (_isVehicleSelector) {

					// vehicle selector mode normally just stays on current page but
					// if on catalog page it should redirect page to match selected vehicle
					window.BATO.vehicleSelector.redirect = _redirectFromPage;
					window.BATO.modal.activateModal(vehicleSelector, true, evt.target, true, 'modal');
					window.BATO.vehicleSelectorFitment.init();
					window.BATO.vehicleSelectorSize.init();


				// if button href has valid size link, redirect browser
				// *** if _hrefArr length logic is correct this shouldnt be needed ***

				} else if (vehicleProfile.status == "known") {

					// build path to include vehicle
					let profileVehicleFitment = vehicleProfile.vehicle;

					// Replaces All Spaces With Dashes
					let trimCleaned = profileVehicleFitment.trim.replace(/[^\w]+/g, '-');

					// Specific to Town & Country Model (Chrysler, 2015) - Need to Strip Ampersand
					let modelCleaned = profileVehicleFitment.model.replace(' & ', '-');

					// Replace periods with hyphens in the model name
					modelCleaned = modelCleaned.replace(/\./g, '-');

					// Replace "+" signs with the word "plus"
					modelCleaned = modelCleaned.replace('+', '-plus')

					let fitmentPath = profileVehicleFitment.make + '/' + modelCleaned + '/' + profileVehicleFitment.year + '/' + trimCleaned;

					fitmentPath = fitmentPath.replace(/\s/g, '-').toLowerCase();

					redirectPath = "/";

					if(languageInUrl){

						if (_hrefArr.length == 2) {
							redirectPath += _hrefArr[0] + '/' + _hrefArr[1] + '/' + fitmentPath;
						}

						if (_hrefArr.length == 3) {
							redirectPath += _hrefArr[0] + '/' + _hrefArr[1] + '/' + fitmentPath + '/' + _hrefArr[2];
						}

					} else {

						redirectPath += _hrefArr[0] + '/' + fitmentPath;

						if (_hrefArr.length == 2) {
							redirectPath += '/' + _hrefArr[1];
						}

					}

					window.location.href = redirectPath;

				// if BOSS has no vehicle, pop selector in redirect mode, preserving any catalog prefilters
				} else {
					window.BATO.vehicleSelector.hrefSuffix = (languageInUrl && _hrefArr.length == 3) ? _hrefArr[2] : "";
					window.BATO.vehicleSelector.redirect = true;
					window.BATO.modal.activateModal(vehicleSelector, true, evt.target, true, 'modal');
				}

			});

		}

	});
}

const show = () => {
	window.BATO.modal.activateModal(vehicleSelector, true, null, true, 'modal');
	window.BATO.vehicleSelectorFitment.init();
	window.BATO.vehicleSelectorSize.init();
}

const hide = () => {
	window.BATO.modal.deactivateModal(vehicleSelector);
}

document.addEventListener("DOMContentLoaded", () => {

	// This is less than ideal bandaid to our problem ...
	// React App Portion of Site is Writing the Header button elements to DOM after this function fires
	// So EventListenrs are not getting attached - The below Catalog Button is the last element to be written
	// By the React App so we wait for this last element to be present before running eventListener scripts
	//
	// TODO: Fix this at the React App Level, either move out of React or Add eventListeners to React
	waitForElement('.vehicle-app-wrap a[href*="/catalog"]').then((elm) => {
    	init();
	});

	// Additionally, since we are now waiting for the React App to render, we need to
	// make sure the below Window Objects get set or the vehicle picker on new profile will not function
	// TODO: dont hijack full valid links to /catalog/size/* OR /catalog/M/M/Y/T*
	vehicleSelector = document.querySelector('.vehicle-selector');
	window.BATO.vehicleSelector = vehicleSelector;
	window.BATO.vehicleSelector.hrefSuffix = "";
	window.BATO.vehicleSelector.selectYear = vehicleFitment.selectYear;

	window.BATO.vehicleSelector.show = show;
	window.BATO.vehicleSelector.hide = hide;

});
