// setup window object for components to consume
if (!window.BATO) window.BATO = {};

const ctaSocial = {
	target: '.footer__social a, .footer__social button',
	properties: {
		type: 'social'	
	}
}

const ctaMainNavigation = {
	target: '.main-nav-list > li > a',
	properties: {
		type: 'navigation',
		nav_location: 'primary nav'	
	}
}

const ctaSubNavigation = {
	target: '.drop-down-menu li a',
	properties: {
		type: 'navigation',
		nav_location: 'sub nav'	
	}
}

const ctaFooter = {
	target: '.footer__links a, .footer__links button',
	properties: {
		type: 'navigation',
		nav_location: 'footer'	
	}
}

const ctaChat = {
	target: '.chat-widget a',
	properties: {
		type: 'chat',
	}
}

const ctaWillItFit = {
	target: '.will-it-fit a[href^="#locate-tire"]',
	properties: {
		type: 'locate_tire'	
	}
}


export const init = () => {
	const ctasSocial = document.querySelectorAll(ctaSocial.target);
	const ctasMainNavigation = document.querySelectorAll(ctaMainNavigation.target); 
	const ctasSubNavigation = document.querySelectorAll(ctaSubNavigation.target); 
	const ctasFooter = document.querySelectorAll(ctaFooter.target); 
	const ctasChat = document.querySelectorAll(ctaChat.target); 
	const ctasWillItFit = document.querySelectorAll(ctaWillItFit.target); 
	
	/*
	console.log("\n ctasSocial", ctasSocial);
	console.log("\n ctasMainNavigation", ctasMainNavigation);
	console.log("\n ctasSubNavigation", ctasSubNavigation);
	console.log("\n ctasFooter", ctasFooter);
	console.log("\n ctasChat", ctasChat);
	console.log("\n ctasWillItFit", ctasWillItFit);
	*/
	
	if (ctasSocial) {
        setDetailData(ctasSocial, ctaSocial.properties); 
	}

	if (ctasMainNavigation) {
        setDetailData(ctasMainNavigation, ctaMainNavigation.properties); 
	}

	if (ctasSubNavigation) {
	    setDetailData(ctasSubNavigation, ctaSubNavigation.properties);  
	}

	if (ctasFooter) {
	    setDetailData(ctasFooter, ctaFooter.properties); 
	}
	
	if (ctasChat) {
	    setDetailData(ctasChat, ctaChat.properties); 
	}
	
	if (ctasWillItFit) {
	    setDetailData(ctasWillItFit, ctaWillItFit.properties); 
	}
}

const setDetailData = (elements, prop) => {
    elements.forEach(el => {
        el.dataset.detail = JSON.stringify(prop); 
    }); 
}

export default {
	init
}