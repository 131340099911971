/**
 * Add Inline Error Message to Specific Form Field
 * @param  {HTMLElement}   item    The Form Element, Any valid Form Element (input, select, etc)
 */

import { validateFields } from './validateFields.js';

const addValidationError = (item) => {

    let element = validateFields(item);
    let valid = element.checkValidity();

    if (!valid) {
        element.closest(".field").classList.add("error");
        element.insertAdjacentHTML('beforebegin', '<p class="invalid error ' + element.name + '">' + element.validationMessage + '</p>');
    }

}

export { addValidationError };