import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  status: 'unknown',
  data: {
    accuracy: "", // "none" only ever at initial. options: network, latlon, user
    city: "",
    country: "",
    ip: "",
    lat: "",
    lon: "",
    region: "",
    zip: "",
  },
};

export const location = createSlice({
  name: 'location',
  initialState: initialState,
  reducers: {
    setLocation: (state, action) => {
      return {
        ...state,
        status: 'known',
        data: action.payload
      }
    },
    setLocationLoading: (state) => {
      const payload = {
        status: 'loading',
      }
      return {
        ...state,
        status: 'loading',
        data: {
          accuracy: "",
          city: "",
          country: "",
          ip: "",
          lat: "",
          lon: "",
          region: "",
          zip: "",
        }
      }
    },
    clearLocation: (state) => {
      return initialState;
    }
  },
});

const actions = location.actions;

export { actions };

if (!window.BATO) {
  window.BATO = {}
}

if (!window.BATO.StoreActions) {
  window.BATO.StoreActions = {}
}

window.BATO.StoreActions.location = actions;

export default location.reducer;