import elementListener from '../../../../../js/library/eventHelpers.js';

// setup window object for components to consume
if (!window.BATO) window.BATO = {};

const vehicleAnalyticsObject = {
	"event":"vehicletireselector.interaction",
	"interaction_category": "vehicle tire selector", 
	"interaction_value":""
}; 

const init = () => {
	let docBody = document.body;  

	elementListener.addBubblerListener(docBody, 'click', '.vehicle-selector-fitment__select.trim .option, .vehicle-selector-size__select.diameter .option', vehicleTireChangeEventHandler);
}

const vehicleTireChangeEventHandler = (evt) => {
	let el = evt.target; 
	let elPar = el.closest("li"); 
	let analyticsObject = JSON.parse(JSON.stringify(vehicleAnalyticsObject)); 
	let interaction_value = (elPar.classList.contains("trim")) ? "select vehicle" : "select tire";
	
	analyticsObject.interaction_value = interaction_value; 
	adobeDataLayer.push(analyticsObject); 
}

export default {
	init
}