import elementListener from '../../../../../js/library/eventHelpers.js';

// setup window object for components to consume
if (!window.BATO) window.BATO = {};

const pageContainer = document.querySelector(".page");

const fsdCardAnalyticsObject = {
    "event": "dealer_transfer.prompt",
    "component_detail": {
        "type": "",
        "title": "",
        "id": ""
    },
    "dealer": {
        "dealer_id": "firestonedirect",
        "dealer_name": "Firestonedirect",
    }
};

export const init = () => {
    if (pageContainer != null) {
        elementListener.addBubblerListener(pageContainer, 'click', '.fsd-card .basket-transfer', fsdCardEventHandler);
        elementListener.addBubblerListener(pageContainer, 'click', '.fsd-card .button--tertiary', fsdCardEventHandler);
        elementListener.addBubblerListener(pageContainer, 'click', '.fsd-card .fsd-card__link', fsdCardEventHandler);
    }
}

const fsdCardEventHandler = (evt) => {

    let el = evt.target;
    let analyticsObject = JSON.parse(JSON.stringify(fsdCardAnalyticsObject));

    const setComponentDetails = (type, title, id) => {

        analyticsObject.component_detail = {
            "type": type,
            "title": title,
            "id": id
        }

        adobeDataLayer.push(analyticsObject);

    };

    if (el.classList.contains('basket-transfer')) {

        setComponentDetails('button', 'Schedule Now', 'schedulenow');

    } else if (el.classList.contains('button--tertiary')) {

        setComponentDetails('anchor', 'Learn More', 'learnmore');

    } else {

        setComponentDetails('anchor', 'on Firestonedirect.com', 'onfirestonedirect');

    }

}

export default {
    init
}