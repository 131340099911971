export const jumpToReviewsAnalytics = () => {
	if (window.adobeDataLayer) window.adobeDataLayer.push({ "event": "tdp.interaction", "interaction_category": "see all reviews" })
 }

export const loadMoreReviewsAnalytics = () => {
	if (window.adobeDataLayer) window.adobeDataLayer.push({ "event": "tdp.interaction", "interaction_category": "load more reviews" });
};

export const sortReviewsAnalytics = () => {
	if (window.adobeDataLayer) window.adobeDataLayer.push({ "event": "tdp.interaction", "interaction_category": "sort review" });
};

// score param = typeof String
export const submitReviewAnalytics = (score) => {
	if (window.adobeDataLayer) window.adobeDataLayer.push({ "event": "tdp.interaction", "interaction_category":  "submit review", "scoreNumber": score });
};

export const writeAReviewStartAnalytics = () => {
	if (window.adobeDataLayer) window.adobeDataLayer.push({ "event": "tdp.interaction", "interaction_category": "start review" });
};