import apiUtils from '../util/apiUtils.js';

const getParams = {
	site: window.BATO?.CONFIG?.site || 'bst',
	country: window.BATO?.CONFIG?.country || 'US',
	lang_iso: window.BATO?.CONFIG?.lang_iso || 'en_US'
}

/*
	tire-sizes/widths?site={{site}}&country={{country}}&lang_iso={{lang_iso}}
*/
export const getWidths = async () => {
	try {
		const response = await fetch(apiUtils.apiPath('tire-sizes/widths', getParams));

	    if (!response.ok) {
	      const errorData = await response.json();
	      const err = `there's a problem with getWidths': ${errorData}`;
	      throw new Error(err);
	    }

	    const data = await response.json();

	    if (data) return data;

	} catch (err) {
	    console.log(`there's been an error in the getWidths() function: ${err}`);
	    return {
	      success: false,
		}
	}
};

/*
	tire-sizes/ratios-by-width?width={{width}}&site={{site}}&country={{country}}&lang_iso={{lang_iso}}
*/
export const getRatios = async (width) => {
	try {
	    if (!width) {
	      throw new Error('you need to provide a width');
	    };

		const newParams = {
			...getParams,
			...{width}
		}

	    const response = await fetch(apiUtils.apiPath('tire-sizes/ratios-by-width', newParams));

	    if (!response.ok) {
	      const errorData = await response.json();
	      const error = `there's an error in getRatios(): ${errorData}`;
	      throw new Error(error);
	    }

	    const data = await response.json();

	    if (data) return data;

	} catch (err) {
	    console.log(`there's been an error in the getRatios() function: ${err}`);
	    return {
	      success: false,
	    }
	}
};

/*
	tire-sizes/diameters-by-width-ratio?width={{width}}&ratio={{ratio}}&site={{site}}&country={{country}}&lang_iso={{lang_iso}}
*/
export const getDiameters = async (width, ratio) => {
	try {
	    if (!width) {
	      throw new Error('you need to provide a width');
	    };

	    if (!ratio) {
	      throw new Error('you need to provide a ratio');
	    };

			const newParams = {
				...getParams,
				...{ width, ratio }
			}

	    const response = await fetch(apiUtils.apiPath('tire-sizes/diameters-by-width-ratio', newParams));

	    if (!response.ok) {
	      const errorData = await response.json();
	      const error = `there's an error in getDiameters(): ${errorData}`;
	      throw new Error(error);
	    }

	    const data = await response.json();

	    if (data) return data;

	} catch (err) {
	    console.log(`there's been an error in the getDiameters() function: ${err}`);
	    return {
	      success: false,
	    }
	}
};

export default {
	getWidths,
	getRatios,
	getDiameters
};