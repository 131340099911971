import React, { Fragment, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useMetaInfo } from '../../../../../react-shared/utils/meta-info-context.js';
import { RatingSimple } from './RatingSimple.jsx';
import { ReportButton } from './ReportButton.jsx';
import classNames from 'classnames';
import { useSelector } from "react-redux";
import { getTranslateReview } from '../../../../../../../../common/components/content/services/v1/js/api/reviews.js';

const cleanMilesOnTiresValue = (miles) => {
    const noCommaMiles = miles.replaceAll(',', '');
    const noTextMiles = noCommaMiles.replace(/\D/g,'');
    const intlFormattedMiles = new Intl.NumberFormat().format(noTextMiles);
    return intlFormattedMiles;
}

const Review = (props) => {
    const i18n = useSelector( state => state.i18n);

    const { data, rating } = props;
    const createdDate = new Date(data.created_date || 0);
    // If more than 5 lines of text, show read more button
    const [isExpanded, setIsExpanded] = useState(false);
    const [height, setHeight] = useState(0);
    const [tooLong, setTooLong] = useState(false);
    const [cardData, setCardData] = useState({
        rating: 0
    });
    const [milesOnTires, setMilesOnTires] = useState(null);
    const commentRef = useRef(null);
    const [state, dispatch] = useMetaInfo();
    const readButtonLabel = isExpanded ? i18n.readless : i18n.readmore;

    const isTranslateButtonEnabled = Boolean(window.BATO?.CONFIG?.REVIEWS?.enableReviewsTranslation) || false;
    const maxCommentHeight = isTranslateButtonEnabled ? state.windowWidth >= 1024 ? 75 : 60 : state.windowWidth >= 1024 ? 75 : 60;
    

    const tireRackReview = (data.syndicating_client == 'Bridgestone Tire Rack' || data.syndicating_client == 'Firestone Tire Rack') ? true : false;
    const isSyndicated = data.syndicated;

    const merchantResponse = (data?.merchant_responses) ? data.merchant_responses[0].response : false;

    const [displayedText, setDisplayedText] = useState({
        comments: data.comments,
        headline: data.headline,
        type: 'original'
    });

    useEffect(() => {
        setCardData({
            rating: data.rating
        });
        if (data?.client_specific_questions?.milesdriveontires?.value) {
            const cleanMiles = cleanMilesOnTiresValue(data?.client_specific_questions?.milesdriveontires?.value);
            setMilesOnTires(cleanMiles);
        }
    }, [data]);

    useEffect(() => {
        setHeight(commentRef.current.clientHeight);
        if (height > maxCommentHeight) {
            setTooLong(true);
        }
    });

    const toggleComment = () => {
        setIsExpanded(!isExpanded);
    };

    const onReadButtonClick = () => {
        toggleComment();
    };

    const syndicatedInfo = () => {

        let tld = data.syndicating_client_url.split('.').pop();
        if(tld.endsWith('/')) { tld = tld.slice(0, -1); }
        
        return (
            <div className={`syndicated-review ${tireRackReview ? 'review-tire-rack' : tld}`}>
                { tireRackReview ? i18n.originallypostedontirerack : i18n.originallypostedon + data.syndicating_client_url?.replace(/^https?:\/\//, '')}
            </div>
        ) 
    }

    const handleTranslateReview = async (id) => {
        try {
            const translationData = await getTranslateReview({ ugc_id: id });

            if(translationData.success) {
                const translationDocument = translationData?.results[0]?.translation_document
                
                const translatedComments = translationDocument?.translated_comments;
                const translatedHeadline = translationDocument?.translated_headline;
    
                setDisplayedText({
                    comments: translatedComments,
                    headline: translatedHeadline,
                    type: 'translated'
                });
            }

        } catch (error) {
            console.error('Error translating review:', error);
        }
      
    }

    const handleOriginalTextReview = () => {
        setDisplayedText({
            comments: data.comments,
            headline: data.headline,
            type: 'original'
        })
    }

    const compareLanguageCodes = (locale1, locale2) => {
        const lang1 = locale1.substring(0, 2);
        const lang2 = locale2.substring(0, 2);
        return lang1 !== lang2;
    };
    
    const translateButton = (id, reviewLocale) => {
        const configLocale = window.BATO.CONFIG.lang_iso;
    
        if (compareLanguageCodes(reviewLocale, configLocale)) {
            return (
                <a className="translate-review__btn" onClick={() => displayedText.type === 'original' ? handleTranslateReview(id) : handleOriginalTextReview()}>
                    {displayedText.type === 'original' ? i18n.showtranslation : i18n.showoriginal}
                </a>
            );
        }
    
        return null; 
    }

    return (
        <div className="review">
            <div className="review-aside">
                <div className="review-rating tire-rating-small">
                    <div className="rating-heading">{i18n.overallrating}</div>

                    <RatingSimple
                        rating={(parseInt(rating))}
                        showNumbers={false}
                    />

                    <div className="rating-breakout tire-rating-extra-small">
                        <div>
                            <div>{i18n.traction}</div>
                            <RatingSimple
                                rating={parseInt(data?.client_specific_questions?.traction?.value) || 0}
                                showNumbers={false}
                            />
                        </div>
                        <div>
                            <div>{i18n.durability}</div>
                            <RatingSimple
                                rating={parseInt(data?.client_specific_questions?.durability?.value) || 0}
                                showNumbers={false}
                            />
                        </div>
                        <div>
                            <div>{i18n.comfort}</div>
                            <RatingSimple
                                rating={parseInt(data?.client_specific_questions?.comfort?.value) || 0}
                                showNumbers={false}
                            />
                        </div>
                        <div>
                            <div>{i18n.value}</div>
                            <RatingSimple
                                rating={parseInt(data?.client_specific_questions?.value?.value) || 0}
                                showNumbers={false}
                            />
                        </div>
                    </div>
                </div>
                <ReportButton />
            </div>
            <div className="review-body">
                <div className="review-meta">
                    <div className="review-date">
                        {createdDate.toLocaleDateString("en-US")}
                    </div>
                    <div className="review-nickname">{data.nickname}</div>
                    <div className="review-vehicle">
                        {data?.client_specific_questions?.caryear?.value &&
                            <>{data.client_specific_questions.caryear.value}</>
                        }
                        {data?.client_specific_questions?.carmake?.value &&
                            <>&nbsp;{data.client_specific_questions.carmake.value}</>
                        }
                        {data?.client_specific_questions?.carmodel?.value &&
                            <>&nbsp;{data.client_specific_questions.carmodel.value}</>
                        }
                        {milesOnTires &&
                            <>,&nbsp;{milesOnTires}{i18n.midrivenontires} </>
                        }
                    </div>
                </div>
                <div className="review-headline">{displayedText.headline}</div>
                <div
                    className={classNames('review-comments', {
                        expanded: isExpanded,
                        'too-long': tooLong
                    })}
                    ref={commentRef}
                >
                    {displayedText.comments}

                    {tooLong && (
                        <button className="read-button" onClick={onReadButtonClick}>
                            {readButtonLabel}
                        </button>
                    )}
                    
                    { isTranslateButtonEnabled && data.syndicated && translateButton(data.id, data.locale) }
                </div>

                { isSyndicated && syndicatedInfo() }

                {merchantResponse && (
                    <div className="merchant-response">
                        <b>{i18n.merchantresponse}</b>
                        <br />
                        {merchantResponse}
                    </div>
                )}

            </div>
        </div>
    );
};

Review.defaultProps = {};

Review.propTypes = {
    data: PropTypes.object.isRequired
};

export { Review };
