import React from 'react';
import ReviewsLoader from './ui/ReviewsLoader.jsx';
import { ReviewsProvider } from './ui/reviews-context.js';

const FullReviewApp = ({ config }) => {
  return (
    <ReviewsProvider>
      <ReviewsLoader config={config} />
    </ReviewsProvider>
  );
};

export { FullReviewApp };
