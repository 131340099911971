// setup window object for components to consume
if (!window.BATO) window.BATO = {};

const formAnalyticsObject = {
	"event": "form_submit",
	"form_id": "",
	"form_name": "",
	"fields_submitted": ""
};

let formElement, formSubmitBtn;

const init = () => {
	formElement = document.querySelector("form.contact-us, form.registration"); 
	
	if (formElement) {
		formElement.addEventListener("submit_success", formEventHandler); 
	}
	
}

const formEventHandler = (evt) => {
	let analyticsObject = JSON.parse(JSON.stringify(formAnalyticsObject));
	let formElements = formElement.elements;
	let fields = {};

	for (let i=0; i<formElements.length; i++) {
		let el = formElements[i];

		if (el.value != undefined || el.value != null || el.value != "") {
			fields[el.name] = el.value;
		}

	}

	analyticsObject["form_name"] = formElement.ariaLabel;
	analyticsObject["fields_submitted"] = fields;

	adobeDataLayer.push(analyticsObject);
}

export default {
	init
}