import elementListener from '../../../../../js/library/eventHelpers.js';

// setup window object for components to consume
if (!window.BATO) window.BATO = {};

const storeFilterContainer = document.querySelector(".store-filter__filters");
const sortByContainer = (storeFilterContainer != null) ? storeFilterContainer.querySelector(".store-filter__filters__sortby") : null;
const radiusContainer = (storeFilterContainer != null) ? storeFilterContainer.querySelector(".store-filter__filters__distance__display b") : null;

const storeDetailsAnalyticsObject = {
	"event": "dealer.sorting",
	"filter": "",
	"sorting": ""
};

export const init = () => {
	if (sortByContainer != null) {
		elementListener.addBubblerListener(sortByContainer, 'click', 'li', storeFilterEventHandler);	
	}
}

const storeFilterEventHandler = (evt) => {
	let el = evt.target;
	let analyticsObject = JSON.parse(JSON.stringify(storeDetailsAnalyticsObject));

	let radiusDistance = radiusContainer.innerHTML;
	analyticsObject.filter = "distance: within " + radiusDistance + " miles";

	let sortByInput = el.innerHTML;
	sortByInput.includes("Select") ? analyticsObject.sorting = "Default" : analyticsObject.sorting = sortByInput;

	adobeDataLayer.push(analyticsObject);
}

export default {
	init
}