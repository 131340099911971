import { isVisible } from './utilsForFrontend.js';

// formatted query selector to search for focusable items
export const focusableElementsQuerySelector = "a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex], *[contenteditable]";

export const trapEscapeKey = (closeEl, evt) => {
  // if escape is pressed
  if (evt.key === 'Escape' || evt.key === 'Esc') {
    // trigger click event of element you wish to have triggered by the Escape key
    closeEl.click();
  }
};

export const trapTabKey = (el, evt) => {
  if (evt.key === 'Tab') {
    const tabItems =  el.querySelectorAll('.cmp-tabs__tab'); //enabling tabbing on the two tabs to override AEMs setting of tabindex to 0 and -1, which takes them out of the tab order
    tabItems.forEach(element => {
      element.setAttribute("tabindex", "1");
    });

    const tempItems = el.querySelectorAll(focusableElementsQuerySelector);

    // get list of focusable items
    const focusableItems = [... new Set(tempItems)].filter(isVisible);
    // get currently focused item
    const focusedItem = document.activeElement;
    
    // number of focusable items
    let numberOfFocusableItems = focusableItems.length;

    // get index in focusable items of the currently focused item
    let focusedItemIndex = focusableItems.indexOf(focusedItem);

    if ((focusedItemIndex == 0) && evt.shiftKey) {
      focusableItems[numberOfFocusableItems - 1].focus();
      evt.preventDefault();
    } else if ((focusedItemIndex === numberOfFocusableItems - 1) && !evt.shiftKey) {
      focusableItems[0].focus();
      evt.preventDefault();
    }

  }
};



export default {
  focusableElementsQuerySelector,
  trapEscapeKey,
  trapTabKey
}