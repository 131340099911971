import React from 'react';
import PropTypes from 'prop-types';
import { RatingBar } from './RatingBar';

const RatingHistogram = (props) => {
    const { histogram, count } = props;

    return (
        <div className="rating-histogram">
            <ul>
                <li>
                    <RatingBar stars={5} count={histogram[4]} total={count} />
                </li>
                <li>
                    <RatingBar stars={4} count={histogram[3]} total={count} />
                </li>
                <li>
                    <RatingBar stars={3} count={histogram[2]} total={count} />
                </li>
                <li>
                    <RatingBar stars={2} count={histogram[1]} total={count} />
                </li>
                <li>
                    <RatingBar stars={1} count={histogram[0]} total={count} />
                </li>
            </ul>
        </div>
    );
};

RatingHistogram.defaultProps = {
    histogram: [0, 0, 0, 0, 0],
    count: 0
};

RatingHistogram.propTypes = {
    histogram: PropTypes.array,
    count: PropTypes.number
};

export { RatingHistogram };
