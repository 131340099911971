import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Rating } from '../../../tire-reviews/v1/js/ui/atoms/Rating.jsx';
import { WriteAReviewButton } from '../../../tire-reviews/v1/js/ui/atoms/WriteAReviewButton.jsx';
import { jumpToReviewsAnalytics } from '../../../tire-reviews/v1/js/analytics.js';
import { useSelector } from "react-redux";

const InlineRating = (props) => {
    const {
        averageRating,
        classes,
        descriptor,
        id,
        meta: {
            apiKey,
            country,
            groupId,
            lang_iso: locale,
            merchId,
            site,
            canReview
        },
        productType,
        reviewCount,
        showRatingDetails,
        showWriteButton,
        tireBrand,
        tireName,
        tireSubBrand,
        url
    } = props;

    const className = `inline-rating ${classes}`;

    const handleReviewCountClick = () => {
        jumpToReviewsAnalytics();
    };

    const i18n = useSelector( state => state.i18n);

    return (
        <div className={className}>
            {reviewCount === 0 ? (
                productType != 'store' ? (
                    <>{canReview && (
                        <div className="write-review-first" className="js-stars" data-rating="0" data-review-count="0">
                            <WriteAReviewButton
                                id={id}
                                inline={true}
                                tireBrand={tireBrand}
                                tireName={tireName}
                                tireSubBrand={tireSubBrand}
                                heading={i18n.submitatirereview}
                                label={`${i18n.bethefirsttoreviewthis} ${descriptor}`}
                            />
                        </div>
                    )}</>

                ) : (
                    <></>
                )
            ) : (
                <>
                    <Rating
                        rating={averageRating}
                        reviewCount={reviewCount}
                        url={url}
                        id={id}
                        productType={productType}
                        classes={classes}
                        reviewCountClick={handleReviewCountClick}
                        showNumbers={showRatingDetails}
                    />
                    {showWriteButton && productType != 'store' && (
                        <div className="write-review">
                            <WriteAReviewButton
                                id={id}
                                inline={true}
                                tireBrand={tireBrand}
                                tireName={tireName}
                                tireSubBrand={tireSubBrand}
                                heading={i18n.submitatirereview}
                                label={i18n.writeareview}
                            />
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

InlineRating.defaultProps = {
    canReview: false,
    tireBrand: '',
    tireName: '',
    showRatingDetails: false,
    showWriteButton: false
};

InlineRating.propTypes = {
    averageRating: PropTypes.number.isRequired,
    canReview: PropTypes.bool,
    classes: PropTypes.string.isRequired,
    descriptor: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    meta: PropTypes.shape({
        apiKey: PropTypes.string.isRequired,
        country: PropTypes.string,
        groupId: PropTypes.string.isRequired,
        lang_iso: PropTypes.string.isRequired,
        merchId: PropTypes.string.isRequired,
        site: PropTypes.string
    }),
    productType: PropTypes.string.isRequired,
    reviewCount: PropTypes.number.isRequired,
    showRatingDetails: PropTypes.bool,
    showWriteButton: PropTypes.bool,
    tireBrand: PropTypes.string,
    tireName: PropTypes.string,
    tireSubBrand: PropTypes.string,
    url: PropTypes.string,
};

export { InlineRating };
