export function cardHTML(dataID, image, name, msrp, warrantyDistance, tireType, rtb1, rtb2, rtb3, rtb4, dryperformance, fuelefficiency, iceperformance, icesnowwinteraverage, offroadperformance, quietride, ridecomfort, snowperformance, wetperformance, winterperformance, speedrating, treaddepth, utqg, tireweight, detailsLink, articlenumber, sizebaseurl, size, width, ratio, diameter, brand, subbrand, productid, rating, reviewCount, i18n) {

    var theCardContent = `

    <div class="cmp-tire-comparison__col" data-id="${dataID}" data-articlenumber="t${articlenumber}" data-articlenumberclean="${articlenumber}" data-sizebaseurl="${sizebaseurl}" data-width="${width}" data-ratio="${ratio}" data-diameter="${diameter}" data-brand="${brand}" data-subbrand="${subbrand}" data-productid="${productid}" data-name="${name}" data-rating="${rating}" data-review-count="${reviewCount}">

        <div class="cmp-tire-card cmp-tire-card-compare">

            <div class="remove-comparison-card"></div>

            <div class="cmp-tire-card-compare__image">
                <img src="${image}" />
            </div>

            <h3>${name}</h3>

            <div class="cmp-tire-card-compare__rating tire-rating-small">
    `

    if(rating != '0') {

        let reviewText = 'Reviews';
        reviewCount == '1' ? reviewText = 'Review' : '';

        theCardContent += `<div class="stars" data-rating="${rating}" aria-label=""><span></span></div>
                            <div>
                                <div class="average-rating">${rating}</div>
                                <div class="review-count">(${reviewCount} ${reviewText})</div>
                            </div>`;

    }

    theCardContent += `

            </div>

        </div>

        <div class="cmp-tire-card-details">

            <div class="details">

                <div class="heading">${i18n.tireCardOverview}</div>
                <i class="expand expanded"></i>

                <div class="details-section overview active">
        `;

    if(msrp && msrp != '' && window.BATO.CONFIG.region !== 'la') {
        let formattedMsrp = msrp;
        let suffix = '';
    
        if (window.BATO.CONFIG.lang_iso === 'fr_CA') {
            // Format the msrp value for Canadian French
            let priceParts = msrp.split('.');
            formattedMsrp = priceParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ',' + priceParts[1] + '<sup>$ch</sup>';
        } else {
            suffix = 'ea';
            formattedMsrp = `<sup>$</sup>${formattedMsrp}`;
        }

        theCardContent += `
            <div>
                <div class="label">${i18n.tireCardMsrpPrice}</div>
                <div class="stat">${formattedMsrp}<sup>${suffix}</sup></div>
            </div>
            <hr />
        `;
    }

    let tdgExists = document.querySelector('.tire-decision-guide') !== null;

    if (window.BATO.CONFIG.region !== 'la') {
        const formattedWarrantyDistance = tdgExists 
            ? (warrantyDistance !== '0.0' ? formatMileage(warrantyDistance) : (window.BATO.CONFIG.lang_iso === 'fr_CA' ? 'S.O.' : 'N/A'))
            : (warrantyDistance !== '0' ? warrantyDistance : (window.BATO.CONFIG.lang_iso === 'fr_CA' ? 'S.O.' : 'N/A'));
    
        theCardContent += `
            <div>
                <div class="label">${i18n.tireCardLimitedMileageWarranty}</div>
                <div class="stat">${formattedWarrantyDistance}</div>
            </div>
            <hr />
        `;    
    }
    
    function formatMileage(mileage) {
        // Convert to an integer to remove any decimals
        const mileageInt = parseInt(mileage, 10);
        // Divide by 1000 and append "K" for thousands
        return `${mileageInt / 1000}K`;
    }
    

    theCardContent += `

                    <div>
                        <div class="label">${i18n.tireCardTireType}</div>
                        <div class="stat">${tireType}</div>
                    </div>

                    <hr />
                    <div>
                        <ul>
                            <li>${rtb1}</li>
                            <li>${rtb2}</li>
        `;

        if(rtb3 && rtb3 != '') {
            theCardContent += `<li>${rtb3}</li>`;
        }

        if(rtb4 && rtb4 != '') {
            theCardContent += `<li>${rtb4}</li>`;
        }

        theCardContent += `
                        </ul>
                    </div>

                </div>

            </div>

            <div class="details">

                <div class="heading">${i18n.tireCardPerformanceRatings}</div>
                <i class="expand expanded"></i>

                <div class="details-section performance active">
                
                `;
                    
                if(dryperformance != '0') {
                    theCardContent += `
                    <div>
                        <span>${i18n.tirePerformanceRatingDryPerformance}</span>
                        <div class="performance__row">
                            <div class="performance__rating-bar"><div class="performance__rating-bar-inner data-stat-${dryperformance}"></div></div>
                        </div>
                    </div>`;
                }

                if(wetperformance != '0') {
                    theCardContent += `
                    <div>
                        <span>${i18n.tirePerformanceRatingWetPerformance}</span>
                        <div class="performance__row">
                            <div class="performance__rating-bar"><div class="performance__rating-bar-inner data-stat-${wetperformance}"></div></div>
                        </div>
                    </div>`;
                }

                if(quietride != '0') {
                    theCardContent += `
                    <div>
                        <span>${i18n.tirePerformanceRatingQuietRide}</span>
                        <div class="performance__row">
                            <div class="performance__rating-bar"><div class="performance__rating-bar-inner data-stat-${quietride}"></div></div>
                        </div>
                    </div>
                    `;
                }

                if(ridecomfort != '0') {
                    theCardContent += `
                    <div>
                        <span>${i18n.tirePerformanceRatingRideComfort}</span>
                        <div class="performance__row">
                            <div class="performance__rating-bar"><div class="performance__rating-bar-inner data-stat-${ridecomfort}"></div></div>
                        </div>
                    </div>
                    `;
                }

                if(fuelefficiency != '0') {
                    theCardContent += `
                    <div>
                        <span>${i18n.tirePerformanceRatingFuelEfficiency}</span>
                        <div class="performance__row">
                            <div class="performance__rating-bar"><div class="performance__rating-bar-inner data-stat-${fuelefficiency}"></div></div>
                        </div>
                    </div>
                    `;
                }
                    
                if(iceperformance != '0') {
                    theCardContent += `
                    <div>
                        <span>${i18n.tirePerformanceRatingIcePerformance}</span>
                        <div class="performance__row">
                            <div class="performance__rating-bar"><div class="performance__rating-bar-inner data-stat-${iceperformance}"></div></div>
                        </div>
                    </div>
                    `;
                }

                if(snowperformance != '0') {
                    theCardContent += `
                    <div>
                        <span>${i18n.tirePerformanceRatingSnowPerformance}</span>
                        <div class="performance__row">
                            <div class="performance__rating-bar"><div class="performance__rating-bar-inner data-stat-${snowperformance}"></div></div>
                        </div>
                    </div>
                    `;
                }

                if(offroadperformance != '0') {
                    theCardContent += `
                    <div>
                        <span>${i18n.tirePerformanceRatingOffRoadPerformance}</span>
                        <div class="performance__row">
                            <div class="performance__rating-bar"><div class="performance__rating-bar-inner data-stat-${offroadperformance}"></div></div>
                        </div>
                    </div>
                    `;
                }
                
        theCardContent +=  `
                        </div>
                    </div>
                    <div class="details">
                        <div class="heading">${i18n.tireCardSpecifications}</div>
                        <i class="expand expanded"></i>
                        <div class="details-section specifications active"> 
                    `;

    if(speedrating && speedrating != '') {

        theCardContent += `

                    <hr />
                    <div>
                        <div class="label">${i18n.tireDetailSpeedRating}</div>
                        <div class="stat">${speedrating}</div>
                    </div>

        `;

    }

    if(treaddepth && treaddepth != '' && treaddepth != '0') {

        theCardContent += `

                    <hr />
                    <div>
                        <div class="label">${i18n.tireDetailTreadDepth}</div>
                        <div class="stat">${treaddepth}/32 in.</div>
                    </div>

        `;

    }

    if(utqg && utqg != '') {

        theCardContent += `

                    <hr />
                    <div>
                        <div class="label">${i18n.tireDetailUtqgRating}</div>
                        <div class="stat">${utqg}</div>
                    </div>

        `;

    }

    if(tireweight && tireweight != '' && tireweight != '0.0') {

        theCardContent += `

                    <hr />
                    <div>
                        <div class="label">${i18n.tireDetailTireWeight}</div>
                        <div class="stat">${tireweight} lbs.</div>
                    </div>

        `;

    }

    theCardContent += `

                </div>
            </div>

            <a class="button see-details" href="${detailsLink}">${i18n.tireCardSeeDetails}</a>
            <a class="button button--tertiary" href="#locate-tire">${i18n.tireCardLocateTire}</a>

        </div>

    </div>

    `;

    return theCardContent;

}