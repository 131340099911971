import elementListener from '../../../../../js/library/eventHelpers.js';

// setup window object for components to consume
if (!window.BATO) window.BATO = {};
if (!window.adobeDataLayer) window.adobeDataLayer = [];

const tireSearchResults = document.querySelector(".tire-search-results");
const tireCardCompareInputs = (tireSearchResults != null) ? tireSearchResults.querySelectorAll(".cmp-tire-card-search .cmp-tire-card__checkbox input") : null;
const tireComparisonTray = (tireSearchResults != null) ? tireSearchResults.querySelector(".tire-comparison-tray") : null;
const tireComparison = (tireSearchResults != null) ? tireSearchResults.querySelector(".tire-comparison") : null;

const tireComparisonAnalyticsObject = {
    "event": "",
    "articleID": [],
    "productID": []
};

export const init = () => {
    if (tireSearchResults) {

        if (tireCardCompareInputs.length > 0) {
            tireCardCompareInputs.forEach(tireCardCompareInput => {
                tireCardCompareInput.addEventListener('click', tireCardCompareHandler)
            });
        }

        if(tireComparisonTray) {
            elementListener.addBubblerListener(tireComparisonTray, 'click', '.remove-tire', tireCardCompareHandler);
            elementListener.addBubblerListener(tireComparisonTray, 'click', '.button', tireComparisonTrayHandler);
        }

        if(tireComparison) {
            elementListener.addBubblerListener(tireComparison, 'click', '.slider', tireComparisonDifferenceHandler);
            elementListener.addBubblerListener(tireComparison, 'click', '.see-details', tireComparisonButtonHandler);
            elementListener.addBubblerListener(tireComparison, 'click', '.button--tertiary', tireComparisonButtonHandler);
        }


    }
}

const tireCardCompareHandler = (evt) => {

    let analyticsObject = JSON.parse(JSON.stringify(tireComparisonAnalyticsObject));
    let cardDataset = evt.target.parentElement.parentElement.dataset;

    // Only need to check for Checked value and this can handle both Checkboxes on Cards and 'X' Buttons in Tray
    if(evt.target.checked === true) {

        analyticsObject.event = 'tires.results.compare-add';
        analyticsObject.articleID.push(cardDataset.articlenumber);
        analyticsObject.productID.push(cardDataset.productid);

    } else {

        // Change source of dataset if coming from 'X' Button
        if(evt.target.classList.contains('remove-tire')) {
            cardDataset = evt.target.dataset;
        }

        analyticsObject.event = 'tires.results.compare-remove';
        analyticsObject.articleID.push(cardDataset.articlenumber);
        analyticsObject.productID.push(cardDataset.productid);

    }

    //console.log(analyticsObject);
    adobeDataLayer.push(analyticsObject);

}

const tireComparisonTrayHandler = (evt) => {

    let analyticsObject = JSON.parse(JSON.stringify(tireComparisonAnalyticsObject)),
        removeTireCards = tireComparisonTray.querySelectorAll('.remove-tire');

    analyticsObject.event = 'tires.results.compare-now';

    removeTireCards.forEach(removeTireCard => {

        analyticsObject.articleID.push(removeTireCard.dataset.articlenumber);
        analyticsObject.productID.push(removeTireCard.dataset.productid);

    });

    //console.log(analyticsObject);
    adobeDataLayer.push(analyticsObject);
}

const tireComparisonDifferenceHandler = (evt) => {

    let analyticsObject = JSON.parse(JSON.stringify(tireComparisonAnalyticsObject));

    analyticsObject.event = 'tire_comparison.highlight_differences';

    delete analyticsObject.articleID;
    delete analyticsObject.productID;

    //console.log(analyticsObject);
    adobeDataLayer.push(analyticsObject);
}

const tireComparisonButtonHandler = (evt) => {

    let analyticsObject = JSON.parse(JSON.stringify(tireComparisonAnalyticsObject));

    if(evt.target.classList.contains('see-details')) {
        analyticsObject.event = 'tire.detail';
    } else {
        analyticsObject.event = 'tire.locator';
    }

    analyticsObject.location = 'tire comparison';

    let comparisonCard = evt.target.closest('.cmp-tire-comparison__col'),
        comparisonCardDataset = comparisonCard.dataset;

    analyticsObject.articleID.push(comparisonCardDataset.articlenumberclean);
    analyticsObject.productID.push(comparisonCardDataset.productid);

    analyticsObject.tire = {};
    analyticsObject.tire['x-section'] = comparisonCardDataset.width;
    analyticsObject.tire['aspect'] = comparisonCardDataset.ratio;
    analyticsObject.tire['rim-size'] = comparisonCardDataset.diameter;

    //console.log(analyticsObject);
    adobeDataLayer.push(analyticsObject);
}

export default {
    init
}