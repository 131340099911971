import apiUtils from '../util/apiUtils.js';

const {
    site,
    country,
    lang_iso
} = window.BATO?.CONFIG || { site: 'bst', country: 'US', lang_iso: 'en_US' };

export const getHandoffByServiceId = async (serviceIds, articleId) => {
    try {
        if (!serviceIds) throw new Error('you need to provide a service id');

        const params = {
            site,
            country,
            lang_iso,
            articleId: articleId,
            serviceIds: serviceIds
        }

        const response = await fetch(apiUtils.apiPath('services/handoff-by-service-id', params));

        if (!response.ok) {
            const errorData = await response.json();
            const error = `the api response in getHandoffByServiceId() is not ok: ${errorData}`;
            throw new Error(error);
        }

        const data = await response.json();

        if (data) return data;

    } catch (err) {
        console.log(`there's been an error in the getHandoffByServiceId() function: ${err}`);
        return {
            success: false
        }
    }
}

export const getAvailabilityBySiteCountryZip = async (zipCode) => {
    try {
        if (!zipCode) throw new Error('you need to provide a zip');

        const params = {
            site,
            country,
            lang_iso,
            zipCode: zipCode
        }

        const response = await fetch(apiUtils.apiPath('services/availability-by-site-country-zip', params));

        if (!response.ok) {
            const errorData = await response.json();
            const error = `the api response in getAvailabilityBySiteCountryZip() is not ok: ${errorData}`;
            throw new Error(error);
        }

        const data = await response.json();

        if (data) return data;

    } catch (err) {
        console.log(`there's been an error in the getAvailabilityBySiteCountryZip() function: ${err}`);
        return {
            success: false
        }
    }
}

export default {
    getHandoffByServiceId,
    getAvailabilityBySiteCountryZip
}