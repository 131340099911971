import '../scss/will-it-fit.scss';

// setup window object for components to consume
if (!window.BATO) window.BATO = {};
if (!window.BATO.tireDetails) window.BATO.tireDetails = {};
window.BATO.tireDetails.willItFitProgrammatically = true; // Flag for Analytics

const catalogService = window.BATO.Services.catalog;
const btnsWithProductId = [];
const hrefContext = 'willitfit';


let vehicleSelector, btns, tireNames, tires, tireDetail, tireDetailData, tireSize, viewedTires, vehicleStatus, vehicleFitment, fitmentWrapper, willItFit, pageType, catalogRefreshFlag = true;

const init = () => {
	willItFit = document.querySelectorAll('.will-it-fit');
	vehicleStatus = window.BATO.Store.getState().vehicles.status;
	vehicleFitment = window.BATO.Store.getState().vehicles.vehicle;

	window.BATO.Store.observeStore(window.BATO.Store, function(state) {
		return state.flex.previouslyViewedTires;
	}, tireSizeChangeEventHandler);

	window.BATO.Store.observeStore(window.BATO.Store, function(state) {
		return state.vehicles;
	}, vehicleSelectorEventHandler);
}

const getCatalogTires = (service, size) => {
	const getTires = (service == 'ymmt') ? catalogService.getTiresByYMMT() : catalogService.getTiresByTireSize();
	let pageType = window?.BATO?.ANALYTICS?.page?.page_type;

	getTires.then(response => {
		setFit(response.data);

	    if (pageType = "pdp") {
	    	tireDetailData = response.data;
			tires = tireDetailData && tireDetailData.tires;
			tireNames = tireDetailData && tireDetailData.tireNames;
			let variantSizes = [];

			if (tires && tireNames) {
				willItFit.forEach(fit => {
					let productId = fit.dataset?.productId;
					let productSize = size;
					let doesFit = tireNames.indexOf(productId) != -1 ? true : false;
					let tire = tires[productId];

					if (tire && productSize) {
						doesFit = tire.variantSizes.indexOf(productSize) != -1 ? true : false;
					}

					if (doesFit) {
						variantSizes = tire.variantSizes;
					}
				});

				window.BATO?.ProfileActions?.setMatchedSizes(variantSizes);
			}
	    }
	});
}

const setFit = (data, size) => {
    tireDetailData = data;
	tires = tireDetailData && tireDetailData.tires;
	tireNames = tireDetailData && tireDetailData.tireNames;

	if (tires && tireNames) {

		willItFit.forEach(fit => {

			let productId = fit.dataset?.productId;
			let productSize = size;
			let doesFit = tireNames.indexOf(productId) != -1 ? true : false;
			let tire = tires[productId];

			// Check if Dropdown Value Exists
			let tireSizeDropdown = document.querySelector('.tire-size-dropdown');
			if (tireSizeDropdown) {
				let selectedSize = tireSizeDropdown.options[tireSizeDropdown.selectedIndex].dataset;
				selectedSize ? productSize = selectedSize.size : '';
			}

			if (tire && productSize) {
			    doesFit = tire.variantSizes.indexOf(productSize) != -1 ? true : false;
			}

			fit.classList.remove('will-it-fit--fit', 'will-it-fit--notfit', 'will-it-fit--notsure');

			if (doesFit) {
				fit.classList.add('will-it-fit--fit');
			} else {
				fit.classList.add('will-it-fit--notfit');
			}

			fit.dispatchEvent(new CustomEvent('will-it-fit', { bubbles: false }));

		});

		if (window.BATO.tireList) {
			window.BATO.tireList.sort();
		}

	} else {
		willItFit.forEach(fit => {
			fit.classList.add('will-it-fit--notsure');
			fit.dispatchEvent(new CustomEvent('will-it-fit', { bubbles: false }));
		});
	}

	// Flag For Analytics
	setTimeout(()=>{
		window.BATO.tireDetails.willItFitProgrammatically = false;
	},500);

}

const setFitSize = (size) => {
	setFit(tireDetailData, size || tireSize);
}

const vehicleSelectorEventHandler = (state) => {
    vehicleStatus = state.status;

    if (vehicleStatus == "known" && willItFit.length > 0) {
    	getCatalogTires('ymmt');
    } else {
        willItFit.forEach(fit => {
			fit.classList.add('will-it-fit--notsure');
		});
    }

}

const tireSizeChangeEventHandler = (state) => {
    let pageType = window?.BATO?.ANALYTICS?.page?.page_type;

	if (pageType = "pdp") {
		tireSize = state?.length > 0 ? state[0]?.productSize : null;
	    setFitSize(tireSize);
	}

}

document.addEventListener("DOMContentLoaded", () => {
	init();
})
