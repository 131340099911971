/**
 * checks for language directory (ie 'fr', 'es', etc) in URL path
**/

const urlLanguageCheck = () => {

    let firstDirectory = window.location.pathname.split('/')[1],
        languageInUrl = false;

    //console.log('firstDirectory',firstDirectory);

    if(firstDirectory.length == 2){

        let langISO = window.BATO.CONFIG.lang_iso;

        if(langISO.includes(firstDirectory)){
            languageInUrl = firstDirectory;

        }
    }

    //console.log('languageInUrl',languageInUrl);

    return languageInUrl;

};

export { urlLanguageCheck };
