export default function stubData() {

    const JSONData = {
        "data": {
            "qty": "2",
            "tireNames": [
                "BRIDGESTONEBLIZZAKLM32",
                "BRIDGESTONEPOTENZARE71R"
            ],
            "tires": {
                "BRIDGESTONEBLIZZAKLM32": {
                    "base": {
                        "brand": "Bridgestone",
                        "subBrand": "Blizzak",
                        "name": "LM-32",
                        "category": "Passenger Tires",
                        "tireType": "Winter",
                        "vehicleType": [
                            "SUV"
                        ],
                        "description": "The Bridgestone Blizzak LM-32 is developed for the drivers of luxury vehicles who need premium performance on snow covered roads."
                    },
                    "performanceRatings": {
                        "dryPerformance": "6",
                        "icePerformance": "7",
                        "iceSnowWinterAverage": "7",
                        "offRoadPerformance": "0",
                        "rideComfort": "6",
                        "snowPerformance": "6",
                        "wetPerformance": "7",
                        "winterPerformance": "8",
                        "fuelEfficiency": "5",
                        "quietRide": "5",
                        "treadLife": "5"
                    },
                    "prices": {
                        "max": {
                            "currencyIso": "USD",
                            "value": "191.99"
                        },
                        "min": {
                            "currencyIso": "USD",
                            "value": "191.99"
                        }
                    },
                    "media": {
                        "assets": {
                            "imageLarge": "/content/dam/bridgestone/consumer/bst/tires/models/blizzak-lm-32/large.jpg",
                            "image1": "/content/dam/bridgestone/consumer/bst/tires/models/blizzak-lm-32/side.jpg",
                            "image2": "/content/dam/bridgestone/consumer/bst/tires/models/blizzak-lm-32/tilted.jpg",
                            "image3": "/content/dam/bridgestone/consumer/bst/tires/models/blizzak-lm-32/front.jpg",
                            "videoSmall": "",
                            "video": ""
                        }
                    },
                    "warranties": {
                        "warrantyTypes": {
                            "basicWarranty": false,
                            "free100rplWarranty": false,
                            "free50rplWarranty": false,
                            "goldWarranty": false,
                            "platinumWarranty": true,
                            "supplementalLimitedWarranty": false,
                            "thirtyDayTrialEligible": false,
                            "winterTemporaryTireWarranty": false
                        }
                    },
                    "marketing": {
                        "statement": "The Bridgestone Blizzak LM-32 is developed for the drivers of luxury vehicles who need premium performance on winter&#8217;s demanding snow covered roads.",
                        "features": [
                            {
                                "benefit": "Provides confident grip and braking",
                                "name": "New Top Compound"
                            },
                            {
                                "benefit": "Improves snow and ice traction by increasing the number of biting edges",
                                "name": "Next Generation High Sipe Density Pattern"
                            },
                            {
                                "benefit": "Provides precision cornering and handling",
                                "name": "Large Shoulder Blocks"
                            },
                            {
                                "benefit": "Improves fuel efficiency",
                                "name": "Optimized Construction"
                            }
                        ],
                        "reasonsToBuy": {
                            "headline": "DRIVE WITH CONFIDENCE IN ALL WINTER CONDITIONS",
                            "reasons": [
                                "Impressive grip and control in winter driving conditions",
                                "Provides dependable cornering and handling",
                                "Maintains performance on wet, dry, icy, slushy and snowy roads",
                                "Winter Tire Limited Warranty*"
                            ]
                        }
                    },
                    "variantArticles": [
                        "2840",
                        "1234"
                    ],
                    "variantSizes": [
                        "225/40R18",
                        "235/35R16"
                    ],
                    "variantsBySizes": {
                        "225/40R18": [
                            "2840"
                        ],
                        "235/35R16": [
                            "1234"
                        ]
                    },
                    "variants": {
                        "2840": {
                            "article": "2840",
                            "specs": {
                                "size": "225/40R18",
                                "width": "225.0",
                                "ratio": "40.0",
                                "diameter": "18.0",
                                "net_weight_lbs": "24.0",
                                "sidewall": "BW",
                                "ply_rating": "XL",
                                "single_load_index": "92",
                                "speed_rating": "V",
                                "UTQC_tread_wear_rating": "0",
                                "UTQC_traction": "N",
                                "UTQC_temperature": "NOT REQUIRED BY FEDERAL LAW",
                                "tread_depth_in_32_of_inch": "11",
                                "warranty_distance": "60000.0",
                                "warranty_units": "miles",
                                "overallDiameter": "25.08",
                                "revsPerMile": "827.0",
                                "treadWear": "0",
                                "catalog": "Y",
                                "statusDescription": "production",
                                "technology": "WINTER",
                                "speedRatingMph": "149",
                                "loadIndexPounds": "1776",
                                "bestInClass": "Y"
                            },
                            "msrp": {
                                "currencyIso": "USD",
                                "value": "191.99"
                            }
                        },
                        "1234": {
                            "article": "1234",
                            "specs": {
                                "size": "235/35R16",
                                "width": "235.0",
                                "ratio": "35.0",
                                "diameter": "16.0",
                                "net_weight_lbs": "31.0",
                                "sidewall": "B",
                                "ply_rating": "XL",
                                "single_load_index": "92",
                                "speed_rating": "V",
                                "UTQC_tread_wear_rating": "0",
                                "UTQC_traction": "N",
                                "UTQC_temperature": "NOT REQUIRED BY FEDERAL LAW",
                                "tread_depth_in_32_of_inch": "11",
                                "warranty_distance": "50000.0",
                                "warranty_units": "miles",
                                "overallDiameter": "25.08",
                                "revsPerMile": "827.0",
                                "treadWear": "0",
                                "catalog": "Y",
                                "statusDescription": "discontinued",
                                "technology": "WINTER",
                                "speedRatingMph": "149",
                                "loadIndexPounds": "1776",
                                "bestInClass": "Y"
                            },
                            "msrp": {
                                "currencyIso": "USD",
                                "value": "N/A"
                            }
                        }
                    }
                },
                "BRIDGESTONEPOTENZARE71R": {
                    "base": {
                        "brand": "Bridgestone",
                        "subBrand": "Potenza",
                        "name": "RE-71R",
                        "category": "Performance",
                        "tireType": "Summer",
                        "vehicleType": [
                            "SUV"
                        ],
                        "description": "Not for the casual driver, the Bridgestone Potenza RE-71R is engineered with technology inspired by racing. Designed for maximum grip under braking and cornering conditions the Potenza RE-71R responds to your driving in wet or dry conditions. So whether you&#8217;re on the road or on the track, the Potenza RE-71R will give you the extra advantage you need to win."
                    },
                    "performanceRatings": {
                        "dryPerformance": "10",
                        "icePerformance": "0",
                        "iceSnowWinterAverage": "0",
                        "offRoadPerformance": "0",
                        "rideComfort": "8",
                        "snowPerformance": "0",
                        "wetPerformance": "9",
                        "winterPerformance": "0",
                        "fuelEfficiency": "0",
                        "quietRide": "7",
                        "treadLife": "7"
                    },
                    "prices": {
                        "max": {
                            "currencyIso": "USD",
                            "value": "255.00"
                        },
                        "min": {
                            "currencyIso": "USD",
                            "value": "255.00"
                        }
                    },
                    "media": {
                        "assets": {
                            "imageLarge": "/content/dam/bridgestone/consumer/bst/tires/models/potenza-re-71R/side.jpg",
                            "image1": "/content/dam/bridgestone/consumer/bst/tires/models/potenza-re-71R/side.jpg",
                            "image2": "/content/dam/bridgestone/consumer/bst/tires/models/potenza-re-71R/tilted.jpg",
                            "image3": "/content/dam/bridgestone/consumer/bst/tires/models/potenza-re-71R/front.jpg",
                            "videoSmall": "",
                            "video": ""
                        }
                    },
                    "warranties": {
                        "warrantyTypes": {
                            "basicWarranty": false,
                            "free100rplWarranty": false,
                            "free50rplWarranty": false,
                            "goldWarranty": false,
                            "platinumWarranty": true,
                            "supplementalLimitedWarranty": false,
                            "thirtyDayTrialEligible": true,
                            "winterTemporaryTireWarranty": false
                        }
                    },
                    "marketing": {
                        "statement": "POTENZA RE-71R 225/35R19 TLBLPS88WXL",
                        "features": [
                            {
                                "benefit": "Test Feature Benefit 95/50R16",
                                "name": "Test Feature Text 95/50R16"
                            },
                            {
                                "benefit": "Improves snow and ice traction by increasing the number of biting edges",
                                "name": "Zigzag Sipes"
                            },
                            {
                                "benefit": "Enhance dry performance while maintaining snow, ice and wet traction",
                                "name": "3-D Sipes"
                            },
                            {
                                "benefit": "Enhances snow traction and water evacuation",
                                "name": "Continuous Lug"
                            },
                            {
                                "benefit": "provides maximum contact and grip when braking and cornering",
                                "name": "UNIQUE TREAD COMPOUND"
                            },
                            {
                                "benefit": "provides effective drainage in wet conditions",
                                "name": "HYDRO EVACUATION SURFACE"
                            },
                            {
                                "benefit": "promotes agile steering response",
                                "name": "A WIDE CENTER RIB"
                            },
                            {
                                "benefit": "allows for extreme cornering",
                                "name": "SHOULDER HYDRO EVACUATION STIFFNESS"
                            }
                        ],
                        "reasonsToBuy": {
                            "headline": "MAKE EVERY HUNDREDTH OF A SECOND COUNT",
                            "reasons": [
                                "Unique construction and tread compounds are designed for maximum grip",
                                "Engineered with Bridgestone&apos;s most extreme racing-inspired technology",
                                "Shoulder stiffness allows for confident cornering",
                                "Extreme summer performance tire"
                            ]
                        }
                    },
                    "variantArticles": [
                        "9742",
                        "4321"
                    ],
                    "variantSizes": [
                        "225/40R18",
                        "215/40R20"
                    ],
                    "variantsBySizes": {
                        "225/40R18": [
                            "9742"
                        ],
                        "215/40R20": [
                            "4321"
                        ]
                    },
                    "variants": {
                        "9742": {
                            "article": "9742",
                            "specs": {
                                "size": "225/40R18",
                                "width": "225.0",
                                "ratio": "40.0",
                                "diameter": "18.0",
                                "net_weight_lbs": "23.0",
                                "sidewall": "BW",
                                "ply_rating": "XL",
                                "single_load_index": "92",
                                "speed_rating": "W",
                                "UTQC_tread_wear_rating": "200",
                                "UTQC_traction": "A",
                                "UTQC_temperature": "A",
                                "tread_depth_in_32_of_inch": "8",
                                "warranty_distance": "60000.0",
                                "warranty_units": "miles",
                                "overallDiameter": "25.1",
                                "revsPerMile": "831.0",
                                "treadWear": "200",
                                "catalog": "Y",
                                "statusDescription": "production",
                                "technology": "RUN FLAT",
                                "speedRatingMph": "168",
                                "loadIndexPounds": "1356",
                                "bestInClass": "Y"
                            },
                            "msrp": {
                                "currencyIso": "USD",
                                "value": "255.00"
                            }
                        },
                        "4321": {
                            "article": "4321",
                            "specs": {
                                "size": "215/40R20",
                                "width": "215.0",
                                "ratio": "40.0",
                                "diameter": "20.0",
                                "net_weight_lbs": "25.0",
                                "sidewall": "BW",
                                "ply_rating": "XL",
                                "single_load_index": "91",
                                "speed_rating": "W",
                                "UTQC_tread_wear_rating": "200",
                                "UTQC_traction": "A",
                                "UTQC_temperature": "A",
                                "tread_depth_in_32_of_inch": "8",
                                "warranty_distance": "40000.0",
                                "warranty_units": "miles",
                                "overallDiameter": "25.1",
                                "revsPerMile": "777.0",
                                "treadWear": "200",
                                "catalog": "Y",
                                "statusDescription": "discontinued",
                                "technology": "RUN FLAT",
                                "speedRatingMph": "168",
                                "loadIndexPounds": "1356",
                                "bestInClass": "Y"
                            },
                            "msrp": {
                                "currencyIso": "USD",
                                "value": "N/A"
                            }
                        }
                    }
                }
            },
            "sizes": [
                "225/40R18",
                "225/40R18",
                "235/35R16"
            ],
            "prices": [
                "N/A",
                "191.99",
                "255.00"
            ]
        },
        "params": {
            "full": "Y",
            "category": "ANY",
            "vehicleType": "ANY",
            "tireType": "ANY",
            "site": "bst",
            "country": "US",
            "lang_iso": "en_US"
        },
        "messages": {
            "error": ""
        },
        "success": "true",
        "status": "200"
    };

    return JSONData;

}