import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Star } from './Star';

const Rating = (props) => {
    const {
        rating,
        reviewCount,
        url,
        classes,
        id,
        productType,
        showNumbers,
        reviewCountClick
    } = props;
    const starSize = classes.includes('large')
        ? 'large'
        : classes.includes('small')
        ? 'small'
        : 'medium';
    const domainArr = window.location.hostname.split('.');
    const storeLink = `https://local.${domainArr[domainArr.length - 2]}.${
        domainArr[domainArr.length - 1]
    }/location/${id}/#iframe-1`;

    // need some way to either link or anchor link to reviews section from review count

    const handleReviewCountClick = () => {
        reviewCountClick();
    };

    const [finalUrl, setFinalUrl] = useState();

    useEffect(()=> {
        setFinalUrl(url);
    },[]);

    const getStarPercent = (index) => {
        let percent;
        // return the fraction of star vector based on index and rating.
        if (rating >= index + 0.95) {
            percent = '100';
        } else if (rating >= index + 0.85) {
            percent = '90';
        } else if (rating >= index + 0.75) {
            percent = '80';
        } else if (rating >= index + 0.65) {
            percent = '70';
        } else if (rating >= index + 0.55) {
            percent = '60';
        } else if (rating >= index + 0.45) {
            percent = '50';
        } else if (rating >= index + 0.35) {
            percent = '40';
        } else if (rating >= index + 0.25) {
            percent = '30';
        } else if (rating >= index + 0.15) {
            percent = '20';
        } else if (rating >= index + 0.05) {
            percent = '10';
        } else {
            percent = '0';
        }

        return percent;
    };

    return (
        <>
            <ul className="stars js-stars" data-rating={rating} data-review-count={reviewCount}>
                <li>
                    <Star size={starSize} percent={getStarPercent(0)} />
                </li>
                <li>
                    <Star size={starSize} percent={getStarPercent(1)} />
                </li>
                <li>
                    <Star size={starSize} percent={getStarPercent(2)} />
                </li>
                <li>
                    <Star size={starSize} percent={getStarPercent(3)} />
                </li>
                <li>
                    <Star size={starSize} percent={getStarPercent(4)} />
                </li>
            </ul>
            {rating && showNumbers && (
                <>
                    <div className="average-rating" aria-label="average star rating">{rating}</div>
                    {reviewCount && (
                        <div className="review-count" aria-label="total number of reviews">
                            {finalUrl ? (
                                <a
                                    href={finalUrl}
                                    onClick={handleReviewCountClick}
                                >{`(${reviewCount})`}</a>
                            ) : productType === 'store' ? (
                                <a
                                    href={storeLink}
                                    onClick={handleReviewCountClick}
                                >{`(${reviewCount})`}</a>
                            ) : (
                                <>{`(${reviewCount})`}</>
                            )}
                        </div>
                    )}
                </>
            )}
        </>
    );
};

Rating.defaultProps = {
    classes: '',
    showNumbers: false,
    reviewCountClick: () => {},
    id: '',
    productType: ''
};

Rating.propTypes = {
    rating: PropTypes.number.isRequired,
    reviewCount: PropTypes.number,
    reviewCountClick: PropTypes.func,
    url: PropTypes.string,
    id: PropTypes.string,
    productType: PropTypes.string,
    classes: PropTypes.string,
    showNumbers: PropTypes.bool
};

export { Rating };
