import '../scss/video.scss';

document.addEventListener("DOMContentLoaded", function() {

    if (!window.BATO) window.BATO = {};

    const videoRender = (e) => {

        const allVideos = document.querySelectorAll(".cmp-video-wrapper");

        allVideos.forEach(function(video,index){

            if(!video.closest('.media-gallery')) {

                let id = video.dataset.videoid,
                    provider = video.dataset.videotype,
                    path = video.dataset.videopath,
                    source = video.dataset.videosource,
                    filePoster = video.dataset.fileposter,
                    playInModal = video.dataset.playinmodal,
                    enablePoster = video.dataset.enableposter,
                    title = video.dataset.title;

                let videoEle = "";

                if(source === 'native') {

                    videoEle = document.createElement('video');
                    videoEle.setAttribute('controls', 'true');
                    videoEle.setAttribute('title', title);

                    let videoSource = document.createElement("source");
                    videoSource.setAttribute('src', path);
                    videoEle.appendChild(videoSource);

                    if (videoSource.getAttribute('src').length) {
                        renderVideo(videoEle,enablePoster);
                    }

                } else {

                    videoEle = document.createElement('iframe');

                    let autoPlay = enablePoster === 'true' ? '1' : '0';

                    switch (true) {

                        case provider === 'youtube':
                            let youtubeEmbedUrl = 'https://www.youtube.com/embed/' + id;
                            video.setAttribute('data-embedUrl', youtubeEmbedUrl);
                            videoEle.setAttribute('src', youtubeEmbedUrl + '?autoplay=' + autoPlay + '&enablejsapi=1' + '&rel=0');
                            videoEle.setAttribute('allow', 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture');
                            break;
                        case provider === 'vimeo':
                            let vimeoEmbedUrl = 'https://player.vimeo.com/video/' + id;
                            video.setAttribute('data-embedUrl', youtubeEmbedUrl);
                            videoEle.setAttribute('src', vimeoEmbedUrl + '?autoplay=' + autoPlay);
                            videoEle.setAttribute('allow', 'autoplay; fullscreen');
                            break;
                        default:
                            break;

                    }

                    if (videoEle.getAttribute('src').length) {

                        videoEle.setAttribute('allowfullscreen', 'allowfullscreen');
                        videoEle.setAttribute('id', 'video-cmp-iframe' + id);
                        videoEle.setAttribute('class', 'video-cmp-iframe');
                        videoEle.setAttribute('title', title);
                        initVideoSeoSchema(video.dataset);
                        renderVideo(videoEle,enablePoster,filePoster,playInModal);

                    }
                }

                function renderVideo(videoEle,enablePoster,filePoster,playInModal) {

                    let videoCmpEle = document.getElementById('cmp-video-' + id);

                    if(playInModal) {

                        videoCmpEle.addEventListener('click', function(e){
                            window.BATO.modal.activateModal(videoEle, true, e.target, true, 'modal');
                        });

                    } else {

                        let videoCmpPosterEle = document.getElementById('cmp-video-poster-' + id);

                        if (enablePoster === 'true') {

                            let videoCmpPosterEleImg = document.querySelector('#cmp-video-poster-' + id + ' picture img');
                            let posterWidth = parseInt(videoCmpPosterEleImg.clientWidth);
                            let posterHeight = parseInt(videoCmpPosterEleImg.clientHeight);

                            videoCmpEle.addEventListener('click', function(e){

                                videoCmpPosterEle.style.opacity = '0';

                                videoCmpPosterEle.addEventListener('transitionend', function(){
                                    videoCmpEle.style.width = posterWidth + "px";
                                    videoCmpEle.style.height = posterHeight + "px";
                                    videoCmpEle.classList.add("cmp-video-iframe-wrapper");
                                    videoCmpEle.replaceChild(videoEle,videoCmpPosterEle);
                                    videoCmpEle.style.opacity = '1';
                                });
                            });

                        } else {
                            videoCmpEle.classList.add("cmp-video-iframe-wrapper");
                            videoCmpEle.replaceChild(videoEle,videoCmpPosterEle);
                            videoCmpEle.style.opacity = '1';
                        }

                    }
                }
            }
        });
    };

    const initVideoSeoSchema = (data) => {

        let name = data.hasOwnProperty('title') ? data.title : '';
        let description = data.hasOwnProperty('title') ? data.description : '';
        let embedUrl = data.hasOwnProperty('embedurl') ? data.embedurl : '';
        let uploadDate = data.hasOwnProperty('lastmodified') ? data.lastmodified : '';
        let source = data.videosource === 'hosted' ? 'embedUrl' : 'contentUrl';
        let filePoster = data.hasOwnProperty('fileposter') ? window.location.origin + data.fileposter : '';

        if (description && name && filePoster) {

            let videoSchemaTemplate = {
                "@context": "https://schema.org",
                "@type": "VideoObject",
                "name": `${name}`,
                "description": `${description}`,
                "thumbnailUrl": [
                    `${filePoster}`
                ],
                [source]: `${embedUrl}`,
                "uploadDate": `${uploadDate}`
            };

            const script = document.createElement('script');
            script.setAttribute('type', 'application/ld+json');
            script.textContent = JSON.stringify(videoSchemaTemplate);
            document.head.appendChild(script);

        }

    };

    videoRender();
    window.BATO.videoRender = videoRender;

});
