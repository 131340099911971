import { validateForm } from './validateForm.js';

const fetchData = async (url, data, method) => {

	const _method = method || "GET";

	const _url = new URL(url);
	_url.search = new URLSearchParams(data);

	const _response = await fetch(_url.href);

	if (!_response.ok) {
	  const errorData = await _response.json();
	  const error = `there's an error in fetchData(): ${errorData.errors}`;
	  throw new Error(error);
	}

	const _data = await _response.json();

	if (_data) {
		return _data;
	}
}

const formResponse = (data, theForm) => {

	const submmissionSuccess = new Event("submit_success");
	const submmissionFail = new Event("submit_fail");

	let success;

	if(theForm.classList.contains('contact-us')){
		success = (data?.status == "200") ? true : false;
	}

	if(theForm.classList.contains('registration')){
		success = (data?.StatusCode == "0") ? true : false;
	}


	const form = theForm.getBoundingClientRect();

	theForm.classList.remove("success", "fail");

	if (success) {
		theForm.classList.add("success");
		theForm.dispatchEvent(submmissionSuccess);
	} else {
		theForm.classList.add("fail");
		theForm.dispatchEvent(submmissionFail);
	}

	window.scrollTo(0, form.top);
}

/**
 * Parent Function for Submitting Form Data to Consumer Tire Server
 * @param  {object}   	   event		Get The Submit Object
 * @param  {HTMLElement}   theForm 	 	The Parent Form to Target
*/

const submitForm = async (event, theForm) => {

	if (event) {
		event.preventDefault();
 	}

 	validateForm(false, theForm);

	const formData = new FormData(theForm);
	const data = {};
	const formValid = theForm.checkValidity();

	formData.forEach(function(value, key){
		data[key] = value;
	});

	if (!formValid) {
		validateForm(false, theForm);
		return false;
	}

	const formFetch = fetchData(theForm.action, data, theForm.method);

	formFetch
		.then(response => {
			formResponse(response, theForm);
		})
		.catch(error => {
			formResponse(error, theForm);
		})

}

export { submitForm };