/**
 * Handler to attach click event to Card types so the entire Card is clickable, not just the
 * inner A button or A link ...
 *
 * @param {card} str - element to look for
 */

const cardClickHandlerAttach = (evt) => {
    const targetTagType = evt.target.tagName.toLowerCase();
    const el = evt.currentTarget;

    // ignore any clicks from targets that likely already have another click action
    if ( !(targetTagType === 'a' || targetTagType === 'button' || targetTagType === 'label') ) {
        // Look for an A tag with class 'button' first as this might contain more detailed link definition
        // i.e. a tire-size to prepend to the URL ...
        let clickableEl = el.querySelector('a.button') ?? el.querySelector('a');
        if (clickableEl) clickableEl.click();
    }
}

const cardClickHandler = (card) => {
    document.querySelectorAll(card).forEach((cardEl) => {
        cardEl.addEventListener('click', cardClickHandlerAttach);
    });
}

export { cardClickHandler };
