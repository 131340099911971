import '../scss/analytics.scss';
import util from './util.js';
import user from './user.js';
import tireDetailPage from './tire-detail.js';
import catalog from "./catalog.js";
import storeDetailsPage from './store-details-page.js';
import cta from './cta.js';
import locateTire from './locate-tire.js';
import productDetail from './product-details.js';
import storeCardLoad from './store-card-load.js';
import storeDealerType from './store-dealer-type.js';
import storeDetails from './store-details.js';
import storeDetailsTelephone from './store-details-telephone.js';
import storeFSD from './store-fsd.js';
import storeLocatorSearch from './store-locator-search.js';
import storePurchase from './store-purchase.js';
import storePurchaseTransfer from './store-purchase-transfer.js';
import storeRadiusFilter from './store-radius-filter.js';
import storeSortBy from './store-sort-by.js';
import storeTelephone from './store-telephone.js';
import tabNav from './tab-navigation.js';
import tireComparison from './tire-comparison.js';
import tireDetailSizeChange from './tire-detail-size-change.js';
import tireFilter from './tire-filter.js';
import tireSortBy from './tire-sort-by.js';
import vehicleChange from './vehicleChange.js';
import vehicleSelector from './vehicleSelector.js';
import contactRegistrationForms from './forms.js'; 
import accordion from './accordion.js'; 
import ctaTypes from './cta-types.js'; 

// setup window object for components to consume
if (!window.BATO) window.BATO = {};

const init = () => {
	cta.init();
	ctaTypes.init(); 
	locateTire.init();
	productDetail.init();
	storeCardLoad.init();
	storeDealerType.init();
	storeDetails.init();
	storeDetailsTelephone.init();
	storeFSD.init();
	storeLocatorSearch.init();
	storePurchase.init();
	storePurchaseTransfer.init();
	storeRadiusFilter.init();
	storeSortBy.init();
	storeTelephone.init();
	tabNav.init();
	tireComparison.init();
	tireDetailSizeChange.init();
	tireFilter.init();
	tireSortBy.init();
	vehicleChange.init();
	vehicleSelector.init();
	contactRegistrationForms.init();
	accordion.init();
}

/* don't wait, init right away */
user.init();
tireDetailPage.init();
catalog.init();
storeDetailsPage.init();

util.cleanObject(window.BATO.ANALYTICS);

document.addEventListener("DOMContentLoaded", () => {
	init();
})

