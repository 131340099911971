import { validateFields } from './validateFields.js';
import { addValidationError } from './addValidationError.js';
import { clearValidationErrors } from './clearValidationErrors.js';

/**
 * Controller for Adding, Removing or Updating Validation Messages
 * @param  {HTMLElement}   formElement    The Form Element, Any valid Form Element (input, select, etc)
 * @param  {HTMLElement}   theForm 	 	  The Parent Form to Target
*/

const validateForm = (formElement, theForm) => {

	// No Single Form Element So Check Them All
	// This Should Only Handle A Final Check On Submit
	if(!formElement) {

		clearValidationErrors(false, theForm);

		let formItems = theForm.querySelectorAll("[type='text'], [type='tel'], [type='email'], select, [type='checkbox']");

		formItems.forEach(item => {
			addValidationError(item);
		});

	} else {

		let formItem = theForm.querySelector('[name="' + formElement.target.name + '"]');
		let formItemErrorExists = formElement.target.previousElementSibling;

		let el = validateFields(formItem);
		let valid = el.checkValidity();

		if (!valid && formItemErrorExists == null) {
			el.closest(".field").classList.add("error");
			el.insertAdjacentHTML('beforebegin', '<p class="invalid error ' + el.name + '">' + el.validationMessage + '</p>');
		}

		// If the Error Messaging Needs Updating
		// i.e. from 'Please Enter Value' to 'Value is Invalid'
		if(!valid && formItemErrorExists != null) {
			if(formItem.previousSibling.innerText !== el.validationMessage) {
				formItem.previousSibling.innerText = el.validationMessage;
			}
		}

		if(valid && formItemErrorExists != null) {
			clearValidationErrors(formElement, theForm);
		}

	}

}

export { validateForm };