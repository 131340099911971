import React, { Fragment } from 'react';
// import PropTypes from 'prop-types';

const ReportButton = (props) => {
    // const {} = props;

    return <></>;
};

ReportButton.defaultProps = {};

ReportButton.propTypes = {};

export { ReportButton };
