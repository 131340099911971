import React, { createContext, useContext, useReducer} from 'react';
import { merge } from 'lodash-es';
import {
  getReviewsById,
  getReviewSummariesByIds
} from '../../../../services/v1/js/api/reviews.js';
import { loadMoreReviewsAnalytics, sortReviewsAnalytics } from '../analytics.js';

const ReviewsContext = createContext();

const initialState = {
  reviewData: {
    status: 'initial',
    data: {
      nextPageKey: '',
      reviews: []
    },
    params: {
      country: '',
      groupId: '',
      id: '',
      lang_iso: '',
      pageSize: '',
      site: '',
      sortBy: '',
    }
  },
  reviewSummary: {
    status: 'initial',
    data: {
      summary: {}
    },
    params: {
      id: '',
      groupId: '',
      site: '',
      country: '',
      lang_iso: '',
    }
  },
  ratings: {
    status: 'initial',
    data: {
      summaries: []
    },
    params: {
      id: '',
      groupId: '',
      site: '',
      country: '',
      lang_iso: '',
    }
  }
}

const reviewsReducer = (state, action) => {
  switch(action.type) {
    case 'fetchReviewsAndSummaryStart': {
      return merge(
        initialState,
        {
          reviewData: {
            status: 'loading'
          }
        },
        {
          reviewSummary: {
            status: 'loading'
          }
        }
      );
    }
    case 'fetchReviewsAndSummarySuccess': {
      return {
        ...state,
        reviewData: {
          status: 'loaded',
          data: action.state.reviewData.data,
          params: action.state.reviewData.params
        },
        reviewSummary: {
          status: 'loaded',
          data: action.state.reviewSummary.data,
          params: action.state.reviewSummary.params
        }
      };
    }
    case 'fetchReviewsAndSummaryError': {
      return { ...state, reviewData: {status: 'error'}, reviewSummary: {status: 'error'}};
    }
    case 'sortReviewsStart': {
      return merge(
        state,
        {
          reviewData: {
            status: 'loading',
            data: {
              nextPageKey: '',
              reviews: []
            }
          }
        }
      )
    }
    case 'sortReviewsSuccess': {
      return {
        ...state,
        reviewData: {
          status: 'loaded',
          data: action.state.reviewData.data,
          params: action.state.reviewData.params
        }
      }
    }
    case 'sortReviewsError': {
      return { ...state, reviewData: { status: 'error' } }
    }
    case 'loadMoreReviewsStart': {
      return {
        ...state,
        reviewData: {
          ...state.reviewData,
          status: 'loadingMore'
        }
      }
    }
    case 'loadMoreReviewsSuccess': {
      return {
        ...state,
        reviewData: {
          status: 'loaded',
          data: {
            nextPageKey: action.state.reviewData.data.nextPageKey,
            reviews: state.reviewData.data['reviews'].concat(action.state.reviewData.data.reviews)
          },
          params: action.state.reviewData.params
        }
      }
     }
    case 'loadMoreReviewsError': { }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function ReviewsProvider({children}) {
  const [state, dispatch] = useReducer(reviewsReducer, initialState);

  const value = [state, dispatch];

  return <ReviewsContext.Provider value={value}>{children}</ReviewsContext.Provider>;
};

function useReviews() {
  const context = useContext(ReviewsContext);
  if (context === undefined) {
    throw new Error('useReviews() must be used within the ReviewsProvider')
  };

  return context;
}

const fetchReviewsAndSummary = async (dispatch, state, {
  id,
  site,
  country,
  lang_iso,
  sortBy,
  pageSize,
  groupId
}) => {
  dispatch({ type: 'fetchReviewsAndSummaryStart', state });
  try {
    let results = await Promise.all([
      getReviewsById({
        id,
        country,
        groupId,
        lang_iso,
        nextPageKey: null,
        pageSize,
        site,
        sortBy
      }),
      getReviewSummariesByIds({
        ids: [id],
        country,
        groupId,
        lang_iso,
        site
      })
    ]);
    const returnObj = {
      reviews: results[0],
      summary: results[1]
    }
    if (returnObj.reviews.success === "true" && returnObj.summary.success === "true") {
      /**
       *  set the single summary from the array of returned summaries
       *  (which really just means unwrap the array of 1)
       *  */
      let newSummary = returnObj.summary.data.summaries.filter(summ => id === summ.product_id)[0];
      const newState = {
        reviewData: returnObj.reviews,
        reviewSummary: {
          data: { summary: newSummary },
          params: returnObj.summary.params,
        }
      };
      dispatch({ type: 'fetchReviewsAndSummarySuccess', state: newState });
    }
  } catch (err) {
    dispatch({ type: 'fetchReviewsAndSummaryError', error: err })
  }
};

const sortReviews = async (dispatch, state, {
  id,
  country,
  groupId,
  lang_iso,
  pageSize,
  site,
  sortBy
})  => {
  dispatch({ type: 'sortReviewsStart', state });
  try {
    const results = await getReviewsById({
      id, country, groupId, lang_iso, nextPageKey: null, pageSize, site, sortBy
    });

    if (results.success === 'true') {
      const newState = {
        reviewData: {
          data: {
            reviews: results.data.reviews,
            nextPageKey: results.data.nextPageKey
          },
          params: results.params
        }
      }
      sortReviewsAnalytics();
      dispatch({type: 'sortReviewsSuccess', state: newState });
    }
  } catch (err) {
    dispatch({ type: 'sortReviewsError', error: err });
  }
}

const fetchMoreReviews = async (dispatch, state, {
  id,
  country,
  groupId,
  lang_iso,
  nextPageKey,
  pageSize,
  site,
  sortBy
}) => {
  dispatch({ type: 'loadMoreReviewsStart'});
  try {
    const results = await getReviewsById({
      id, country, groupId, lang_iso, nextPageKey, pageSize, site, sortBy
    });

    if (results.success === 'true') {
      const newState = {
        reviewData: {
          data: {
            reviews: results.data.reviews,
            nextPageKey: results.data.nextPageKey
          },
          params: results.params
        }
      }
      loadMoreReviewsAnalytics();
      dispatch({ type: 'loadMoreReviewsSuccess', state: newState })
    }
  } catch (err) {
    dispatch({ type: 'loadMoreReviewsError', error: err });
  }
};

export {
  ReviewsProvider,
  fetchMoreReviews,
  fetchReviewsAndSummary,
  sortReviews,
  useReviews,
};