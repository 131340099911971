import {fsdHTML} from "./fsd-card-markup.js";
import {fsdModalHTML} from "./fsd-modal-markup.js";

//**********************************//
// For FSD Integration
// Check that user has Vehicle, Location and at least 1 Viewed Tire
// Then check for FSD availability against users Profile Zip
// Then get Handoff Parameters to construct URL
// Prepend this card after store cards have rendered (in case of any issues with microservices)

if (!window.BATO) window.BATO = {};
if (!window.BATO.storeLocator) window.BATO.storeLocator = {};

window.BATO.storeLocator.handoff = {
    type: 'FSD',
    exists: false
}

document.addEventListener("DOMContentLoaded", function() {

    const handoffService = window.BATO.Services.handoff;

    const promiseAnimation = (elem, animation) => new Promise(resolve => {
        elem.style.animation = animation;
        elem.addEventListener("animationend", resolve);
    });


    const handoffCardAddRemove = (fsdHtml) => {

        const cardsWapperInner = document.querySelectorAll('.cards-wrapper');

        if (cardsWapperInner.length > 0) {

            if(fsdHtml) {

                cardsWapperInner.forEach((cwi) => {

                    // Since this method inserts the FSD card into a cards-wrapper, on pages
                    // where there is a cards-wrapper with an FSD card already inserted, and a dynamic
                    // flyout that could potentially have an FSD card, we need
                    // to ensure we do not keep perpetually adding FSD cards to the non-dynamic instance

                    if (!cwi.querySelector('.fsd-card')) {

                        let fsdCard = document.createElement('div');
                        fsdCard.classList.add('fsd-card');
                        fsdCard.innerHTML = fsdHtml;
                        fsdCard.tabIndex = 0;

                        cwi.prepend(fsdCard);

                        // Schedule Now Button Handler
                        let btnScheduleNow = fsdCard.querySelector('.basket-transfer');

                        btnScheduleNow.addEventListener("click", (e) => {
                            window.BATO.storeLocator.purchaseButtonEvent(btnScheduleNow);
                        });

                        // Learn More Button Handler
                        let btnLearnMore = fsdCard.querySelector('.button--tertiary');

                        btnLearnMore.addEventListener("click", (e) => {

                            // We can only have one modal on the screen at a time - this checks and removes any
                            // We also need to wait for the removal animations to finish before adding our new modal
                            if (document.querySelector('.dynamic-top-container')) {

                                window.BATO.storeLocator.purchaseButtonModalClose();
                                setTimeout(() => {
                                    // Pass Data Variables for Analytics
                                    window.BATO.modal.activateModal(fsdModalHTML(), true, null, true, 'modal');
                                }, 200);

                            } else {
                                window.BATO.modal.activateModal(fsdModalHTML(), true, null, true, 'modal');
                            }

                        });

                        // FSD Card Animations
                        const fsdHeight = () => promiseAnimation(fsdCard, "fsdHeight ease 0.8s forwards");
                        const fsdFade = () => promiseAnimation(fsdCard, "fsdFade ease 1s forwards");

                        Promise.resolve()
                            .then(fsdHeight)
                            .then(fsdFade)
                    }
                });

            } else {

                cardsWapperInner.forEach((cwi) => {
                    let fsdCard = cwi.querySelector('.fsd-card');
                    if (fsdCard) {
                        fsdCard.remove();
                    }
                });
            }
        }
    }

    const handoffCheck = () => {

        window.BATO.storeLocator.handoff.exists = 'pending';

        // Sanity check to ensure we even have a a handoff type provided before proceeding ...
        (async () => {
            while (!window.BATO.storeLocator.handoff.hasOwnProperty("type"))
                await new Promise(resolve => setTimeout(resolve, 250));

            if (window.BATO.Store.getState().vehicles.status === "known" &&
                window.BATO.Store.getState().location.status === "known" &&
                window.BATO.Store.getState().flex.previouslyViewedTires[0]) {

                // First check that Zipcode has a service available
                let getHandoffAvailabilityResponse = handoffService.getAvailabilityBySiteCountryZip(window.BATO.Store.getState().location.data.zip);

                getHandoffAvailabilityResponse.then(availabilityResponse => {

                    if (availabilityResponse?.success === 'true') {

                        // serviceToUse is something like 'FSD' (NA) or 'MELI' (LATAM)
                        let serviceToUse = window.BATO.storeLocator.handoff.type;

                        // Check for inclusion of our serviceToUse in the list of available services
                        if (availabilityResponse?.data?.serviceIds.includes(serviceToUse)) {

                            let handoffByServiceIdResponse = handoffService.getHandoffByServiceId(serviceToUse);

                            handoffByServiceIdResponse.then(serviceResponse => {

                                if (serviceResponse?.success === 'true' && serviceResponse?.data?.services[serviceToUse]) {

                                    window.BATO.storeLocator.handoff.exists = true

                                    let urlSchema = serviceResponse.data.services[serviceToUse],
                                        locationDataForHandoff = window.BATO.Store.getState().location.data,
                                        vehicleDataForHandoff = window.BATO.Store.getState().vehicles.vehicle,
                                        tireDataForHandoff = window.BATO.Store.getState().flex.previouslyViewedTires[0];

                                    // Data is named slightly differently on Catalog page ... :/
                                    let pb = (tireDataForHandoff.hasOwnProperty('productBrand')) ? tireDataForHandoff.productBrand : tireDataForHandoff.brand,
                                        psb = (tireDataForHandoff.hasOwnProperty('productSubbrandName')) ? tireDataForHandoff.productSubbrandName : tireDataForHandoff.subbrand,
                                        ps = (tireDataForHandoff.hasOwnProperty('productSize')) ? tireDataForHandoff.productSize : tireDataForHandoff.sizebase;

                                    let name = 'Firestone Direct',
                                        urlPfx = urlSchema.urlPfx,
                                        fsdParameterMap = {
                                            "zipCode": locationDataForHandoff.zip,
                                            "year": vehicleDataForHandoff.year,
                                            "make": vehicleDataForHandoff.make,
                                            "model": vehicleDataForHandoff.model,
                                            "trim": vehicleDataForHandoff.trim,
                                            "acesVehicleId": vehicleDataForHandoff.acesVehicleId,
                                            "frontTireName": pb + ' ' + psb,
                                            "sizeFront": ps,
                                            "frontArticleNumber": tireDataForHandoff.articlenumber,
                                            "frontQuantity": "4"
                                    };

                                    const fsdHtml = fsdHTML(name, urlPfx, fsdParameterMap);

                                    let modalWrapper = document.querySelector('.dynamic-top-content');

                                    if(modalWrapper) {

                                        let modalCardWrapper = modalWrapper.querySelector('.cmp-store-cards');

                                        const waitForElementToChange = (selector) => {
                                            return new Promise(resolve => {

                                                const mCallback = (mutations) => {
                                                    for (let mutation of mutations) {
                                                        if (mutation.type === 'childList') {
                                                            resolve(selector);
                                                            observer.disconnect();
                                                        }
                                                    }
                                                }

                                                const observer = new MutationObserver(mCallback);

                                                observer.observe(modalWrapper, {
                                                    subtree: true,
                                                    childList: true,
                                                });
                                            });
                                        }

                                        waitForElementToChange(modalCardWrapper).then(element => {
                                            handoffCardAddRemove(fsdHtml);
                                        });

                                    } else {
                                        handoffCardAddRemove(fsdHtml);
                                    }
                                }
                            });
                        } else {
                            window.BATO.storeLocator.handoff.exists = false;
                            handoffCardAddRemove();
                        }
                    }
                });
            }
        })();
    }

    const enableFSD = () => {

        window.BATO.Store.observeStore(window.BATO.Store, function(state) {
            return state.location
        }, handoffCheck);

        window.BATO.storeLocator.handoffCheck = handoffCheck;
        window.BATO.storeLocator.handoffCardAddRemove = handoffCardAddRemove;
    }

    if(!document.querySelector('.store-seo-funnel')) {
        enableFSD();
    }

});
