import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  status: 'unknown',
  tireBySize: {
    front: {
      cs: "",
      ar: "",
      rs: "",
      tireSize: "",
      brand: "",
      subBrand: "",
      product: "",
      article: "",
      qty: "",
    },
    rear: {
      cs: "",
      ar: "",
      rs: "",
      tireSize: "",
      brand: "",
      subBrand: "",
      product: "",
      article: "",
      qty: "",
    }
  },
  previousTiresBySize: [],
}

export const tiresBySize = createSlice({
  name: 'tiresBySize',
  initialState: initialState,
  reducers: {
    clearTires: (state) => {
      return initialState;
    },
    setTiresBySize: (state, action) => {
      let previousTires = state.previousTiresBySize;
      let newPreviousTires = [];
      if (state.status === 'known') {
        if (previousTires.length > 0) {
          newPreviousTires = previousTires.filter((itm, idx) => idx < 1);
        }
        newPreviousTires.unshift(state.tireBySize);
      }
      const { front, rear } = action.payload;

      return {
        ...state,
        status: 'known',
        tireBySize: {
          front: {
            cs: front.cs ? front.cs : "",
            ar: front.ar ? front.ar : "",
            rs: front.rs ? front.rs : "",
            tireSize: front.tireSize ? front.tireSize : "",
            brand: front.brand ? front.brand : "",
            subBrand: front.subBrand ? front.subBrand : "",
            product: front.product ? front.product : "",
            article: front.article ? front.article : "",
            qty: front.qty ? front.qty : "",
          },
          ...(rear ? { rear: rear } : {
            rear: {
              cs: "",
              ar: "",
              rs: "",
              tireSize: "",
              brand: "",
              subBrand: "",
              product: "",
              article: "",
                qty: "",
            }
          })
        },
        previousTiresBySize: newPreviousTires
      }
    }
  }
});

const actions = tiresBySize.actions;

export { actions };

if (!window.BATO) {
  window.BATO = {}
}

if (!window.BATO.StoreActions) {
  window.BATO.StoreActions = {}
}

window.BATO.StoreActions.tiresBySize = actions;

export default tiresBySize.reducer;