const getBestMatches = (data) => {

    const tdgENUM = {
        tireType: {
            1: "winter",        //"Winter Tires",
            2: "all season",    //"All Season Tires",
            3: "summer",        //"Summer Tires"
        },
        drivingStyle: {
            1: "Highway",
            2: "City",
            3: "Off-Road"
        }, 
        topFeatures: {
            1: "treadLife",         // "Tread Life",
            2: "fuelEfficiency",    // "Fuel Efficiency",
            3: "quietRide",         // "Quiet Ride",
            4: "wetPerformance",    // "Wet Handling",
            5: "dryPerformance",    //"Dry Handling",
            6: "Price",
            7: "rideComfort",       // "Ride Comfort"
            8: "offRoadPerformance" // Off-Road      
        },
    }
    Object.freeze(tdgENUM["tireType"]);   // make object immutable
    Object.freeze(tdgENUM["drivingStyle"]);   // make object immutable
    Object.freeze(tdgENUM["topFeatures"]);   // make object immutable
    

    let answersToFilterBy = null;
    if (sessionStorage.getItem('4') !== null) {
        const step4answer = JSON.parse(sessionStorage.getItem('4'));
        const step5answer = JSON.parse(sessionStorage.getItem('5'));
        const step6answer = JSON.parse(sessionStorage.getItem('6'));
        answersToFilterBy = [step4answer, step5answer, step6answer];
    } else {
        const step7answer = JSON.parse(sessionStorage.getItem('tdg_params'));
        answersToFilterBy = [step7answer[3]?.values, step7answer[4]?.values, step7answer[5]?.values];
    }

    const keys = Object.keys(data.data.tires);

    const tiresArray = keys.map(tires => data.data.tires[tires]);

    let resultsArray = [];
    
    // handle tireType
    answersToFilterBy[0]?.forEach(answer => {
        tiresArray.filter(({marketing}) => marketing?.categories?.some(c => c === tdgENUM.tireType[answer])).forEach(f => resultsArray.push(f))
    });

    // handle drivingStyle
    answersToFilterBy[1]?.forEach(answer => {
        if (answer === '3') {
            tiresArray.filter(({performanceRatings}) => performanceRatings[tdgENUM.topFeatures['8']] >= 8).forEach(f => resultsArray.push(f))
        } else {
            tiresArray.forEach(f => resultsArray.push(f));
        }
    });

    // handle topFeatures
    answersToFilterBy[2]?.forEach((answer, i) => {
        if (answer === '6') {
            tiresArray.sort((a, b) => a.prices.max.value - b.prices.max.value);
            for (let x = 0; x < 3; x++) {
                resultsArray.splice(x, 0, tiresArray[x]);
            }
            return;
        }

        tiresArray.filter(({performanceRatings}) => performanceRatings[tdgENUM.topFeatures[answersToFilterBy[2][0]]] >= 7).forEach(f => resultsArray.push(f))
        tiresArray.filter(({performanceRatings}) => performanceRatings[tdgENUM.topFeatures[answer]] >= 8).forEach(f => resultsArray.push(f))
    }); 

    const finalResults = [...new Map(resultsArray.map(v => [v.variants, v])).values()].splice(0,3);    
    return finalResults;
}


export default getBestMatches;