import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { urlLanguageCheck } from '../../../../../js/utils/urlLanguageCheck.js';

import './header-vehicle-app.scss';

const HeaderVehicleApp = (props) => {
  const {
    status,
    vehicle: {
      make,
      model
    }
  } = useSelector(state => state.vehicles);

  const [isHovered, setIsHovered] = useState(false);
  const [isCanadianSite, setIsCanadianSite] = useState(false);

  const {
    searchForTires,
    viewTires,
    tireDecisionGuide
  } = props;

  const searchForTiresText = searchForTires || 'Search for Tires';
  const viewTiresText = viewTires || 'View Tires';
  const tireDecisionGuideText = tireDecisionGuide || 'Tire Decision Guide';

  const [catalogURL, setCatalogURL] = useState();

  useEffect(() => {
      const languageInUrl = urlLanguageCheck();
      setIsCanadianSite(window.location.hostname.includes('.ca'));

      let catalogURL = '/catalog/';
      languageInUrl ? catalogURL = '/' + languageInUrl + catalogURL : '';

      setCatalogURL(catalogURL);
  }, []);

  return (
    <>
      {status !== 'known' &&
        <div className="vehicle-app-wrap one-col">
          <div className="button">
            <a href={catalogURL} className="cmp-button icon-medium icon-left">
              <i className="icon-consumer-tire"></i>
              {searchForTiresText}
            </a>
          </div>
        </div>
      }
      {status === 'known' &&
        <div className="vehicle-app-wrap two-col">
          <div className="button button--secondary">
            <a href="#vehicle-selector" className="cmp-button icon-small icon-top make-model" onMouseOver={() => setIsHovered(true)} onMouseLeave={()=> setIsHovered(false)}>
              {/* <i className="icon-consumer-front"></i> */}
              <i className={`${isHovered ? 'icon-consumer-caredit' : 'icon-consumer-front'}`}></i>
              {`${make} ${model}`}
            </a>
          </div>
          <div className="button">
            <a href={catalogURL} className="cmp-button icon-medium icon-left">
              {viewTiresText}
            </a>
          </div>
        </div>
      }
      {!isCanadianSite && (
      <div className="vehicle-app-wrap one-col button-tdg-container">
        <div className="button">
          <a href="tire-decision-guide/" className="cmp-button button-tdg">
              {tireDecisionGuideText}
          </a>
        </div>
      </div>
      )}
    </>
  );
};

export { HeaderVehicleApp };