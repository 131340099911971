export function cardHTML(index, storeNumber, externalStoreId, storeName, storeDistance, storeDistanceUnit, storeAddress, simpleZip, openClose, openCloseClass, closingTime, openingTime, closingTimeFormatted, storeDetails, urlTarget, storeHandoff, storePhone, awayTxt, whatsAppNumber, excellenceStamp) {

    // If storeName is "Canadian Tire", set storeHandoff to "TCE"
    if (storeName === "Canadian Tire") {
        storeHandoff = "TCE";
    }

    var theCardContent = `
        <div class="store-card" tabindex="0" data-name="${storeName}" data-number="${storeNumber}" data-distance="${storeDistance}" data-close="${closingTime}" data-open="${openingTime}" data-relevance="${index}">
            <h5><a class="store-card_details" href="${storeDetails}" target="${urlTarget}"><span>${index}</span>. ${storeName}</a></h5>
    `;

    if(storeDistance && storeDistanceUnit) {

        let storeDistanceString = `${storeDistance} ${storeDistanceUnit} ${awayTxt}`;
        theCardContent += `<p class="store-card_distance">${storeDistanceString}</p>`;

    }

    theCardContent += `<p class="store-card_address">${storeAddress}</p>`;

    if(openClose && closingTimeFormatted) {

        theCardContent += `<p class="store-card_closing"><span class="${openCloseClass}">${openClose}</span> - ${closingTimeFormatted}</p>`;

    }

    // Check if storeHandoff is set correctly
    if (storeHandoff) {

        // data-href populated by store type for further construction in purchaseButtonSetup()
        theCardContent += `<button class="button basket-transfer" data-name="${storeName}" data-zip="${simpleZip}" data-number="${storeNumber}" `;
        if(externalStoreId) {
            theCardContent += `data-externalstoreid="${externalStoreId}" `;
        }
        theCardContent += `data-storehandoff="${storeHandoff}">Purchase at Store</button>`;

    }

    if(storePhone) {

        theCardContent += `<a class="button button--tertiary" href="tel:${storePhone}">${storePhone}</a>`;

    }

    if (whatsAppNumber) {
         // Remove everything that is not a number (digits) from the phone number
        let whatsappNumberFormated = whatsAppNumber.replace(/[^\d]/g, '');
    
        // Remove leading zeros
        whatsappNumberFormated = whatsappNumberFormated.replace(/^0+/, '');
    
        //theCardContent += `<a class="whatsapp-icon" href="https://wa.me/${whatsappNumberFormated}" target="_blank"></a>`;
        theCardContent += `<a class="whatsapp-icon" href="https://api.whatsapp.com/send?phone=${whatsappNumberFormated}&text=Ol%C3%A1%2C%20gostaria%20de%20falar%20com%20o%20atendimento%20Bridgestone%20%28dealerlocator%29" target="_blank"></a>`;
    }

    if(excellenceStamp){
        let imageES = 'excellence_stamp_'+excellenceStamp+'.png';
        theCardContent += `<div style="margin-top: 15px;text-align: center;"><img src="/content/dam/consumer/shared/la/logos/${imageES}" loading="lazy" style="width: 50%;"/></div>`;
    }

    theCardContent += `
        </div>`;

    return theCardContent;

}