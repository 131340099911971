import '../scss/tire-specs.scss';
import { urlLanguageCheck } from '../../../../../js/utils/urlLanguageCheck.js';


const tireDetailSpecs = document.querySelector(".tire-specs");
const tireDetailSpecsWrapper = (tireDetailSpecs != null) ? tireDetailSpecs.querySelector(".tire-detail-specs") : null;

const tireDetail = document.querySelector(".tire-detail");
const tireDataWrapper = (tireDetail != null) ? tireDetail.querySelector(".tire-detail-datawrapper") : null;

if(tireDetailSpecs && !tireDetailSpecsWrapper.classList.contains("tire-detail-authoring-mode") ){

    const tireDetailSpecsDD = tireDetailSpecs.querySelector(".tire-spec-dropdown");
    const tireDetailDD = document.querySelector(".tire-size-dropdown");

    let tireSizeDropdownWrapper = tireDetail.querySelector(".tire-detail__sizes");
    let singleSize = tireDetail.querySelector(".tire-detail__size");

    let currentURL = window.location.href,
        urlDepth = currentURL.split(/[?#]/).shift().match(/\/[^/]+?/g).length, // 3 is Tire Detail Root, 4 has Size Attached
        sizeURL = '';

    // Helper function for iterating over NodeList
    const forEach = (array, callback, scope) => {
        for (var i = 0; i < array.length; i++) {
            callback.call(scope, i, array[i]); // passes back stuff we need
        }
    }

    let dataObject = {};

    tireDetailSpecsDD.addEventListener('change', (event) => {

        let selectedSize = event.target.options[event.target.selectedIndex].dataset;
        setDetailSpecs(selectedSize);

        if(tireDetailDD) {

            // If Only 1 Size Fits, the Tire Dropdown At Top of TDP is hidden
            // If BOSS changes Tire Size from Tire Spec Dropdown, We Un-Hide Tire Dropdown At Top
            tireSizeDropdownWrapper.classList.remove('single-size');
            singleSize.classList.remove('single-size');

            tireDetailDD.value = event.target.value;
            window.BATO.tireDetails.setSpecs(selectedSize.warrantydistance,selectedSize.warrantyunit,selectedSize.msrp,selectedSize.articlenumber,selectedSize.size,selectedSize.articles);
        }

        //console.log('tire specs fired');
        if ((window.BATO.Store.getState().flex.previouslyViewedTires[0]?.articlenumber !== tireDataWrapper.dataset?.articlenumber) &&
            (tireDataWrapper != null)) {

            //console.log("setViewedTires from tireSpecs", tireDataWrapper.dataset);
            window.BATO.ProfileActions.setViewedTires({ ...tireDataWrapper.dataset });
        }


        // Update 'Purchase at Store' Buttons Where Applicable
        window.BATO.storeLocator.purchaseButtonSetup();

    });

    const setDetailSpecs = (selectedSize) => {

        let warranty = "",
            units = "",
            treadDepth;

        if (selectedSize.warrantydistance != 'N/A') {
            units = " " + selectedSize.warrantyunits;
        }

        warranty = selectedSize.warrantydistance + units;

        treadDepth = "<sup>" + selectedSize.treaddepth + "</sup>/<sub>32 in.</sub>"

        // Full dataset in Object
        dataObject = {
            size: selectedSize.size,
            servicedesc: selectedSize.servicedesc,
            loadrange: selectedSize.loadrange,
            speedrating: selectedSize.speedrating,
            sidewallstyling: selectedSize.sidewallstyling,
            articlenumber: selectedSize.articlenumber,
            rimwidth: selectedSize.rimwidth,
            tirediameter: selectedSize.tirediameter,
            treadDepth: treadDepth,
            tireweight: selectedSize.tireweight,
            warranty: warranty,
            noise: selectedSize.noise,
            noiserating: selectedSize.noiserating,
            rollingresistance: selectedSize.rollingresistance,
            wetgrip: selectedSize.wetgrip,
            tiretype: selectedSize.tiretype,
            noisefeature: selectedSize.noisefeature,
            sustainabilityfeature: selectedSize.sustainabilityfeature,
        }

        // If URL Contains 'fr', 'es', we must modify our URL Depth Checker to Accomodate
        // Utility Check returns true/false for smoke test of language directory
        let languageInUrl = urlLanguageCheck();
        let urlDepthModifier = 0;
        languageInUrl ? urlDepthModifier = 1 : '';

        currentURL = window.location.href;
        urlDepth = (currentURL.split(/[?#]/).shift().match(/\/[^/]+?/g).length) - urlDepthModifier;

        // No URL Rewrites When Authoring
        if (window.location.href.indexOf("/content/consumer/") == -1) {

            // Attach Size Selector into URL
            let newSize = dataObject.size.replace("/","-");
            sizeURL = "./" + newSize + "/";

            // Size In URL
            if (urlDepth == 4) {

                let urlSplit = currentURL.split("/");
                let currentSize = urlSplit[urlSplit.length-2];

                sizeURL = currentURL.replace(currentSize, newSize);

            // No Size in URL
            } else if (urlDepth == 3) {

                // If No Size, Append the First One In the DropDown
                sizeURL = `${location.protocol}//${location.host}${location.pathname}${newSize}/`;

                // If QueryString, Append it After The Above
                window.location.search ? sizeURL += window.location.search : '';

                window.location.hash ? sizeURL += window.location.hash : '';

            }

            history.pushState({dataObject}, `Selected: ${sizeURL}`, sizeURL);

        }

        renderDetailSpecs(dataObject);
    }

    const renderDetailSpecs = (data) => {
        
         // Searching Data Values With Object Key Names
         Object.keys(data).forEach(key => {
            const tireDetailSpecsDDDetail = tireDetailSpecs.querySelectorAll(`[data-specs-detail="${key}"]`);
            if(tireDetailSpecsDDDetail){
                tireDetailSpecsDDDetail.forEach( el => {
                    el.querySelector('.specs-detail').innerHTML = data[key] ? data[key] : '';
                });
            }
        });
        
        let selectedTireSize = data.size;

        tireDetailSpecsDD.value = selectedTireSize;

        checkForVariants(selectedTireSize);

    }

    let tireVariantBox = tireDetailSpecs.querySelector(".tire-detail-specs__variants");
    let tireVariantBoxH1 = tireDetailSpecs.querySelector(".tire-detail-specs__variants .h1");

    const toggleVariants = (event) => {
        tireVariantBox.classList.toggle('expanded');
        tireVariantBoxH1.classList.toggle('open');
    }

    tireVariantBox.addEventListener("click", toggleVariants, true);

    const checkForVariants = (selectedTireSize) => {

        tireVariantBox.classList.remove('active');
        tireVariantBox.classList.remove('expanded');
        tireVariantBoxH1.classList.remove('open');

        let tireVariants = tireDetailSpecs.querySelectorAll(".tire-variant");

        forEach(tireVariants, (index, tireVariant) => {
            tireVariant.classList.remove('show');
        });

        tireVariants = tireDetailSpecs.querySelectorAll("[data-varianttiresize='" + selectedTireSize + "']");

        if(tireVariants.length > 0) {

            tireVariantBox.classList.add('active');

            forEach(tireVariants, (index, tireVariant) => {
                tireVariant.classList.add('show');
            });

        }

    }

    // Handle Browser Navigation - Back/Forward Button
    window.addEventListener('popstate', e => {
        if(e.state){
            renderDetailSpecs(e.state.dataObject);
        }

    });

    // For Initial Load, Look at URL and Set Dropdown and Initial Values
    let urlSplit = currentURL.split("/"),
        currentSize = urlSplit[urlSplit.length-2],
        currentSizeSelect = currentSize.replace("-","/").toUpperCase(),
        currentSizeSelectIndex = '';

    const tireSizes = Array.from(tireDetailSpecsDD.options);

    tireSizes.forEach((tireSize, i) => {
        if (tireSize.value.indexOf(currentSizeSelect)>=0) tireDetailSpecsDD.selectedIndex = i;
    });

    let initialSize = tireDetailSpecsDD.options[tireDetailSpecsDD.selectedIndex].dataset;
    setDetailSpecs(initialSize);





    // Wait for dataObject to be Rendered
    // No URL Rewrites When Authoring
    if (window.location.href.indexOf("/content/consumer/") == -1) {

        (async() => {
            while(Object.keys(dataObject).length == 0)
                await new Promise(resolve => setTimeout(resolve, 250));
                history.replaceState({dataObject}, 'Default state', sizeURL);
        })();

    }

    // See More / See Less Button
    const seeMoreBtn = tireDetailSpecs.querySelector(".see-more");

    const toggleDetails = (e) => {
        let fullSpecs = tireDetailSpecs.querySelector(".tire-detail-specs__full");
        fullSpecs.classList.toggle('active');
        fullSpecs.classList.contains('active') ? e.target.innerHTML = e.target.dataset.btnTxtSeeLess : e.target.innerHTML = e.target.dataset.btnTxtSeeMore;
    }

    if(seeMoreBtn){
        seeMoreBtn.addEventListener("click", toggleDetails, false);
    }



}
