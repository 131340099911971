import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

export const setModalHandler = (setModal) => {
  const handleSetModal = (component, closeModalOverrideFunction) => {
    // console.log("COMPONENT:setModalHandler(): ", component);
    // TODO: Refactor this to accept a forwardRef() to work with disableBodyScroll()
    const modalComponent = document.querySelector('.rm-modal .rm-container');
    let popHandler = () => {

      setModal({
        isOpen: false,
        content: null,
        closeModalFunction: null
      });
      window.history.forward();
      enableBodyScroll(modalComponent);
      /* setTimeout(() => {
      window.addEventListener('popstate', popHandler, { once: true })
  }, 50) */
    };
    if (component) {
      disableBodyScroll(modalComponent);
      if (
        closeModalOverrideFunction &&
        typeof closeModalOverrideFunction === 'function'
      ) {
        setModal({
          isOpen: true,
          content: component,
          closeModalFunction: () => closeModalOverrideFunction()
        });
      } else {
        setModal({
          isOpen: true,
          content: component,
          closeModalFunction: () => handleSetModal()
        });
      }

      window.addEventListener('popstate', popHandler, { once: true });
      //window.history.pushState(null,null,null);
    } else {
      setModal({
        isOpen: false,
        content: null,
        closeModalFunction: null
      });
      enableBodyScroll(modalComponent);
      window.removeEventListener('popstate', popHandler, { once: true });
    }
  };

  return handleSetModal;
};