import '../scss/tire-detail.scss';
import '../../tire-detail-features/v1/scss/tire-detail-features.scss';

import { urlLanguageCheck } from '../../../../../js/utils/urlLanguageCheck.js';
import { getStoresByExclusivity } from '../../../services/v1/js/api/stores.js';
import storesApi from '../../../services/v1/js/api/stores.js';
import { actions as storesActions } from '../../../client-store/v1/js/stores/stores.js';
// import { FeatureSwitches } from '../../../client-store/v1/js/profile/utils/featureFlag/feature-switches.js';



document.addEventListener("DOMContentLoaded", function() {
    // call the getStoresByExclusivity function/non-blocking/don't wait for page to load
    // const featureSwitches = new FeatureSwitches();
    // featureSwitches.create('consumer-ui-exclusivity', true).remote();
    // featureSwitches.initialize();

    // if(window.BATO.CONFIG?.country === 'CA' && featureSwitches?.isTrue('consumer-ui-exclusivity')) {
    // if(window.BATO.CONFIG?.country === 'CA') {
        const fetchStoresData = async (productID, location) => {
            try {
                let cachedStoreNumbers = JSON.parse(localStorage.getItem(`storeNumbers_${productID}`));
        
                if (cachedStoreNumbers) {
                    return cachedStoreNumbers;
                } else {
                    const storesData = await getStoresByExclusivity({ product: productID, location });

                    if (storesData.success && storesData.data && storesData.data.storeNumbers) {
                        localStorage.setItem(`storeNumbers_${productID}`, JSON.stringify(storesData.data.storeNumbers));
                        return storesData.data.storeNumbers;
                    } else {
                        return [];
                    }
                }
            } catch (error) {
                console.error("Error in fetchStoresData:", error);
                return [];
            }
        };

        const filterStoresByLocation = async (storeNumbers) => {
            const location = window.BATO.Store.getState().location.data;
            const lat = location?.lat;
            const lon = location?.lon;
        
            const storeList = await storesApi.getStoresByLatLon({ lat, lon });
        
            if (storeList.data?.stores) {
                let storesArray = Object.keys(storeList.data.stores).map(i => storeList.data.stores[i]);

                if (storeNumbers.length > 0) {
                    storesArray = storesArray.filter(store => storeNumbers.includes(store.base.storeNumber));
                    console.log("Filtered stores by exclusivity:", storesArray.map(store => store.base.storeNumber));
                }

                // If there are no exclusive storeNumbers
                if (storeNumbers.length === 0) {
                    // Sort stores by distance
                    storesArray.sort((a, b) => a.location.distance - b.location.distance);
                }

                window.BATO.Store.dispatch(storesActions.setStores({
                    quantity: storesArray.length,
                    storeNumbers: storesArray.map(store => store.base.storeNumber),
                    stores: storesArray.reduce((acc, store) => {
                        acc[store.base.storeNumber] = store;
                        return acc;
                    }, {}),
                    lang_iso: storeList.params.lang_iso
                }));

                return storesArray.map(store => store.base.storeNumber);
            } else {
                return [];
            }
        };

        const updateStoresForProduct = async (productID) => {
            const location = window?.BATO?.Store?.getState()?.location?.data;
            const storeNumbers = await fetchStoresData(productID, location);
            await filterStoresByLocation(storeNumbers);
        };

        const productID = window.BATO?.ANALYTICS?.products ? window.BATO.ANALYTICS.products[0]?.product_id : null;
        if (productID && window.BATO?.CONFIG?.country === 'CA') {
            updateStoresForProduct(productID);
        } else {
            console.log("Country is not CA or productID is invalid. No stores update performed.");
        }

        window.addEventListener('updateStoresForProduct', (event) => {
            const { productID } = event.detail;
            updateStoresForProduct(productID);
        });

        if (!window.BATO) window.BATO = {};
        if (!window.BATO.tireDetails) window.BATO.tireDetails = {};

        const tireDetail = document.querySelector(".tire-detail");

        if(tireDetail){
            // execute the getStoresByExclusivity function
            // Fetch user location from Redux store
            const location = window.BATO.Store.getState().location.data;
            fetchStoresData(productID, location);
            updateStoresForProduct(productID);
    
            // Adding an event listener for custom events
            window.addEventListener('updateStoresForProduct', (event) => {
                const { productID } = event.detail;
                updateStoresForProduct(productID);
            });
            let initialLoad = 1,
                renderStoreCardsRender = true;
    
            let tireSizeDropdown = tireDetail.querySelector(".tire-size-dropdown");
            let tireDataWrapper = tireDetail.querySelector(".tire-detail-datawrapper");
            let tireSpecDropdown = document.querySelector(".tire-spec-dropdown");
    
            let tireSizeDropdownWrapper = tireDetail.querySelector(".tire-detail__sizes");
            let singleSize = tireDetail.querySelector(".tire-detail__size");
    
            let msrp = tireDetail.querySelector(".tire-detail__specs .msrp .h2");
            let warranty = tireDetail.querySelector(".tire-detail__specs .warranty .h2");
            let unitText = tireDetail.querySelector(".tire-detail__specs .warranty span");
            let productDetailData = tireDetail.querySelector(".tire-detail-datawrapper"); // was [data-product-detail]
            let carouselContainer = document.querySelectorAll('.media-gallery .cmp-carousel');
            let mobileContainer = document.querySelector('.tire-detail__info');
    
    
            // method to prepend detail stuff to sticky nav
            const populateStickyNav = () => {
                // TODO: whenever data changes with popstate, this needs to be refreshed
    
                /* TODO: use below structure and create by addition rather than subtraction
                <div class="tire-detail__main">
    
                    <div class="tire-detail__info">
    
                        <h2><span class="h3">Bridgestone</span> Blizzak W965</h2>
    
                        <div class="tire-detail__rating">
                            <span>Winter</span><span>&nbsp;|&nbsp;Winter</span>
    
                            <div class="ratings-app" id="detail-rating-BRIDGESTONEBLIZZAKW965" data-class="tire-rating-medium medium" data-id="BRIDGESTONEBLIZZAKW965" data-show-rating-details="true" data-show-write-button="true" data-tire-brand="Bridgestone" data-tire-subbrand="Blizzak" data-tire-name="W965" data-product-type="tire" data-type="rating">
                            </div>
                        </div>
    
                    </div>
    
                    <div class="tire-detail__specs">
                        <div class="msrp">
                            <span>MSRP Price</span>
                            <div class="h2"><sup>$</sup>186<sup>.99ea</sup></div>
                        </div>
                        <div class="warranty">
                            <span>Limited Mileage Warranty</span>
                            <div class="h2">N/A</div>
                        </div>
                    </div>
    
                    <a href="#locate-tire" class="button button--tertiary">Locate Tire</a>
    
                </div>
                */
    
                const navEl = document.querySelector(".in-page-nav--sticky");
                const mainEl = document.querySelector(".tire-detail__main");
    
                // see if one already exists and if so remove
                const existingEl = (navEl != null) ? navEl.querySelector(".tire-detail__main") : null;
    
    
                if (existingEl) existingEl.remove();
    
                if (navEl && mainEl) {
                    const cloneEl = mainEl.cloneNode(true);
    
                    // find/remove/modify the elements we dont need
                    let h1El = cloneEl.getElementsByTagName('h1');
                    let infoEl = cloneEl.querySelector('.tire-detail__info');
                    let sizeEl = cloneEl.querySelector('.tire-detail__size');
                    let sizesEl = cloneEl.querySelector('.tire-detail__sizes');
                    let storesEl = cloneEl.querySelector('.tire-detail__stores');
                    let vehEl = cloneEl.querySelector('.tire-detail__vehicle-info');
                    let fitEl = cloneEl.querySelector('.tire-detail__willitfit');
                    let msgsEl = cloneEl.querySelector('.tire-detail__variations-message');
                    let galleryEl = cloneEl.querySelector('.cmp-carousel');
    
                    if (sizeEl) sizeEl.remove();
                    if (sizesEl) sizesEl.remove();
                    if (storesEl) storesEl.remove();
                    if (vehEl) vehEl.remove();
                    if (msgsEl) msgsEl.remove();
                    if (galleryEl) galleryEl.remove();
    
                    if (h1El) {
                        let newElem = document.createElement('h2');
                        newElem.innerHTML = h1El[0].innerHTML;
                        infoEl.insertAdjacentElement('afterbegin', newElem);
                        h1El[0].remove();
                    }
    
                    navEl.insertAdjacentElement('afterbegin', cloneEl);
                }
            }
    
    
            const setSpecs = (distance,unit,priceSplit,article,size,articles) => {
                //initialLoad++;
                if(priceSplit) {
    
                    tireDataWrapper.setAttribute("data-msrp", priceSplit);
                    let price = priceSplit.split(".");
                    //console.log("initialLoad: ",initialLoad);
                    let msrpDiv = tireDetail.querySelector(".tire-detail__specs .msrp");
                    //removing this per request for now - it scrolls the tire page up when the tire spec drop down is selected
                    //if(initialLoad>2) msrpDiv.focus();
                    if (window.BATO.CONFIG.lang_iso === 'fr_CA') {
                        msrp.innerHTML = price[0] + "," + price[1] + "<sup>$ch</sup>";
                    } else {
                        msrp.innerHTML = "<sup>$</sup>" + price[0] + "<sup>." + price[1] + "ea</sup>";
                    }
    
                } else {
    
                    msrp.innerHTML = "N/A";
    
                }
    
                if (window.BATO.CONFIG.lang_iso === 'fr_CA' && distance === 'N/A') {
                    warranty.innerHTML = 'S.O.';
                } else {
                    warranty.innerHTML = distance;
                }
                tireDataWrapper.dataset.articlenumber = article;
                tireDataWrapper.dataset.productSize = size;
                tireDataWrapper.dataset.articles = articles;
    
                localStorage.setItem('tireLastViewed', JSON.stringify(productDetailData.dataset));
    
                populateStickyNav();
            }
    
            // For Initial Load, Look at URL and Set Dropdown and Initial Values
            let currentURL = window.location.href,
                urlSplit = currentURL.split("/"),
                currentSize = urlSplit[urlSplit.length-2],
                currentSizeSelect = currentSize.replace("-","/").toUpperCase(),
                currentSizeSelectIndex = '';
    
            const tireSizes = Array.from(tireSizeDropdown.options);
    
            tireSizes.forEach((tireSize, i) => {
                if (tireSize.value.indexOf(currentSizeSelect)>=0) tireSizeDropdown.selectedIndex = i;
            });
    
            // Set Initial Size - We Need This To Trigger the Heading Data to Be Displayed
            // Specifically in instances where No Vehicle is Selected by User
            if(window.BATO.Store.getState().vehicles.status == 'unknown'){
                //console.log('vehicle unknown');
                let initialSize = tireSizeDropdown.options[tireSizeDropdown.selectedIndex].dataset;
                setSpecs(initialSize.warrantydistance,initialSize.warrantyunit,initialSize.msrp,initialSize.articlenumber,initialSize.size,initialSize.articles);
            }
    
            // TODO: move this into setSpecs so it syncs with size changes as needed
            //populateStickyNav();
    
            window.BATO.tireDetails.setSpecs = setSpecs;
            mobileContainer.appendChild(carouselContainer[0]);
    
            const setSizeFits = () => {
    
                if (window.BATO.Store.getState().flex.previouslyMatchedSizes &&
                    window.BATO.Store.getState().flex.previouslyMatchedSizes.length > 0) {
    
                    tireSizeDropdownWrapper.classList.remove('single-size');
                    singleSize.classList.remove('single-size');
    
                    let matchingSizes = window.BATO.Store.getState().flex.previouslyMatchedSizes[0],
                        matchingSize = "",
                        matchingSizeIndex = 0,
                        totalSizesCount = tireSizes.length,
                        matchingSizesCount = 0;
    
                    tireSizes.forEach((tireSize, i) => {
                        if(matchingSizes.indexOf(tireSize.value) !== -1) {
                            tireSizeDropdown.options[i].innerHTML = tireSize.value + " " + tireSizeDropdown.dataset.fitsYourVehicleTxt;
                            matchingSize = tireSize.value;
    
                            //If SelectIndex = 0 (Meaning No Tire Size Was Sent With URL), We Default To the First Matching Size
                            tireSizeDropdown.selectedIndex == 0 ? tireSizeDropdown.selectedIndex = i : '';
                            tireSizeDropdown.dispatchEvent(new Event('change'));
    
                            matchingSizeIndex = i;
                            matchingSizesCount++;
                        } else {
                            tireSizeDropdown.options[i].innerHTML = tireSize.value + " " + tireSizeDropdown.dataset.doesNotFitTxt;
                        }
                    });
    
                    // However, If Only 1 Size Fits Vehcile AND No Size Was Sent With URL OR The Size Sent Matches The 1 Size
                    // We Hide The Dropdown and Display Different Treatment
                    // This Can Be Undone w/o Page Reload if BOSS Changes Tire Size from Tire Spec Dropdown (tire-spec.js)
                    if( (matchingSizesCount == 1) && ((tireSizeDropdown.selectedIndex == 0) || (tireSizeDropdown.selectedIndex == matchingSizeIndex)) ){
    
                        tireSizeDropdown.selectedIndex = matchingSizeIndex;
                        tireSizeDropdownWrapper.classList.add('single-size');
                        singleSize.classList.add('single-size');
                        singleSize.innerHTML = "<strong>" + singleSize.dataset.tireSize + "</strong><div class='h2'>" + matchingSize + "</div>";
    
                    } else {
    
                        // We Need To Fire This Here To Sync Dropdowns
                        tireSizeDropdown.dispatchEvent(new Event('change'));
    
                    }
    
                }
            }
    
            // Listen for Update to Redux Store flex.previouslyMatchedSizes
            window.BATO.Store.observeStore(window.BATO.Store, function(state) {
                return state.flex.previouslyMatchedSizes;
            }, setSizeFits);
    
    
            // Change Event Listener for Dropdown at Top of Tire Detail Page
            tireSizeDropdown.addEventListener('change', (event) => {
    
                let selectedSize = event.target.options[event.target.selectedIndex].dataset;
    
                if(tireSpecDropdown) {
                    tireSpecDropdown.value = event.target.value;
                    tireSpecDropdown.dispatchEvent(new Event('change'));
                }
    
            });
    
            // Handle Browser Navigation - Back/Forward Button
            window.addEventListener('popstate', e => {
                if(e.state){
                    tireSizeDropdown.value = e.state.dataObject.size;
                    let selectedSize = tireSizeDropdown.options[tireSizeDropdown.selectedIndex].dataset;
                    setSpecs(selectedSize.warrantydistance,selectedSize.warrantyunit,selectedSize.msrp,selectedSize.articlenumber,selectedSize.size,selectedSize.articles);
                }
            });
    
            // Store Card Renders
            let storeCards = tireDetail.querySelector(".tire-detail__stores"),
                storeCardsWrapper = storeCards.querySelector(".tire-detail__stores-wrapper"),
                languageInUrl = urlLanguageCheck(),
                urlRedirect = '/dealer/';
    
            languageInUrl ? urlRedirect = '/' + languageInUrl + urlRedirect : '';
    
            const renderStoreCards = () => {
    
                if(renderStoreCardsRender === true) {
    
                    renderStoreCardsRender = false;
    
                    let translatedText = storeCardsWrapper.dataset;
    
                    window.BATO.storeLocator.getStoreCards(2).then((cardData) => {
                        storeCardsWrapper.innerHTML = `<div class="cmp-store-cards">
                                                        <div class="cmp-store-heading">
                                                            <h3>${translatedText.nearbystores}</h3>
                                                            <div class="tire-search-flyout__filters__location"><span></span></div>
                                                        </div>
                                                        <div class="cmp-store-card-list">` + cardData + `</div>
                                                        <div class="cmp-store-dealers-cta">
                                                            <a href="` + urlRedirect + `" class="button button--tertiary">${translatedText.showmore}</a>
                                                        </div>
                                                    </div>`;
                    });
    
                    let closeOverlay = document.createElement('div');
                    closeOverlay.classList.add('close-overlay');
                    closeOverlay.innerHTML = "&#x2715;";
                    closeOverlay.addEventListener('click', () => {
                        locationSearch.classList.remove('location-search--overlay');
                    });
    
                    let locationSearch = storeCards.querySelector('.location-search');
                    locationSearch.appendChild(closeOverlay);
    
                    let zipWrapper = storeCardsWrapper.querySelector('.tire-search-flyout__filters__location span');
                    if (zipWrapper) {
                        zipWrapper.addEventListener('click', () => {
                            locationSearch.classList.add('location-search--overlay');
                        });
    
                        // Zip / Location Render
                        const renderZip = () => {
                            let zip = window.BATO.Store.getState().location.data.zip;
                            zipWrapper.innerText = zip.split('-')[0];
                        }
    
                        // Wait for Zip to update in Profile before updating it on screen -
                        // this has been broken on production for years (!)
                        window.BATO.Store.observeStore(window.BATO.Store, function (state) {
                            return state.location.data.zip;
                        }, renderZip);
                    }
    
                    window.BATO.storeLocator.purchaseButtonSetup();
    
                    // Since we need to add our observers below, and these observers fire upon page load,
                    // this function has been firing 3 times on every page load for many years (!)
                    // This is just a very basic throttling mechanism to prevent this function firing multiple times
                    // in rapid succession ...
                    setTimeout(() => {
                        renderStoreCardsRender = true;
                    }, 1000);
    
                }
            }
    
            // Wait for Status to be available, if !known then remove storeCards Element
            (async() => {
                while(!window.BATO.Store.getState().stores.hasOwnProperty("status"))
                    await new Promise(resolve => setTimeout(resolve, 250));
                if (window.BATO.Store.getState().stores.status === "known") {
                    renderStoreCards();
                } else {
                    storeCards.parentNode.removeChild(storeCards);
                }
            })();
    
            // Purchase Button Was Firing Before Redux Store Was Updated
            // Wait for Previously Viewed Tire to Update before Rendering and Configuring Purchase Buttons ...
            window.BATO.Store.observeStore(window.BATO.Store, function(state) {
                return state.flex.previouslyViewedTires;
            }, renderStoreCards);
    
            // Update 'Default' Store Cards on Tire Details Page when Updated from Store Locator Flyout
            window.BATO.Store.observeStore(window.BATO.Store, function(state) {
                return state.stores.data.stores;
            }, renderStoreCards);
    
            // Set Initial previouslyViewedTires in Flex Slice of Redux Profile ONLY If Vehicle is Known
            if(window.BATO.Store.getState().vehicles.status === 'unknown' && tireDataWrapper != null){
                window.BATO.ProfileActions.setViewedTires({ ...tireDataWrapper.dataset });
            }
    
    
    
    
    
            const initTdpSeoSchema = () => {
    
                const data = document.querySelector(".tire-detail-datawrapper");
    
                let brand = data.dataset.productBrand || '',
                    description = data.dataset.productDescription || '',
                    images = Array.from(data.querySelectorAll(".media-gallery-item .image img")) || [],
                    imageArray = [],
                    price = data.dataset.msrp || '',
                    name = data.dataset.productSubbrandName || '',
                    /* Temporarily disabled until post launch
                    ratingValue = data.querySelector(".average-rating")?.innerText  || '',
                    reviewCount = data.querySelector(".review-count a")?.innerText.replaceAll(/[^0-9]/g,"")  || '',
                    */
                    size = data.dataset.productSize || '',
                    sku = data.dataset.productId || '',
                    url = window.location.href;
    
                images.forEach((img) => {
                    let path = window.location.origin + img.getAttribute('src');
                    imageArray.push(path);
                });
    
                let tdpSchemaObject = {
                    "@context": "https://schema.org/",
                    "@type": "Product",
                    "name": `${name}`,
                    "image": imageArray,
                    "description": `${description}`,
                    "sku": `${sku}`,
                    "size": `${size}`,
                    "brand": {
                        "@type": "Brand",
                        "name": `${brand}`
                    },
                    /* "aggregateRating": {
                        "@type": "AggregateRating",
                        "ratingValue": `${ratingValue}`,
                        "reviewCount": `${reviewCount}`
                    }, */
                    "offers": {
                        "@type": "Offer",
                        "url": `${url}`,
                        "priceCurrency": "USD",
                        "price": `${price}`
                    }
                };
    
                const script = document.createElement('script');
                script.setAttribute('type', 'application/ld+json');
                script.textContent = JSON.stringify(tdpSchemaObject);
                document.head.appendChild(script);
    
            };
    
            initTdpSeoSchema();
    
            // vehicle-info logic
            const vehicleInfoLogic = () => {
                const vehicles = window.BATO.Store.getState().vehicles;
                const vehicleInfoWrap = document.getElementsByClassName('tire-detail__vehicle-info')[0];
                const vehicleInfo = vehicleInfoWrap.getElementsByClassName('fitment')[0];
                const editIcon = vehicleInfoWrap.getElementsByClassName('icon-consumer-pencil')[0];
    
                //console.log("vehicle info element: ", vehicleInfo);
    
                if (vehicles.status === 'known') {
                    // set anchor text to 'year make model'
                    vehicleInfo.textContent = `${vehicles.vehicle?.year || ''}${' ' + vehicles.vehicle?.make || ''}${' ' + vehicles.vehicle?.model || ''}`;
                    // add/keep .icon-consumer-pencil
                    if (!editIcon) {
                        const theIcon = document.createElement('i');
                        theIcon.classList.add('icon-consumer-pencil');
                        const theAnchor = document.querySelector('.tire-detail__vehicle-info a');
                        theAnchor.appendChild(theIcon);
                    }
                } else {
                    // set Anchor text to 'Enter Your Vehicle'
                    vehicleInfo.textContent = vehicleInfo.dataset.btnTxt;
                    // remove .icon-consumer-pencil element
                    if (editIcon) editIcon.remove();
                }
            };
    
            if (window.BATO?.Store) {
                window.BATO.Store.observeStore(window.BATO.Store, function (state) {
                    return state.vehicles;
                }, vehicleInfoLogic);
    
                vehicleInfoLogic();
            }
    
        } 
    });
//     else {
//         console.log("Feature flag is off or country is not CA. Skipping store data fetch.");
//         if (!window.BATO) window.BATO = {};
//         if (!window.BATO.tireDetails) window.BATO.tireDetails = {};

//         const tireDetail = document.querySelector(".tire-detail");
//         if(tireDetail && !featureSwitches?.isTrue('consumer-ui-exclusivity')){

//             let initialLoad = 1,
//                 renderStoreCardsRender = true;
    
//             let tireSizeDropdown = tireDetail.querySelector(".tire-size-dropdown");
//             let tireDataWrapper = tireDetail.querySelector(".tire-detail-datawrapper");
//             let tireSpecDropdown = document.querySelector(".tire-spec-dropdown");
    
//             let tireSizeDropdownWrapper = tireDetail.querySelector(".tire-detail__sizes");
//             let singleSize = tireDetail.querySelector(".tire-detail__size");
    
//             let msrp = tireDetail.querySelector(".tire-detail__specs .msrp .h2");
//             let warranty = tireDetail.querySelector(".tire-detail__specs .warranty .h2");
//             let unitText = tireDetail.querySelector(".tire-detail__specs .warranty span");
//             let productDetailData = tireDetail.querySelector(".tire-detail-datawrapper"); // was [data-product-detail]
//             let carouselContainer = document.querySelectorAll('.media-gallery .cmp-carousel');
//             let mobileContainer = document.querySelector('.tire-detail__info');
    
    
//             // method to prepend detail stuff to sticky nav
//             const populateStickyNav = () => {
//                 // TODO: whenever data changes with popstate, this needs to be refreshed
    
//                 /* TODO: use below structure and create by addition rather than subtraction
//                 <div class="tire-detail__main">
    
//                     <div class="tire-detail__info">
    
//                         <h2><span class="h3">Bridgestone</span> Blizzak W965</h2>
    
//                         <div class="tire-detail__rating">
//                             <span>Winter</span><span>&nbsp;|&nbsp;Winter</span>
    
//                             <div class="ratings-app" id="detail-rating-BRIDGESTONEBLIZZAKW965" data-class="tire-rating-medium medium" data-id="BRIDGESTONEBLIZZAKW965" data-show-rating-details="true" data-show-write-button="true" data-tire-brand="Bridgestone" data-tire-subbrand="Blizzak" data-tire-name="W965" data-product-type="tire" data-type="rating">
//                             </div>
//                         </div>
    
//                     </div>
    
//                     <div class="tire-detail__specs">
//                         <div class="msrp">
//                             <span>MSRP Price</span>
//                             <div class="h2"><sup>$</sup>186<sup>.99ea</sup></div>
//                         </div>
//                         <div class="warranty">
//                             <span>Limited Mileage Warranty</span>
//                             <div class="h2">N/A</div>
//                         </div>
//                     </div>
    
//                     <a href="#locate-tire" class="button button--tertiary">Locate Tire</a>
    
//                 </div>
//                 */
    
//                 const navEl = document.querySelector(".in-page-nav--sticky");
//                 const mainEl = document.querySelector(".tire-detail__main");
    
//                 // see if one already exists and if so remove
//                 const existingEl = (navEl != null) ? navEl.querySelector(".tire-detail__main") : null;
    
    
//                 if (existingEl) existingEl.remove();
    
//                 if (navEl && mainEl) {
//                     const cloneEl = mainEl.cloneNode(true);
    
//                     // find/remove/modify the elements we dont need
//                     let h1El = cloneEl.getElementsByTagName('h1');
//                     let infoEl = cloneEl.querySelector('.tire-detail__info');
//                     let sizeEl = cloneEl.querySelector('.tire-detail__size');
//                     let sizesEl = cloneEl.querySelector('.tire-detail__sizes');
//                     let storesEl = cloneEl.querySelector('.tire-detail__stores');
//                     let vehEl = cloneEl.querySelector('.tire-detail__vehicle-info');
//                     let fitEl = cloneEl.querySelector('.tire-detail__willitfit');
//                     let msgsEl = cloneEl.querySelector('.tire-detail__variations-message');
//                     let galleryEl = cloneEl.querySelector('.cmp-carousel');
    
//                     if (sizeEl) sizeEl.remove();
//                     if (sizesEl) sizesEl.remove();
//                     if (storesEl) storesEl.remove();
//                     if (vehEl) vehEl.remove();
//                     if (msgsEl) msgsEl.remove();
//                     if (galleryEl) galleryEl.remove();
    
//                     if (h1El) {
//                         let newElem = document.createElement('h2');
//                         newElem.innerHTML = h1El[0].innerHTML;
//                         infoEl.insertAdjacentElement('afterbegin', newElem);
//                         h1El[0].remove();
//                     }
    
//                     navEl.insertAdjacentElement('afterbegin', cloneEl);
//                 }
//             }
    
    
//             const setSpecs = (distance,unit,priceSplit,article,size,articles) => {
//                 //initialLoad++;
//                 if(priceSplit) {
    
//                     tireDataWrapper.setAttribute("data-msrp", priceSplit);
//                     let price = priceSplit.split(".");
//                     //console.log("initialLoad: ",initialLoad);
//                     let msrpDiv = tireDetail.querySelector(".tire-detail__specs .msrp");
//                     //removing this per request for now - it scrolls the tire page up when the tire spec drop down is selected
//                     //if(initialLoad>2) msrpDiv.focus();
//                     if (window.BATO.CONFIG.lang_iso === 'fr_CA') {
//                         msrp.innerHTML = price[0] + "," + price[1] + "<sup>$ch</sup>";
//                     } else {
//                         msrp.innerHTML = "<sup>$</sup>" + price[0] + "<sup>." + price[1] + "ea</sup>";
//                     }
    
//                 } else {
    
//                     msrp.innerHTML = "N/A";
    
//                 }
    
//                 if (window.BATO.CONFIG.lang_iso === 'fr_CA' && distance === 'N/A') {
//                     warranty.innerHTML = 'S.O.';
//                 } else {
//                     warranty.innerHTML = distance;
//                 }
//                 tireDataWrapper.dataset.articlenumber = article;
//                 tireDataWrapper.dataset.productSize = size;
//                 tireDataWrapper.dataset.articles = articles;
    
//                 localStorage.setItem('tireLastViewed', JSON.stringify(productDetailData.dataset));
    
//                 populateStickyNav();
    
//             }
    
//             // For Initial Load, Look at URL and Set Dropdown and Initial Values
//             let currentURL = window.location.href,
//                 urlSplit = currentURL.split("/"),
//                 currentSize = urlSplit[urlSplit.length-2],
//                 currentSizeSelect = currentSize.replace("-","/").toUpperCase(),
//                 currentSizeSelectIndex = '';
    
//             const tireSizes = Array.from(tireSizeDropdown.options);
    
//             tireSizes.forEach((tireSize, i) => {
//                 if (tireSize.value.indexOf(currentSizeSelect)>=0) tireSizeDropdown.selectedIndex = i;
//             });
    
//             // Set Initial Size - We Need This To Trigger the Heading Data to Be Displayed
//             // Specifically in instances where No Vehicle is Selected by User
//             if(window.BATO.Store.getState().vehicles.status == 'unknown'){
//                 //console.log('vehicle unknown');
//                 let initialSize = tireSizeDropdown.options[tireSizeDropdown.selectedIndex].dataset;
//                 setSpecs(initialSize.warrantydistance,initialSize.warrantyunit,initialSize.msrp,initialSize.articlenumber,initialSize.size,initialSize.articles);
//             }
    
//             // TODO: move this into setSpecs so it syncs with size changes as needed
//             //populateStickyNav();
    
//             window.BATO.tireDetails.setSpecs = setSpecs;
//             mobileContainer.appendChild(carouselContainer[0]);
    
//             const setSizeFits = () => {
    
//                 if (window.BATO.Store.getState().flex.previouslyMatchedSizes &&
//                     window.BATO.Store.getState().flex.previouslyMatchedSizes.length > 0) {
    
//                     tireSizeDropdownWrapper.classList.remove('single-size');
//                     singleSize.classList.remove('single-size');
    
//                     let matchingSizes = window.BATO.Store.getState().flex.previouslyMatchedSizes[0],
//                         matchingSize = "",
//                         matchingSizeIndex = 0,
//                         totalSizesCount = tireSizes.length,
//                         matchingSizesCount = 0;
    
//                     tireSizes.forEach((tireSize, i) => {
//                         if(matchingSizes.indexOf(tireSize.value) !== -1) {
//                             tireSizeDropdown.options[i].innerHTML = tireSize.value + " " + tireSizeDropdown.dataset.fitsYourVehicleTxt;
//                             matchingSize = tireSize.value;
    
//                             //If SelectIndex = 0 (Meaning No Tire Size Was Sent With URL), We Default To the First Matching Size
//                             tireSizeDropdown.selectedIndex == 0 ? tireSizeDropdown.selectedIndex = i : '';
//                             tireSizeDropdown.dispatchEvent(new Event('change'));
    
//                             matchingSizeIndex = i;
//                             matchingSizesCount++;
//                         } else {
//                             tireSizeDropdown.options[i].innerHTML = tireSize.value + " " + tireSizeDropdown.dataset.doesNotFitTxt;
//                         }
//                     });
    
//                     // However, If Only 1 Size Fits Vehcile AND No Size Was Sent With URL OR The Size Sent Matches The 1 Size
//                     // We Hide The Dropdown and Display Different Treatment
//                     // This Can Be Undone w/o Page Reload if BOSS Changes Tire Size from Tire Spec Dropdown (tire-spec.js)
//                     if( (matchingSizesCount == 1) && ((tireSizeDropdown.selectedIndex == 0) || (tireSizeDropdown.selectedIndex == matchingSizeIndex)) ){
    
//                         tireSizeDropdown.selectedIndex = matchingSizeIndex;
//                         tireSizeDropdownWrapper.classList.add('single-size');
//                         singleSize.classList.add('single-size');
//                         singleSize.innerHTML = "<strong>" + singleSize.dataset.tireSize + "</strong><div class='h2'>" + matchingSize + "</div>";
    
//                     } else {
    
//                         // We Need To Fire This Here To Sync Dropdowns
//                         tireSizeDropdown.dispatchEvent(new Event('change'));
    
//                     }
    
//                 }
//             }
    
//             // Listen for Update to Redux Store flex.previouslyMatchedSizes
//             window.BATO.Store.observeStore(window.BATO.Store, function(state) {
//                 return state.flex.previouslyMatchedSizes;
//             }, setSizeFits);
    
    
//             // Change Event Listener for Dropdown at Top of Tire Detail Page
//             tireSizeDropdown.addEventListener('change', (event) => {
    
//                 let selectedSize = event.target.options[event.target.selectedIndex].dataset;
    
//                 if(tireSpecDropdown) {
//                     tireSpecDropdown.value = event.target.value;
//                     tireSpecDropdown.dispatchEvent(new Event('change'));
//                 }
    
//             });
    
//             // Handle Browser Navigation - Back/Forward Button
//             window.addEventListener('popstate', e => {
//                 if(e.state){
//                     tireSizeDropdown.value = e.state.dataObject.size;
//                     let selectedSize = tireSizeDropdown.options[tireSizeDropdown.selectedIndex].dataset;
//                     setSpecs(selectedSize.warrantydistance,selectedSize.warrantyunit,selectedSize.msrp,selectedSize.articlenumber,selectedSize.size,selectedSize.articles);
//                 }
//             });
    
//             // Store Card Renders
//             let storeCards = tireDetail.querySelector(".tire-detail__stores"),
//                 storeCardsWrapper = storeCards.querySelector(".tire-detail__stores-wrapper"),
//                 languageInUrl = urlLanguageCheck(),
//                 urlRedirect = '/dealer/';
    
//             languageInUrl ? urlRedirect = '/' + languageInUrl + urlRedirect : '';
    
//             const renderStoreCards = () => {
    
//                 if(renderStoreCardsRender === true) {
    
//                     renderStoreCardsRender = false;
    
//                     let translatedText = storeCardsWrapper.dataset;
    
//                     window.BATO.storeLocator.getStoreCards(2).then((cardData) => {
//                         storeCardsWrapper.innerHTML = `<div class="cmp-store-cards">
//                                                         <div class="cmp-store-heading">
//                                                             <h3>${translatedText.nearbystores}</h3>
//                                                             <div class="tire-search-flyout__filters__location"><span></span></div>
//                                                         </div>
//                                                         <div class="cmp-store-card-list">` + cardData + `</div>
//                                                         <div class="cmp-store-dealers-cta">
//                                                             <a href="` + urlRedirect + `" class="button button--tertiary">${translatedText.showmore}</a>
//                                                         </div>
//                                                     </div>`;
//                     });
    
//                     let closeOverlay = document.createElement('div');
//                     closeOverlay.classList.add('close-overlay');
//                     closeOverlay.innerHTML = "&#x2715;";
//                     closeOverlay.addEventListener('click', () => {
//                         locationSearch.classList.remove('location-search--overlay');
//                     });
    
//                     let locationSearch = storeCards.querySelector('.location-search');
//                     locationSearch.appendChild(closeOverlay);
    
//                     let zipWrapper = storeCardsWrapper.querySelector('.tire-search-flyout__filters__location span');
//                     if (zipWrapper) {
//                         zipWrapper.addEventListener('click', () => {
//                             locationSearch.classList.add('location-search--overlay');
//                         });
    
//                         // Zip / Location Render
//                         const renderZip = () => {
//                             let zip = window.BATO.Store.getState().location.data.zip;
//                             zipWrapper.innerText = zip.split('-')[0];
//                         }
    
//                         // Wait for Zip to update in Profile before updating it on screen -
//                         // this has been broken on production for years (!)
//                         window.BATO.Store.observeStore(window.BATO.Store, function (state) {
//                             return state.location.data.zip;
//                         }, renderZip);
//                     }
    
//                     window.BATO.storeLocator.purchaseButtonSetup();
    
//                     // Since we need to add our observers below, and these observers fire upon page load,
//                     // this function has been firing 3 times on every page load for many years (!)
//                     // This is just a very basic throttling mechanism to prevent this function firing multiple times
//                     // in rapid succession ...
//                     setTimeout(() => {
//                         renderStoreCardsRender = true;
//                     }, 1000);
    
//                 }
//             }
    
//             // Wait for Status to be available, if !known then remove storeCards Element
//             (async() => {
//                 while(!window.BATO.Store.getState().stores.hasOwnProperty("status"))
//                     await new Promise(resolve => setTimeout(resolve, 250));
//                 if (window.BATO.Store.getState().stores.status === "known") {
//                     renderStoreCards();
//                 } else {
//                     storeCards.parentNode.removeChild(storeCards);
//                 }
//             })();
    
//             // Purchase Button Was Firing Before Redux Store Was Updated
//             // Wait for Previously Viewed Tire to Update before Rendering and Configuring Purchase Buttons ...
//             window.BATO.Store.observeStore(window.BATO.Store, function(state) {
//                 return state.flex.previouslyViewedTires;
//             }, renderStoreCards);
    
//             // Update 'Default' Store Cards on Tire Details Page when Updated from Store Locator Flyout
//             window.BATO.Store.observeStore(window.BATO.Store, function(state) {
//                 return state.stores.data.stores;
//             }, renderStoreCards);
    
//             // Set Initial previouslyViewedTires in Flex Slice of Redux Profile ONLY If Vehicle is Known
//             if(window.BATO.Store.getState().vehicles.status === 'unknown' && tireDataWrapper != null){
//                 //console.log("setViewedTires from tireDetail",tireDataWrapper.dataset);
//                 window.BATO.ProfileActions.setViewedTires({ ...tireDataWrapper.dataset });
//             }
    
    
    
    
    
//             const initTdpSeoSchema = () => {
    
//                 const data = document.querySelector(".tire-detail-datawrapper");
    
//                 let brand = data.dataset.productBrand || '',
//                     description = data.dataset.productDescription || '',
//                     images = Array.from(data.querySelectorAll(".media-gallery-item .image img")) || [],
//                     imageArray = [],
//                     price = data.dataset.msrp || '',
//                     name = data.dataset.productSubbrandName || '',
//                     /* Temporarily disabled until post launch
//                     ratingValue = data.querySelector(".average-rating")?.innerText  || '',
//                     reviewCount = data.querySelector(".review-count a")?.innerText.replaceAll(/[^0-9]/g,"")  || '',
//                     */
//                     size = data.dataset.productSize || '',
//                     sku = data.dataset.productId || '',
//                     url = window.location.href;
    
//                 images.forEach((img) => {
//                     let path = window.location.origin + img.getAttribute('src');
//                     imageArray.push(path);
//                 });
    
//                 let tdpSchemaObject = {
//                     "@context": "https://schema.org/",
//                     "@type": "Product",
//                     "name": `${name}`,
//                     "image": imageArray,
//                     "description": `${description}`,
//                     "sku": `${sku}`,
//                     "size": `${size}`,
//                     "brand": {
//                         "@type": "Brand",
//                         "name": `${brand}`
//                     },
//                     /* "aggregateRating": {
//                         "@type": "AggregateRating",
//                         "ratingValue": `${ratingValue}`,
//                         "reviewCount": `${reviewCount}`
//                     }, */
//                     "offers": {
//                         "@type": "Offer",
//                         "url": `${url}`,
//                         "priceCurrency": "USD",
//                         "price": `${price}`
//                     }
//                 };
    
//                 const script = document.createElement('script');
//                 script.setAttribute('type', 'application/ld+json');
//                 script.textContent = JSON.stringify(tdpSchemaObject);
//                 document.head.appendChild(script);
    
//             };
    
//             initTdpSeoSchema();
    
//             // vehicle-info logic
//             const vehicleInfoLogic = () => {
//                 const vehicles = window.BATO.Store.getState().vehicles;
//                 const vehicleInfoWrap = document.getElementsByClassName('tire-detail__vehicle-info')[0];
//                 const vehicleInfo = vehicleInfoWrap.getElementsByClassName('fitment')[0];
//                 const editIcon = vehicleInfoWrap.getElementsByClassName('icon-consumer-pencil')[0];
    
//                 //console.log("vehicle info element: ", vehicleInfo);
    
//                 if (vehicles.status === 'known') {
//                     // set anchor text to 'year make model'
//                     vehicleInfo.textContent = `${vehicles.vehicle?.year || ''}${' ' + vehicles.vehicle?.make || ''}${' ' + vehicles.vehicle?.model || ''}`;
//                     // add/keep .icon-consumer-pencil
//                     if (!editIcon) {
//                         const theIcon = document.createElement('i');
//                         theIcon.classList.add('icon-consumer-pencil');
//                         const theAnchor = document.querySelector('.tire-detail__vehicle-info a');
//                         theAnchor.appendChild(theIcon);
//                     }
//                 } else {
//                     // set Anchor text to 'Enter Your Vehicle'
//                     vehicleInfo.textContent = vehicleInfo.dataset.btnTxt;
//                     // remove .icon-consumer-pencil element
//                     if (editIcon) editIcon.remove();
//                 }
//             };
    
//             if (window.BATO?.Store) {
//                 window.BATO.Store.observeStore(window.BATO.Store, function (state) {
//                     return state.vehicles;
//                 }, vehicleInfoLogic);
    
//                 vehicleInfoLogic();
//             }
    
//         }

//     }

//     }
