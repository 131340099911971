const powerReviewsData = (formPackage) => {
  const blackbox = window.ioGetBlackbox().blackbox;
  const prPackage = {
    "iovation_black_box": blackbox,
    "fields": [
      {
        "id": "ag148419_msq81186",
        "field_type": "simple",
        "key": "caryear",
        "value": formPackage.caryear
      },
      {
        "id": "ag148420_msq81187",
        "field_type": "simple",
        "key": "carmake",
        "value": formPackage.carmake
      },
      {
        "id": "ag148421_msq81188",
        "field_type": "simple",
        "key": "carmodel",
        "value": formPackage.carmodel
      },
      {
        "id": "ag148401_msq81176",
        "field_type": "simple",
        "key": "milesdriveontires",
        "value": formPackage.milesdriveontires
      },
      {
        "id": "ag4",
        "field_type": "collection",
        "key": "pros",
        "values": formPackage.pros
      },
      {
        "id": "ag2",
        "field_type": "simple",
        "key": "rating",
        "value": parseInt(formPackage.rating)
      },
      {
        "id": "ag148396_msq81171",
        "field_type": "simple",
        "key": "traction",
        "value": parseInt(formPackage.traction)
      },
      {
        "id": "ag148395_msq81170",
        "field_type": "simple",
        "key": "durability",
        "value": parseInt(formPackage.durability)
      },
      {
        "id": "ag148394_msq81169",
        "field_type": "simple",
        "key": "comfort",
        "value": parseInt(formPackage.comfort)
      },
      {
        "id": "ag148397_msq81172",
        "field_type": "simple",
        "key": "value",
        "value": parseInt(formPackage.value)
      },
      {
        "id": "ag1",
        "field_type": "simple",
        "key": "headline",
        "value": formPackage.headline
      },
      {
        "id": "ag3",
        "field_type": "simple",
        "key": "comments",
        "value": formPackage.comments
      },
      {
        "id": "ag7",
        "field_type": "simple",
        "key": "name",
        "value": formPackage.name
      },
      {
        "id": "ag8",
        "field_type": "simple",
        "key": "location",
        "value": formPackage.location
      },
      {
        "id": "ag148441_msq81199",
        "field_type": "simple",
        "key": "state",
        "value": formPackage.state
      },
      {
        "field_type": "simple",
        "key": "email_collection",
        "value": formPackage.useremail
      }
    ]
  }

  return prPackage;
}

export default powerReviewsData;