/**
 * Remove Validation Error From Specific Form Element
 * @param  {HTMLElement}   formElement    The Form Element, Any valid Form Element (input, select, etc)
 * @param  {HTMLElement}   theForm    	  The Parent Form to Target
 */

const clearValidationErrors = (formElement, theForm) => {

	const clearErrors = (element) => {
		element.closest(".field").classList.remove("error");
		element.remove();
	}

	if(!formElement) {

		let validationEls = theForm.querySelectorAll("p.error");

		validationEls.forEach(item => {
			clearErrors(item);
		});

	} else {

		let validationEl = formElement.target.previousSibling;

		if(validationEl) {
			clearErrors(validationEl)
		}
	}
}

export { clearValidationErrors };
