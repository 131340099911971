import { matches } from './utilsForFrontend.js';

/**
 * Event Delegation
 * addEventListener to element for selectors within that element.
 */
const addBubblerListener = (el, evtName, selector, callback) => {
	try {
		if (el == null) {
			throw new Error('el is null');
		};

		if (!el) {
			throw new Error('el is missing');
		};

		if (!evtName) {
			throw new Error('evtName is missing');
		};

		if (!selector) {
			throw new Error('selector is missing');
		};

		if (!callback) {
			throw new Error('callback is missing');
		};

		el.addEventListener(evtName, (evt) => {
			if (matches(evt.target, selector)) {
					callback(evt);
			}
		});

	} catch (err) {
	    console.error(`Error in the addBubblerListener(): ${err}`);
	    return {
	      success: false,
	    }
	}
}

export default {
	addBubblerListener
}