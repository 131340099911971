import { postOptions } from '../../../../services/v1/js/util/apiUtils.js';

const postSync = async (options, errorString, profileId) => {
  try {
    // profile load/update sequence
    let qsVal = { country: '', lang_iso: '', site: '' };
    if (!window.BATO || !window.BATO.CONFIG) {
      throw new Error("The site must output a window.BATO.CONFIG object for the Profile functionality to consume");
    } else {
      const {
        country,
        lang_iso,
        site
      } = window.BATO.CONFIG;
      qsVal.country = country;
      qsVal.lang_iso = lang_iso;
      qsVal.site = site;
      if (profileId) qsVal.id = profileId;
    }
    const currentUrl = new URL(window.location.origin);
    let url = new URL(`${currentUrl}brgtrestwebservices/session/sync`);
    url.search = new URLSearchParams(qsVal);
    //console.log("POST OPTIONS: ", options);
    const response = await fetch(url.href, options);

    if (!response.ok) {
      const errorData = await response.json();
      const error = `${errorString}: ${errorData}`;
      throw new Error(error);
    }

    const data = await response.json();

    if (data) return data;

  } catch (err) {
    console.log(`there's been an error in the postSync() function: ${err}`);
    return {
      success: false,
    }
  }

}

export const updateProfile = async (profile) => {
  try {
    const options = postOptions(profile);
    const errorText = `there's been an issue updating the profile data from the server`;

    return await postSync(options, errorText);

  } catch (err) {
    console.log(`there's been an error in the updateProfile() section: ${err}`);
    return {
      success: false,
    }
  }
}

// no requirement for this as of yet
// export const updateFlex = (flexObject, profileId ) => {}

export const updateLocation = async (locationObject, profileId)  => {
  try {
    let opts = { profile: { location: locationObject } };
    // if (profileId) opts.profile.id = profileId;
    const options = postOptions(opts);
    const errorText = "there's been an issue updating the location";

    return await postSync(options, errorText, profileId);

  } catch (err) {
    console.log(`there's been an error in the updateLocation() function: ${err}`);
    return {
      success: false,
    }
  }


}

export const updateStore = async (storeObject, profileId) => {
  try {
    const store = { profile: { store: storeObject } }
    const options = postOptions(store);
    const errorText = `there's been an issue updating the store on the profile`;

    return await postSync(options, errorText, profileId);

  } catch (err) {
    console.log(`there's been an error in the updateStore() function: ${err}`);
    return {
      success: false,
    }
  }
}

export const updateLocationAndStore = async (locationObject, storeObject, profileId) => {
    try {

        const locationAndStore = { profile: { location: locationObject, store: storeObject } }
        const options = postOptions(locationAndStore);
        const errorText = `there's been an issue updating the location and store on the profile`;

        return await postSync(options, errorText, profileId);

    } catch (err) {
        console.log(`there's been an error in the updateLocationAndStore() function: ${err}`);
        return {
            success: false,
        }
    }
}

export const updateTiresOnVehicle = async (tiresObject, profileId) => {
  try {
    const products = { profile: {
      products: {
        tireByVehicle0: {
          ftire: tiresObject.ftire,
          ...(tiresObject.hasOwnProperty('rtire') && { rtire: tiresObject.rtire }),
        }
      }
    }}

    const options = postOptions({ products });
    const errorText = `there's been an issue adding tires to the profile`;

    return postSync(options, errorText, profileId);

  } catch (err) {
    console.log(`there's been an error in the updateTires() function: ${err}`);
    return {
      success: false,
    }
  }
}

export const updateTiresBySize = async (tiresObject, profileId) => {
  try {
    const products = {
      profile: {
        products: {
          tireBySize0: {
            ftire: tiresObject.ftire,
            ...(tiresObject.hasOwnProperty('rtire') && { rtire: tiresObject.rtire }),
          }
        }
      }
    }

    const options = postOptions(products);
    const errorText = `there's been an issue adding tires by size to the profile`;

    return postSync(options, errorText, profileId);

  } catch (err) {
    console.log(`there's been an error in the updateTiresBySize() function: ${err}`);
    return {
      success: false,
    }
  }
}

export const updateVehicle = async (vehicleObject, profileId) => {
  try {
    const products = {
      profile: {
        products: {
          tireByVehicle0: {
            vehicle: vehicleObject,
          }
        }
      }
    }
    const options = postOptions(products);
    const errorText = `there's been an issue updating the vehicle on the profile`;

    return postSync(options, errorText, profileId);

  } catch (err) {
    console.log(`there's been an error in the updateVehicle() function: ${err}`);
    return {
      success: false,
    }
  }
}

export const getProfileById = async (id) => {
  try{
    let params = {site,country,lang_iso};
    if (!id) throw new Error(`You must prove an 'id' value to getProfileById()`)
    const currentUrl = new URL(window.location.origin);
    let url = new URL(`${currentUrl}brgtrestwebservices/session/profile-by-id`);
    url.search = new URLSearchParams(params);
    console.log("URL: ", url);
    const response = await fetch(url.href, {credentials: 'include'});
    if (!response.ok) {
      const errorData = await response.json();
      const error = `${errorString}: ${errorData}`;
      throw new Error(error);
    }
    const data = await response.json();
    if (data) return { success: true, data };
  } catch (err) {
    console.log(`there's been an error in the getProfileById() function: ${err}`);
    return {
      success: false,
    }
  }
}

export default {
  getProfileById,
  // updateFlex,
  updateLocation,
  updateProfile,
  updateStore,
  updateLocationAndStore,
  updateTiresOnVehicle,
  updateTiresBySize,
  updateVehicle
}